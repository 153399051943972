import _ from "lodash"
import { i18n } from "@/utils/i18n"
import { partyTypes } from "../constants"

const listRouteMixin = {
  data () {
    return {
      initPage: 1
    }
  },
  methods: {
    applyFilters (field, value) {
      const { query, name } = this.$route
      const queryParams = { ...query, [field]: value }

      for(let item in queryParams) {
        let nullValues = [null, undefined, ""]
        if (nullValues.includes(queryParams[item])) delete queryParams[item]
      }

      let page = this.initPage || 1

      this.$router.replace({
        name,
        query: { ...queryParams, page }
      }).catch(() => {})
    },
    onPagination (page) {
      const { name, params, query } = this.$route
      // ideally we shouldn't pass context in params
      // this hack is done here to prevent duplicate api requests
      // should be revisited when a proper fix is found
      params.paginated = true
      this.$router.push({ name, params, query: { ...query, page } })
        .catch(() => {})
    },
    clearRoute () {
      const { name } = this.$route

      Object.keys(this.filters).forEach((key) =>
        this.filters[key] = null
      )

      this.$router.replace({
        name,
        query: {},
      }).catch(() => {})
    },
    setFilters () {
      const { query } = this.$route

      Object.entries(query).forEach(([key, value]) => {
        const camelCaseKey = _.camelCase(key)
        this.filters[camelCaseKey] = value
      })
    },
  },
  watch: {
    $route (to) {
      if (!to.params.paginated) this.getData()
    },
  },
  mounted () {
    let page = this.$route.query.page
    this.initPage = page ? parseInt(page) : 1
  },
  updated () {
    this.initPage = 1
  }
}

const landingWorkTransformations = {
  methods: {
    getPartyShare (party, chains) {
      const isContributor = party.type === partyTypes.CONTRIBUTOR
      const name = isContributor ? `${party.firstName} ${party.lastName}` : party.name

      const relatedPIDs = isContributor ?
        chains.filter(chain => chain.contributor === party.proprietaryId).map(chain => chain.publisher) :
        chains.filter(chain => chain.publisher === party.proprietaryId).map(chain => chain.contributor)
      return {
        id: party.proprietaryId,
        party: {
          name: name,
          type: party.type,
          ipiNameNumber: party.ipiName,
          affiliations: party.affiliations,
        },
        role: party.role,
        mechanical: party.ownershipShares.mrShare,
        performing: party.ownershipShares.prShare,
        chains: relatedPIDs
      }
    },
    getShares (normalizedData) {
      let { chains, contributors, publishers } = normalizedData
      contributors = contributors.map(c => ({ ...c, type: partyTypes.CONTRIBUTOR }))
      publishers = publishers.map(p => ({ ...p, type: partyTypes.PUBLISHER }))

      return { original: [...contributors, ...publishers].map(party => this.getPartyShare(party, chains)) }
    },
    transformLandingWork (landingWork) {
      let work = landingWork?.normalizedData || {}
      work.id = landingWork.id
      work.language = this.getLanguage(work.language)
      work.titles = work.alternativeTitles
      work.versionType = work.type.versionType
      work.arrangement = work.type.arrangement
      work.adaptation = work.type.adaptation
      work.iswcs = work.iswcs && Array.isArray(work.iswcs) ? work.iswcs.map((value, id) => ({ value, id })) : []
      if(work.iswc) work.iswcs.push({ id: work.iswcs.length, value: work.iswc, preferred: true })
      return work
    }
  },
}

const selectionMixin = {
  methods: {
    toggleSelection () {
      this.viewingSelection = !this.viewingSelection
      this.getData()
    },
    clearSelection () {
      this.viewingSelection = false
      this.selectedData = []
      this.getData()
    },
    filterSelectionItems () {
      let responseData = {
        results: this.selectedData,
        count: this.selectedData.length,
      }

      let selectionData = new Promise((res, rej) => {
        res({
          data: responseData,
        })
        rej(i18n.t("nothing_has_been_selected"))
      })
      return selectionData
    },
  }
}

const fomatterMixin = {
  methods: {
    setCorrectTextCursorPosition (event) {
      const {
        target: {
          selectionEnd,
          selectionStart
        }
      } = event

      // This sets the input cursor in the right place (and not at the end as it is by default)
      setTimeout(() => {
        try {
          event.target.selectionStart = selectionStart
          event.target.selectionEnd = selectionEnd
        } catch {
          // NOTE:
          // Some elements do not have the selectionStart or selectionEnd properties,
          // or cannot be set. In those cases, we catch and ignore the error.
        }
      })
    }
  }
}

export {
  fomatterMixin,
  landingWorkTransformations,
  listRouteMixin,
  selectionMixin,
}
