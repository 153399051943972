<template>
  <div
    class="d-flex align-items-center mr-3"
    :class="{ clickable }"
    @click="$emit('click', name )"
  >
    <div class="icon mr-1">
      <fa-icon v-if="!!icon" class="icon" size="sm" :class="color" :icon="icon"/>
    </div>

    <div class="text" :class="{ active }">
      <span v-if="showCount()" class="count mr-2">{{ count | formatNumber }}</span>
      <span>{{ text | capitalize }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .green {
    color: var(--green);
  }

  .orange {
    color: var(--orange);
  }

  .red {
    color: var(--red);
  }

  .info {
    color: var(--info);
  }

  .gray {
    color: var(--input-gray);
  }

  .icon {
    min-width: 20px;
  }

  .text {
    color: var(--gray);
    font-size: 0.8rem;
    font-weight: 300;
    letter-spacing: 0.2px;
  }

  .count {
    min-width: 15px;
    text-align: right;
    display: inline-block;
  }

  .clickable {
    cursor: pointer;
  }

  .active {
    span {
      color: var(--primary);
      font-weight: 600;
    }
  }
</style>

<script>
import { isNumber } from "lodash"

export default {
  name: "Status",
  props: {
    text: String,
    name: [String, Number],
    count: [String, Number],
    icon: Array,
    color: String,
    clickable: Boolean,
    active: Boolean,
  },
  methods: {
    showCount () {
      return isNumber(this.count)
    }
  }
}
</script>
