import { API } from "./utils"


export const products = {
  productsList: (params=null) => API.get("/products/", { params }),
  productsRetrieve: (id) => API.get(`/products/${id}/`),
  productsWorksList: (id) => API.get(`/products/${id}/works/`),
  productsLinkWorks: (id, data) => API.post(`/products/${id}/works/add/`, data),
  productsUnlinkWorks: (id, workId, data) => API.post(`/products/${id}/works/${workId}/remove/`, data),
}
