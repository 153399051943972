<template>
  <b-row>
    <!-- Work -->
    <b-col :cols="workColumns" class="px-1 pt-2">
      <work-detail :id="id" :work="work" :shares="shares" lite>
        <template v-slot:heading>
          <div class="d-flex justify-content-between mb-3">
            <h3>
              {{ $t("work_matching") | capitalize }}
              <b-badge :variant="badgeVariant">{{ getStatusDisplay(userAction.status) | capitalize }}</b-badge>
            </h3>
            <div v-if="userAction.status !== statuses.DONE">
              <action-button
                @click="createWork"
              >
                {{ $t("create_work") | capitalize }}
              </action-button>
            </div>
          </div>
        </template>
        <template v-slot:subHeading>
          <div class="d-flex justify-content-between mb-3">
            <router-link
              v-if="userAction.status === statuses.DONE && landingWork.work"
              :to="{ name: 'works-detail', params: { id: landingWork.work }}"
              class="text-info"
            >
              {{ $t("go_to_work") | capitalize }}
            </router-link>
            <div v-else>
              <div class="font-weight-bolder">{{ $t("incoming_work") | capitalize }}</div>
              <div
                v-for="file in landingWork.repertoireFiles"
                :key="file.id"
              >
                <router-link
                  :to="{ name: 'landing-works-list', params: { id: file.id }}"
                  class="text-info"
                >
                  {{ file.filename }}
                </router-link>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:extra>
          <div>
            <h3>{{ $t("source") | startcase }}</h3>
            <template v-if="fileSourceTypes.includes(landingWork.sourceType)">
              <router-link
                v-if="landingWork.canView"
                class="text-info"
                :to="{ name: 'landing-works-list', params: { id: landingWork.repertoireFile.id } }"
              >
                {{ landingWork.repertoireFile.filename }}
              </router-link>
              <span v-else>
                {{ landingWork.repertoireFile.filename }}
              </span>
              ({{ getWorkSourceTypesDisplay(landingWork.sourceType) }})
              <date-time-cell :dateTime="landingWork.repertoireFile.uploadDate"/>
            </template>
            <!-- Other Source Types -->
            <template v-else>
              {{ getWorkSourceTypesDisplay(landingWork.sourceType) }}
            </template>
          </div>
        </template>
      </work-detail>
    </b-col>

    <!-- Candidates -->
    <b-col cols="6" class="px-0 mt-2" v-if="userAction.status !== statuses.DONE">
      <div class="pl-2">
        <!-- Actions -->
        <div class="d-flex justify-content-start" v-if="userAction.status !== statuses.DONE">
          <!-- Search Input -->
          <b-form-input
            :placeholder="$t('search_existing_works')"
            v-model="searchInput"
            debounce="1000"
            class="w-auto"
            @update="setSearchTab()"
          />
        </div>

        <!-- Tabs -->
        <tabs-layout
          ref="tabs"
          :tabs="tabs"
          v-model="tabIndex"
          @change="onTabChange"
        >
          <!-- Tab Title -->
          <template v-slot:title="{ tab }">
            <span>{{ tab.title }}</span>
          </template>

          <!-- Tab Content -->
          <template v-slot:content>
            <!-- Candidate Tabs -->
            <Candidate
              :work="selectedCandidate"
              :shares="selectedCandidateShares"
              @match="matchWork"
              class="ml-2"
            />
          </template>

          <!-- Search Results Tab -->
          <template v-slot:static-tabs>
            <b-tab
              :title="$t('search_results')"
              :disabled="!searchInput"
              ref="search"
              class="p-0"
            >
              <Candidate
                v-if="resultSelected && selectedCandidate"
                :work="selectedCandidate"
                :shares="selectedCandidateShares"
                @match="matchWork"
                @resetSelectedResult="onResetSelectedResult"
                isSearchResult
                class="ml-2"
              />
              <WorksMatchingWorksList @select="setSelectedResult" v-else />
            </b-tab>
          </template>
        </tabs-layout>
      </div>
    </b-col>
  </b-row>
</template>

<style lang="scss" scoped>
  ::v-deep .table {
    font-size: 0.9rem;
    max-width: 98%;
  }
</style>

<script>
import { getUserActionStatusDisplay, getWorkSourceTypesDisplay, userActionStatuses, workSourceTypes } from "@/constants"
import { mapMutations, mapState } from "vuex"
import Candidate from "./Candidate"
import WorksMatchingWorksList from "./WorksMatchingWorksList"
import { get } from "lodash"
import { landingWorkTransformations } from "@/utils/mixins"

export default {
  name: "WorksMatchingDetail",
  mixins: [landingWorkTransformations],
  components: {
    Candidate,
    WorksMatchingWorksList,
  },
  data () {
    return {
      tabIndex: 0,
      userAction: {},
      statuses: userActionStatuses,
      landingWork: {},
      work: { titles: [], chains: [] },
      shares: { original: [], distribution: [] },
      selectedCandidateShares: { original: [], distribution: [] },
      candidates: [],
      selectedCandidate: null,
      resultSelected: false,
    }
  },
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    ...mapMutations("consts", ["setLanguages"]),
    ...mapMutations("filters", ["setSearch"]),
    getLanguage (code) {
      let language = this.languages.filter(lang => lang.code === code).pop()
      return language?.name
    },
    getCandidateShares (id) {
      this.$api.repertoire.worksSharesList(id)
        .then(response => {
          this.selectedCandidateShares = { ...response.data }
        })
    },
    getStatusDisplay (status) {
      return getUserActionStatusDisplay(status)
    },
    getWorkSourceTypesDisplay,
    async setSelectedResult (result) {
      this.resultSelected = true
      let response = await this.$api.repertoire.worksRetrieve(result)
      this.selectedCandidate = response.data
    },
    onResetSelectedResult () {
      this.resultSelected = false
      this.selectedCandidateShares = { original: [], distribution: [] }
    },
    createWork () {
      this.$api.userActions.worksMatchingCreateWork(this.userAction.id).then(() => {
        this.success(this.$t("work_successfully_created"))
        this.refreshData()
      })
    },
    matchWork (workAtlasId) {
      this.$api.userActions.worksMatchingSelectWork(this.userAction.id, { workAtlasId })
        .then(() => {
          this.success(this.$t("candidate_matched"))
          this.refreshData()
        }).catch(err => {
          this.error(err.response.data.detail)
        })
    },
    refreshData () {
      this.$api.userActions.worksMatchingRetrieve(this.id).then((response) => {
        this.userAction = response.data

        this.$api.userActions.relatedObjectRetrieve(this.userAction.relatedObject).then((response) => {
          this.landingWork = response.data
        })
      })
    },
    onTabChange ({ tab }) {
      this.resultSelected = false
      this.selectedCandidate = get(tab, "candidate") ? tab.candidate : null
    },
    setSearchTab () {
      // This timeout prevents from the tab being activated before it is enabled
      setTimeout(() => this.$refs.search.activate(), 100)
    },
  },
  computed: {
    ...mapState("filters", ["search"]),
    ...mapState("consts", ["languages"]),
    fileSourceTypes () { return [workSourceTypes.CWR_V2_1, workSourceTypes.CWR_V2_2, workSourceTypes.ATLAS_SWR_V0_1] },
    badgeVariant () {
      return this.userAction.status == this.statuses.DONE ? "success" : "warning"
    },
    workColumns () {
      return this.userAction.status !== this.statuses.DONE ? "6" : "12"
    },
    contributors () {
      let { contributors } = this.landingWork.normalizedData
      return contributors
    },
    publishers () {
      let { publishers } = this.landingWork.normalizedData
      return publishers
    },
    searchInput: {
      get () {
        return this.search
      },
      set (value) {
        this.setSearch(value)
      }
    },
    tabs () {
      let tabs = this.candidates.map((candidate, index) => ({
        title: `${this.$t("candidate")} ${index + 1}`,
        candidate: candidate,
      }))
      return tabs
    },
  },
  watch: {
    async $route (params) {
      if (params.name === "actions-works-matching-detail") {
        let response = await this.$api.userActions.worksMatchingRetrieve(this.id)
        this.userAction = response.data
      }
    },
    selectedCandidate (work) {
      if (work) this.getCandidateShares(work.id)
    },
  },
  props: {
    id: [String, Number],
  },
  async mounted () {
    let response = await this.$api.countries.languagesList()
    this.setLanguages(response.data)

    response = await this.$api.userActions.worksMatchingRetrieve(this.id)
    this.userAction = response.data

    response = await this.$api.userActions.relatedObjectRetrieve(response.data.relatedObject)
    this.landingWork = response.data
    this.work = this.transformLandingWork(this.landingWork)
    this.shares = this.getShares(this.work)

    this.candidates = get(this.userAction, "candidates", [])

    if (this.candidates.length) this.selectedCandidate = this.candidates[0]
  }
}
</script>
