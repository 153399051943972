<template>
  <div>
    <!-- Add Heir -->
    <div
      class="p-2 d-flex justify-content-end"
      v-if="isAdmin"
    >
      <b-button
        size="md"
        variant="success"
        v-b-modal.heirs-modal
      >
        {{ $t("add_heir") | startcase }}
      </b-button>
    </div>

    <v-server-table
      name="heirsList"
      ref="heirsList"
      :columns="columns"
      :options="options"
       @loaded="onLoaded"
    >
      <!-- Actions -->
      <template v-slot:actions="{ row }" v-if="isAdmin">
        <div class="d-flex justify-content-end">
          <action
            :title="$t('edit')"
            v-if="isAdmin"
            @click="onEdit(row)"
            :icon="['fas', 'pen']"
          />
          <action
            :title="$t('delete')"
            v-if="isAdmin"
            @click="onDelete(row)"
            :icon="['fas', 'trash']"
          />
        </div>
      </template>
    </v-server-table>

    <!-- Create/Update Modal -->
    <b-modal
      id="heirs-modal"
      :title="$t('add_heir') | startcase"
      @hidden="onHidden"
      hide-footer
    >
      <HeirForm
        name="heir-form"
        :partyId="this.id"
        :heir="currentHeir"
        @success="onSuccess"
      />
    </b-modal>
    <!-- Delete Modal -->
    <modal
      id="heirs-delete-modal"
      :title="$t('delete_heir')"
      :okTitle="$t('yes') | capitalize"
      :cancelTitle="$t('no') | capitalize"
      @ok="onConfirmDelete"
      @hidden="onHidden"
    >
      <div>{{ $t("confirm_delete_heir") }}?</div>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import HeirForm from "./HeirForm"
import { startCase } from "lodash"

export default {
  name: "Heirs",
  data () {
    return {
      count: 0,
      currentHeir: null,
      columns: [
        "contactName",
        "split",
        "inheritanceDate",
        "primaryEmail",
        "secondaryEmail",
        "address",
        "primaryPhone",
        "secondaryPhone",
        "actions"
      ],
      options: {
        headings: {
          split: startCase(this.$t("split")),
          inheritance_date: startCase(this.$t("inheritance_date")),
          contactName: startCase(this.$t("contact_name")),
          primaryPhone: startCase(this.$t("primary_phone")),
          secondaryPhone: startCase(this.$t("secondary_phone")),
          address: startCase(this.$t("address")),
          primaryEmail: startCase(this.$t("primary_email")),
          secondaryEmail: startCase(this.$t("secondary_email")),
          actions: "",
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction () {
          const id = this.$parent.$parent.id
          return this.$api.parties.heirsList(id)
        }
      }
    }
  },
  components: {
    HeirForm
  },
  methods: {
    ...mapActions("alert", ["success", "error"]),
    getData () {
      this.$refs.heirsList.getData()
    },
    onLoaded ({ data }) {
      this.count = data.count
    },
    onHidden () {
      this.currentHeir = null
    },
    onSuccess (message) {
      this.success(message)
      this.getData()
      this.$bvModal.hide("heirs-modal")
    },
    onEdit (heir) {
      this.currentHeir = heir
      this.$bvModal.show("heirs-modal")
    },
    onDelete (heir) {
      this.currentHeir = { ...heir }
      this.$bvModal.show("heirs-delete-modal")
    },
    onConfirmDelete () {
      this.$api.parties.heirsDelete(this.id, this.currentHeir.id)
        .then(() => {
          this.getData()
          this.success(this.$t("heir_deleted"))
        })
    },
  },
  watch: {
    count (value) {
      this.$emit("count", value)
    }
  },
  computed: {
    ...mapGetters("user", ["isAdmin"]),
  },
  mounted () {
  },
  props: {
    id: String,
  },
}
</script>
