<template>
  <b-nav-item-dropdown>
    <template v-slot:button-content>
      <span v-if="currentLanguage" class="text-white mr-2">{{ currentLanguage.name | capitalize }}</span>
    </template>
    <b-dropdown-item
      @click="onClick(language)"
      v-for="language in languages"
      :key="language.code"
    >
      {{ language.name | capitalize }}
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { mapActions, mapState } from "vuex"

export default {
  name: "LanguageSelector",
  data () {
    return {
      languages: [
        {
          name: "english", //Language names are not translated
          code: "en"
        },
        {
          name: "thai",
          code: "th"
        },
        {
          name: "indonesian",
          code: "in"
        },
        {
          name: "filipino",
          code: "tl"
        },
      ]
    }
  },
  methods: {
    ...mapActions("user", ["setLanguage"]),
    onClick: function (language) {
      if (!language) return

      this.$api.auth.setLanguage(language.code)
      this.setLanguage(language.code)
    },
    getLanguage (language) {
      return this.languages.find((lang) => lang.code === language)
    }
  },
  computed: {
    ...mapState("user", ["user"]),
    currentLanguage () {
      return this.getLanguage(this.user.language)
    }
  },
}
</script>
