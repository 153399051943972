<template>
  <div>
    <h2 class="text-center font-weight-bold pb-2">{{ $t("login") }}</h2>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)" >
        <form-field
          name="email"
          :label="$t('email')"
          type="email"
          rules="required|email"
          autocomplete="username"
          v-model="form.email"
          @input="resetError"
        />
        <form-field
          name="password"
          :label="$t('password')"
          type="password"
          rules="required"
          autocomplete="current-password"
          v-model="form.password"
        />
        <div class="d-flex justify-content-end mb-2">
          <router-link :to="{ name: 'reset-password' }" class="text-info">{{ $t("reset_password") }}</router-link>
        </div>
        <b-form-invalid-feedback class="text-center mb-2" :state="valid">{{ error }}</b-form-invalid-feedback>
        <submit-button class="btn-block" variant="secondary">{{ $t("login") }}</submit-button>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate"
import { get } from "lodash"
import { mapActions } from "vuex"

export default {
  name: "LoginForm",
  components: {
    ValidationObserver,
  },
  data () {
    return {
      form: {
        email: "",
        password: ""
      },
      valid: true,
      error: ""
    }
  },
  methods: {
    ...mapActions("user", ["setUser"]),
    resetError () {
      this.valid = true
      this.error = ""
    },
    async onSubmit () {
      try {
        // Set the session
        let response = await this.$api.auth.token(this.form.email, this.form.password)
        this.$auth.setSession(response.data)

        // Fetch and set user
        response = await this.$api.auth.getUser()
        this.setUser(response.data)

        // Redirect after login, either to the path specified in the redirect query
        // or to the works list page.
        let nextRoute = get(this.$route.query, "redirect", { name: "works-list" })
        this.$router.replace(nextRoute)
      } catch (error) {
        this.valid = false
        this.error = error.response.data.message
      }

    }
  },
}
</script>
