<template>
  <div>
    <work-detail :id="id" :work="work" :shares="shares" inSingleView>
      <template v-slot:heading>
        <div class="d-flex justify-content-between mb-2">
          <h3>{{ work.title }}</h3>
          <div>
            <b-button
              variant="outline-secondary"
              class="ml-2"
              @click="goBack"
            >
               <fa-icon class="icon" size="sm" :icon="['fas', 'arrow-left']"/> {{ $t("back") | capitalize }}
            </b-button>
            <b-button
              v-if="work.canEdit"
              variant="outline-secondary"
              @click="goToEdit()"
              class="ml-2"
            >
              {{ $t("edit_work") | startcase }}
            </b-button>
          </div>
        </div>
      </template>
    </work-detail>
    <b-row class="my-3">
      <b-col class="d-flex justify-content-end">
        <b-button
          v-if="work.canEdit && isSociety"
          variant="outline-secondary"
          @click="onDelete"
        >
          {{ $t("delete_work") | startcase }}
        </b-button>
      </b-col>
    </b-row>
    <modal
      :id="'confirmation-modal-' + id"
      :title="$t('delete_work') | startcase"
      :okTitle="$t('yes') | capitalize"
      :cancelTitle="$t('no') | capitalize"
      @ok="onConfirmDelete"
    >
      <div>{{ $t("confirm_delete_works") | capitalize }}?</div>
    </modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex"
import { EventBus } from "@/utils/event-bus.js"
import { get } from "lodash"

export default {
  name: "WorksDetail",
  data () {
    return {
      work: { titles: [], chains: [] },
      shares: { original: [], distribution: [] },
      showDistribution: false,
    }
  },
  computed: {
    ...mapState("consts", ["languagesHash"]),
    ...mapGetters("user", ["isSociety"]),
  },
  methods: {
    ...mapMutations("alert", ["error", "success"]),
    ...mapMutations("consts", ["setLanguages"]),
    resetWork (id) {
      if (id === this.id) this.getWork()
    },
    onDelete () {
      this.$bvModal.show(`confirmation-modal-${this.id}`)
    },
    onConfirmDelete () {
      this.$api.repertoire.worksDelete(this.id)
        .then(() => {
          this.success(this.$t("work_successfully_deleted"))
          this.$router.push({ name: "works-list" })
        })
        .catch(err => this.error(err.response.data))
    },
    getWork () {
      this.$api.repertoire.worksRetrieve(this.id)
        .then(response => {
          this.work = { ...response.data }
          this.work.language = this.getLanguage(this.work.language)
        })
        .catch(err => {
          this.error(err.response.data)
        })
    },
    getShares () {
      // Perform the call to get the shares
      this.$api.repertoire.worksSharesList(this.id)
        .then(response => {
          this.shares = response.data
        })
    },
    getLanguage (language) {
      return get(this.languagesHash, language, { name: "" }).name
    },
    goBack () {
      this.$router.back()
    },
    goToEdit () {
      this.$router.replace({ name: "works-edit" })
        .catch(() => {})
    },
    refresh (id) {
      if (id === this.id) {
        this.getWork()
        this.getShares()
      }
    },
  },
  watch: {
    id () {
      this.getWork()
    }
  },
  mounted () {
    // Set event listeners
    EventBus.$on("reset-work", this.resetWork)
    EventBus.$on("update-accepted", this.refresh)

    this.$api.countries.languagesList().then(response => {
      this.setLanguages(response.data)
    })

    // Get Data
    this.getWork()
    this.getShares()
  },
  beforeDestroy () {
    // Unset event listeners
    EventBus.$off("reset-work", this.resetWork)
    EventBus.$off("update-accepted", this.refresh)
  },
  props: {
    id: [String, Number],
  }
}
</script>
