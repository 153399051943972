<template>
  <b-row class="my-4">
    <b-col>
      <div class="d-flex align-items-center">
        <span class="rule-badge">
          <b-badge class="mr-2 my-3 text-white" pill>{{ level }}</b-badge>
        </span>
        <span>{{ $t("rule") | capitalize }}</span>
      </div>
      <b-form-radio-group
        v-model="selected"
        :options="options"
        button-variant="outline-gray"
        name="distributionPoolRulesLevelOne"
        buttons
        class="level"
        @input="onInput"
      ></b-form-radio-group>
    </b-col>
  </b-row>
</template>

<style lang="scss" scoped>
  ::v-deep .btn-outline-gray {
    color: $gray-darker !important;
    border-color: $gray-dark !important;

    &:hover,
    &.active {
      color: $info !important;
      border-color: $info !important;
      background-color: var(--info-light) !important;
    }
  }

  .rule-badge {
    font-size: 1.4rem;
  }

  ::v-deep .level {
    .btn {
      margin-right: 10px;
      border-radius: 0.25rem !important;
    }
  }
</style>

<script>
export default {
  name: "PoolRulesLevel",
  data () {
    return {
      selected: null,
    }
  },
  methods: {
    onInput () {
      this.$emit("selected", this.selected)
    },
    updateValue () {
      this.selected = this.value
    }
  },
  watch: {
    value (selected) {
      this.selected = selected
    }
  },
  mounted () {
    this.updateValue()
  },
  props: {
    level: Number,
    value: String,
    options: {
      type: Array,
      default: () => []
    },
  }
}
</script>
