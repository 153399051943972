<template>
  <div class="work-details-container">
    <!-- Top row -->
    <b-row class="p-3">
      <!-- Original title -->
      <b-col cols="3">
        <p class="text-gray">{{ $t("original_title") | startcase }}</p>
        <p class="font-weight-bolder">{{ work.title }}</p>
      </b-col>

      <!-- Work ID -->
      <b-col v-if="inSingleView">
        <p class="text-gray">Atlas ID</p>
        <p>{{ work.id }}</p>
      </b-col>

      <!-- Landing Work Proprietary ID -->
      <b-col v-else>
        <p class="text-gray">{{ $t("proprietary_id") | startcase }}</p>
        <p>{{ work.proprietaryId }}</p>
      </b-col>


      <!-- Work Codes -->
      <b-col>
        <p class="text-gray">{{ $t("work_codes") | startcase }}</p>
        <p v-for="(code) in work.codes" :key="code.id" class="mb-0">
          <span>{{ code.type.toUpperCase() }}</span> - <span>{{ code.value }}</span>
        </p>
      </b-col>

      <!-- Language -->
      <b-col>
        <p class="text-gray">{{ $t("language") | startcase }}</p>
        <p>{{ work.language }}</p>
      </b-col>

      <!-- Is Public Domain -->
      <b-col class="text-secondary" v-if="work.isPublicDomain">
        <fa-icon :icon="['fab', 'creative-commons-pd']" size="lg" />
        <p>{{ $t("copyright_free") | startcase }} - {{ $t("public_domain") }}</p>
      </b-col>
    </b-row>

    <!-- Bottom row -->
    <b-row class="p-3 mb-2">
      <!-- Alternative titles -->
      <b-col cols="3">
        <p class="text-gray">{{ $t("alternate_titles") | startcase }}</p>
        <p class="mb-0" v-for="title in titleVariants" :key="title.id">
          <span
            v-b-tooltip.hover.topleft
            :title="workTitleTypesVerbose[title.type] | startcase"
          >
            {{ title.type }}
          </span>
          <span class="text-gray ml-2">
            {{ title.title }}
          </span>
        </p>
        <span class="text-info clickable" v-if="titlesAreTruncated" @click="toggleAlternateTitles"> {{ collapseAlternateTitles ? $t("less") : $t("more") }}</span>
      </b-col>

      <!-- ISWCs -->
      <b-col>
        <p class="text-gray">{{ work.iswcs && (work.iswcs.length > 1) ? $t("iswc") + 's': $t("iswc") }}</p>
        <p v-for="(iswc) in work.iswcs" :key="iswc.id" class="mb-0">
          <span>{{ iswc.value }}</span><span v-if="work.iswcs && (work.iswcs.length > 1) && iswc.preferred" class="ml-2 text-gray">- {{ $t("preferred") }}</span>
        </p>
      </b-col>

      <b-col></b-col>
      <b-col></b-col>
      <b-col v-if="work.isPublicDomain"></b-col>
    </b-row>
  </div>
</template>

<style lang="scss" scoped>
  .clickable {
    cursor: pointer;
  }
</style>

<script>
import { workTitleTypes, workTitleTypesVerbose } from "@/constants"

export default {
  name: "Metadata",
  data () {
    return {
      workTitleTypesVerbose,
      titlesAreTruncated: false,
      collapseAlternateTitles: false,
      titleVariants: [],
    }
  },
  methods: {
    getTitleVariants () {
      const maxTitles = 4
      let titles = []
      if (this.work.titles) titles = this.work.titles.filter(title => title.type !== workTitleTypes.OT)
      if (titles.length > maxTitles && !this.collapseAlternateTitles) {
        titles = titles.slice(0, maxTitles)
        this.titlesAreTruncated = true
      }
      this.titleVariants = titles
    },
    toggleAlternateTitles () {
      this.collapseAlternateTitles = !this.collapseAlternateTitles
    },
  },
  watch: {
    work () {
      this.getTitleVariants()
    },
    collapseAlternateTitles () {
      this.getTitleVariants()
    },
  },
  mounted () {},
  props: {
    work: Object,
    inSingleView: {
      type: Boolean,
      default: false
    },
  }
}
</script>