<template>
  <div>
    <ValidationObserver ref="workForm" v-slot="{ handleSubmit }" vid="detail" @input="resetFormError()">
      <b-form @submit.prevent="handleSubmit(onClickSubmit)">
        <div class="d-flex justify-content-between mb-3">
          <h3>{{ form.title }}</h3>
          <div>
            <b-button
              class="mr-2"
              variant="outline-secondary"
              @click="onCancel"
            >
              {{ $t("cancel") | startcase }}
            </b-button>
            <submit-button variant="secondary">
              {{ submitButtonText | startcase }}
            </submit-button>
          </div>
        </div>

        <div class="work-details-container">
          <b-row class="px-2">
            <b-col :cols="isWorkEdition ? '4' : '5'" class="px-3">
              <!-- Original Title -->
              <b-row class="top-label mb-2">
                 <b-col cols="6">
                  {{ $t('work_title') | startcase }}: <span class="text-danger">*</span>
                </b-col>
                <b-col cols="6" class="text-right">
                  <a
                    href="#"
                    class="text-info mt-2 title-add-link"
                    @click.prevent="addTitle"
                  >
                    {{ $t("add_title") | startcase }}
                  </a>
                </b-col>
              </b-row>
              <b-row>
                 <b-col cols="12">
                  <form-field
                    name="title"
                    type="text"
                    rules="required"
                    v-model="form.originalTitle.title"
                    upper
                  />
                </b-col>
              </b-row>

              <!-- Title Variants -->
              <template v-for="(title, index) in form.titles">
                <b-row class="mb-3" :key="index" v-if="!title.action">
                  <b-col cols="11" class="pr-0">
                    <div id="work-titles">
                      <WorkTitle
                        :title="{ ...title, index}"
                        @input="onInputTitle"
                      />
                    </div>
                  </b-col>
                  <b-col cols="1">
                    <action
                      :title="$t('delete')"
                      :icon="['fas', 'trash']"
                      color="red"
                      class="m-0 p-0 pt-1 mt-2 pr-2"
                      @click="onRemoveTitle(index)"
                    />
                  </b-col>
                </b-row>
              </template>
            </b-col>

            <!-- ISWCs -->
            <b-col v-if="isSociety" :cols="isWorkEdition ? '3' : '3'" class="px-3">
              <b-row class="top-label mb-2">
                <b-col cols="6" class="text-left">{{ $t("iswc") }}:</b-col>
                 <b-col cols="6" class="text-right">
                  <a
                    href="#"
                    @click.prevent="addIswc"
                    class="text-info mt-1 iswc-add-link"
                  >
                    {{ $t("add") | startcase }} {{ $t("iswc") }}
                  </a>
                 </b-col>
              </b-row>
              <b-row v-for="(iswc, index) in form.iswcs" :key="`${index}`">
                <b-col cols="12">
                  <b-row v-if="iswc.action !== workFieldActionTypes.REMOVE">
                    <b-col cols="10" class="mb-0">
                      <form-field
                        :name="`iswc-${index}`"
                        type="text"
                        :value="iswc.value"
                        rules="iswc"
                        upper
                        @input="onInputIswc($event, iswc, index)"
                        :helpText="preferredIswc(iswc)"
                      />
                    </b-col>
                    <b-col cols="1" class="mt-2 px-1">
                      <b-form-checkbox
                        :id="`checkbox-${index}`"
                        :checked="iswc.preferred"
                        :name="`checkbox-${index}`"
                        @change="setPreferredIswc(iswc)"
                        v-b-tooltip.hover :title="iswc.value && !iswc.preferred ? $t('set_as_preferred_iswc') : ''"
                        :disabled="!iswc.value"
                      >
                      </b-form-checkbox>
                    </b-col>
                    <b-col cols="1" class="p-0 pt-1 mt-2">
                      <action
                        :title="$t('delete')"
                        :icon="['fas', 'trash']"
                        color="red"
                        @click="onRemoveIswc(index)"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>

            <b-col v-if="!isSociety && isWorkEdition" cols="3" class="pl-4">
              <p class="px-3">{{ work && work.iswcs && (work.iswcs.length > 1) ? $t("iswc") + 's:': $t("iswc") }}</p>
              <b-row v-for="(iswc) in form.iswcs" :key="iswc.id" class="px-3">
                <b-col>
                  <p><span>{{ iswc.value }}</span><span v-if="work.iswcs && (work.iswcs.length > 1) && iswc.preferred" class="ml-2 text-gray">- {{ $t("preferred") }}</span></p>
                </b-col>
              </b-row>
            </b-col>

            <!-- Work Codes -->
            <b-col cols="3" class="px-4" v-if="isSociety && isWorkEdition">
              <b-row class="top-label mb-2">
                <b-col cols="6" class="text-left">{{ $t("work_code") | startcase }}:</b-col>
                 <b-col cols="6" class="text-right">
                  <a
                    href="#"
                    @click.prevent="addWorkCode"
                    class="text-info mt-1 mr-0 code-add-link"
                  >
                    {{ $t("add") | startcase }} {{ $t("work_code") }}
                  </a>
                 </b-col>
              </b-row>
              <b-row v-for="(code, index) in form.codes" :key="`${index}`">
                <b-col cols="12">
                  <b-row v-if="workCodeTypes.length > 0 && code.action !== workFieldActionTypes.REMOVE">
                    <b-col cols="11" class="mb-3">
                       <WorkCode
                        :code="{ ...code, index}"
                        :codeTypes="workCodeTypes"
                        @input-work-code="onInputWorkCode"
                      />
                    </b-col>
                    <b-col cols="1" class="mt-2 pt-1 pl-0">
                      <action
                        :title="$t('delete')"
                        :icon="['fas', 'trash']"
                        color="red"
                        @click="onRemoveWorkCode(index)"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col :cols="isWorkEdition ? '2' : '4'" class="px-3">
              <!-- Language -->
               <b-row class="top-label mb-2">
                <b-col cols="12">
                  {{ $t('language') | startcase }}:
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <multi-select-field
                    name="language"
                    v-model="form.language"
                    :options="languages"
                    :show-labels="false"
                    choiceLabel="name"
                    track-by="id"
                    :allowEmpty="false"
                    placeholder
                  />
                </b-col>
              </b-row>
              <b-row v-if="isWorkEdition">
                <b-col>
                  <p>{{ $t("atlas_id") }}:</p>
                  <p>{{ form.id }}</p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>

        <!-- Interested Parties -->
        <div class="mt-5">
          <div class="d-flex justify-content-between mb-3">
            <h3>{{ $t("interested_parties") | startcase }}</h3>
            <div>
              <b-button
                variant="outline-secondary"
                @click="addInterestedParty"
              >
                {{ $t("add_interested_party") | startcase }}
              </b-button>
            </div>
          </div>
          <SharesEditTable
            :shares="shares"
            :newShares="newShares"
            :formErrors="formErrors"
            @edited="getEditedShares"
            @deleted="getDeletedShares"
          />
        </div>

        <b-form-checkbox
          id="override_distribution_switch"
          v-model="overrideDistribution"
          v-if="isSociety"
          switch
        >
          {{ $t("override_distribution") | startcase }}
        </b-form-checkbox>

        <!-- Payees -->
        <div class="mt-5" v-if="overrideDistribution">
          <div class="d-flex justify-content-between mt-5 mb-3">
            <h3>{{ $t("payees") | startcase }}</h3>
          </div>

          <PayeeSharesEditTable :payeeShares="payeeShares" @edited="getPayeeShares" />
        </div>

        <!-- Performers -->
        <div class="mt-5">
          <div class="d-flex justify-content-between mb-3">
            <h3>{{ $t("performers") | startcase }}</h3>
            <div>
              <b-button
                variant="outline-secondary"
                @click="addPerformer"
              >
                {{ $t("add_performer") | startcase }}
              </b-button>
            </div>
          </div>
          <div class="work-details-container">
            <div class="ml-4 pt-3">
              <div v-for="(performer, index) in form.performers" :key="index">
                <b-row class="d-flex align-items-center" >
                  <b-col cols="10">
                    <form-field
                      name="performers"
                      type="text"
                      v-model="performer.name"
                      upper
                    />

                  </b-col>
                  <b-col class="pl-0">
                    <action
                      :title="$t('delete')"
                      :icon="['fas', 'trash']"
                      color="red"
                      class="mb-3"
                      @click="onRemovePerformer(index)"
                    />
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>

        <!-- Type -->
        <div class="mt-5" v-if="!editMode">
          <div class="d-flex justify-content-between mb-3">
            <h3>{{ $t("type") | startcase }}</h3>
          </div>
          <div class="work-details-container">
            <b-row class="mx-3 pt-3">
              <b-col>
                <multi-select-field
                  name="type"
                  :label="$t('type')"
                  rules="required"
                  v-model="form.type"
                  :value="form.type"
                  :options="versions"
                  choiceLabel="label"
                  track-by="name"
                  :allowEmpty="false"
                  placeholder
                />
              </b-col>
              <b-col>
                <multi-select-field
                  v-if="form.type && form.type.name === workVersionTypes.MOD"
                  name="type"
                  :label="$t('lyrical_adaptations') | startcase"
                  :rules="!form.arrangement ? 'required' : ''"
                  v-model="form.adaptation"
                  :options="adaptations"
                  choiceLabel="label"
                  track-by="name"
                  :allowEmpty="false"
                  placeholder
                />
              </b-col>
              <b-col>
                <multi-select-field
                  v-if="form.type && form.type.name === workVersionTypes.MOD"
                  name="type"
                  :label="$t('music_arrangement') | capitalize"
                  :rules="!form.adaptation ? 'required' : ''"
                  v-model="form.arrangement"
                  :options="arrangements"
                  choiceLabel="label"
                  track-by="name"
                  :allowEmpty="false"
                  placeholder
                />
              </b-col>
            </b-row>
          </div>
        </div>
      </b-form>
    </ValidationObserver>

    <modal
      id="member-update-confirmation-modal"
      :title="$t('confirm_update') | startcase"
      :okTitle="$t('yes') | startcase"
      :cancelTitle="$t('no') | startcase"
      @ok="onSubmit"
    >
      <div>{{ $t("confirm_member_work_update") }}</div>
      <div>{{ $t("member_work_update_notice") }} {{ $t("do_you_want_to_continue") }}</div>
    </modal>

    <b-modal
      id="success-modal"
      hide-header
      size="lg"
      centered
      ok-only
      :okTitle="$t('close') | startcase"
      @hidden="onSuccessModalHidden"
      class="p-4 d-flex justify-content-center"
    >
      <b-container class="text-center mt-4">
        <div>{{ successDisplay["successMessage"] | capitalize }}</div>
        <div>
          {{ $t("go_to") | startcase }}
          <router-link
            :to="successDisplay['successDestination']"
            class="text-info"
          >
            {{ successDisplay["linkText"] }}
          </router-link>
        </div>
        
      </b-container>
      
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>

  .title-add-link,
  .code-add-link,
  .iswc-add-link {
    font-size: 0.85rem;
  }

  .top-label {
    font-size: 0.85rem;
  }

  p {
    font-size: 0.85rem;
  }

</style>

<script>
import {
  adaptationTypes,
  arrangementTypes,
  getAdaptationTypeDisplay,
  getArrangementTypeDisplay,
  getWorkVersionTypeDisplay,
  partyRolesMap,
  workCodeTypes,
  workFieldActionTypes,
  workTitleTypes,
  workVersionTypes,
} from "@/constants"
import { cloneDeep, isEqual, startCase } from "lodash"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import PayeeSharesEditTable from "./PayeeSharesEditTable"
import SharesEditTable from "./SharesEditTable"
import { ValidationObserver } from "vee-validate"
import WorkCode from "./WorkCode"
import WorkTitle from "./WorkTitle"

const { ADD, EDIT, REMOVE } = workFieldActionTypes
const versions = Object
  .keys(workVersionTypes)
  .map(type => ({ name: type, label: startCase(getWorkVersionTypeDisplay(type)) }))
const adaptations = Object
  .keys(adaptationTypes)
  .map(type => ({ name: type, label: startCase(getAdaptationTypeDisplay(type)) }))
const arrangements = Object
  .keys(arrangementTypes)
  .map(type => ({ name: type, label: startCase(getArrangementTypeDisplay(type)) }))

export default {
  name: "WorkForm",
  components: {
    PayeeSharesEditTable,
    SharesEditTable,
    ValidationObserver,
    WorkCode,
    WorkTitle,
  },
  data () {
    return {
      editMode: false,
      workFieldActionTypes,
      workVersionTypes,
      workCodeTypes,
      versions,
      adaptations,
      arrangements,
      form: {
        originalTitle: { type: workTitleTypes.OT, title: "" },
        titles: [],
        title: "",
        language: null,
        id: "",
        performers: [{ name: "" }],
        iswcs: [],
        codes: [],
        interestedParties: [],
        type: { name: workVersionTypes.ORI, label: startCase(getWorkVersionTypeDisplay(workVersionTypes.ORI)) },
        adaptation: null,
        arrangement: null,
      },
      formErrors: {},
      shares: [],
      deletedShares: [],
      editedShares: [],
      newShares: [],
      payeeShares: [],
      work: null,
      overrideDistribution: false,
      successDisplay: {},
    }
  },
  computed: {
    ...mapGetters("user", ["isSociety"]),
    ...mapState("consts", ["languages"]),
    isWorkEdition () {
      return this.$route.name === "works-edit"
    },
    submitButtonText () {
      if (!this.isSociety && this.editMode) {
        return this.$t("request_changes")
      } else if (!this.isSociety && !this.editMode) {
        return this.$t("submit_for_review")
      }
      return this.$t("save")
    },
  },
  methods: {
    ...mapActions("alert", ["error", "success"]),
    ...mapMutations("consts", ["setLanguages"]),
    ...mapMutations("user", ["setUnauthorized"]),
    getSuccessDisplay (result, relatedObject) {
      const urlParam = this.id || relatedObject
      let successDestination = { name: "works-detail", params: { id: urlParam } }
      let successMessage = ""
      let linkText = this.$t("work_detail")

      if (this.isSociety) {
        if (this.editMode) {
          successMessage = this.$t("work_successfully_updated")
        } else if (result == "create") {
          successMessage = this.$t("work_registration_created")
        } else if(result == "link") {
          successMessage = this.$t("work_registration_linked")
        } else if(result == "conflict") {
          successMessage = this.$t("work_registration_conflict")
          successDestination = { name: "actions-tabs", params: { type: "worksMatching" } }
          linkText = this.$t("works_matching")
        }
      } else {
        if (this.editMode) {
          successMessage = this.$t("work_edit_to_be_reviewed")
        } else {
          successMessage = this.$t("work_registration_to_be_reviewed")
          successDestination = { name: "actions-registration-detail", params: { id: urlParam } }
          linkText = this.$t("work_registration_details")
        }
      }

      return {
        successMessage,
        successDestination,
        linkText,
      }
    },
    onSuccessModalHidden () {
      let destination = { ...(!this.isSociety) && { query: { own: true } }, name: "works-list" }
      if (this.successDisplay?.successDestination?.name == "works-detail") {
        destination = this.successDisplay.successDestination
      }
      this.$router.replace(destination)
    },
    // ISWC methods
    addIswc () {
      let preferred = this.form.iswcs.length === 0
      this.form.iswcs = [ ...this.form.iswcs, { value: "", preferred, action: ADD } ]
    },
    onInputIswc (value, iswc, index) {
      if (iswc.value !== value) {
        iswc.value = value
        iswc.action = iswc.action ? iswc.action: EDIT
      }
      iswc.preferred = iswc.value ? iswc.preferred: false
      this.form.iswcs.splice(index, 1, { ...iswc })
      this.validateIswc()
    },
    onRemoveIswc (index) {
      let iswc = this.form.iswcs[index]

      if (iswc.id) {
        this.form.iswcs.splice(index, 1, {
          ...iswc,
          action: REMOVE
        })
      } else {
        this.form.iswcs.splice(index, 1)
      }
      this.validateIswc()
    },
    setPreferredIswc (preferredIswc) {
      let isPreferred = !preferredIswc.preferred
      let iswcs = this.form.iswcs.map(iswc => {
        if (
          ((iswc.preferred && iswc.value !== preferredIswc.value)
          || (!iswc.action && iswc.value === preferredIswc.value))
          && iswc.id
        ) {
          iswc = { ...iswc, action: EDIT }
        }
        iswc = { ...iswc, preferred: iswc.value === preferredIswc.value && isPreferred }
        return iswc
      })
      this.form.iswcs = [ ...iswcs ]
    },
    setDuplicateErrors (fieldName, valueField, list, duplicates) {
      let fieldIndices = list.reduce((acc, elem, i) => {
        if (duplicates.includes(elem[valueField])) acc.push(i)
        return acc
      }, [])

      let previousErrors = { ...this.$refs.workForm.errors }
      for (let key in previousErrors) {
        if (key.includes(fieldName)) {
          delete previousErrors[key]
        }
      }

      if (fieldIndices.length) {
        let fieldErrors = {}
        fieldIndices.forEach(index => fieldErrors[`${fieldName}-${index}`] = this.$t("duplicates_not_allowed"))
        this.$refs.workForm.reset()
        this.formErrors = { ...fieldErrors, ...previousErrors }
        this.$refs.workForm.setErrors(this.formErrors)
      } else {
        this.$refs.workForm.reset()
        this.formErrors = { ...previousErrors }
        this.$refs.workForm.setErrors(this.formErrors)
      }
    },
    validateIswc () {
      const iswcValues = this.form.iswcs.filter(iswc => iswc.action !== REMOVE).map(item => item.value)
      const duplicates = this.getDuplicates(iswcValues)

      this.setDuplicateErrors("iswc", "value", this.form.iswcs, duplicates)
    },
    preferredIswc (iswc) {
      return iswc.preferred ? this.$t("preferred_iswc"): ""
    },
    addWorkCode () {
      this.form.codes = [ ...this.form.codes, { type: this.workCodeTypes[0], value: "" } ]
    },
    onInputWorkCode (code, index) {
      if (this.formErrors[`code-${index}`]) {
        this.formErrors = { ...this.formErrors, [`code-${index}`]: [] }
        this.$refs.workForm.setErrors(this.formErrors)
      }
      if (!code.value) return
      if (code.id && (code.value !== this.form.codes[index].value || code.type !== this.form.codes[index].type)) {
        this.form.codes.splice(index, 1, { ...code, action: EDIT })
      }
      if (!code.id) {
        this.form.codes.splice(index, 1, { ...code, action: ADD })
      }
    },
    onRemoveWorkCode (index) {
      if (this.formErrors[`code-${index}`]) {
        this.formErrors = { ...this.formErrors, [`code-${index}`]: [] }
        this.$refs.workForm.setErrors(this.formErrors)
      }
      let code = this.form.codes[index]
      if (code.id) {
        this.form.codes.splice(index, 1, {
          ...code,
          action: REMOVE
        })
      } else {
        this.form.codes.splice(index, 1)
      }
    },
    getDuplicates (list) {
      return list.filter((val, idx) => val && list.indexOf(val) === idx && list.lastIndexOf(val) !== idx)
    },

    addPerformer () {
      this.form.performers = [ ...this.form.performers, { name: "" }]
    },
    addInterestedParty () {
      // adding to this.newShares instead of this.shares avoids recalculating of chains that breaks reactivity
      let newShare = {
        role: "CA",
        chains: [],
        party: {},
        mechanical: "0.00",
        performing: "0.00",
        action: ADD,
      }
      this.newShares = [ ...this.newShares, newShare ]
    },
    addTitle () {
      this.form.titles = [ ...this.form.titles, { type: "", title: null } ]
    },
    onCancel () {
      if (this.editMode) {
        this.$router.replace({ name: "works-detail", params: { id: this.id } })
      } else {
        this.$router.go(-1)
      }
    },
    onRemoveShare (index) {
      this.form.shares.splice(index, 1)
    },
    onRemoveTitle (index) {
      let title = this.form.titles[index]
      if (title.id) {
        this.form.titles.splice(index, 1, {
          ...title,
          action: REMOVE
        })
      } else {
        this.form.titles.splice(index, 1)
      }

    },
    onRemovePerformer (index) {
      this.form.performers.splice(index, 1)
      if (!this.form.performers.length) this.form.performers.push({ name: "" })
    },
    hasFormErrors () {
      let hasErrors = Object.values(this.formErrors).find(value => {
        let errors = Array.isArray(value) ? !!value.length: !!value
        if (errors) return errors
      })
      return hasErrors
    },
    getRequest (data) {
      return {
        "works-create": {
          call: "worksCreate",
          payload: [data],
        },
        "works-edit": {
          call: "worksUpdate",
          payload: [this.work?.id, data],
        },
      }[this.$route.name]
    },
    onClickSubmit () {
      if (!this.isSociety && this.editMode) {
        this.$bvModal.show("member-update-confirmation-modal")
      } else {
        this.onSubmit()
      }
    },
    onSubmit () {
      if (!this.hasFormErrors()) {
        let data = this.getRequestData()
        let request = this.getRequest(data)

        this.$api.repertoire[request.call](...request.payload)
          .then(response => {
            const { relatedObject, result } = response.data
            this.successDisplay = this.getSuccessDisplay(result, relatedObject)
            this.$bvModal.show("success-modal")
          })
          .catch(error => {
            let indexedFields = [
              { field: "iswcs", prefix: "iswc" },
              { field: "titles", prefix: "title" },
              { field: "codes", prefix: "code" }
            ]
            let fieldErrors  = {}
            let errors = error.response.data

            indexedFields.forEach(field => {
              fieldErrors = { ...this.getFieldErrors(errors, fieldErrors, field.field, field.prefix) }
            })

            if (errors && Object.keys(errors).length) {
              let messages = []
              Object.keys(errors).forEach(key => {
                if (!indexedFields.map(field => field.field).includes(key)) {
                  let newMessage = Array.isArray(errors[key]) ? errors[key]?.join(" ") : errors[key]
                  messages.push(newMessage)
                }
              })
              if (messages.length) this.error(messages.join(" "))
            }

            this.formErrors = fieldErrors
            this.$refs.workForm.setErrors(this.formErrors)
          })
      }
      return this.$refs.workForm.setErrors(this.formErrors)
    },
    getFieldErrors (errors, fErrors, field, prefix) {
      let fieldErrors = { ...fErrors }

      if (errors[field]) {
        for (let index in errors[field]) {
          if (typeof errors[field] === "string") {
            fieldErrors[`${prefix}-${index}`] = errors[field]
          }
          else if (typeof errors[field][index] === "object" && errors[field][index]["value"]) {
            fieldErrors[`${prefix}-${index}`] = errors[field][index]["value"]
          } else {
            fieldErrors[`${prefix}-${index}`] = errors[field][index]
          }
        }
      }
      return fieldErrors
    },
    onInputTitle (title, index) {
      this.form.titles.splice(index, 1, title)
      if (title?.title.trim()) {
        this.formErrors = { ...this.formErrors, [`title-${index}`]: [] }
        this.$refs.workForm.setErrors(this.formErrors)
      }
    },

    // Shares
    getShares () {
      this.$api.repertoire.worksSharesList(this.id)
        .then(response => {
          this.shares = response.data.original ? response.data.original : []

          // payee shares
          this.payeeShares = this.work?.payeeShares?.length ? this.work.payeeShares : this.deduplicatePayeeShares(this.shares)
          // remove chains from payee shares
          this.payeeShares = this.payeeShares.map(share => {
            delete share.chains
            return share
          })
          this.overrideDistribution = !!this.work?.payeeShares?.length
        })
    },
    getDeletedShares (shares) {
      this.deletedShares = [ ...shares ]
    },
    getEditedShares (shares) {
      this.editedShares = [ ...shares ]
      this.validateParties()
    },
    getSharesData () {
      return [ ...this.editedShares, ...this.deletedShares ]
    },
    getPayeeShares (payeeShares) {
      this.payeeShares = payeeShares
    },
    deduplicatePayeeShares (allShares) {
      let shares = cloneDeep(allShares)
      let uniqueIpiNameNumbers = [ ...new Set(shares.map(share => share.party.ipiNameNumber))]
      let payeeShares = []
      for (let ipiNameNumber of uniqueIpiNameNumbers) {
        let share = shares.find(s => s.party.ipiNameNumber === ipiNameNumber)
        payeeShares.push(share)
      }
      return payeeShares
    },
    getRequestData () {
      let data = {}

      // Titles
      data.titles = this.editMode ? this.addActionToTitles(this.form.titles) : this.form.titles.concat([this.form.originalTitle])
      data.titles = this.removeTitleDuplicates(data.titles)

      // Language
      data.language = this.form.language && this.form.language.id

      // Performers
      let performers = this.form.performers.map(performer => performer.name).filter(name => name.trim() !== "")
      if (!this.work || (this.work && !isEqual(this.work.performers, performers))) {
        data.performers = [ ...performers ]
      }

      // ISWCs
      data.iswcs = this.form.iswcs.filter(iswc => !!iswc.value).map(iswc => {
        iswc.action = iswc.action ? iswc.action : null
        return iswc
      } )

      // Work Codes
      data.codes = this.form.codes.filter(code => !!code.value).map(code => {
        code.action = code.action ? code.action : null
        return code
      })

      // Shares
      data.shares = this.getSharesData()

      // Payee Shares
      data.payeeShares = this.overrideDistribution ? this.payeeShares : []

      // Type
      data.version_type = this.form.type.name

      // Adaptation
      data.adaptation = this.form.adaptation ? this.form.adaptation.name : null

      // Arrangment
      data.arrangement = this.form.arrangement ? this.form.arrangement.name : null

      return data
    },
    getLanguage (languageId) {
      return this.languages.filter(language => language.id === languageId).pop()
    },
    addActionToLanguage (language) {
      if (!this.work.language) return { id: language.id, action: ADD }
      return { id: language.id, action: EDIT }
    },
    addActionToTitles (titles) {
      let requestTitles = titles.map(title => {
        // Added
        if (!title.id) return { ...title, action: ADD }
        // Changed
        let initialTitle = this.work.titles.filter(init => init.id === title.id).pop()
        if (
          initialTitle.title !== title.title ||
          initialTitle.type !== title.type
        ) return { ...title, action: EDIT }
        return title
      })

      // Original title
      if (this.form.originalTitle.title.trim() !== this.work.title) {
        requestTitles.unshift({ ...this.form.originalTitle, action: EDIT })
      }
      return requestTitles
    },
    validateParties () {
      let previousErrors = { ...this.$refs.workForm.errors }
      let errors = {}
      this.editedShares.forEach((share, index) => {
        if (!share.party.name) {
          errors[`party-${index}`] = this.$t("this_field_is_required")
        }
        else if (share.party?.name && (partyRolesMap[share.role] !== share.party.type)) {
          errors[`party-${index}`] = this.$t("wrong_role_for_party")
        } else {
          errors[`party-${index}`] = []
        }
      })
      this.formErrors = { ...previousErrors, ...errors }
      this.$refs.workForm.setErrors(this.formErrors)
    },
    resetFormError () {
      this.formError = ""
    },
    setInitial () {
      // Titles
      this.form.title = this.work.title
      for (let title of this.work.titles) {
        if (title.type === workTitleTypes.OT) {
          this.form.originalTitle = { ...title }
        } else {
          this.form.titles = [ ...this.form.titles, title ]
        }
      }
      // Language
      this.form.language = this.getLanguage(this.work.language)
      // Atlas ID
      this.form.id = this.work.id
      // Performers
      if (this.work.performers.length) {
        this.form.performers = this.work.performers.map(name => ({ name }))
      }
      // ISWCs
      this.form.iswcs = this.work.iswcs
      if (!this.form.iswcs.length) this.addIswc()

      // Work codes
      this.form.codes = this.work.codes
      if (!this.form.codes.length) this.addWorkCode()
    },
    removeTitleDuplicates (items) {
      items = items.filter((item, index, self) =>
        index === self.findIndex((i) => (
          i.type === item.type && i.title === item.title
        ))
      )
      return items
    },
  },
  watch: {
    "form.originalTitle": {
      deep: true,
      handler: function (title) {
        if (title?.title.trim()) {
          this.formErrors = { ...this.formErrors, title: [] }
          this.$refs.workForm.setErrors(this.formErrors)
        }
      }
    },
    languages () {
      this.form.language = this.work && this.getLanguage(this.work.language)
    },
    editedShares (value) {
      this.payeeShares = this.deduplicatePayeeShares(value)
    },
  },
  async mounted () {
    let response = await this.$api.countries.languagesList()
    this.setLanguages(response.data)

    this.editMode = this.$route.name === "works-edit"

    if (this.editMode) {
      response = await this.$api.repertoire.worksRetrieve(this.id)
      this.work = response.data

      // Read canEdit flag either set initial data or unauthorize view.
      this.work.canEdit ? this.setInitial() : this.setUnauthorized(true)
      this.getShares()
    }

    if (!this.editMode) {
      this.addIswc()
      this.addWorkCode()
      this.addInterestedParty()
    }
  },
  props: {
    id: [String, Number],
  }
}
</script>
