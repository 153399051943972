<template>
  <b-modal
    id="distributions-modal"
    visible
    hide-header
    hide-footer
    @hidden="onHidden"
  >
    <DistributionsForm :id="id" :initial="initial" />
  </b-modal>
</template>

<script>
import DistributionsForm from "./DistributionsForm"
import { EventBus } from "@/utils/event-bus.js"

export default {
  name: "DistributionsModal",
  components: {
    DistributionsForm
  },
  data () {
    return {}
  },
  methods: {
    onHidden () {
      EventBus.$emit("reset")
      this.$router.go(-1)
    }
  },
  props: {
    id: String,
    initial: Object,
  },
}
</script>