<template>
  <div class="mx-2 d-flex">
    <div :class="containerClasses" @click="disabled ? '' : $emit('click')">
      <b-spinner v-if="waiting" :class="color" small></b-spinner>
      <fa-icon v-else :class="iconClasses" :icon="icon" :title="title"/>
      <span v-if="text" :class="textClasses">{{ text }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .clickable {
    cursor: pointer;
    position: relative;
    height: auto;

    &:active {
      top: 1px;
    }
  }

  .gray-dark {
    color: var(--gray-dark);
  }

  .gray {
    color: var(--muted-gray);
  }

  .red {
    color: var(--red);
  }

  .info {
    color: $info;
  }

  .green {
    color: var(--green);
  }

  .disabled {
    opacity: 0.4;
  }
</style>

<script>
export default {
  name: "Action",
  computed: {
    containerClasses () {
      let values = ["d-flex", "align-items-center"]
      if (!this.disabled) values.push("clickable")
      return values.join(" ")
    },
    iconClasses () {
      let values = [this.color]
      if (this.disabled) values.push("disabled")
      return values.join(" ")
    },
    textClasses () {
      let values = [this.color, "pl-1"]
      return values.join(" ")
    }
  },
  props: {
    color: {
      type: String,
      default: "text-gray"
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    waiting: {
      type: Boolean,
      default: false,
    },
    title: String,
    text: String,
    icon: Array,
  }
}
</script>
