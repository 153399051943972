import { render, staticRenderFns } from "./DistributionsList.vue?vue&type=template&id=eef4024c&scoped=true&"
import script from "./DistributionsList.vue?vue&type=script&lang=js&"
export * from "./DistributionsList.vue?vue&type=script&lang=js&"
import style0 from "./DistributionsList.vue?vue&type=style&index=0&id=eef4024c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eef4024c",
  null
  
)

export default component.exports