<template>
  <div class="mb-4">
    <b-row>
      <b-col class="d-flex pt-3 ml-3">
        <date-picker
          v-model="dateRange"
          :placeholder="$t('select_date_range') | capitalize"
          range
          :format="$config.DATE_FORMAT"
          input-class="form-control"
        />
      </b-col>
    </b-row>

    <vertical-timeline :events="logs" />

    <!-- Pagination -->
    <b-row class="mt-3">
      <b-col cols="1" xs="4" class="pt-3 px-0">
      </b-col>
      <b-col md="6">
        <b-pagination
          v-if="totalCount > perPage"
          v-model="currentPage"
          :total-rows="totalCount"
          :per-page="perPage"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import { parseDate } from "@/utils/dates"


export default {
  name: "History",
  data: () => {
    return {
      logs: [],
      dateRange: [],
      totalCount: 0,
      currentPage: 1,
      perPage: 10,
    }
  },
  methods: {
    ...mapActions("alert", ["success", "error"]),
    getLogs (params=null) {
      this.$api.repertoire
        .worksLogsList(this.workId, params)
        .then((response) => {
          this.logs = [ ...response.data.results ]
          this.totalCount = response.data.count
        })
        .catch((error) => {
          this.error(error.response)
        })
    },
    parseDate (val) {
      return val ? parseDate(val, this.$config.ISO_DATE_FORMAT) : ""
    },
  },
  watch: {
    currentPage (value) {
      this.getLogs({ page: value })
    },
    dateRange (value) {
      const from = this.parseDate(value[0])
      const to = this.parseDate(value[1])    
      this.getLogs({ from, to })
    },
  },
  async mounted () {
    this.getLogs()
  },
  props: {
    workId: [String, Number],
  }
}
</script>