<template>
  <ValidationObserver ref="distributionsForm" v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(onSubmit)" >
      <h3 class="mb-4">
        {{ editing ? $t("edit_distribution") : $t("create_distribution") | capitalize }}
      </h3>

      <p v-if="copying">{{ $t("copy_configuration_from") | capitalize }} {{ initial.name }}</p>

      <!-- Name -->
      <form-field
        name="name"
        :label="$t('name') | startcase"
        type="text"
        rules="required"
        v-model="form.name"
      />

      <!-- Period -->
      <div class="d-flex">
        <date-field
          :label="$t('start_date') | startcase"
          name="startDate"
          rules="required"
          v-model="form.startDate"
          class="w-50 mr-3"
        />
        <date-field
          :label="$t('end_date') | startcase"
          name="endDate"
          v-model="form.endDate"
          :disabled="!form.startDate"
          :disabled-date="beforeStartDate"
          class="w-50"
          rules="required"
        />
      </div>

      <form-textarea
        name="description"
        :label="$t('description') | startcase"
        v-model="form.description"
      />

      <!-- Action Buttons -->
      <div class="float-right my-3">
        <b-button
          class="mr-2"
          variant="outline-secondary"
          @click="onCancel"
        >
          {{ $t("cancel") | capitalize }}
        </b-button>
        <submit-button variant="secondary">
          {{ $t("save") | capitalize }}
        </submit-button>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate"
import { mapMutations } from "vuex"
import moment from "moment"

export default {
  name: "DistributionsForm",
  components: {
    ValidationObserver,
  },
  data () {
    return {
      form: {
        name: "",
        description: "",
        startDate: "",
        endDate: "",
      },
    }
  },
  computed: {
    editing () {
      return this.id && this.initial.name
    },
    copying () {
      return !this.id && this.initial.name
    }
  },
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    onSubmit () {
      let data = { ...this.form }
      let { distributionsCopy, distributionsCreate, distributionsUpdate } =  this.$api.distributions

      let call = null

      if (this.editing) {
        call = distributionsUpdate(this.id, data)
      } else if (this.copying) {
        call = distributionsCopy(this.initial.id, data)
      } else {
        call = distributionsCreate(data)
      }

      call
        .then(() => {
          // Show success toast
          this.editing
            ? this.success(this.$t("distribution_successfully_edited"))
            : this.success(this.$t("distribution_successfully_created"))

          this.$router.go(-1)
        })
        .catch(error => {
          const { data } = error.response
          if (data?.length) this.error(data[0])
          this.$refs.distributionsForm.setErrors(data)
        })
    },
    onCancel () {
      this.$bvModal.hide("distributions-modal")
    },
    beforeStartDate (date) {
      return date < moment(this.form.startDate)
    },
  },
  watch: {
    initial (value) {
      this.form = { ...value }
    }
  },
  props: {
    id: String,
    initial: Object,
  }
}
</script>
