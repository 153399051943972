<template>
  <b-container fluid class="h-100" v-if="initialized">
    <!-- Private pages render here. -->
    <template v-if="loggedIn">
      <!-- Rended only when the user is fetched -->
      <logged-in-layout v-if="fetched">
        <page-not-found v-if="pageNotFound" />
        <unauthorized v-else-if="unauthorized" />
        <router-view v-else></router-view>
      </logged-in-layout>
    </template>

    <!-- Public pages render here. -->
    <template v-else>
      <router-view></router-view>
    </template>

    <!-- Alerts will be shown here. -->
    <b-toast
      :visible="visible"
      @hidden="clearAlert()"
      toaster="b-toaster-bottom-right"
      :variant="type"
      body-class="custom-toast-body"
      auto-hide-delay="10000"
    >
      {{ message }}
    </b-toast>
  </b-container>
</template>

<style lang="scss" scoped>
  .b-toast {
    z-index: 1080; // See _variables.scss for Z-index master list
  }

  ::v-deep .custom-toast-body {
    text-align: center;
    font-size: 1rem;
    padding: 20px 40px;
    letter-spacing: 1px;
    font-weight: bold;
  }
</style>

<script>
import { PageNotFound, Unauthorized } from "@/pages/"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"

export default {
  name: "app",
  components: {
    PageNotFound,
    Unauthorized
  },
  data () {
    return {
      initialized: false,
    }
  },
  computed: {
    ...mapState("alert", ["message", "type"]),
    ...mapState("user", ["pageNotFound", "unauthorized", "fetched", "loggedIn"]),
    ...mapGetters("alert", ["visible"]),
  },
  methods: {
    ...mapActions("user", ["setUser"]),
    ...mapActions("alert", ["clearAlert"]),
    ...mapActions("filters", ["clearFilters"]),
    ...mapMutations("user", ["setLoggedIn"]),
  },
  watch: {
    $route () {
      // clear filters on location change
      this.clearFilters()
    }
  },
  async mounted () {
    // Set logged in state
    this.setLoggedIn(this.$auth.isAuthenticated())

    // Set user, only if logged in
    if (this.loggedIn) {
      let response = await this.$api.auth.getUser()
      this.setUser(response.data)
    }
    this.initialized = true
  }
}
</script>