<template>
  <div>
    <work-detail :id="id" :work="work" :shares="shares">
      <template v-slot:heading>
        <div class="d-flex justify-content-between mb-2">
          <b-col>
            <h3>
              {{ work.title }}
              <b-badge v-if="userAction.resolutionType" :variant="badgeVariant" class="ml-4">
                {{ resolutionDisplay(userAction.resolutionType) | capitalize }}
              </b-badge>
            </h3>
            <router-link
              v-if="followUpLink"
              :to="{ name: followUpLink.name, params: { id: followUpLink.id } }"
              class="text-info"
            >
              {{ followUpLink.displayText | capitalize }}
            </router-link>
            <p v-if="!isSociety && worksMatching">{{ $t("member_work_matching_message") }}</p>
          </b-col>
          <div class="d-flex justify-content-between">
            <div>
              <b-button variant="outline-secondary" @click="$router.go(-1)">
                {{ $t("cancel") | capitalize }}
              </b-button>
            </div>
            <div v-if="isSociety && status === userActionStatuses.PENDING">
              <action-button class="ml-3" variant="outline-secondary" @click="onDecline">
                {{ $t("decline") | capitalize }}
              </action-button>
              <action-button variant="secondary" class="ml-3" @click="onAccept">
                {{ $t("accept") | capitalize }}
              </action-button>
            </div>
          </div>
        </div>
      </template>
    </work-detail>

    <!-- Resolution confirmation modal -->
    <b-modal
      id="resolution-confirmation"
      :title="$t('resolution') | capitalize"
      :hide-footer="true"
      :visible="true"
      v-if="doneData"
    >
      <b-container class="text-center">
        <span>{{ doneText | capitalize }}: </span>
        <router-link
          class="text-info"
          :to="{ name: doneRoute, params: { id: doneData.item }}"
        >
          {{ doneLinkText }}
        </router-link>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import {
  getUserActionResolutionTypeDisplay,
  workRegistrationResolutions as resolutions,
  userActionResolutionTypes,
  userActionStatuses,
  userActionTypes,
} from "@/constants"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { get } from "lodash"
import { landingWorkTransformations } from "@/utils/mixins"

const { ACCEPTED, DECLINED } = userActionResolutionTypes

export default {
  name: "WorkRegistrationDetail",
  mixins: [landingWorkTransformations],
  data () {
    return {
      status: null,
      userAction: {},
      work: { titles: [], chains: [] },
      shares: { original: [] },
      userActionStatuses,
      doneData: null,
    }
  },
  computed: {
    ...mapGetters("user", ["isSociety"]),
    ...mapState("consts", ["languages"]),
    badgeVariant () {
      return {
        [ACCEPTED]: "success",
        [DECLINED]: "danger"
      }[this.userAction.resolutionType]
    },
    relatedWork () {
      if (this.userAction.resolutionType == ACCEPTED) return this.userAction?.landingWork?.work
      return null
    },
    worksMatching () {
      if (this.userAction.landingWork) {
        let { userActions } = this.userAction.landingWork
        let unresolved = userActions.filter(action => action.type === userActionTypes.WORKS_MATCHING && !action.resolutionType)
        let id = unresolved.length ? unresolved[0].id : null
        return id
      }
      return null
    },
    followUpLink () {
      let links = {
        work: {
          name: "works-detail",
          displayText: this.$t("view_in_works_database"),
          id: this.relatedWork,
        },
        conflict: {
          name: "actions-works-matching-detail",
          displayText: this.$t("go_to_works_matching_user_action"),
          id: this.worksMatching,
        }
      }
      if (this.relatedWork) return links.work
      if (this.isSociety && this.worksMatching) return links.conflict
      return null
    },
    doneText () {
      return {
        [resolutions.CREATE]: this.$t("new_work_created"),
        [resolutions.LINK]: this.$t("linked_to_existing_work"),
        [resolutions.CONFLICT]: this.$t("conflict_with_existing_work"),
        null: ""
      }[get(this.doneData, "result", null)]
    },
    doneRoute () {
      return {
        [resolutions.CREATE]: "works-detail",
        [resolutions.LINK]: "works-detail",
        [resolutions.CONFLICT]: "actions-works-matching-detail",
        null: ""
      }[get(this.doneData, "result", null)]
    },
    doneLinkText () {
      return {
        [resolutions.CREATE]: this.$t("view_work"),
        [resolutions.LINK]: this.$t("view_work"),
        [resolutions.CONFLICT]: this.$t("view_user_action"),
        null: ""
      }[get(this.doneData, "result", null)]
    }
  },
  methods: {
    ...mapActions("alert", ["success", "error"]),
    ...mapMutations("consts", ["setLanguages"]),
    onResolve (resolution, data) {
      let call = { accept: "workRegistrationAccept", decline: "workRegistrationDecline" }[resolution]
      return this.$api.userActions[call](this.$route.params.id, data)
        .then((response) => {
          if (!response.data) {
            this.success(this.$t("declined"))
          }
          this.doneData = response.data
        }).catch(err => {
          let errorMessage = err.response.status == 500 ? this.$t("server_error_message") : err.response.data.detail
          this.error(errorMessage)
        })
    },
    onAccept (data) {
      return this.onResolve("accept", data)
    },
    onDecline (data) {
      return this.onResolve("decline", data)
    },
    getLanguage (code) {
      let language = this.languages.filter(lang => lang.code === code).pop()
      return language?.name
    },
    resolutionDisplay (resolution) {
      return resolution ? getUserActionResolutionTypeDisplay(resolution) : ""
    },
    getUserAction () {
      this.$api.userActions.workRegistrationRetrieve(this.id)
        .then(({ data }) => {
          this.setData(data)
        })
        .catch(err => {
          this.error(err.response.data)
        })
    },
    setData (data) {
      this.userAction = { ...data }
      this.status = this.userAction?.status
      this.work = this.transformLandingWork(this.userAction?.landingWork)
      this.shares = this.getShares(this.work)
    }
  },
  watch: {
    doneData () {
      this.getUserAction()
    }
  },
  async mounted () {
    let response = await this.$api.countries.languagesList()
    this.setLanguages(response.data)

    this.getUserAction()
  },
  props: {
    id: [String, Number],
  },
}
</script>
