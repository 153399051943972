<template>
  <ValidationObserver ref="poolsForm" v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(onSubmit)" >
      <div class="d-flex justify-content-between mb-4">
        <h3>
          {{ pid ? $t("edit_vod_distribution_pool") : $t("create_vod_distribution_pool") | upperfirst }}
        </h3>
        <!-- Action Buttons -->
        <div class="d-flex justify-content-end my-3">
          <b-button
            class="mr-2"
            variant="outline-secondary"
            @click="$router.go(-1)"
          >
            {{ $t("back_to_distribution") | capitalize }}
          </b-button>
          <submit-button variant="secondary">
            {{ $t("save") | capitalize }}
          </submit-button>
        </div>
      </div>

      <!-- 1st Row -->
      <b-row>
        <!-- Name -->
        <b-col cols="6">
          <form-field
            name="name"
            :label="$t('name')"
            type="text"
            rules="required"
            v-model="form.name"
          />
        </b-col>
        <!-- Code -->
        <b-col cols="3">
          <form-field
            name="code"
            :label="$t('code')"
            type="text"
            maxlength="20"
            rules="required|alphaNum"
            v-model="form.code"
            upper
          />
        </b-col>
        <!-- DSP -->
        <b-col cols="3" class="pb-0 m-0">
          <multi-select-field
            id="dsp"
            class="pr-3 w-20"
            v-model="form.dsp"
            name="dsp"
            rules="required"
            :options="dspOptions"
            label="DSP"
            choiceLabel="display"
            track-by="value"
            :showLabels="false"
          />
        </b-col>
      </b-row>

      <!-- 2nd Row -->
      <b-row>
        <!-- Right Types -->
        <b-col cols="3">
          <multi-select-field
            v-model="form.rightType"
            name="rightType"
            :options="rightTypeOptions"
            :allow-empty="false"
            :label="$t('rights')"
            choiceLabel="display"
            track-by="value"
            :showLabels="false"
            placeholder
          />
        </b-col>
        <!-- Fund ID -->
        <b-col cols="3">
          <form-field
            name="fundInternalId"
            :label="$t('fund_id')"
            type="text"
            v-model="form.fundInternalId"
          />
        </b-col>

        <!-- Start date -->
        <b-col cols="3">
          <date-field
            :label="$t('start_date')"
            name="startDate"
            v-model="form.startDate"
            class="w-50 mr-3"
          />
        </b-col>

        <!-- End date -->
        <b-col cols="3">
          <date-field
            :label="$t('end_date')"
            name="endDate"
            v-model="form.endDate"
            :disabled="!form.startDate"
            :disabled-date="beforeStartDate"
            class="w-50"
          />
        </b-col>

      </b-row>

      <!-- 3rd Row -->
      <b-row>
        <!-- Bank Amount -->
        <b-col cols="3">
          <form-field
            name="grossAmount"
            :label="$t('bank_amount')"
            type="number"
            step="0.01"
            min="0"
            v-model="form.grossAmount"
          />
        </b-col>
         <!-- Deductibles -->
        <b-col>
          <label for="deductibles" class="top-label">{{ $t('deductibles') | startcase }}:</label>
          <type-and-value-input
            v-if="form.deductiblesUnit !== null"
            :item="deductiblesItem"
            :itemTypes="amountTypeOptions"
            @input="onDeductiblesInput"
            name="deductibles"
            class="top-label"
            type="number"
            step="0.01"
            min="0"
            :max="getMaxValue(form.deductiblesUnit)"
            :helpText="form.deductiblesHelpText"
          />
        </b-col>
        <!-- Admin Costs -->
        <b-col>
          <label for="administrationCosts" class="top-label">{{ $t('administration_costs') | startcase }}:</label>
          <type-and-value-input
            v-if="form.administrationCostsUnit !== null"
            :item="administrationCostsItem"
            :itemTypes="amountTypeOptions"
            @input="onAdministrationCostsInput"
            name="administrationCosts"
            class="top-label"
            type="number"
            step="0.01"
            min="0"
            :max="getMaxValue(form.administrationCostsUnit)"
            :helpText="form.administrationCostsHelpText"
          />
        </b-col>
        <!-- Net Distributable Amount -->
        <b-col cols="3">
          <div class="d-flex align-items-center px-0 mx-0 w-100">
            <form-field
              name="netDistributableAmount"
              :label="$t('net_distributable_amount')"
              type="number"
              step="0.01"
              min="0"
              v-model="form.netDistributableAmount"
              :disabled="computeNetDistributableAmount"
              class="w-100"
            />
            <action
              :title="$t('edit')"
              @click="onEditNetDistributableAmount"
              :icon="['fas', 'pen']"
              class="ml-2 mt-2"
            />
          </div>
        </b-col>
      </b-row>

      <!-- 4th Row -->
      <b-row>
        <b-col>
           <label>
          {{ $t('vod_files') | upperfirst }}:
          </label>
          <!-- File selector -->
            <files-selector
              :initial="form.vodFiles"
              :externalFilters="fileFilters"
              :fileOnlySelection="true"
              :fileType="selectableFileTypes.VOD"
              @select-files="onSelectFiles"
            />
        </b-col>
      </b-row>

    </b-form>
  </ValidationObserver>
</template>

<style lang="scss" scoped>
label {
  font-weight: bold;
  font-size: 0.85rem;
}

.top-label {
  font-size: 0.85rem;
}
</style>

<script>
import { distributionPoolRuleTypes, dsps, fundUnitTypes, poolsFormType, rightCodes, selectableFileTypes } from "@/constants"
import { ValidationObserver } from "vee-validate"
import { mapMutations } from "vuex"
import moment from "moment"

const UNIT_TYPES = {
  A: "A",
  P: "P",
}

export default {
  name: "VODPoolsForm",
  components: {
    ValidationObserver
  },
  data () {
    return {
      computeNetDistributableAmount: false,
      amountTypeOptions: [
        fundUnitTypes.P,
        fundUnitTypes.A,
      ],
      form: {
        name: "",
        code: "",
        fundInternalId: "",
        grossAmount: null,
        deductibles: null,
        deductiblesPercent: 0,
        deductiblesUnit: null,
        deductiblesHelpText: null,
        deductiblesUnitsHaveChanged: false,
        administrationCosts: null,
        administrationCostsPercent: 0,
        administrationCostsUnit: null,
        administrationCostsHelpText: null,
        administrationCostsUnitsHaveChanged: false,
        netDistributableAmount: null,
        distributionPoolRules: [{ 0: distributionPoolRuleTypes.VOD }],
        vodFiles: [],
        dsp: null,
        startDate: null,
        endDate: null,
        rightType: null,
      },
      dspOptions: [
        { value: dsps.AMAZON, display: "Amazon" },
        { value: dsps.APPLETV, display: "AppleTV" },
        { value: dsps.DISNEY, display: "Disney" },
        { value: dsps.NETFLIX, display: "Netflix" },
      ],
      rightTypeOptions: [
        { value: rightCodes.MEC, display: rightCodes.MEC },
        { value: rightCodes.PER, display: rightCodes.PER },
        { value: null, display: this.$t("per_and_mec") },
      ],
      fileFilters: null,
      selectableFileTypes,
    }
  },
  computed: {
    administrationCostsItem () {
      let type = this.form.administrationCostsUnit
      let value = type === UNIT_TYPES.P ? this.form.administrationCostsPercent : this.form.administrationCosts

      return {
        type: fundUnitTypes[type],
        value,
      }
    },
    deductiblesItem () {
      let type = this.form.deductiblesUnit
      let value = type === UNIT_TYPES.P ? this.form.deductiblesPercent : this.form.deductibles

      return {
        type: fundUnitTypes[type],
        value,
      }
    }
  },
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    beforeStartDate (date) {
      return date < moment(this.form.startDate)
    },
    onChange () {
      this.$nextTick(() => {
        this.$emit("input", this.form)
      })
    },
    onSelectFiles (vodFiles) {
      this.form = { ...this.form, vodFiles }
    },
    onAdministrationCostsInput (administrationCosts) {
      let { type } = administrationCosts
      if (
        (type === fundUnitTypes.P && this.form.administrationCostsUnit !== UNIT_TYPES.P)
        || (type === fundUnitTypes.A && this.form.administrationCostsUnit !== UNIT_TYPES.A)
      ) {
        administrationCosts.value = 0
        this.administrationCostsUnitsHaveChanged = true
      }

      if (type === fundUnitTypes.P) {
        this.form.administrationCostsUnit = UNIT_TYPES.P
        this.form.administrationCostsPercent = administrationCosts.value
      } else {
        this.form.administrationCostsUnit = UNIT_TYPES.A
        this.form.administrationCosts = administrationCosts.value
      }
    },
    onDeductiblesInput (deductibles) {
      let { type } = deductibles
      if (
        (type === fundUnitTypes.P && this.form.deductiblesUnit !== UNIT_TYPES.P)
        || (type === fundUnitTypes.A && this.form.deductiblesUnit !== UNIT_TYPES.A)
      ) {
        deductibles.value = 0
        this.deductiblesUnitsHaveChanged = true
      }
  
      if (type === fundUnitTypes.P) {
        this.form.deductiblesUnit = UNIT_TYPES.P
        this.form.deductiblesPercent = deductibles.value
      } else {
        this.form.deductiblesUnit = UNIT_TYPES.A
        this.form.deductibles = deductibles.value
      }
    },
    onEditNetDistributableAmount () {
      this.form.deductibles = 0
      this.form.deductiblesPercent = 0
      this.form.deductiblesUnit = UNIT_TYPES.A
      this.form.deductiblesHelpText = null
      this.form.administrationCosts = 0
      this.form.administrationCostsPercent = 0
      this.form.administrationCostsUnit = UNIT_TYPES.A
      this.form.administrationCostsHelpText = null
      this.computeNetDistributableAmount = false
    },
    getMaxValue (unit) {
      if (unit === UNIT_TYPES.P) return 100.00
    },
    convertPercentToAmount (percentValue) {
      return this.form.grossAmount * percentValue/100
    },
    onSubmit () {
      let data = { ...this.form }
      let { distributionPoolsCreate, distributionPoolsUpdate } =  this.$api.distributions
      data.vodFiles = data.vodFiles.map(f => f.id)
      data.dsp = data.dsp ? data.dsp.value : null
      data.rightType = data.rightType ? data.rightType.value : null

      let call = this.pid ? distributionPoolsUpdate(this.id, this.pid, data, poolsFormType.VOD) : distributionPoolsCreate(this.id, data, poolsFormType.VOD)

      call
        .then(() => {
          if (this.pid) {
            // On edit: Show success toast.
            this.success(this.$t("distribution_pool_successfully_edited"))
            this.loadData()
          } else {
            // On create: Show success toast and redirect.
            this.success(this.$t("distribution_pool_successfully_created"))
            this.$router.go(-1)
          }
        })
        .catch(error => {
          const { data } = error.response
          if (data?.length) this.error(data[0])
          this.$refs.poolsForm.setErrors(data)
        })
    },
    async loadData () {
      const response = await this.$api.distributions.distributionPoolsRetrieve(this.id, this.pid, poolsFormType.VOD)

      const dsp = this.dspOptions.find(o => o.value === response.data.dsp)
      let { rightType } = response.data
      let selectedRightType = this.rightTypeOptions.find(r => r.value === rightType)

      this.form = {
        ...response.data,
        dsp,
        rightType: selectedRightType,
        vodFiles: response.data.vodFiles || [],
      }
    },
  },
  watch: {
    "form.dsp" (value) {
      let dsp = value ? value.value : null
      this.fileFilters = { ...this.fileFilters, dsp }
    },
    "form": {
      deep: true,
      handler: function (formData) {
        let administrationCostsAmount = formData.administrationCosts
        let deductiblesAmount = formData.deductibles

        if (formData.administrationCostsUnit === UNIT_TYPES.P) {
          administrationCostsAmount = this.convertPercentToAmount(formData.administrationCostsPercent)
          this.form.administrationCostsHelpText = administrationCostsAmount.toString()
        } else {
          this.form.administrationCostsHelpText = ""
        }

        if (formData.deductiblesUnit === UNIT_TYPES.P) {
          deductiblesAmount = this.convertPercentToAmount(formData.deductiblesPercent)
          this.form.deductiblesHelpText = deductiblesAmount.toString()
        } else {
          this.form.deductiblesHelpText = ""
        }

        this.computeNetDistributableAmount = Boolean(parseFloat(administrationCostsAmount)) || Boolean(parseFloat(deductiblesAmount)) || this.administrationCostsUnitsHaveChanged || this.deductiblesUnitsHaveChanged

        if (this.computeNetDistributableAmount) {
          let netDistributableAmount = formData.grossAmount - administrationCostsAmount - deductiblesAmount
          this.form.netDistributableAmount = netDistributableAmount.toFixed(2)
        }
      }
    },
  },
  async mounted () {
    // Retrieve a pool if a pool ID is passed as prop
    if (this.pid) {
      this.loadData()
    } else {
      this.form = { 
        ...this.form,
        deductiblesUnit: UNIT_TYPES.A,
        administrationCostsUnit: UNIT_TYPES.A,
        deductibles: 0,
        administrationCosts: 0,
        rightType: { value: null, display: this.$t("per_and_mec") },
      }
    }
  },
  props: {
    id: String, // Distrbution ID
    pid: String, // Pool ID,
  }
}
</script>
