<template>
  <list-layout :title="title" :count="count">
    <template v-slot:subtitle>
      <div v-if="uploader">
        <p class="mb-0">{{ uploader | get("name") }}
          <span class="font-weight-bold">({{ uploader | get("party") | get("name") }})</span>
        </p>
        <p class="text-gray">{{ uploadDate }}</p>
      </div>
    </template>

    <!-- Status -->
    <template v-slot:status>
      <div class="mr-3">
        <status
          :text="$t('created')"
          :name="landingAgreementStatuses.CREATED"
          :count="summary.created"
          :icon="['fas', 'check-circle']"
          color="green"
          clickable
          :active="selectedStatus == landingAgreementStatuses.CREATED"
          @click="onStatusClick"
        />
        <status
          :text="$t('linked')"
          :name="landingAgreementStatuses.LINKED"
          :count="summary.linked"
          :icon="['fas', 'check-circle']"
          color="green"
          clickable
          :active="selectedStatus == landingAgreementStatuses.LINKED"
          @click="onStatusClick"
        />
      </div>
      <div class="mr-3">
        <status
          :text="$t('pending')"
          :name="landingAgreementStatuses.PENDING"
          :count="summary.pending"
          :icon="['fas', 'question-circle']"
          color="orange"
          clickable
          :active="selectedStatus == landingAgreementStatuses.PENDING"
          @click="onStatusClick"
        />
        <status
          :text="$t('error')"
          :name="landingAgreementStatuses.ERROR"
          :count="summary.error"
          :icon="['fas', 'exclamation-circle']"
          color="red"
          clickable
          :active="selectedStatus == landingAgreementStatuses.ERROR"
          @click="onStatusClick"
        />
      </div>
      <div class="mr-3 align-self-start">
        <status
          :text="$t('clear_filters')"
          :count="''"
          :icon="['fas', 'times']"
          clickable
          :active="selectedStatus == ''"
          @click="onStatusClick('')"
        />
      </div>
    </template>

    <!-- Table -->
    <v-server-table
      :columns="columns"
      :options="options"
      @loaded="onLoaded"
      @pagination="onPagination"
      ref="landingAgreements"
    >
      <!-- Type -->
      <template v-slot:type="{ row }">
        {{ row.type }}
      </template>

      <!-- Transaction Type -->
      <template v-slot:transactionType="{ row }">
        {{ row.transactionType }}
      </template>

      <!-- Assignor -->
      <template v-slot:assignor="{ row }">
        {{ row.assignorName || '-' }}
        <div class="text-small">{{ row.assignorIpiNameNumber }}</div>
      </template>

      <!-- Assignee -->
      <template v-slot:assignee="{ row }">
        {{ row.assigneeName || "-" }}
        <div class="text-small">{{ row.assigneeIpiNameNumber }}</div>
      </template>

      <!-- Right Types -->
      <template v-slot:rightTypes="{ row }">
        {{ getRightTypesDisplay(row.rightTypes) }}
      </template>

      <!-- Share -->
      <template v-slot:share="{ row }">
        {{ row.share }}
      </template>

      <!-- Dates -->
      <template v-slot:dates="{ row }">
        <!-- Start date-->
        <template v-if="row.startDate">
          <span class="text-gray text-small">{{ $t("start") }}</span>
          <date-time-cell :dateTime="row.startDate" date-only/>
        </template>
        <!-- End date-->
        <template v-if="row.endDate">
          <span class="text-gray text-small">{{ $t("end") }}</span>
          <date-time-cell :dateTime="row.endDate" date-only/>
        </template>
        <!-- Collection date-->
        <template v-if="row.collectionDate">
          <span class="text-gray text-small">{{ $t("collection_date") }}</span>
          <date-time-cell :dateTime="row.collectionDate" date-only/>
        </template>
      </template>

      <!-- Landing Agreement Status -->
      <template v-slot:status="{ row }">
        <status
          v-if="row.status === landingAgreementStatuses.CREATED"
          :text="$t('created')"
          :icon="['fas', 'check-circle']"
          color="green"
        />
        <status
          v-else-if="row.status === landingAgreementStatuses.PENDING"
          :text="$t('pending')"
          :icon="['fas', 'question-circle']"
          color="orange"
        />
        <status
          v-else-if="row.status === landingAgreementStatuses.LINKED"
          :text="$t('linked')"
          :icon="['fas', 'question-circle']"
          color="green"
        />
        <status
          v-else-if="row.status === landingAgreementStatuses.ERROR"
          :text="$t('error')"
          :icon="['fas', 'exclamation-circle']"
          color="red"
        />
      </template>

      <!-- Agreement -->
      <template v-slot:agreement="{ row }">
        <router-link
          v-if="[landingAgreementStatuses.CREATED, landingAgreementStatuses.LINKED].includes(row.status)"
          :to="{ name: 'agreements-update', params: { agreementId: row.agreement } }"
          class="text-info"
        >
          {{ row.agreement }}
        </router-link>
      </template>
    </v-server-table>
  </list-layout>
</template>

<script>
import { capitalize, get } from "lodash"
import { landingAgreementStatuses } from "@/constants"
import { listRouteMixin } from "@/utils/mixins"
import { parseDate } from "@/utils/dates"

export default {
  name: "LandingAgreementsList",
  mixins: [listRouteMixin],
  data () {
    return {
      count: 0,
      summary: {},
      title: "",
      landingAgreementStatuses,
      uploader: null,
      uploadDate: "",
      selectedStatus: "",
      columns: ["type", "transactionType", "assignor", "assignee", "rightTypes", "share", "dates", "status", "agreement"],
      options: {
        customFilters: ["status"],
        initFilters: { "status": get(this.$route.query, "status", "") },
        showChildRowToggler: true,
        openChildRows: [],
        headings: {
          type: capitalize(this.$t("type")),
          transactionType: capitalize(this.$t("transaction")),
          assignor: capitalize(this.$t("assignor")),
          assignee: capitalize(this.$t("assignee")),
          rightTypes: capitalize(this.$t("right_types")),
          share: capitalize(this.$t("share")),
          status: capitalize(this.$t("status")),
          dates: capitalize(this.$t("dates")),
          agreement: capitalize(this.$t("agreement")),
        },
        responseAdapter ({ data }) {
          let component = this.$parent.$parent.$parent
          // close all open rows when new data is received
          component.options = { ...component.options, openChildRows: [] }
          component.$refs.landingAgreements.$refs.table.openChildRows = []

          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          let { params, query } = this.$route
          queryParams = { ...queryParams, ...query }
          this.page = Number(get(queryParams, "page", 1))

          return this.$api.agreements.landingAgreementsList(params.id, queryParams)
        },
        rowClassCallback (row) {
          return this.openChildRows.includes(row.id) ? "open-row" : ""
        }
      },
    }
  },
  methods: {
    getRightTypesDisplay (rightTypes) {
      return rightTypes.join(", ")
    },
    onStatusClick (status) {
      this.selectedStatus = status
      this.applyFilters("status", status)
    },
    onLoaded ({ data }) {
      this.title = this.pageTitle

      if (data.context) {
        const { filename, uploadDate, uploader } = data.context
        this.title = filename
        this.uploader = uploader
        this.uploadDate = parseDate(uploadDate, `${this.$config.DATE_FORMAT} h:mm`)
      }
      this.count = data.count
      this.summary = data.summary
    },
    parseDate (val) {
      return val ? parseDate(val, this.$config.ISO_DATE_FORMAT) : ""
    },
    getData () {
      this.$refs.landingAgreements.getData()
    },
  },
  mounted () {
    this.selectedStatus = get(this.$route.query, "status", "")
  },
}
</script>
