<template>
  <div>
    <ValidationObserver ref="agreementsUpdate" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div class="d-flex justify-content-end align-items-baseline">
          <div class="mb-4 mt-0 text-danger mr-auto">
            <div v-if="form.userAction">
              <span>{{ $t("agreement_is_in_conflict") | capitalize }}, </span>
              <router-link
                :to="{ name: 'actions-agreement-detail', params: { id: form.userAction } }"
                v-if="isSociety"
                class="text-danger font-weight-bold "
              >
              <u>{{ $t("go_to_agreement_conflict") }}</u>
              </router-link>
            </div>

            <div v-if="!isApplied && !form.userAction">
              {{ $t("agreement_is_inactive") | capitalize }}
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <b-button variant="outline-secondary" @click="$router.go(-1)">
              {{ $t("cancel") | capitalize }}
            </b-button>
            <b-button variant="outline-secondary" class="ml-2" @click="onDelete">
              {{ $t("delete") | capitalize }}
            </b-button>
            <submit-button class="ml-2" variant="secondary">{{ $t("save") | capitalize }}</submit-button>
          </div>
        </div>

        <div class="d-flex">

          <!-- Status -->
          <div class="w-50 mr-3">
            <p class="label">{{ $t("status") | capitalize }}: </p>
            <div class="d-flex flex-row justify-content-start">
              <p>
                <status
                  v-if="form.status === agreementStatuses.PENDING"
                  :text="$t('pending')"
                  :icon="['fas', 'question-circle']"
                  color="orange"
                />
                <status
                  v-if="form.status === agreementStatuses.PROCESSING"
                  :text="$t('processing')"
                  :icon="['fas', 'question-circle']"
                  color="orange"
                />
                <status
                  v-if="form.status === agreementStatuses.APPLIED"
                  :text="$t('applied')"
                  :icon="['fas', 'check-circle']"
                  color="green"
                />
                <status
                  v-if="form.status === agreementStatuses.INACTIVE"
                  :text="$t('inactive')"
                  :icon="['fas', 'exclamation-circle']"
                  color="gray"
                />
                <status
                  v-if="form.status === agreementStatuses.ERROR"
                  :text="$t('error')"
                  :icon="['fas', 'exclamation-circle']"
                  color="red"
                />
              </p>
            </div>
          </div>

          <!-- Current -->
          <div class="w-50">
            <p class="label">{{ $t("current") | capitalize }}: </p>
            <div class="d-flex flex-row justify-content-start">
              <p>
                <status
                  v-if="form.current"
                  :icon="['fas', 'check-circle']"
                  :text="$t('current')"
                  color="green"
                />
                <status
                  v-if="!form.current"
                  :icon="['fas', 'times-circle']"
                  :text="$t('not_current')"
                  color="red"
                />
              </p>
            </div>
          </div>
        </div>

        <div class="d-flex">
          <!-- Asignor -->
          <div class="w-50">
            <p class="label">{{ $t("assignor") | capitalize }}: </p>
            <p>{{ form.assignor.name }}</p>
          </div>

          <!-- Asignee -->
          <div class="w-50 mr-3">
            <p class="label">{{ $t("assignee") | capitalize }}: </p>
            <p>{{ form.assignee.name }}</p>
          </div>
        </div>

        <!-- Dates -->
        <div class="d-flex">
          <date-field
            :label="$t('start_date')"
            name="startDate"
            v-model="form.startDate"
            rules="required"
            class="w-50 mr-3"
            :disabled="!canEdit"
          />
          <date-field
            :label="$t('end_date')"
            name="endDate"
            v-model="form.endDate"
            :disabled="!form.startDate || !canEdit"
            :disabled-date="beforeStartDate"
            class="w-50"
          />
        </div>

        <div class="d-flex">
          <!-- Rights -->
          <div class="w-50 mr-3">
            <p class="label">{{ $t("rights") | capitalize }}: </p>
            <p>
              <b-badge
                v-for="rightType in form.rightTypes"
                :key="rightType"
                pill
                variant="success"
                class="text-white mr-2 py-2 px-3"
              >
                {{ getRightTypeDisplay(rightType) }}
              </b-badge>
            </p>
          </div>

          <!-- Share -->
          <div class="w-50">
            <p class="label">{{ $t("share") | capitalize }}: </p>
            <p>{{ form.share }}</p>
          </div>
        </div>

        <!-- TYPE -->
        <p class="label">{{ $t('type') | capitalize }}:</p>
        <p class="d-flex flex-row align-items-center">
          <span>{{ getAgreementTypeDisplay(form.type) | capitalize }}</span>

          <!-- INCLUDE/EXCLUDE -->
          <template v-if="form.type === agreementTypes.SPECIFIC && form.works.length">
            <span class="mx-3">{{ getInclusionFlagDisplay(form.inclusionFlag) }}</span>
            <div class="d-flex flex-wrap">
              <b-badge
                v-for="work in form.works" :key="work.id"
                pill variant="success" class="mr-2 mb-1 py-2 px-3"
              >
                <router-link class="text-white" :to="{ name: 'works-detail', params: { id: work.id }}">
                  <template v-if="work.iswc">{{ work.iswc }} - </template>{{ work.title }}
                </router-link>
              </b-badge>
            </div>
          </template>
        </p>
        <modal
          id="delete-confirmation-modal"
          :title="$t('delete_agreement') | capitalize"
          :okTitle="$t('yes') | capitalize"
          :cancelTitle="$t('no') | capitalize"
          @ok="onConfirmDelete"
        >
          <div>{{ $t("confirm_delete_agreement") | capitalize }}?</div>
        </modal>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<style lang="scss" scoped>
  .label {
    font-weight: bold;
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
  }
</style>

<script>
import { agreementStatuses, agreementTypes, getAgreementTypeDisplay, getRightTypeDisplay, inclusionFlags } from "@/constants"
import { mapGetters, mapMutations, mapState } from "vuex"
import { ValidationObserver } from "vee-validate"
import moment from "moment"

export default {
  name: "AgreementsUpdate",
  components: {
    ValidationObserver,
  },
  data () {
    return {
      agreementStatuses,
      agreementTypes,
      form: {
        id: "",
        assignor: { name: "" },
        assignee: { name: "" },
        share: 0,
        startDate: null,
        endDate: null,
        type: agreementTypes.GENERAL,
        inclusionFlag: null,
        works: [],
        rightTypes: [],
        userAction: "",
        status: "",
      },
      getRightTypeDisplay,
      getAgreementTypeDisplay
    }
  },
  computed: {
    ...mapState("consts", ["countriesHash"]),
    ...mapGetters("user", ["isPublisher", "isSociety"]),
    isApplied () {
      return this.form.status === agreementStatuses.APPLIED
    },
    canEdit () {
      return this.isPublisher || this.isSociety
    }
  },
  methods: {
    ...mapMutations("consts", ["setCountries"]),
    beforeStartDate (date) {
      return date < moment(this.form.startDate)
    },
    onSubmit () {
      let data = this.getRequestData()
      this.$emit("submit", data)
    },
    onDelete () {
      this.$bvModal.show("delete-confirmation-modal")
    },
    onConfirmDelete () {
      this.$api.agreements.agreementsDelete(this.form.id)
        .then(() => {
          this.success(this.$t("agreement_deleted"))
          this.getData()
        }).catch(error => {
          if (error.response.data && error.response.data.detail) this.error(error.response.data.detail)
        })
    },
    getRequestData () {
      let { startDate, endDate } = this.form
      return { startDate, endDate }
    },
    getCountryDisplay (country) {
      return this.countriesHash[country] ? this.countriesHash[country].name: ""
    },
    getInclusionFlagDisplay (inclusionFlag) {
      return {
        [inclusionFlags.INCLUDE]: this.$t("include"),
        [inclusionFlags.EXCLUDE]: this.$t("exclude"),
      }[inclusionFlag]
    }
  },
  watch: {
    errors (value) {
      this.$refs.agreementsUpdate.setErrors(value)
    },
    agreement (value) {
      this.form = value
    }
  },
  async mounted () {
    let response = await this.$api.countries.countriesList()
    this.setCountries(response.data)
  },
  props: {
    agreement: Object,
    errors: Object,
  }
}
</script>
