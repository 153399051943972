<template>
  <b-card no-body class="py-3 px-3 h-100">
    <b-row>
      <b-col class="d-flex">
        <!-- Name search -->
        <search-input
          :value="fileSearch"
          :placeholder="$t('search_by_filename') | capitalize"
          class="w-40 mr-2 mb-3"
          @search="onFileSearch"
        />

        <div v-if="fileType == selectableFileTypes.USAGES">
          <!-- Tag filters -->
          <b-button
            id="popover-target-1"
            class="form-control filters-popover"
            variant="outline-info"
            @click="onShowFiltersPopover"
          >
            <fa-icon class="popover-icon icon" :icon="['fa', 'sliders-h']" />
            {{ $t("tag_filters") | capitalize }}
          </b-button>
          <b-popover target="popover-target-1" :show.sync="showFilterPopover" placement="top">
            <template v-slot:title>
              <div class="d-flex justify-content-between">
                <span>
                  {{ $t("tag_filters") | capitalize }}
                </span>
                <b-button
                  aria-label="close"
                  @click="showFilterPopover = !showFilterPopover"
                  variant="outline-secondary"
                  class="py-0 px-2"
                >
                  <span
                    class="d-inline-block font-weight-bolder"
                    aria-hidden="true"
                  >&times;</span>
                </b-button>
              </div>
            </template>
            <TagFilters @filter="onTagsFilter" :initFilters="camelcaseKeys(tagFilters)" />
            <a
              href=""
              class="text-info float-right pb-3 pr-3"
              @click.prevent="clearFilters"
            >
              {{ $t("clear_filters") | capitalize }}
            </a>
          </b-popover>
        </div>
      </b-col>
    </b-row>

    <!-- Files Table -->
    <v-server-table
      :columns="columns"
      :options="options"
      :ref="tableRef"
      @loaded="onLoaded"
    >
      <!-- Select all -->
      <template slot="h__selection">
        <div class="d-flex">
          <b-checkbox
            v-model="options.allFilesAndSheetsSelected"
            @change="onPageFilesSelect"
          />
        </div>
      </template>

      <!-- Selection -->
      <template v-slot:selection="{ row }">
        <div class="d-flex">
          <b-checkbox
            v-if="(row.sheets && row.sheets.length) || fileOnlySelection"
            v-model="filesWithAllSheetsSelected"
            :value="row.id"
            @change="onChange($event, row)"
          />
        </div>
      </template>

      <!-- File Name -->
      <template v-slot:filename="{ row }">
        <b-row>
          <b-col>
            <div>{{ row.filename }}</div>
            <!-- Tags -->
            <div class="mt-2">
              <tag :text="tag.value" :icon="getTagIcon(tag)" v-for="(tag, index) in row.tags" :key="`tag_${row.id}_${index}`" />
            </div>
          </b-col>
        </b-row>
      </template>

      <!-- Period -->
      <template v-slot:period="{ row }">
        <span class="font-8">
          {{ getUsagePeriodDisplay(row) }}
        </span>
      </template>

      <!-- Child Rows -->
      <template v-slot:child_row="{ row }">
        <FileSheetSelector
          v-if="!fileOnlySelection && row.sheets.length"
          :items="row.sheets"
          :file="row"
          :key="row.id"
          :selected="selectedSheets"
          :parentTable="tableRef"
        />
      </template>
    </v-server-table>
  </b-card>
</template>

<style lang="scss">
  .sheet-icon {
    color: $info;
  }

  .sheets-table {
    border-bottom: 1px solid $gray-dark;
    font-size: 0.9rem;
    font-weight: 300;
    color: var(--gray);
  }

  .selection-column {
    width: 55px !important;
    padding: 0;
  }

  .font-8 {
    font-size: 0.8rem;
  }
</style>

<script>
import { fileStatuses, selectableFileTypes } from "@/constants"
import { Event } from "vue-tables-2"
import FileSheetSelector from "./FileSheetSelector.vue"
import TagFilters from "@/pages/Usages/TagFilters"
import camelcaseKeys from "camelcase-keys"
import { capitalize } from "lodash"
import fileSelectionMixin from "./fileSelectionMixin"

const defaultSearchAndSelectColumns = ["selection", "filename"]

export default {
  name: "FileSearchAndSelect",
  mixins: [fileSelectionMixin],
  components: {
    TagFilters,
    FileSheetSelector,
  },
  data () {
    return {
      tableRef: "searchAndSelectTable",
      showFilterPopover: false,
      selectableFileTypes,
      fileSearch: "",
      filesWithAllSheetsSelected: [],
      tagFilters: {},
      files: [],
      columns: defaultSearchAndSelectColumns,
      options: {
        skin: "table",
        customFilters: ["dsp", "licensee", "use_type", "priority", "usage_period", "remitting_society"],
        allFilesAndSheetsSelected: false,
        perPage: 5,
        showChildRowToggler: false,
        headings: {
          selection: "",
          filename: capitalize(this.$t("filename")),
          period: capitalize(this.$t("period")),
        },
        columnsClasses: {
          selection: "selection-column",
          filename: "text-break",
        },
        requestKeys: { query: "fuzzy_search" },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, status: fileStatuses.MATCHED }
          let component = this.$parent.$parent

          return component.getRequestFunction()(queryParams)
        },
        responseAdapter ({ data }) {

          return {
            data: data.results,
            count: data.count,
          }
        },
      },
    }
  },
  methods: {
    camelcaseKeys,
    getRequestFunction () {
      return {
        [selectableFileTypes.DIGITAL]: this.$api.digital.digitalFilesList,
        [selectableFileTypes.USAGES]: this.$api.usages.usageFilesList,
        [selectableFileTypes.CRD]: this.$api.crd.crdFilesList,
        [selectableFileTypes.VOD]: this.$api.vod.vodFilesList,
      }[this.fileType]
    },
    onShowFiltersPopover () {
      this.showFilterPopover = !this.showFilterPopover
    },
    onFileSearch (value) {
      this.fileSearch = value
    },
    onTagsFilter (type, value) {
      this.tagFilters[type] = value
      Event.$emit(`vue-tables.filter::${type}`, value)
    },
    onLoaded ({ data }) {
      this.files = data.results
      if (this.fileOnlySelection) {
        this.filesWithAllSheetsSelected = this.files.filter(f => this.selectedFiles.includes(f.id)).map(f => f.id)
      } else {
        this.filesWithAllSheetsSelected = this.files.filter(f => f.sheets.length && f.sheets.every(s => this.selectedSheets.includes(s.id))).map(f => f.id)
      }

    },
    onChange (value, row) {
      let otherSheetIds = this.pageSheetIds.filter(i => !row.sheets.map(s => s.id).includes(i))
      this.checkAllSelected(otherSheetIds, this.selectedSheets)

      this.$emit("select-file", value, row)
    },
    onPageFilesSelect (value) {
      this.$emit("select-page-files", value, this.files)
    },
    clearFilters () {
      for (let filter in this.tagFilters) {
        Event.$emit(`vue-tables.filter::${filter}`, "")
      }

      this.tagFilters = {}
    }
  },
  watch: {
    fileSearch (query) {
      this.$refs[this.tableRef].setFilter(query)
    },
    searchAndSelectColumns (columns) {
      this.columns = columns
    },
    externalFilters (filters) {
      for (let filter in filters) {
        Event.$emit(`vue-tables.filter::${filter}`, filters[filter])
      }
    }
  },
  mounted () {
    this.columns = this.searchAndSelectColumns
  },
  props: {
    fileType: String,
    searchAndSelectColumns: {
      type: Array,
      default: () => defaultSearchAndSelectColumns
    },
    externalFilters: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
