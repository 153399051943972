import { render, staticRenderFns } from "./DomesticPoolsDetail.vue?vue&type=template&id=288ce370&scoped=true&"
import script from "./DomesticPoolsDetail.vue?vue&type=script&lang=js&"
export * from "./DomesticPoolsDetail.vue?vue&type=script&lang=js&"
import style0 from "./DomesticPoolsDetail.vue?vue&type=style&index=0&id=288ce370&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "288ce370",
  null
  
)

export default component.exports