<template>
  <ValidationObserver ref="poolsForm" v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(onSubmit)" >
      <div class="d-flex justify-content-between mb-4">
        <h3>
          {{ pid ? $t("edit_distribution_pool") : $t("create_distribution_pool") | capitalize }}
        </h3>
        <!-- Action Buttons -->
        <div class="d-flex justify-content-end my-3">
          <b-button
            class="mr-2"
            variant="outline-secondary"
            @click="$router.go(-1)"
          >
            {{ $t("back_to_distribution") | capitalize }}
          </b-button>
          <submit-button variant="secondary">
            {{ $t("save") | capitalize }}
          </submit-button>
        </div>
      </div>


      <!-- 1st Row -->
      <b-row>
        <b-col cols="6">
          <!-- Name -->
          <form-field
            name="name"
            :label="$t('name')"
            type="text"
            rules="required"
            v-model="form.name"
          />
        </b-col>
        <b-col cols="3">
          <!-- Code -->
          <form-field
            name="code"
            :label="$t('code')"
            type="text"
            maxlength="20"
            rules="required|alphaNum"
            v-model="form.code"
            upper
          />
        </b-col>
        <b-col cols="3">
          <!-- Fund ID -->
          <form-field
            name="fundInternalId"
            :label="$t('fund_id')"
            type="text"
            v-model="form.fundInternalId"
          />
        </b-col>
      </b-row>

      <!-- 2nd Row -->
      <b-row>
        <!-- Use Type -->
        <b-col cols="6">
          <multi-select-field
            :label="$t('use_type')"
            name="useType"
            rules="required"
            v-model="form.useType"
            :options="useTypeOptions"
            choiceLabel="name"
            track-by="code"
            @input="onChange()"
            placeholder
            group-values="rightTypeGroup"
            group-label="display"
            :group-select="false"
            :custom-label="useTypeCustomLabel"
          />
        </b-col>
        <!-- Period -->
        <b-col>
          <date-field
            :label="$t('start_date')"
            name="startDate"
            v-model="form.startDate"
            class="w-50 mr-3"
          />
        </b-col>
        <b-col>
          <date-field
            :label="$t('end_date')"
            name="endDate"
            v-model="form.endDate"
            :disabled="!form.startDate"
            :disabled-date="beforeStartDate"
            class="w-50"
          />
        </b-col>
      </b-row>

      <!-- 3rd Row -->
      <b-row>
        <!-- Gross -->
        <b-col>
          <form-field
            name="grossAmount"
            :label="$t('bank_amount')"
            type="number"
            step="0.01"
            min="0"
            v-model="form.grossAmount"
          />
        </b-col>
        <!-- Deductibles -->
        <b-col>
          <label for="deductibles" class="top-label">{{ $t('deductibles') | startcase }}:</label>
          <type-and-value-input
            v-if="form.deductiblesUnit !== null"
            :item="deductiblesItem"
            :itemTypes="amountTypeOptions"
            @input="onDeductiblesInput"
            name="deductibles"
            class="top-label"
            type="number"
            step="0.01"
            min="0"
            :max="getMaxValue(form.deductiblesUnit)"
            :helpText="form.deductiblesHelpText"
          />
        </b-col>
        <!-- Admin Costs -->
        <b-col>
          <label for="administrationCosts" class="top-label">{{ $t('administration_costs') | startcase }}:</label>
          <type-and-value-input
            v-if="form.administrationCostsUnit !== null"
            :item="administrationCostsItem"
            :itemTypes="amountTypeOptions"
            @input="onAdministrationCostsInput"
            name="administrationCosts"
            class="top-label"
            type="number"
            step="0.01"
            min="0"
            :max="getMaxValue(form.administrationCostsUnit)"
            :helpText="form.administrationCostsHelpText"
          />
        </b-col>
        <!-- Net Distributable Amount -->
        <b-col cols="3">
          <div class="d-flex align-items-center px-0 mx-0 w-100">
            <form-field
              name="netDistributableAmount"
              :label="$t('net_distributable_amount')"
              type="number"
              step="0.01"
              min="0"
              v-model="form.netDistributableAmount"
              :disabled="computeNetDistributableAmount"
              class="w-100"
            />
            <action
              :title="$t('edit')"
              @click="onEditNetDistributableAmount"
              :icon="['fas', 'pen']"
              class="ml-2 mt-2"
            />
          </div>
        </b-col>
      </b-row>

      <!-- Selection Section -->
      <b-row class="my-5">
        <b-col>
          <radio-pill-toggle @selected="onSectionSelect" :options="SECTIONS" />
          <div class="content">
            <!-- Rules Selection -->
            <div class="m-5" v-if="activeSection === 'rules'">
              <!-- Level 1 Rules -->
              <PoolRulesLevel
                :level="1"
                :options="LEVEL_ONE_OPTIONS"
                :value="form.distributionPoolRules.levelOne"
                @selected="onLevelOneSelected"
                ref="poolRulesLevelOne"
              />
              <!-- Level 2 Rules -->
              <PoolRulesLevel
                v-if="levelOneIsUsageBased"
                :level="2"
                :options="LEVEL_TWO_OPTIONS"
                :value="form.distributionPoolRules.levelTwo"
                @selected="onLevelTwoSelected"
              />
            </div>

            <!-- Files Selection -->
            <div class="m-5" v-if="activeSection === 'files'">

              <!-- Usage based -->
              <div v-if="levelOneIsUsageBased">
                <UsageFilesSelector
                  :usageFiles="form.usageFiles"
                  @select-usage-sheets="onSelectUsageSheets"
                />
              </div>

              <!-- Pro-rata based -->
              <div v-if="levelOneIsProRataBased" class="mt-4 pt-4">
                <div class="d-flex justify-content-center">
                  <div v-if="pid">
                    <div v-if="form.proRataFile" class="text-center d-flex">
                      <!-- Download current pro-rata file -->
                      <b-button
                        class="mx-2"
                        @click="downloadProRataFile"
                      >
                        {{ $t("download_current_file") | capitalize }}
                      </b-button>

                      <!-- Delete current pro-rata file -->
                      <div class="mx-2">
                        <b-button
                        @click="onDeleteFile"
                        >
                          {{ $t('delete_pro_rata_file') | capitalize }}
                        </b-button>
                        <modal
                          :id="'delete-pro-rata-modal'"
                          :title="$t('delete_pro_rata_file') | capitalize"
                          :okTitle="$t('yes') | capitalize"
                          :cancelTitle="$t('no') | capitalize"
                          @ok="onConfirmDelete()"
                        >
                          <div>{{ $t("confirm_delete_pro_rata_file") | capitalize }}?</div>
                        </modal>
                      </div>
                    </div>
                    <div v-else class="d-flex flex-column justify-content-center">
                       <!-- Download pro-rata file template -->
                      <div class="download-link mb-4">
                        <span class="float-right text-info" @click="onDownloadTemplate">
                          {{ $t('download_prorata_file_template') | capitalize }}
                        </span>
                      </div>
                      <!-- Upload pro-rata file -->
                      <b-button
                        @click="onUploadFile"
                      >
                        {{ $t('upload_pro_rata_file') | capitalize }}
                      </b-button>
                      <file-upload-modal
                        :title="$t('upload_pro_rata_file')"
                        :uploadUrl="proRataFileUploadUrl"
                        @pro-rata-file-upload="onProRataFileUpload"
                      />
                    </div>
                  </div>
                  <!-- Pool is not created yet -->
                  <div v-else>{{ $t("pool_must_be_created_before_attaching") | capitalize }}</div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-form>
    <modal
      id="rules-change-warning"
      :title="`${$t('warning')}` | capitalize"
      @ok="changeRuleType"
      @cancel="onCancelRuleChange"
    >
      <p>{{ $t("rules_change_warning") }}?</p>
    </modal>
  </ValidationObserver>
</template>

<style lang="scss" scoped>
.download-link:hover {
  cursor: pointer;
}

.top-label {
  font-size: 0.85rem;
}
</style>

<script>
import { capitalize, chain } from "lodash"
import { clickOnUrl, downloadFile } from "@/services/utils"
import { distributionPoolRuleTypes, fundUnitTypes, getDistributionPoolRuleTypes } from "@/constants"
import { mapMutations, mapState } from "vuex"
import PoolRulesLevel from "./PoolRulesLevel"
import UsageFilesSelector from "./UsageFilesSelector.vue"
import { ValidationObserver } from "vee-validate"
import { i18n } from "@/utils/i18n"
import moment from "moment"

const {
  PRORATA_EQUAL,
  PRORATA_PERCENTAGE,
  WITH_UP,
  WITHOUT_UP,
  PER_PLAY,
  PER_DURATION,
  PER_WEIGHTED_PLAY,
} = distributionPoolRuleTypes

const usageBasedRuleTypes = [ WITH_UP, WITHOUT_UP ]
const proRataBasedRuleTypes = [PRORATA_EQUAL, PRORATA_PERCENTAGE]
const levelOneTypes = [ PRORATA_EQUAL, PRORATA_PERCENTAGE, WITH_UP, WITHOUT_UP ]
const levelTwoTypes = [ PER_DURATION, PER_PLAY, PER_WEIGHTED_PLAY ]

const LEVEL_ONE_OPTIONS = levelOneTypes.map(type => ({ text: getDistributionPoolRuleTypes(type), value: type }))
const LEVEL_TWO_OPTIONS = levelTwoTypes.map(type => ({ text: getDistributionPoolRuleTypes(type), value: type }))

const SECTIONS = [
  { text: capitalize(i18n.t("rules")), value: "rules" },
  { text: capitalize(i18n.t("files")), value: "files" },
]

const UNIT_TYPES = {
  A: "A",
  P: "P",
}

export default {
  name: "PoolsForm",
  components: {
    PoolRulesLevel,
    UsageFilesSelector,
    ValidationObserver,
  },
  data () {
    return {
      SECTIONS,
      LEVEL_ONE_OPTIONS,
      LEVEL_TWO_OPTIONS,
      activeSection: SECTIONS[0].value,
      computeNetDistributableAmount: false,
      amountTypeOptions: [
        fundUnitTypes.P,
        fundUnitTypes.A,
      ],
      distributionPoolRuleTypes,
      form: {
        name: "",
        code: "",
        fundInternalId: "",
        useType: null,
        startDate: null,
        endDate: null,
        grossAmount: null,
        deductibles: null,
        deductiblesPercent: 0,
        deductiblesUnit: null,
        deductiblesHelpText: null,
        deductiblesUnitsHaveChanged: false,
        administrationCosts: null,
        administrationCostsPercent: 0,
        administrationCostsUnit: null,
        administrationCostsHelpText: null,
        administrationCostsUnitsHaveChanged: false,
        netDistributableAmount: null,
        distributionPoolRules: {
          levelOne: this.pid ? null : WITH_UP,
          levelTwo: this.pid ? null : PER_DURATION,
        },
        sheets: [],
        proRataFile: "",
      },
      useTypeOptions: [],
      selectedLevelOne: null
    }
  },
  computed: {
    ...mapState("consts", ["useTypes", "useTypesHash", "rightTypesHash"]),
    levelOneIsUsageBased () {
      return usageBasedRuleTypes.includes(this.form.distributionPoolRules.levelOne)
    },
    levelOneIsProRataBased () {
      return proRataBasedRuleTypes.includes(this.form.distributionPoolRules.levelOne)
    },
    proRataFileUploadUrl () {
      return `/distributions/${this.id}/pools/${this.pid}/pro_rata_file/`
    },
    hasFiles () {
      return this.form.usageFiles?.length > 0 || this.form.sheets?.length > 0 || this.form.proRataFile
    },
    administrationCostsItem () {
      let type = this.form.administrationCostsUnit
      let value = type === UNIT_TYPES.P ? this.form.administrationCostsPercent : this.form.administrationCosts

      return {
        type: fundUnitTypes[type],
        value,
      }
    },
    deductiblesItem () {
      let type = this.form.deductiblesUnit
      let value = type === UNIT_TYPES.P ? this.form.deductiblesPercent : this.form.deductibles

      return {
        type: fundUnitTypes[type],
        value,
      }
    }
  },
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    ...mapMutations("consts", ["setUseTypes", "setRightTypes"]),
    beforeStartDate (date) {
      return date < moment(this.form.startDate)
    },
    transformRules (rules) {
      let levelOneMatches = rules.filter(r => levelOneTypes.includes(r.type))
      let levelTwoMatches = rules.filter(r => levelTwoTypes.includes(r.type))
      return {
        levelOne: levelOneMatches.length ? levelOneMatches[0].type : this.form.distributionPoolRules.levelOne,
        levelTwo: levelTwoMatches.length ? levelTwoMatches[0].type : this.form.distributionPoolRules.levelTwo,
      }
    },
    onAdministrationCostsInput (administrationCosts) {
      let { type } = administrationCosts
      if (
        (type === fundUnitTypes.P && this.form.administrationCostsUnit !== UNIT_TYPES.P)
        || (type === fundUnitTypes.A && this.form.administrationCostsUnit !== UNIT_TYPES.A)
      ) {
        administrationCosts.value = 0
        this.administrationCostsUnitsHaveChanged = true
      }

      if (type === fundUnitTypes.P) {
        this.form.administrationCostsUnit = UNIT_TYPES.P
        this.form.administrationCostsPercent = administrationCosts.value
      } else {
        this.form.administrationCostsUnit = UNIT_TYPES.A
        this.form.administrationCosts = administrationCosts.value
      }
    },
    onDeductiblesInput (deductibles) {
      let { type } = deductibles
      if (
        (type === fundUnitTypes.P && this.form.deductiblesUnit !== UNIT_TYPES.P)
        || (type === fundUnitTypes.A && this.form.deductiblesUnit !== UNIT_TYPES.A)
      ) {
        deductibles.value = 0
        this.deductiblesUnitsHaveChanged = true
      }
  
      if (type === fundUnitTypes.P) {
        this.form.deductiblesUnit = UNIT_TYPES.P
        this.form.deductiblesPercent = deductibles.value
      } else {
        this.form.deductiblesUnit = UNIT_TYPES.A
        this.form.deductibles = deductibles.value
      }
    },
    onEditNetDistributableAmount () {
      this.form.deductibles = 0
      this.form.deductiblesPercent = 0
      this.form.deductiblesUnit = UNIT_TYPES.A
      this.form.deductiblesHelpText = null
      this.form.administrationCosts = 0
      this.form.administrationCostsPercent = 0
      this.form.administrationCostsUnit = UNIT_TYPES.A
      this.form.administrationCostsHelpText = null
      this.computeNetDistributableAmount = false
    },
    onLevelOneSelected (value) {
      const ruleTypeChanges = this.checkPoolRuleTypeChanges(value)
      this.selectedLevelOne = value

      //If rule type has changed and there are files attached we display the warning modal
      if (this.hasFiles && ruleTypeChanges) {
        this.$bvModal.show("rules-change-warning")
      } else if (ruleTypeChanges) {
        //If rule type has changed but there're no files attached, we don't display warning modal and we change rule type directly
        this.changeRuleType()
      } else {
        this.onLevelOneChange()
      }
    },
    onLevelOneChange () {
      this.form = { ...this.form, distributionPoolRules: { ...this.form.distributionPoolRules, levelOne: this.selectedLevelOne } }
      if (this.levelOneIsUsageBased && !this.form.distributionPoolRules.levelTwo) this.form = { ...this.form, distributionPoolRules: { levelOne: this.selectedLevelOne, levelTwo: PER_DURATION } }
      if (!this.levelOneIsUsageBased && this.form.distributionPoolRules.levelTwo) this.form = { ...this.form, distributionPoolRules: { levelOne: this.selectedLevelOne, levelTwo: null } }
    },
    onLevelTwoSelected (value) {
      this.form = { ...this.form, distributionPoolRules: { ...this.form.distributionPoolRules, levelTwo: value } }
    },
    onChange () {
      this.$nextTick(() => {
        this.$emit("input", this.form)
      })
    },
    onSelectUsageSheets (sheets) {
      this.form = { ...this.form, sheets }
    },
    onDownloadTemplate () {
      const { levelOne: ruleType } = this.form.distributionPoolRules
      const params = {
        [PRORATA_EQUAL]: "equal",
        [PRORATA_PERCENTAGE]: "percentage",
      }

      this.$api.distributions.distributionsPoolProRataTemplate(this.id, this.pid, params[ruleType]).then((response) => {
        try {
          const filename = response.headers["content-disposition"].replace(/^inline; filename=|"/g, "")
          downloadFile(response.data, filename)
        } catch (error) {
          this.error(error)
        }
      })
    },
    onUploadFile () {
      this.$bvModal.show("upload-file-modal")
    },
    checkPoolRuleTypeChanges (selectedLevelOne) {

      const levelOneTypes = {
        proRata: proRataBasedRuleTypes,
        usageFiles: usageBasedRuleTypes,
      }

      const { levelOne: currentLevelOne } = this.form.distributionPoolRules
      if (!selectedLevelOne || !currentLevelOne) return false

      //This checks if current level has changed level types
      const isCurrentTypeEqualsToInitType = Object.values(levelOneTypes).find((levelOneType) => levelOneType.includes(currentLevelOne)).includes(selectedLevelOne)

      //This checks if current level has changed between prorata types
      const changedBetweenProRataTypes = this.levelOneIsProRataBased && this.isLevelProRataBased(selectedLevelOne) && selectedLevelOne !== currentLevelOne

      return !isCurrentTypeEqualsToInitType || changedBetweenProRataTypes
    },
    clearFiles () {
      if (this.levelOneIsUsageBased) {
        this.form.proRataFile = ""
      } else if (this.levelOneIsProRataBased) {
        this.form.sheets = []
        this.form.usageFiles = []
      }
    },
    onCancelRuleChange () {
      this.$refs.poolRulesLevelOne.updateValue()
    },
    changeRuleType () {
      this.onLevelOneChange()
      this.clearFiles()
      if (this.pid) this.onSubmit()
    },
    onSubmit () {
      let data = { ...this.form }
      data.distributionPoolRules = Object.values(data.distributionPoolRules).map(type => ({ type })).filter(rule => rule.type)
      data.useType = data.useType ? data.useType.code : null

      let { distributionPoolsCreate, distributionPoolsUpdate } =  this.$api.distributions

      let call = this.pid ? distributionPoolsUpdate(this.id, this.pid, data) : distributionPoolsCreate(this.id, data)

      call
        .then(() => {
          if (this.pid) {
            // On edit: Show success toast.
            this.success(this.$t("distribution_pool_successfully_edited"))
            this.loadData()
          } else {
            // On create: Show success toast and redirect.
            this.success(this.$t("distribution_pool_successfully_created"))
            this.$router.go(-1)
          }
        })
        .catch(error => {
          const { data } = error.response
          if (data?.length) this.error(data[0])
          this.$refs.poolsForm.setErrors(data)
        })
    },
    async loadData () {
      const response = await this.$api.distributions.distributionPoolsRetrieve(this.id, this.pid)

      this.form = {
        ...response.data,
        useType: this.useTypesHash[response.data.useType],
        distributionPoolRules: this.transformRules(response.data.distributionPoolRules),
      }
    },
    onSectionSelect (value) {
      this.activeSection = value
    },
    onDeleteFile () {
      this.$bvModal.show("delete-pro-rata-modal")
    },
    onConfirmDelete () {
      this.$api.distributions.distributionsPoolProRataDelete(this.id, this.pid)
        .then(() => {
          this.success(this.$t("pro_rata_file_deleted"))
          this.form.proRataFile = ""
        }).catch(error => {
          if (error.response.data && error.response.data.detail) this.error(error.response.data.detail)
        })
    },
    downloadProRataFile () {
      this.$api.distributions.distributionPoolsDownloadProRataFile(this.id, this.pid)
        .then(({ data }) => {
          const { url } = data
          clickOnUrl(url)
        })
        .catch((err) => {
          let message = err.response.data?.message || err.response.data || err.response
          this.error(message)
        })
    },
    onProRataFileUpload (file) {
      this.form.proRataFile = file
    },
    useTypeCustomLabel (element) {
      return element.code ? `${element.code} - ${element.name}` : ""
    },
    populateUseTypeSelector () {
      this.useTypeOptions = chain(this.useTypes).groupBy("rightType").map((value, key) => (
        { key: key,
          display: capitalize(this.rightTypesHash[key].name),
          rightTypeGroup: value
        }
      )).value()
    },
    isLevelProRataBased (level) {
      return proRataBasedRuleTypes.includes(level)
    },
    getMaxValue (unit) {
      if (unit === UNIT_TYPES.P) return 100.00
    },
    convertPercentToAmount (percentValue) {
      return this.form.grossAmount * percentValue/100
    },
  },
  watch: {
    "form": {
      deep: true,
      handler: function (formData) {
        let administrationCostsAmount = formData.administrationCosts
        let deductiblesAmount = formData.deductibles

        if (formData.administrationCostsUnit === UNIT_TYPES.P) {
          administrationCostsAmount = this.convertPercentToAmount(formData.administrationCostsPercent)
          this.form.administrationCostsHelpText = administrationCostsAmount.toString()
        } else {
          this.form.administrationCostsHelpText = ""
        }

        if (formData.deductiblesUnit === UNIT_TYPES.P) {
          deductiblesAmount = this.convertPercentToAmount(formData.deductiblesPercent)
          this.form.deductiblesHelpText = deductiblesAmount.toString()
        } else {
          this.form.deductiblesHelpText = ""
        }

        this.computeNetDistributableAmount = Boolean(parseFloat(administrationCostsAmount)) || Boolean(parseFloat(deductiblesAmount))

        if (this.computeNetDistributableAmount || this.administrationCostsUnitsHaveChanged || this.deductiblesUnitsHaveChanged) {
          let netDistributableAmount = formData.grossAmount - administrationCostsAmount - deductiblesAmount
          this.form.netDistributableAmount = netDistributableAmount.toFixed(2)
        }
      }
    },
  },
  async mounted () {
    // Set UseTypes
    let response = await this.$api.rights.useTypesList()
    this.setUseTypes(response.data)

    response = await this.$api.rights.rightsList()
    this.setRightTypes(response.data)

    this.populateUseTypeSelector()

    // Retrieve a pool if a pool ID is passed as prop
    if (this.pid) {
      this.loadData()
    } else {
      this.form = { 
        ...this.form,
        deductiblesUnit: UNIT_TYPES.A,
        administrationCostsUnit: UNIT_TYPES.A,
        deductibles: 0,
        administrationCosts: 0
      }
    }
  },
  props: {
    id: String, // Distrbution ID
    pid: String, // Pool ID,
    crd: Boolean,
  }
}
</script>
