<template>
  <component :is="poolComponent" :id="id" :pid="pid"/>
</template>

<script>
import CRDPoolsForm from "./CRD/CRDPoolsForm"
import DigitalPoolsForm from "./Digital/DigitalPoolsForm"
import DomesticPoolsForm from "./Domestic/DomesticPoolsForm"
import VODPoolsForm from "./VOD/VODPoolsForm.vue"
import { poolsFormType  } from "@/constants"

export default {
  name: "PoolsFormPage",
  components: { CRDPoolsForm, DomesticPoolsForm, DigitalPoolsForm, VODPoolsForm },
  computed: {
    poolComponent () {
      if (!this.type) {
        return DomesticPoolsForm
      }
      return {
        [poolsFormType.DOMESTIC]: DomesticPoolsForm,
        [poolsFormType.CRD]: CRDPoolsForm,
        [poolsFormType.DIGITAL]: DigitalPoolsForm,
        [poolsFormType.VOD]: VODPoolsForm,
      }[this.type]
    }
  },
  props: {
    id: String, // Distribution ID
    pid: String, // Pool ID,
    type: String,
  }
}
</script>
