<template>
  <div class="d-flex align-items-center justify-content-between">
    <div>
      <b-badge @click="switchView" class="mr-1 clickable" pill variant="secondary">
        {{ selectedItems.length }} {{ $t("selected") }}
      </b-badge>
      <span @click="clear" class="clickable text-danger small">
        {{ $t("clear_selected") }}
      </span>
    </div>
    <!-- Actions -->
    <slot name="actions"></slot>
  </div>
</template>

<script>
export default {
  name: "SelectionSummary",
  props: {
    selectedItems: {
      type: Array,
      required: true,
    },
    switchView: {
      type: Function,
      required: true,
    },
    clear: {
      type: Function,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.badge {
  font-size: 75% !important;
}

.clickable {
  cursor: pointer;
}
</style>
