<!-- Based On: https://codesandbox.io/s/validation-components-bootstrapvue-usdwv -->
<template>
  <ValidationProvider :vid="$attrs.name" :name="$attrs.name" :rules="rules" v-slot="{ classes, errors: validationErrors }">
    <transition name="fade" mode="out-in">
      <b-form-group
        v-bind="$attrs"
        :label="null"
      >
        <label :for="$attrs.name" v-if="$attrs.label">
          {{ $attrs.label | startcase }}: <span v-if="isRequired" class="text-danger">*</span>
        </label>
        <multi-select
          v-model="innerValue"
          v-bind="$attrs"
          v-on="$listeners"
          :label="$attrs.choiceLabel"
          :class="classes"
        >
          <template v-slot:option="props">
            <slot name="option" v-bind="props"></slot>
          </template>
          <template v-slot:noOptions v-if="$attrs.placeholder">
            {{ $attrs.placeholder }}
          </template>
          <template v-slot:noResult v-if="noResultMessage">
            {{ noResultMessage }}
          </template>
        </multi-select>
        <b-form-text v-if="helpText">{{ helpText }}</b-form-text>
        <b-form-invalid-feedback :state="!errors[0] && !validationErrors[0]">{{ errors[0] || validationErrors[0] }}</b-form-invalid-feedback>
      </b-form-group>
    </transition>
  </ValidationProvider>
</template>

<style lang="scss" scoped>
  label {
    font-weight: bold;
    font-size: 0.9rem;
  }

  ::v-deep .multiselect {
    width: 100%;

    [class$="multiselect__tags"] {
      border-color: $input-border-color;
    }
    &.invalid { [class$="multiselect__tags"] { border-color: var(--danger); } }
  }
</style>

<script>
import { ValidationProvider } from "vee-validate"

export default {
  name: "MultiSelectField",
  components: {
    ValidationProvider
  },
  props: {
    rules: {
      type: [Object, String],
      default: ""
    },
    // must be included in props
    value: {
      type: null
    },
    errors: {
      type: Array,
      default: () => []
    },
    noResultMessage: {
      type: String,
      default: ""
    },
    helpText: String
  },
  data: () => ({
    innerValue: ""
  }),
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit("input", newVal)
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal
    }
  },
  computed: {
    isRequired () {
      return this.rules ? this.rules.split("|").includes("required") : false
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>
