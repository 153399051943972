<template>
  <div class="d-inline align-items-center">
    <span class="text mr-2" >{{ count }}</span>
    <fa-icon class="icon" :icon="icon" sm/>
  </div>
</template>

<style lang="scss" scoped>
  .text {
    color: var(--info);
    font-size: 1.2rem;
  }

  .icon {
    color: var(--info);
  }
</style>

<script>
export default {
  name: "ItemCount",
  props: {
    count: [String, Number],
    icon: Array
  }
}
</script>