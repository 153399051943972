<template>
  <div>
    <ValidationObserver ref="partyCreateForm" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onChange)">
        <!-- Names -->
        <form-field
          name="firstNamePA"
          :label="firstNameLabel"
          :capitalizeLabel="false"
          type="text"
          rules="required"
          v-model="form.firstName"
          upper
        />
        <form-field
          v-if="isContributor"
          name="lastNamePA"
          :label="lastNameLabel"
          :capitalizeLabel="false"
          type="text"
          v-model="form.lastName"
          upper
        />
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate"
import { partyTypes } from "@/constants"
import { startCase } from "lodash"

const { CONTRIBUTOR } = partyTypes

export default {
  name: "NonIPICreateForm",
  components: {
    ValidationObserver,
  },
  data () {
    return {
      form: {
        firstName: this.selectedParty?.firstName,
        lastName: this.selectedParty?.lastName,
      }
    }
  },
  computed: {
    isContributor () {
      return this.type == CONTRIBUTOR
    },
    firstNameLabel () {
      return this.isContributor ? `${startCase(this.$t("first_name"))} (${startCase(this.$t("patronym"))})`: startCase(this.$t("name"))
    },
    lastNameLabel () {
      return `${startCase(this.$t("last_name"))} (${startCase(this.$t("patronym"))})`
    }
  },
  watch: {
    "form.firstName" () {
      this.onChange()
    },
    "form.lastName" () {
      this.onChange()
    },
    "selectedParty.firstName" () {
      this.form.firstName = this.selectedParty?.firstName
    },
    "selectedParty.lastName" () {
      this.form.lastName = this.selectedParty?.lastName
    }
  },
  methods: {
    onChange () {
      this.form.lastName = this.isContributor ? this.form.lastName : null
      this.$emit("create-party", this.form)
    },
  },
  props: {
    type: String,
    selectedParty: Object
  }
}
</script>
