import Vue from "vue"
import { router } from "../utils"


let auth = new Vue({
  data () {
    return {
      user: {},
      error: null,
      loading: true,
    }
  },
  methods: {
    getToken (){
      return localStorage.getItem("access_token")
    },
    isAuthenticated () {
      // Check whether the current time is past the access token's expiry time
      let expiresAt = JSON.parse(localStorage.getItem("expires_at"))
      return new Date().getTime() < expiresAt
    },
    setSession (authResult) {
      // Set the time that the access token will expire at
      let expiresAt = JSON.stringify(
        authResult.expiresIn * 1000 + new Date().getTime()
      )
      localStorage.setItem("access_token", authResult.accessToken)
      localStorage.setItem("id_token", authResult.idToken)
      localStorage.setItem("expires_at", expiresAt)
    },
    clearSession () {
      localStorage.removeItem("access_token")
      localStorage.removeItem("id_token")
      localStorage.removeItem("expires_at")
      localStorage.removeItem("society")
    },
    logout () {
      // Clear access token and ID token from local storage
      // and proceed to login.
      this.clearSession()
      router.replace({ name: "login" })
    }
  }
})

export const Authentication = {
  install (Vue) {
    Vue.prototype.$auth = auth
  }
}