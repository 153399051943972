<template>
  <list-layout
    :title="title"
    :count="count"
    :show-refresh="true"
  >
    <template v-slot:subtitle>
      <template v-if="context.uploader">
        <b>{{ $t("uploaded_by") | capitalize }}: </b>
          {{ context.uploader | get("name") }} ({{ context.uploader | get("party") }})<br>
        <b>{{ $t("upload_date") | capitalize }}: </b> {{ parseDate(context.uploadDate) }} <br>
      </template>
      <template v-if="!isEmpty(context.metadata)">
        <b>{{ $t("creation_date") | capitalize }}: </b> {{ parseDate(context.metadata.creationDate) }} <br>
        <b>{{ $t("sender") | capitalize }}: </b> {{ context.metadata.senderName }} <br>
      </template>
    </template>

    <!-- Status -->
    <template v-slot:status v-if="showFilters">
      <div class="d-flex align-items-top">
        <div class="mr-3">
          <status
            :text="$t('created')"
            :name="landingWorkStatuses.CREATED"
            :count="summary.created"
            :icon="['fas', 'check-circle']"
            color="green"
            clickable
            :active="selectedStatus == landingWorkStatuses.CREATED"
            @click="onStatusClick"
          />
          <status
            :text="$t('linked')"
            :name="landingWorkStatuses.LINKED"
            :count="summary.linked"
            :icon="['fas', 'check-circle']"
            color="green"
            clickable
            :active="selectedStatus == landingWorkStatuses.LINKED"
            @click="onStatusClick"
          />
          <status
            :text="$t('demerged')"
            :name="landingWorkStatuses.DEMERGED"
            :count="summary.demerged"
            :icon="['fas', 'check-circle']"
            color="green"
            clickable
            :active="selectedStatus == landingWorkStatuses.DEMERGED"
            @click="onStatusClick"
          />
          <status
            :text="$t('pending')"
            :name="landingWorkStatuses.PENDING"
            :count="summary.pending"
            :icon="['fas', 'question-circle']"
            color="orange"
            clickable
            :active="selectedStatus == landingWorkStatuses.PENDING"
            @click="onStatusClick"
          />
        </div>
        <div class="mr-3 mt-0">
          <status
            :text="$t('manually_created')"
            :name="landingWorkStatuses.MANUALLY_CREATED"
            :count="summary.manuallyCreated"
            :icon="['fas', 'check-circle']"
            color="green"
            clickable
            :active="selectedStatus == landingWorkStatuses.MANUALLY_CREATED"
            @click="onStatusClick"
          />
          <status
            :text="$t('manually_linked')"
            :name="landingWorkStatuses.MANUALLY_LINKED"
            :count="summary.manuallyLinked"
            :icon="['fas', 'check-circle']"
            color="green"
            clickable
            :active="selectedStatus == landingWorkStatuses.MANUALLY_LINKED"
            @click="onStatusClick"
          />
          <status
            :text="$t('clear_filters')"
            :count="''"
            :icon="['fas', 'times']"
            clickable
            :active="selectedStatus == ''"
            @click="onStatusClick('')"
          />
        </div>
      </div>
    </template>

    <template v-slot:search>
      <b-row class="mb-3">
        <b-col cols="sm-4">
          <search-input
            :value="landingWorkSearch"
            @search="onLandingWorkSearch"
          />
        </b-col>
      </b-row>
    </template>

    <!-- Table -->
    <v-server-table
      :columns="columns"
      :options="options"
      @loaded="onLoaded"
      @pagination="onPagination"
      ref="landingWorks"
    >

      <!-- Title -->
      <template v-slot:title="{ row }">
        <span>{{ row.title }}</span>
      </template>

      <!-- Work Type -->
      <template v-slot:workType="{ row }">
        <span>{{ getWorkType(row) }}</span>
      </template>

      <!-- Landing Work Status -->
      <template v-slot:status="{ row }">
        <status
          v-if="row.status === landingWorkStatuses.CREATED"
          :text="$t('created')"
          :icon="['fas', 'check-circle']"
          color="green"
        />
        <status
          v-else-if="row.status === landingWorkStatuses.PENDING"
          :text="$t('pending')"
          :icon="['fas', 'question-circle']"
          color="orange"
        />
        <status
          v-else-if="row.status === landingWorkStatuses.LINKED"
          :text="$t('linked')"
          :icon="['fas', 'question-circle']"
          color="green"
        />
        <status
          v-else-if="row.status === landingWorkStatuses.DEMERGED"
          :text="$t('demerged')"
          :icon="['fas', 'question-circle']"
          color="green"
        />
        <status
          v-else-if="row.status === landingWorkStatuses.MANUALLY_CREATED"
          :text="$t('manually_created')"
          :icon="['fas', 'question-circle']"
          color="green"
        />
        <status
          v-else-if="row.status === landingWorkStatuses.MANUALLY_LINKED"
          :text="$t('manually_linked')"
          :icon="['fas', 'question-circle']"
          color="green"
        />
      </template>

      <!-- Reconciliation -->
      <template v-slot:reconciliation="{ row }">
        <status
          v-if="row.reconciliationStatus === workReconciliationStatuses.DONE"
          :text="$t('reconciled')"
          :icon="['fas', 'check-circle']"
          color="green"
        />
        <status
          v-else-if="row.reconciliationStatus === workReconciliationStatuses.PENDING"
          :text="$t('pending')"
          :icon="['fas', 'question-circle']"
          color="orange"
        />
        <status
          v-else-if="row.reconciliationStatus === workReconciliationStatuses.ERROR"
          :text="$t('error')"
          :icon="['fas', 'exclamation-circle']"
          color="red"
        />
      </template>

      <!-- Linked work -->
      <template v-slot:linkedWork="{ row }">
        <router-link
          v-if="row.work && row.reconciliationStatus === workReconciliationStatuses.DONE"
          :to="{ name: 'works-detail', params: { id: row.work }}"
          class="text-info"
        >
          <span class="text-info">{{ row.work }}</span>
        </router-link>
      </template>

      <!-- User Action -->
      <template v-slot:userAction="{ row }">
        <div class="d-flex justify-content-center">
          <action
            v-if="getWorksMatchingUserAction(row.userActions).length"
            @click="goToUserAction(row.userActions)"
            :title="$t('user_action')"
            :icon="['fas', 'angle-right']"
          />
        </div>
      </template>


      <!-- Actions -->
      <template v-slot:actions="{ row }">
        <div class="d-flex justify-content-end">
          <action
            @click="displayMetadataInfo(row)"
            :title="$t('show_source_metadata')"
            :icon="['far', 'eye']"
          />
        </div>
      </template>
    </v-server-table>
    <b-modal
      id="show-source-metadata-modal"
      :title="$t('show_source_metadata') | capitalize"
      hide-footer
      size="xl"
    >
      <work-detail :work="landingWork" :shares="shares" lite />
    </b-modal>
  </list-layout>
</template>

<script>
import { capitalize, get, isEmpty, upperCase } from "lodash"
import { landingWorkStatuses, userActionTypes, workReconciliationStatuses } from "@/constants"
import { landingWorkTransformations, listRouteMixin } from "@/utils/mixins"
import { mapMutations, mapState } from "vuex"
import { parseDate } from "@/utils/dates"

export default {
  name: "LandingWorksList",
  mixins: [listRouteMixin, landingWorkTransformations],
  data () {
    return {
      count: 0,
      summary: {},
      landingWork: {},
      shares: {},
      title: "",
      landingWorkSearch: "",
      landingWorkStatuses,
      workReconciliationStatuses,
      context: {
        uploader: null,
        uploadDate: "",
        metadata: {}
      },
      selectedStatus: "",
      columns: ["title", "iswc", "proprietaryId", "workType", "status", "reconciliation", "linkedWork", "userAction", "actions"],
      options: {
        customFilters: ["status"],
        initFilters: { "status": get(this.$route.query, "status", "") },
        headings: {
          title: capitalize(this.$t("title")),
          iswc: upperCase(this.$t("iswc")),
          proprietaryId: capitalize(this.$t("proprietary_id")),
          workType: capitalize(this.$t("work_type")),
          status: capitalize(this.$t("status")),
          reconciliation: capitalize( this.$t("reconciliation")),
          userAction: capitalize( this.$t("user_action")),
          linkedWork: capitalize( this.$t("linked_work")),
          actions: "",
        },
        requestKeys: { query: "fuzzy_search" },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          let { params, query } = this.$route
          queryParams = { ...queryParams, ...query }
          this.page = Number(get(queryParams, "page", 1))

          return this.$api.repertoire.repertoireFilelandingWorksList(params.id, queryParams)
        }
      },
    }
  },
  computed: {
    ...mapState("filters", ["search"]),
    ...mapState("consts", ["languages"]),
  },
  methods: {
    ...mapMutations("consts", ["setLanguages"]),
    isEmpty,
    parseDate (value) {
      return value ? parseDate(value, this.$config.DATE_FORMAT) : ""
    },
    onStatusClick (status) {
      this.selectedStatus = status
      this.applyFilters("status", status)
    },
    getLanguage (code) {
      let language = this.languages.filter(lang => lang.code === code).pop()
      return language?.name
    },
    displayMetadataInfo (row) {
      if  (row.id !== this.landingWork.id) {
        this.landingWork = this.transformLandingWork(row)
        this.shares = this.getShares(this.landingWork)
      }
      this.$bvModal.show("show-source-metadata-modal")
    },
    onLandingWorkSearch (value) {
      this.landingWorkSearch = value
    },
    onLoaded ({ data }) {
      this.title = this.pageTitle

      if (data.context) {
        const { filename, uploadDate, uploader, metadata } = data.context
        this.title = filename
        this.context.uploader = uploader
        this.context.uploadDate = uploadDate
        this.context.metadata = metadata
      }
      this.count = data.count
      this.summary = data.summary
    },
    getData () {
      this.$refs.landingWorks.getData()
    },
    getWorksMatchingUserAction (userActions) {
      return userActions.filter(action => action.type === userActionTypes.WORKS_MATCHING)
    },
    goToUserAction (userActions) {
      let worksMatchingUserAction = this.getWorksMatchingUserAction(userActions)
      let id = worksMatchingUserAction.length ? worksMatchingUserAction[0].id : null
      this.$router.push({ name: "actions-works-matching-detail", params: { id } })
    },
    getWorkType (row) {
      return row.normalizedData.type ? get(row.normalizedData.type, "type") : ""
    },
  },
  watch: {
    landingWorkSearch (value) {
      this.applyFilters("fuzzy_search", value)
    },
  },
  async mounted () {
    let response = await this.$api.countries.languagesList()
    this.setLanguages(response.data)

    this.landingWorkSearch = get(this.$route.query, "fuzzy_search", "")
    this.selectedStatus = get(this.$route.query, "status", "")
  },
  props: {
    source: String,
    showFilters: {
      type: Boolean,
      default: true
    },
    pageTitle: String,
  },
}
</script>
