<template>
  <v-server-table
    name="worksMatching"
    ref="worksMatching"
    :columns="columns"
    :options="options"
    @loaded="onLoaded"
    @pagination="onPagination"
  >
    <!-- Title -->
    <template v-slot:title="{ row }">
      <router-link
        :to="{ name: 'actions-works-matching-detail', params: { id: row.id } }"
        class="text-info"
      >
        {{ row.title }}
      </router-link>
    </template>

    <!-- ISWC -->
    <template v-slot:iswc="{ row }">
      <span class="text-primary">
        {{ row.iswc }}
      </span>
    </template>

    <!-- Status -->
    <template v-slot:status="{ row }">
      <status
        v-if="row.status === statuses.DONE"
        :text="$t('done')"
        :icon="['fas', 'check-circle']"
        color="green"
      />
      <status
        v-else-if="row.status === statuses.PENDING"
        :text="$t('pending')"
        :icon="['fas', 'question-circle']"
        color="orange"
      />
    </template>
  </v-server-table>
</template>

<script>
import { capitalize, get } from "lodash"
import { listRouteMixin } from "@/utils/mixins"
import { userActionStatuses } from "@/constants"

export default {
  name: "WorksMatchingList",
  mixins: [listRouteMixin],
  data () {
    return {
      statuses: userActionStatuses,
      columns: ["title", "iswc", "status"],
      options: {
        headings: {
          title: capitalize(this.$t("title")),
          iswc: this.$t("iswc"),
          status: capitalize(this.$t("status")),
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          return this.$api.userActions.worksMatchingList(queryParams)
        }
      },
    }
  },
  methods: {
    getData () {
      this.$refs.worksMatching.getData()
    },
    onLoaded ({ data }) {
      this.$emit("loaded", data.count)
    }
  },
  watch: {
    status (value) {
      this.applyFilters("status", value ? value.value : null)
    },
    showWithUsagesOnly (showWithUsagesOnly) {
      this.applyFilters("with_usages", showWithUsagesOnly)
    },
    currentFilter (filter) {
      let [type, value] = filter
      this.applyFilters(type, value)
    },
    codeSearch (value) {
      this.applyFilters("code", value)
    },
    titleSearch (value) {
      this.applyFilters("title", value)
    },
  },
  props: {
    titleSearch: String,
    codeSearch: String,
    status: Object,
    showWithUsagesOnly: Boolean,
    currentFilter: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>
