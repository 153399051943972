<template>
  <b-modal
    :title="$t('request_work_report') | upperfirst"
    v-bind="$attrs"
    hide-footer
  >
    <ValidationObserver ref="workReportsForm" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onAcceptModal)" >
      <form-field
        name="workId"
        :label="$t('work_id')"
        type="text"
        rules="required"
        v-model="selectedWorkId"
      />
      <div class="d-flex justify-content-center">
        <submit-button variant="secondary" class="mx-2">
          {{ $t("accept") | capitalize }}
        </submit-button>
        <b-button variant="primary" @click="closeModal" class="mx-2">
          {{ $t("cancel") | capitalize }}
        </b-button>
      </div>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { ValidationObserver } from "vee-validate"

export default {
  name: "WorkReportModal",
  components: { ValidationObserver },
  data () {
    return {
      selectedWorkId: null
    }
  },
  methods: {
    onAcceptModal () {
      this.$emit("ok", this.selectedWorkId)
      this.closeModal()
    },
    closeModal () {
      this.$bvModal.hide((this.$attrs.id))
      this.selectedWorkId = null
    }
  }
}
</script>
