<template>
  <work-detail-layout>
    <template v-slot:title>
      <div class="d-flex justify-content-between mb-2">
        <h3>{{ $t("recording_details") | startcase }}</h3>
      </div>
    </template>

    <template v-slot:top>
      <!-- CSS class defined in project.scss -->
      <div class="work-details-container">
        <b-table-lite :fields="fields" :items="[product]" borderless>
          <template v-slot:head()="item">
            <div class="head">
              {{ item.label | capitalize }}
            </div>
          </template>

          <!-- Type -->
          <template v-slot:cell(type)="{ item }">
            {{ getTypeDisplay(item) | capitalize }}
          </template>
        </b-table-lite>
      </div>
    </template>

    <template v-slot:content>
      <page-section :title="$t('works') | capitalize" :collapsibe="false">
        <template v-slot:body>
          <works-table-lite :extraOptions="workExtraOptions" :id="id" />
        </template>
      </page-section>
    </template>
  </work-detail-layout>
</template>

<script>
import { getProductTypeDisplay, getRecordingTypeDisplay, productTypes } from "@/constants"


export default {
  name: "ProductsDetail",
  data () {
    return {
      product: {},
      srFields: [
        { key: "title", label: this.$t("title") },
        { key: "type", label: this.$t("type") },
        { key: "isrc", label: "ISRC" },
        { key: "artist", label: this.$t("artist") },
      ],
      releaseFields: [
        { key: "name", label: this.$t("name") },
        { key: "type", label: this.$t("type") },
      ],
      workExtraOptions: {
        requestFunction () {
          const id = this.$parent.$parent.id
          const queryParams = { page: this.page }
          return this.$api.products.productsWorksList(id, queryParams)
        }
      }
    }
  },
  methods: {
    getTypeDisplay (product) {
      return product.type == productTypes.RECORDING ? getRecordingTypeDisplay(product.recordingType) : getProductTypeDisplay(product.type)
    },
  },
  computed: {
    fields () {
      return this.product.type == productTypes.RECORDING ? this.srFields : this.releaseFields
    }
  },
  async mounted () {
    let response = await this.$api.products.productsRetrieve(this.id)
    this.product = response.data
  },
  props: {
    id: [String, Number],
  }
}
</script>