<template>
  <section>
    <b-skeleton-table
      v-if="waiting"
      :rows="1"
      :columns="workColumns.length"
      :table-props="{ bordered: false, striped: true }"
    />
    <v-server-table
      name="worksList"
      ref="worksList"
      :columns="workColumns"
      :options="workOptions"
      @loaded="onLoaded"
    >
      <!-- Title -->
      <template v-slot:title="{ row }">
        <router-link
          :to="{ name: 'works-detail', params: { id: row.id } }"
          class="text-info"
        >
          {{ row.title }}
        </router-link>
      </template>

      <!-- ISWC -->
      <template v-slot:iswc="{ row }">
        <span class="text-primary">
          {{ row.iswc }}
        </span>
      </template>
    </v-server-table>
  </section>
</template>

<script>
import { capitalize } from "lodash"
import { mapState } from "vuex"

export default {
  name: "WorksTableLite",
  data () {
    return {
      count: null,
      workColumns: ["title", "id", "iswc"],
      workOptions: {
        headings: {
          title: capitalize(this.$t("title")),
          id: "Atlas ID",
          iswc: this.$t("iswc"),
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        ...this.extraOptions,
      },
    }
  },
  computed: {
    ...mapState("user", ["waiting"]),
  },
  methods: {
    onLoaded ({ data }) {
      this.count = data.count
    },
  },
  watch: {
    count (value) {
      this.$emit("count", value)
    }
  },
  props: {
    id: [String, Number],
    extraOptions: Object,
  },
}
</script>
