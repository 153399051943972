var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{attrs:{"items":_vm.items,"fields":_vm.fields,"borderless":"","striped":"","show-empty":""},scopedSlots:_vm._u([{key:"cell(ipiNameNumber)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.party.ipiNameNumber)+" ")]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('b-col',{staticClass:"pl-0"},[_c('span',[_vm._v(_vm._s(item.party.name))])])],1)]}},{key:"cell(performing)",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('split-input',{staticClass:"narrow",attrs:{"name":("payee-performing-" + index),"value":item.performing,"max":"100","disabled":!_vm.canEdit(item)},on:{"input":function($event){return _vm.onPerformingInput($event, item, index)}}}),_c('div',{staticClass:"ml-2 mb-3"},[_vm._v("%")])],1)]}},{key:"cell(mechanical)",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('split-input',{staticClass:"narrow",attrs:{"name":("payee-mechanical-" + index),"value":item.mechanical,"max":"100","disabled":!_vm.canEdit(item)},on:{"input":function($event){return _vm.onMechanicalInput($event, item, index)}}}),_c('div',{staticClass:"ml-2 mb-3"},[_vm._v("%")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }