const state = {
  selection: [],
}

const mutations = {
  setSelection (state, selection) {
    state.selection = selection
  },
  clear (state) {
    state.selection = []
  }
}

const actions = {
  clearSelection ({ commit }) {
    commit("clear")
  },
  setSelection ({ commit }, selection) {
    commit("setSelection", selection)
  },
}

export const selection = {
  namespaced: true,
  state,
  actions,
  mutations
}