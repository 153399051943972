<template>
  <div>
    <div v-for="(title, i) in titles" :key="i" class="mb-2">
      <b-row>
        <b-col>
          <p class="my-0">{{ title.title }}</p>
          <small class="my-0">{{ $t("type") | capitalize }}: {{ title.type }}</small>
        </b-col>
        <b-col cols="3">{{ $t(title.action) | capitalize }}</b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { getWorkFieldActionTypesDisplay } from "@/constants"

export default {
  name: "TitlesUpdate",
  methods: {
    getWorkFieldActionTypesDisplay,
  },
  props: {
    titles: Array,
  }
}
</script>