<template>
  <list-layout
    :title="$t('digital_files')"
    :count="count"
    :filters="filters"
    :show-refresh="true"
  >
    <!-- Status -->
    <template v-slot:status>
      <div>
        <status
          :text="$t('matched')"
          :name="fileStatuses.MATCHED"
          :count="summary.matched"
          :icon="['fas', 'check-circle']"
          color="green"
          :active="filters.status === fileStatuses.MATCHED"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('ingested')"
          :name="fileStatuses.INGESTED"
          :count="summary.ingested"
          :icon="['fas', 'check-circle']"
          color="green"
          :active="filters.status == fileStatuses.INGESTED"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('landed')"
          :name="fileStatuses.LANDED"
          :count="summary.landed"
          :icon="['fas', 'check-circle']"
          color="green"
          :active="filters.status === fileStatuses.LANDED"
          clickable
          @click="onStatusClick"
        />
      </div>
      <div class="mr-3 align-self-start">
        <status
          :text="$t('processing')"
          :name="fileStatuses.PROCESSING"
          :count="summary.processing"
          :icon="['fas', 'question-circle']"
          color="orange"
          :active="filters.status == fileStatuses.PROCESSING"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('pending')"
          :name="fileStatuses.PENDING"
          :count="summary.pending"
          :icon="['fas', 'question-circle']"
          color="orange"
          :active="filters.status == fileStatuses.PENDING"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('error')"
          :name="fileStatuses.ERROR"
          :count="summary.error"
          :icon="['fas', 'exclamation-circle']"
          color="red"
          :active="filters.status == fileStatuses.ERROR"
          clickable
          @click="onStatusClick"
        />
      </div>
    </template>

    <!-- Search and Filters -->
    <template v-slot:search>
      <!-- Filters -->
      <div class="d-flex flex-wrap">
          <search-input
            :value="filters.fuzzySearch"
            @search="onFileSearch"
            :placeholder="$t('search_by_filename') | capitalize"
            class="form-group mt-auto"
          />
      </div>
    </template>

    <!-- Table -->
    <template v-slot:table>
      <v-server-table
        :columns="columns"
        :options="options"
        :ref="tableRef"
        @loaded="onLoaded"
        @pagination="onPagination"
      >
        <!-- File Name -->
        <template v-slot:filename="{ row }">
          {{ row.filename }}
          <p class="text-gray">
            <small>
              {{ $t("uploaded_by") | capitalize }} "{{ row.uploader.name }}"
            </small>
          </p>
        </template>

        <!-- Upload date -->
        <template v-slot:uploadDate="{ row }">
          <date-time-cell :dateTime="row.uploadDate"/>
        </template>

        <!-- DSP -->
        <template v-slot:dsp="{ row }">
          <small>
            <b>DSP:</b> <span class="text-gray"> {{ row.dsp }}</span><br>
            <b>{{ $t("service") | capitalize }}:</b> <span class="text-gray">{{ row.service }}</span><br>
          </small>
        </template>

        <!-- Status -->
        <template v-slot:status="{ row }">
          <status
            v-if="row.status === fileStatuses.PENDING"
            :text="$t('pending')"
            :icon="['fas', 'question-circle']"
            color="orange"
          />
          <status
            v-if="row.status === fileStatuses.PROCESSING"
            :text="$t('processing')"
            :icon="['fas', 'question-circle']"
            color="orange"
          />
          <status
            v-if="row.status === fileStatuses.LANDED"
            :text="$t('landed')"
            :icon="['fas', 'check-circle']"
            color="green"
          />
          <status
            v-if="row.status === fileStatuses.INGESTED "
            :text="$t('ingested')"
            :icon="['fas', 'check-circle']"
            color="green"
          />
          <status
            v-if="row.status === fileStatuses.MATCHED "
            :text="$t('matched')"
            :icon="['fas', 'check-circle']"
            color="green"
          />
          <status
            v-if="row.status === fileStatuses.ERROR"
            :text="$t('error')"
            :icon="['fas', 'exclamation-circle']"
            color="red"
            :active="true"
            @click="showErrors(row, 'file')"
            clickable
          />
        </template>

        <!-- Actions -->
        <template v-slot:actions="{ row }">
          <div class="d-flex justify-content-end">
            <action
              @click="showExtraInfo(row)"
              :title="$t('more_information')"
              :icon="['fas', !isOpen(row.id) ?  'angle-down': 'angle-up']"
            />
          </div>
        </template>

         <!-- Child Rows -->
        <template v-slot:child_row="{ row }">
          <row-extra-info :key="row.id">
            <b-row class="mb-2">
              <b-col cols="3" class="mr-5">
                <p class="text-gray">{{ $t('metadata') | capitalize }}</p>
                <b-row>
                  <b-col cols="6" class="text-left">{{ $t("start_date") | capitalize }}</b-col>
                  <b-col class="text-right text-gray pr-0" cols="6">{{ parseDate(row.startDate) }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" class="text-left">{{ $t("end_date") | capitalize }}</b-col>
                  <b-col class="text-right text-gray pr-0" cols="6">{{ parseDate(row.endDate) }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" class="text-left">{{ $t("claimed_total") | capitalize }}:</b-col>
                  <b-col class="text-right text-gray pr-0" cols="6">{{ row.claimedTotal | formatNumber }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" class="text-left">{{ $t("claimed_per") | capitalize }}:</b-col>
                  <b-col class="text-right text-gray pr-0" cols="6">{{ row.claimedPr | formatNumber }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" class="text-left">{{ $t("claimed_mec") | capitalize }}:</b-col>
                  <b-col class="text-right text-gray pr-0" cols="6">{{ row.claimedMr | formatNumber }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" class="text-left">{{ $t("currency") | capitalize }}:</b-col>
                  <b-col class="text-right text-gray pr-0" cols="6">{{ row.currency }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" class="text-left">{{ $t("territory") | capitalize }}:</b-col>
                  <b-col class="text-right text-gray pr-0" cols="6">{{ row.territory }}</b-col>
                </b-row>
              </b-col>
              <b-col v-if="row.stats.landingDbIngestion" cols="3" class="mr-5 text-left">
                <p class="text-gray">{{ $t('ingestion_stats') | capitalize }}</p>
                <ingestion-stats :stats="row.stats.landingDbIngestion" @show-errors="showErrors(row)" />
              </b-col>
              <b-col v-if="row.stats.matching" cols="3" class="mr-3">
                <p class="text-gray">{{ $t('matching_stats') | capitalize }}</p>
                <matching-stats :stats="row.stats.matching" />
              </b-col>
            </b-row>
          </row-extra-info>
        </template>
      </v-server-table>
    </template>

    <FileErrorsModal :item="selectedFile" :onlyShowGlobalErrors="onlyShowGlobalErrors" :errorsListOptions="errorsListOptions" />
  </list-layout>
</template>

<style lang="scss" scoped>
  .metadata {
    font-size: 0.8rem;
  }
</style>

<script>
import { capitalize, get } from "lodash"
import { digitalFileTypesVerbose, fileStatuses } from "@/constants"
import FileErrorsModal from "@/components/FileErrorsModal"
import { listRouteMixin } from "@/utils/mixins"
import { parseDate } from "@/utils/dates"

export default {
  name: "DigitalFilesList",
  components: {
    FileErrorsModal,
  },
  mixins: [listRouteMixin],
  data () {
    return {
      fileStatuses,
      columns: ["filename", "uploadDate", "dsp", "status", "actions"],
      viewingSelection: false,
      onlyShowGlobalErrors: false,
      selectedFile: {},
      openChildRows: [],
      tableRef: "digitalFilesListRef",
      options: {
        skin: "table",
        allSelected: false,
        showChildRowToggler: false,
        headings: {
          filename: capitalize(this.$t("filename")),
          uploadDate: capitalize(this.$t("upload_date")),
          dsp: "DSP",
          status: capitalize(this.$t("status")),
          actions: "",
        },
        columnsClasses: {
          filename: "w-30 text-break",
          type: "w-15",
          status: "w-15",
          ingestionStats: "w-20 text-center",
          matchingStats: "w-20 text-center",
          actions: "w-0",
        },
        requestKeys: { query: "fuzzy_search" },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))

          return this.$api.digital.digitalFilesList(queryParams)

        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
      },
      errorsListOptions: {
        requestFunction (params) {
          return this.$api.digital.digitalFileErrorsList(this.url, params)
        }
      },
      filters: {
        status: "",
        fuzzySearch: "",
      },
      summary: {},
      count: 0,
      digitalFileTypesVerbose
    }
  },
  methods: {
    showErrors (item, level) {
      this.onlyShowGlobalErrors = level == "file"
      this.selectedFile = item
      this.$bvModal.show("file-errors-modal")
    },
    parseDate (date) {
      return parseDate(date, this.$config.DATE_FORMAT)
    },
    parseTime (date) {
      return parseDate(date, "h:mm")
    },
    onLoaded ({ data }) {
      this.count = data.count
      this.status = data.status
      this.summary = { ...data.summary }
    },
    onFileSearch (value) {
      this.filters.fuzzySearch = value
    },
    onStatusClick (status) {
      this.filters.status = status
      this.applyFilters("status", status)
    },
    getData () {
      this.$refs[this.tableRef].getData()
    },
    isOpen (id) {
      return this.openChildRows.includes(id)
    },
    showExtraInfo ({ id }) {
      if (this.isOpen(id)) {
        this.openChildRows = this.openChildRows.filter(openRowId => id !== openRowId)
      } else {
        this.openChildRows.push(id)
      }
      this.$refs[this.tableRef].toggleChildRow(id)
    },
  },
  watch: {
    "filters.fuzzySearch" (value) {
      this.applyFilters("fuzzy_search", value)
    },
  },
  mounted () {
    this.setFilters()
  }
}
</script>
