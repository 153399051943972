<template>
  <list-layout :title="$t('usages_matching')" :count="count" :filters="filters">
    <!-- Status Filters -->
    <template v-slot:status>
      <div class="mr-3">
        <status
          :text="$t('automatched')"
          :name="matchingStatuses.AUTOMATCHED"
          :count="summary.automatched"
          :icon="['fas', 'check-circle']"
          color="green"
          :active="filters.status == matchingStatuses.AUTOMATCHED"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('manually_matched')"
          :name="matchingStatuses.MANUALLY_MATCHED"
          :count="summary.manuallyMatched"
          :icon="['fas', 'check-circle']"
          color="green"
          :active="filters.status == matchingStatuses.MANUALLY_MATCHED"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('unmatched')"
          :name="matchingStatuses.UNMATCHED"
          :count="summary.unmatched"
          :icon="['fas', 'exclamation-circle']"
          color="red"
          :active="filters.status == matchingStatuses.UNMATCHED"
          clickable
          @click="onStatusClick"
        />
      </div>
    </template>

    <!-- Search and Filters -->
    <template v-slot:search>
      <TagFilters @filter="onTagsFilter" :initFilters="initialTagFilters" />
      <b-row class="d-flex align-items-center my-2">
        <b-col cols="sm-3 pr-0">
          <distribution-filter
            name="distributionSearch"
            :label="$t('distribution') | capitalize"
            v-model="filters.distribution"
            choiceLabel="name"
            :placeholder="$t('search_and_select') | capitalize"
            />
        </b-col>
      </b-row>
      <b-row class="d-flex align-items-end my-2">
        <b-col cols="sm-3 pr-0">
          <search-input
            :value="filters.title"
            @search="onTitleSearch"
            :placeholder="$t('search_by_title') | capitalize"
            class="form-group pr-0"
          />
        </b-col>
        <b-col cols="sm-3 pr-0">
          <search-input
            :value="filters.artist"
            @search="onArtistSearch"
            :placeholder="$t('search_by_artist') | startcase"
            class="form-group pr-0"
          />
        </b-col>
        <b-col cols="sm-3 pr-0">
          <search-input
            :value="filters.workId"
            @search="onWorkSearch"
            :placeholder="$t('search_by_atlas_id') | upperfirst"
            class="form-group pr-0"
          />
        </b-col>
        <b-col class="d-flex justify-content-end mb-3">
          <b-form-checkbox v-model="filters.hideReviewed" switch>
            {{ $t("hide_reviewed") | capitalize }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </template>

    <template v-slot:table>
      <div class="table-container">
        <v-server-table
          :columns="columns"
          :options="options"
          ref="uniqueUsages"
          @loaded="onLoaded"
          @pagination="onPagination"
          @sorted="onSorted"
        >
          <!-- Title -->
          <template v-slot:title="{ row }">
            <div v-if="row.work" class="pl-4">
              <router-link
                :to="{ name: 'works-detail', params: { id: row.work.id }}"
                class="text-info"
              >
                {{ row.title }}
              </router-link>
            </div>
            <div v-else class="d-flex align-items-start">
              <action
                @click="showCandidatesPanel(row)"
                :title="$t('more_information')"
                :icon="['fas', !isOpen(row.id) ?  'angle-right': 'angle-up']"
                class="my-1"
              />
              {{ row.title }}
            </div>
          </template>

          <!-- Contributors -->
          <template v-slot:contributors="{ row }">
            {{ getContributorNames(row) }}
          </template>

          <!-- Source -->
          <template v-slot:source="{ row }">
            <div v-if="row.sources && row.sources.length === 1">
              <router-link
                class="text-info"
                :to="{ name: 'usages-list', params: { id: row.sources[0].id } }"
              >
                {{ row.sources[0].usageFile.filename }} - {{  row.sources[0].name }}
              </router-link>
            </div>
            <div v-if="row.sources && row.sources.length > 1">
              {{ `${row.sources.length} ${$t("files")}` }}
              <fa-icon
                class="text-info clickable ml-2"
                size="sm"
                :icon="['fas', 'plus-circle']"
                @click="showSourcesModal(row)"
              />
            </div>
          </template>

          <!-- Status -->
          <template v-slot:status="{ row }">
            <status
              v-if="row.status === matchingStatuses.AUTOMATCHED"
              :text="getMatchingStatusesDisplay(row.status)"
              :icon="['fas', 'check-circle']"
              color="green"
            />
            <status
              v-if="row.status === matchingStatuses.MANUALLY_MATCHED"
              :text="getMatchingStatusesDisplay(row.status)"
              :icon="['fas', 'check-circle']"
              color="green"
            />
            <status
              v-if="row.status === matchingStatuses.UNMATCHED"
              :text="getMatchingStatusesDisplay(row.status)"
              :icon="['fas', 'exclamation-circle']"
              color="red"
            />
          </template>

          <!-- Actions -->
          <template v-slot:actions="{ row }">
            <div class="d-flex align-items-center">
              <span id="match-info">
                <action
                  v-if="row.work && row.work.id"
                  @click="showMatchedWorkInfo(row)"
                  :icon="['fas', 'exclamation-circle']"
                  color="text-info"
                />
              </span>
              <span id="unmatch-action">
                <action
                  v-if="row.work && row.work.id"
                  @click="showUnmatchModal(row)"
                  :icon="['fas', 'unlink']"
                  color="text-info"
                />
              </span>
              <span id="mark-reviewed-action" v-if="row.status === matchingStatuses.UNMATCHED">
                <div v-if="row.hasBeenReviewed" class="mx-2">
                  <fa-icon :icon="['fa', 'check']" class="green-dark font-weight-bold" />
                </div>
                <action
                  v-else
                  :title="$t('mark_as_reviewed')"
                  :icon="['fa', 'check']"
                  color="gray"
                  class="unreviewed"
                  @click="onMarkReviewed(row)"
                  :waiting="reviewInProgress === row.id"
                />
              </span>
            </div>
            <b-tooltip target="match-info" :title="$t('matched_work')"></b-tooltip>
            <b-tooltip target="unmatch-action" :title="$t('unmatch')"></b-tooltip>
          </template>

          <!-- Child Rows -->
          <template v-slot:child_row="{row}">
            <Candidates
              v-if="row.status === matchingStatuses.UNMATCHED"
            :key="row.id"
            class="w-100 mt-3"
            :element="row"
            :type="candidatesTypes.USAGE"
            @match-success="onMatchSuccess"
            />
          </template>
        </v-server-table>
      </div>
    </template>

    <!-- Sources Modal -->
    <b-modal
      id="usage-sources-modal"
      :title="$t('sources') | capitalize"
      @hidden="onHideInfoModal"
      hide-footer
    >
      <p>{{ $t('sources') | capitalize }} {{ $t('for') }} "{{ currentUsage.title }}"</p>
      <div v-if="currentUsage.sources && currentUsage.sources.length > 1">
        <p v-for="source in currentUsage.sources" :key="source.id">
          <router-link
            class="text-info"
            :to="{ name: 'usages-list', params: { id: source.id } }"
          >
            {{ source.usageFile.filename }} - {{  source.name }}
          </router-link>
        </p>
      </div>
    </b-modal>

    <!-- Unmatch Confirmation Modal -->
    <modal
      id="unmatch-confirmation-modal"
      :title="$t('unmatch_confirmation') | capitalize"
      :okTitle="$t('yes') | capitalize"
      :cancelTitle="$t('no') | capitalize"
      @ok="onConfirmUnmatch"
      @hidden="onHideUnmatchModal"
    >
      <div>{{ $t('confirm_unmatch_usage') | capitalize }}: {{ currentUsage.title }}?</div>
    </modal>

    <!-- Matched Work Info Modal -->
    <b-modal
      id="matched-work-modal"
      :title="$t('matched_work') | capitalize"
      @hidden="onHideInfoModal"
      hide-footer
      size="xl"
    >
      <p>{{ $t('matched_work') | capitalize }} {{ $t('for') }} "{{ currentUsage.title }}"</p>
      <p v-if="currentUsage.matchedBy" class="text-muted">{{ $t('matched_by') | capitalize }} {{ currentUsage.matchedBy.name }}</p>
      <work-detail :id="currentWork.id" :work="currentWork" :shares="currentWorkShares" lite inSingleView />
    </b-modal>
  </list-layout>
</template>

<style scoped>
  .unreviewed:hover {
    box-shadow: 0 0 2px 2px var(--input-gray);
    padding: 2px;
  }

  .selection-column {
    width: 55px;
    padding: 0;
  }

  .green-dark {
    color: var(--green-dark);
  }

  ::v-deep tbody > tr > td {
    font-size: 0.9rem;
  }

  ::v-deep th:first-child {
    width: 32px;
  }

  /* stylelint-disable */
  ::v-deep .VueTables__child-row.open-row {
    border: 1px solid var(--info);
    border-right: 2px solid var(--info);
    border-top: none;
  }
  /* stylelint-enable */
  ::v-deep .open-row {
    border: 1px solid var(--info);
    border-right: 2px solid var(--info);
    border-bottom: none;
  }

</style>

<script>
import { candidatesTypes, getDistributionStatusDisplay, getMatchingStatusesDisplay, matchingStatuses } from "@/constants"
import { capitalize, get } from "lodash"
import { mapActions, mapState } from "vuex"
import Candidates from "./Candidates"
import TagFilters from "./TagFilters"
import { listRouteMixin } from "@/utils/mixins"


export default {
  name: "UsagesMatchingList",
  components: {
    Candidates,
    TagFilters,
  },
  mixins: [listRouteMixin],
  data () {
    return {
      matchingStatuses,
      candidatesTypes,
      currentUsage: {},
      currentWork: {},
      currentWorkShares: [],
      filters: {
        title: "",
        artist: "",
        distribution: "",
        status: "",
        hideReviewed: false,
        workId: ""
      },
      initialTagFilters: {},
      summary: {},
      reviewInProgress: null,
      count: 0,
      columns: ["title", "artist", "contributors", "release", "iswc", "isrc", "playcount", "source", "status", "actions"],
      options: {
        showChildRowToggler: true,
        openChildRows: [],
        headings: {
          title: capitalize(this.$t("title")),
          artist: capitalize(this.$t("artist")),
          contributors: capitalize(this.$t("contributors")),
          release: capitalize(this.$t("album")),
          iswc: this.$t("iswc"),
          isrc: this.$t("isrc"),
          playcount: capitalize(this.$t("plays")),
          source: capitalize(this.$t("source")),
          status: capitalize(this.$t("status")),
          actions: "",
        },
        sortable: ["playcount"],
        columnsClasses: {
          actions: "align-start width-smaller px-0",
          title: "width-large text-break px-0",
          artist: "width-medium text-break",
          contributors: "width-medium text-break",
          release: "width-small text-break",
          isrc: "width-small",
          iswc: "width-small",
          source: "width-medium text-break",
          playcount: "width-smaller",
          status: "px-0",
        },
        requestKeys: { orderBy: "order_by" },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))

          return this.$api.usages.uniqueUsagesList(queryParams)
        },
        responseAdapter ({ data }) {
          let component = this.$parent.$parent.$parent
          // close all open rows when new data is received
          component.options = { ...component.options, openChildRows: [] }
          component.$refs.uniqueUsages.$refs.table.openChildRows = []
          component.reviewInProgress = null

          return {
            data: data.results,
            count: data.count,
          }
        },
        rowClassCallback (row) {
          return this.openChildRows.includes(row.id) ? "open-row" : ""
        }
      },
    }
  },
  computed: {
    ...mapState("user", ["waiting"]),
  },
  methods: {
    ...mapActions("alert", ["success", "error"]),
    getDistributionStatusDisplay,
    getMatchingStatusesDisplay,
    showSourcesModal (usage) {
      this.currentUsage = usage
      this.$bvModal.show("usage-sources-modal")
    },
    async showMatchedWorkInfo (usage) {
      this.currentUsage = usage
      let workId = this.currentUsage.work.id

      try {
        const [workResponse, sharesResponse] = await Promise.all([
          this.$api.repertoire.worksRetrieve(workId),
          this.$api.repertoire.worksSharesList(workId)
        ])
        this.currentWork = { ...workResponse.data }
        this.currentWorkShares = { ...sharesResponse.data }
        this.$bvModal.show("matched-work-modal")
      } catch (err) {
        let message = err?.response?.data || err?.response || err
        this.error(message)
      }
    },
    showUnmatchModal (usage) {
      this.currentUsage = usage
      this.$bvModal.show("unmatch-confirmation-modal")
    },
    showCandidatesPanel ({ id }) {
      let openRows = []
      if (this.isOpen(id)) {
        openRows = this.options.openChildRows.filter(openRowId => id !== openRowId)
      } else {
        openRows = [ ...this.options.openChildRows, id]
      }
      this.options = { ...this.options, openChildRows: openRows }
      this.$refs.uniqueUsages.toggleChildRow(id)
    },
    getContributorNames (usage) {
      return usage?.contributors?.length ? usage.contributors.join(", ") : ""
    },
    isOpen (id) {
      return this.options.openChildRows.includes(id)
    },
    onConfirmUnmatch () {
      this.$api.usages.uniqueUsagesUnmatch(this.currentUsage.id)
        .then(() => {
          this.success(this.$t("usage_successfully_unmatched"))
          this.$bvModal.hide("unmatch-confirmation-modal")
          this.getData()
        })
        .catch(err => {
          let message = err?.response?.data || err?.response || err
          this.error(message)
        })
    },
    onMatchSuccess () {
      this.getData()
    },
    onMarkReviewed (row) {
      this.reviewInProgress = row.id
      this.$api.usages.uniqueUsagesMarkReviewed(row.id)
        .then(() => {
          this.getData()
        })
        .catch(err => {
          this.reviewInProgress = null
          let message = err?.response?.data || err?.response || err
          this.error(message)
        })
    },
    onHideInfoModal () {
      this.currentUsage = {}
      this.currentWork = {}
    },
    onHideUnmatchModal () {
      this.currentUsage = {}
    },
    onSorted (sorting) {
      this.applyFilters("order_by", sorting.column)
      this.applyFilters("ascending", sorting.ascending)
    },
    onLoaded ({ data }) {
      this.count = data.count
      this.status = data.status
      this.summary = data.summary
    },
    onStatusClick (status) {
      this.filters.status = status
      this.applyFilters("status", status)
    },
    onTitleSearch (value) {
      this.filters.title = value
    },
    onArtistSearch (value) {
      this.filters.artist = value
    },
    onWorkSearch (value) {
      this.filters.workId = value
    },
    onTagsFilter (type, value) {
      this.applyFilters(type, value)
    },
    getInitialTagFilters () {
      let licensee = get(this.$route.query, "licensee", "")
      let useType = get(this.$route.query, "use_type", "")
      let priority = get(this.$route.query, "priority", "")
      let usagePeriod = get(this.$route.query, "usage_period", "")

      return {
        licensee,
        useType,
        priority,
        usagePeriod,
      }
    },
    getData () {
      this.$refs.uniqueUsages.getData()
    },
  },
  watch: {
    "filters.title" (value) {
      this.applyFilters("title", value)
    },
    "filters.artist" (value) {
      this.applyFilters("artist", value)
    },
    "filters.hideReviewed" (value) {
      this.applyFilters("hide_reviewed", value)
    },
    "filters.distribution" (value) {
      this.applyFilters("distribution", value)
    },
    "filters.workId" (value) {
      this.applyFilters("work_id", value)
    }
  },
  mounted () {
    this.setFilters()
    this.initialTagFilters = this.getInitialTagFilters()
  }
}
</script>
