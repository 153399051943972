var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table-lite',{attrs:{"fields":_vm.fields,"items":_vm.mandates,"borderless":""},scopedSlots:_vm._u([{key:"head()",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("capitalize")(data.label))+" ")]}},{key:"cell(name)",fn:function(ref){
var mandate = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('b',[_vm._v(_vm._s(mandate.useType.name))]),_c('span',{staticClass:"text-small"},[_vm._v(" ("+_vm._s(mandate.useType.code)+") - "+_vm._s(mandate.useType.rightType)+" ")])])]}},{key:"cell(startDate)",fn:function(ref){
var mandate = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayDate(mandate.startDate))+" ")]}},{key:"cell(endDate)",fn:function(ref){
var mandate = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayDate(mandate.endDate))+" ")]}},{key:"cell(territories)",fn:function(ref){
var mandate = ref.item;
return [_vm._v(" "+_vm._s(mandate.includedTerritories.join(", "))+" "),(!mandate.excludedTerritories)?[_vm._v(" "+_vm._s(_vm.$t("but"))+" "+_vm._s(mandate.excludedTerritories.join(", "))+" ")]:_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }