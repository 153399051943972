<template>
  <list-layout
    :title="isSociety ? $t('works_single_view') : $t('my_works')"
    :count="count"
    :show-refresh="true"
    :filters="filters"
  >

    <!-- Actions -->
    <template v-slot:actions>
      <div>
        <b-button :to="{ name: 'works-create'}" variant="danger">
          {{ $t("declare_new_work") | startcase }}
        </b-button>
        <b-button
          v-if="isMember"
          variant="outline-secondary"
          @click="onDownloadWorksReport"
          class="ml-2"
        >
          {{ $t("download_works_list") | startcase }}
        </b-button>
      </div>
    </template>

    <!-- Search -->
    <template v-slot:search>
      <div class="d-flex align-items-center">
        <!-- Title Search -->
        <search-input
          class="w-20 pr-3 pb-3"
          :placeholder="$t('search_by_title_or_alternate_titles')"
          :value="filters.titleSearch"
          :label="$t('work')"
          @search="onTitleSearch"
        />

        <!-- Code Search -->
        <search-input
          class="w-20 pr-3 pb-3"
          :placeholder="$t('search_by_iswc_atlas_id_or_work_code')"
          :value="filters.codeSearch"
          :label="$t('code')"
          @search="onCodeSearch"
        />

        <!-- Party Search name -->
        <search-input
          class="w-20 pr-3 pb-3"
          :placeholder="$t('search_by_party_name')"
          :value="filters.ipName"
          :label="$t('interested_party_name')"
          @search="onIPNameSearch"
        />

        <!-- Party Search number-->
        <search-input
          v-if="isSociety"
          class="w-20 pr-3 pb-3"
          :placeholder="$t('search_by_party_number') | startcase"
          :value="filters.ipNumber"
          :label="$t('interested_party_number')"
          @search="onIPNumberSearch"
        />

        <!-- Performer Search -->
        <search-input
          class="w-20 pb-3"
          :placeholder="$t('search_by_performer')"
          :value="filters.performerSearch"
          :label="$t('performer')"
          @search="onPerformerSearch"
        />
      </div>

      <div class="d-flex align-items-center">
          <!-- Source Type -->
          <multi-select-field
            v-if="isSociety"
            id="sourceType"
            class="pr-3 w-20"
            v-model="filters.sourceType"
            name="sourceType"
            :options="sourceTypeOptions"
            :label="$t('source')"
            choiceLabel="display"
            track-by="value"
            :showLabels="false"
          />

          <!-- Latest -->
          <multi-select-field
            v-if="isSociety"
            id="latest"
            class="pr-3 w-20"
            v-model="filters.latest"
            name="latest"
            :options="latestOptions"
            :label="$t('latest')"
            choiceLabel="display"
            track-by="value"
            :showLabels="false"
          />

          <!-- Switch Inputs -->
          <div class="ml-auto d-flex">
            <!-- Domestic -->
            <b-form-checkbox v-if="!isMember" v-model="filters.domestic" switch class="pr-2">
              {{ $t("only_domestic") | capitalize }}
            </b-form-checkbox>

            <!-- Own works -->
            <!-- <b-form-checkbox v-if="isMember" v-model="filters.own" switch class="pr-2">
              {{ $t("own_works") | capitalize }}
            </b-form-checkbox> -->
          </div>
      </div>
    </template>

    <!-- Count -->
    <template v-slot:count>
      <selection-summary
        v-if="selectedData.length || viewingSelection"
        class="mb-2 smaller"
        :selectedItems="selectedData"
        :clear="clearSelection"
        :switchView="toggleSelection"
      >
        <template v-slot:actions>
          <b-button
            v-if="selectedData.length > 1"
            @click="checkMergeAllowed"
            :to="getMergeRoute()"
            variant="outline-secondary"
            size="sm"
          >
            {{ $t("merge_works") | capitalize }}
          </b-button>
        </template>
      </selection-summary>
    </template>

    <!-- Table -->
    <template v-slot:table>
      <b-skeleton-table
        v-if="loadingTable"
        :rows="10"
        :columns="5"
        :table-props="{ bordered: false, striped: true }"
      />
      <v-server-table
        :class="loadingTable ? 'd-none' : ''"
        :columns="columns"
        :options="options"
        ref="works"
        @loaded="onLoaded"
        @loading="onLoading"
        @pagination="onPagination"
        @sorted="onSorted"
      >
        <!-- Select all -->
        <template slot="h__selection" v-if="!viewingSelection">
          <div class="d-flex" v-if="isSociety">
            <b-checkbox
              v-model="options.allSelected"
              @change="selectPage"
            />
          </div>
        </template>

        <!-- Selection -->
        <template v-slot:selection="{ row}">
          <div class="d-flex" v-if="isSociety">
            <b-checkbox
              v-model="selectedData"
              :value="row"
            />
          </div>
        </template>

        <!-- Title -->
        <template v-slot:title="{ row }">
          <div>
            <router-link
              v-if="row.canView"
              :to="{ name: 'works-detail', params: { id: row.id }}"
              class="text-info"
            >
              {{ row.title }}
            </router-link>
            <span v-else>
              {{ row.title }}
            </span>
            <span
              v-if="row.isPublicDomain"
              v-b-tooltip.hover.topright
              :title="$t('public_domain')"
              class="text-secondary"
            >
              <fa-icon class="mr-2" :icon="['fab', 'creative-commons-pd']" size="sm" />
            </span>
            <div v-if="row.titles.length">
              <div
                v-for="(title, index) in getTitleVariants(row.titles)"
                :key="title+index"
                class="text-gray text-small"
              >
                {{ title }}
              </div>
              <span v-if="row.titles.length > maxTitlesLength" class="text-gray text-small">...</span>
            </div>
          </div>
        </template>

         <!-- Contributors -->
        <template v-slot:contributors="{ row }">
          {{ contributorNames(row) }}
        </template>

         <!-- Performers -->
        <template v-slot:performers="{ row }">
          {{ getPerformers(row.performers) }}
        </template>

        <!-- Child Rows -->
        <template v-slot:child_row="{ row }">
          <row-extra-info :key="row.id">
            <WorksListRowExtra :work="row" />
          </row-extra-info>
        </template>

        <!-- Actions -->
        <template v-slot:actions="{ row }">
          <div class="d-flex justify-content-end">
            <action
              @click="showExtraInfo(row)"
              :title="$t('more_information')"
              :icon="['fas', !isOpen(row.id) ?  'angle-down': 'angle-up']"
            />
          </div>
        </template>
      </v-server-table>
    </template>
    <b-modal
      id="download-workslist-confirmation-modal"
      hide-header
      size="lg"
      centered
      ok-only
      :okTitle="$t('close') | capitalize"
      class="p-4 d-flex justify-content-center"
    >
      <b-container class="text-center mt-4">
        <div>{{ $t("works_list_for_you_will_be_emailed") | capitalize }}</div>        
      </b-container>
      
    </b-modal>
    <router-view name="create"></router-view>
  </list-layout>
</template>

<style lang="scss" scoped>
  .selection-column {
    width: 20px;
  }
</style>

<script>
import { capitalize, get } from "lodash"
import { getWorkSourceTypesDisplay, latestPeriods, workSourceTypes, workTitleTypes } from "@/constants"
import { listRouteMixin, selectionMixin } from "@/utils/mixins"
import { mapActions, mapGetters, mapState } from "vuex"
import WorksListRowExtra from "./WorksListRowExtra"

const MERGE_LIMIT = 3

export default {
  name: "Works",
  components: { WorksListRowExtra },
  mixins: [listRouteMixin, selectionMixin],
  methods: {
    ...mapActions("alert", ["error"]),
    ...mapActions("selection", ["setSelection"]),
    contributorNames (work) {
      return work.contributors.map(
        contributor => contributor.lastName
      ).join(", ")
    },
    showExtraInfo ({ id }) {
      if (this.isOpen(id)) {
        this.openChildRows = this.openChildRows.filter(openRowId => id !== openRowId)
      } else {
        this.openChildRows.push(id)
      }
      this.$refs.works.toggleChildRow(id)
    },
    isOpen (id) {
      return this.openChildRows.includes(id)
    },
    onDownloadWorksReport () {
      this.$api.parties.partiesWorksReport(this.user.party.id, {})
        .then(() => {
          this.$bvModal.show("download-workslist-confirmation-modal")
        })
        .catch((err) => {
          let message = err.response.data?.message || err.response.data || err.response
          this.error(message)
        })
    },
    onLoading () {
      this.loadingTable = true
    },
    onLoaded ({ data }) {
      this.loadingTable = false
      this.count = data.count
      this.recomputeAllSelected()
    },
    onSorted (sorting) {
      this.applyFilters("order_by", sorting.column)
      this.applyFilters("ascending", sorting.ascending)
    },
    recomputeAllSelected (newVal=false) {
      let selectedData = newVal || this.selectedData
      let allSelected = this.options.allSelected

      if (this.$refs.works && this.$refs.works.data.length){
        allSelected = this.$refs.works.data.every(d => {
          return selectedData.map(r => r.id).includes(d.id)
        })
      } else {
        allSelected = false
      }
      this.options = { ...this.options, allSelected }
      this.setSelection(selectedData)
    },
    selectPage () {
      if (!this.options.allSelected) {
        this.selectedData.push(
          ...this.$refs.works.data
            .filter(d => !this.selectedData.map(w => w.id).includes(d.id))
            .map(d => d)
        )
      } else {
        this.$refs.works.data.forEach(d => this.removeSelected(d.id))
      }
    },
    removeSelected (id) {
      this.selectedData.splice(this.selectedData.map(w => w.id).indexOf(id), 1)
    },
    getData () {
      this.$refs.works.getData()
    },
    onTitleSearch (value) {
      this.filters.titleSearch = value
    },
    onCodeSearch (value) {
      this.filters.codeSearch = value
    },
    onIPNameSearch (value) {
      this.filters.ipName = value
    },
    onIPNumberSearch (value) {
      this.filters.ipNumber = value
    },
    onPerformerSearch (value) {
      this.filters.performerSearch = value
    },
    getTitleVariants (titles) {
      let titlesToDisplay = titles
        .filter(title => title.type !== workTitleTypes.OT)
        .map(title => title.title)
      if (titlesToDisplay.length > this.maxTitlesLength) {
        titlesToDisplay = titlesToDisplay.slice(0, this.maxTitlesLength)
      }
      return titlesToDisplay
    },
    getPerformers (performers) {
      if (performers.length > this.maxPerformersLength) {
        return `${performers.join(", ").slice(0, this.maxPerformersLength)} ...`
      }
      return performers.join(", ")
    },
    checkMergeAllowed () {
      if (this.nonDomesticSelection.length) {
        let nonDomesticTitles = this.nonDomesticSelection.map(work => work.title).join(", ")
        let warning = capitalize(this.$t("merging_not_allowed_for_non_domestic_works"))
        let message = `${warning}:\n ${nonDomesticTitles}`
        this.error(message)
      }
      if (this.mergeLimitExceeded) this.error(capitalize(this.$t("merging_more_than_three_works_not_allowed")))
    },
    getMergeRoute () {
      if (!this.nonDomesticSelection.length && !this.mergeLimitExceeded) {
        let selectedWorksIds = this.selection.map(work => work.id).join(",")
        return { name: "works-merge", query: { works: selectedWorksIds } }
      }
      return this.$route
    },
    getLatestValue (value) {
      return this.latestOptions.find((item) => value === item.value)
    },
    getSourceTypeValue (value) {
      return this.sourceTypeOptions.find((item) => value === item.value)
    },
  },
  data () {
    return {
      count: 0,
      columns: ["selection", "title", "id", "iswc", "contributors", "performers", "actions"],
      viewingSelection: false,
      selectedData: [],
      openChildRows: [],
      loadingTable: false,
      latestOptions: [
        { value: latestPeriods.DAY, display: this.$t("day") },
        { value: latestPeriods.WEEK, display: this.$t("week") },
        { value: latestPeriods.MONTH, display: this.$t("month") },
      ],
      options: {
        allSelected: false,
        showChildRowToggler: false,
        headings: {
          selection: "",
          title: capitalize(this.$t("title")),
          id: "Atlas ID",
          iswc: this.$t("iswc"),
          contributors: capitalize(this.$t("contributors")),
          performers: capitalize(this.$t("performers")),
          actions: "",
        },
        sortable: ["title"],
        columnsClasses: { selection: "selection-column" },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          let component = this.$parent.$parent.$parent
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          if (component.viewingSelection) {
            return component.filterSelectionItems()
          } else {
            return this.$api.repertoire.worksList(queryParams)
          }
        }
      },
      filters: {
        titleSearch: "",
        ipName: "",
        ipNumber: "",
        codeSearch: "",
        performerSearch: "",
        sourceType: "",
        latest: "",
        domestic: false,
        own: false,
      },
      maxTitlesLength: 4,
      maxPerformersLength: 200,
    }
  },
  computed: {
    ...mapState("selection", ["selection"]),
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["isMember", "isSociety"]),
    nonDomesticSelection () {
      return this.selection.filter(work => !work.domestic)
    },
    mergeLimitExceeded () {
      return this.selection.length && this.selection.length > MERGE_LIMIT
    },
    sourceTypeOptions () {
      // SINGLEVIEW is not an option we want to provide in the filter
      return Object.values(workSourceTypes)
        .filter(value => value !== workSourceTypes.SINGLEVIEW)
        .map(value => ({ value, display: getWorkSourceTypesDisplay(value) }))
    }
  },
  watch: {
    "filters.titleSearch" (value) {
      this.applyFilters("title_search", value)
    },
    "filters.codeSearch" (value) {
      this.applyFilters("code_search", value)
    },
    "filters.ipName" (value) {
      this.applyFilters("ip_name", value)
    },
    "filters.ipNumber" (value) {
      this.applyFilters("ip_number", value)
    },
    "filters.performerSearch" (value) {
      this.applyFilters("performer_search", value)
    },
    "filters.latest" (value) {
      this.applyFilters("latest", value ? value.value : null)
    },
    "filters.sourceType" (value) {
      this.applyFilters("source_type", value ? value.value : null)
    },
    "filters.domestic" (value) {
      this.applyFilters("domestic", value || null)
    },
    "filters.own" (value) {
      this.applyFilters("own", value || null)
    },
    selectedData (newVal) {
      this.recomputeAllSelected(newVal)
    },
  },
  mounted () {
    // set data from query params
    this.setFilters()

    if (this.isMember) {
      this.$refs.works.setOrder("title", true)
      this.applyFilters("order_by", "title")
      this.applyFilters("ascending", true)
    }

    const sourceTypeQuery = get(this.$route.query, "source_type", "")
    this.filters.sourceType = sourceTypeQuery ? this.getSourceTypeValue(sourceTypeQuery) : ""

    const latestQuery = get(this.$route.query, "latest", "")
    this.filters.latest = latestQuery ? this.getLatestValue(latestQuery) : ""
  },
}
</script>
