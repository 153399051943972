<template>
  <b-modal
    id="user-update-modal"
    :title="`${$t('update_user')}: ${user.name}`"
    size="md"
    :visible="visible"
    :hide-footer="true"
    @hidden="$router.go(-1)"
  >
    <UsersUpdateForm
      :party="party"
      :initial="user" />
  </b-modal>
</template>

<script>
import UsersUpdateForm from "./UsersUpdateForm"

export default {
  name: "UsersUpdateModal",
  components: {
    UsersUpdateForm
  },
  data () {
    return {
      user: { name: "" },
      party: null,
      visible: false
    }
  },
  mounted () {
    let { id, uid } = this.$route.params
    this.$api.users.usersRetrieve(id, uid)
      .then(response => {
        this.user = response.data
        this.party = response.data.party
        this.visible = true
      })
  },
}
</script>