<template>
  <b-table-lite :fields="fields" :items="mandates" borderless>
    <!-- Heading -->
    <template v-slot:head()="data">
      {{ data.label | capitalize }}
    </template>

    <!-- Use Type -->
    <template v-slot:cell(name)="{ item: mandate }">
      <div class="d-flex flex-column">
        <b>{{ mandate.useType.name }}</b>
        <span class="text-small">
          ({{ mandate.useType.code }}) - {{ mandate.useType.rightType }}
        </span>
      </div>
    </template>

    <!-- From -->
    <template v-slot:cell(startDate)="{ item: mandate }">
      {{ displayDate(mandate.startDate) }}
    </template>

    <!-- To -->
    <template v-slot:cell(endDate)="{ item: mandate }">
      {{ displayDate(mandate.endDate) }}
    </template>

    <!-- Territories -->
    <template v-slot:cell(territories)="{ item: mandate }">
      {{ mandate.includedTerritories.join(", ") }}
      
      <template v-if="!mandate.excludedTerritories">
        {{ $t("but") }} {{ mandate.excludedTerritories.join(", ") }}
      </template>
    </template>
  </b-table-lite>
</template>

<script>
import moment from "moment"

export default {
  name: "Mandates",
  data () {
    return {
      fields: [
        { key: "name", label: this.$t("use_type") },
        { key: "roleCode", label: this.$t("role") },
        { key: "startDate", label: this.$t("from") },
        { key: "endDate", label: this.$t("to") },
        { key: "territories", label: this.$t("territories") },
      ],
    }
  },
  methods: {
    displayDate (str) {
      return str ? moment(str).format(this.$config.DATE_FORMAT) : "-"
    },
  },
  props: {
    mandates: Array,
  },
}
</script>