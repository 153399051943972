<template>
  <div>
    <b-table
      :items="items"
      :fields="fields"
      :tbody-tr-class="getRowClass"
      borderless
      striped
      show-empty
    >
      <!-- Performing heading -->
      <template v-slot:head(performing)="data">
        <div>
          <span>{{ data.label | capitalize }}</span>
          <span v-if="workStatusForRight(rightCodes.PER)" class="mx-2">
            <fa-icon
              :icon="workStatusForRight(rightCodes.PER)['icon']"
              :title="workStatusForRight(rightCodes.PER)['title']"
              :class="workStatusForRight(rightCodes.PER)['color']"
            />
          </span>
        </div>
      </template>

      <!-- Mechanical heading -->
      <template v-slot:head(mechanical)="data">
        <div>
          <span>{{ data.label | capitalize }}</span>
          <span v-if="workStatusForRight(rightCodes.MEC)" class="mx-2">
            <fa-icon
              :icon="workStatusForRight(rightCodes.MEC)['icon']"
              :title="workStatusForRight(rightCodes.MEC)['title']"
              :class="workStatusForRight(rightCodes.MEC)['color']"
            />
          </span>
        </div>
      </template>

      <!-- Role -->
      <template v-slot:cell(role)="{ item }">
        <div>{{ item.role }}</div>
        <span class="small-text text-gray">
          {{ partyRolesVerbose[item.role] }}
        </span>
      </template>

      <!-- Indent to show contributor - publisher - sub-publisher relationship -->
      <template v-slot:cell(name)="{ item }">
        <div :class="`margin-${item.level} d-flex`">
          <fa-icon
            class="mr-2"
            :rotation="90"
            :icon="['fas', 'level-up-alt']"
            v-if="item.level == levels.SUBPUBLISHER"
          />
          <div class="d-flex flex-column">
            <router-link
              :class="getLinkClass(item)"
              :to="{ name: 'parties-detail', params: { id: item.party.id } }"
              v-if="item.party.id && isSociety"
            >
              {{ item.party.name ? item.party.name : item.party.ipiNameNumber }}
            </router-link>
            
            <span v-else>{{ item.party.name }}</span>
            <span class="small-text text-gray">
              {{ item.party.ipiNameNumber }}
            </span>
          </div>
        </div>
      </template>

      <!-- Performing -->
      <template v-slot:cell(performing)="{ item }">
        <div>{{ item.performing || "0.00" }}%</div>
        <span class="text-gray">
          {{ item.perAffiliation }}
        </span>
      </template>

      <!-- Mechanical -->
      <template v-slot:cell(mechanical)="{ item }">
        <div>{{ item.mechanical || "0.00" }}%</div>
        <span class="text-gray">
          {{ item.mecAffiliation }}
        </span>
      </template>

      <!-- Source -->
      <template v-slot:cell(source)="{ item }">
        <div v-if="item.source" class="d-flex">
          <div v-if="item.source.file" class="d-flex flex-column text-break pr-4">
            {{ item.source.file }}
          </div>
          <div>
            <b class="text-small">{{ getSourceDisplay(item.source) | uppercase }}</b>
          </div>
        </div>
      </template>

      <!-- Total -->
      <template v-if="inSingleView && !lite" v-slot:custom-foot>
        <tr class="font-weight-bolder border-darker bg-light">
          <td></td>
          <td>{{ $t("total") | uppercase }}</td>
          <td></td>
          <td>{{ performingTotals || "0.00" }}%</td>
          <td>{{ mechanicalTotals || "0.00" }}%</td>
          <td></td>
        </tr>
      </template>
    </b-table>
  </div>
</template>

<style lang="scss" scoped>
  .margin-1 {
    margin-left: 1rem,;
  }

  .green {
    color: var(--green);
  }

  .orange {
    color: var(--orange);
  }

  .red {
    color: var(--red);
  }

  .border-darker {
    border-top: 2px solid $gray-dark;
  }

  ::v-deep .narrow {
    width: 15px !important;
    white-space: nowrap;
  }

  ::v-deep th:first-child {
    width: 400px;
  }
</style>

<script>
import { capitalize, each, get } from "lodash"
import { contributorRolesVerbose, partyRolesMap, partyTypes, publisherRolesVerbose, rightCodes, workSourceTypes, workStatusTypes } from "@/constants"
import { mapGetters } from "vuex"

const CONTRIBUTOR = partyTypes.CONTRIBUTOR
const PUBLISHER = partyTypes.PUBLISHER

const levels = {
  CONTRIBUTOR: 0,
  PUBLISHER: 0,
  SUBPUBLISHER: 1,
}

const partyRolesVerbose = {
  ...contributorRolesVerbose,
  ...publisherRolesVerbose,
}

const { COMPLETE, CONFLICT, INCOMPLETE } = workStatusTypes

export default {
  name: "SharesTable",
  data () {
    return {
      defaultFields: [
        { key: "chainDisplay", label: this.$t("ca_e_link"), class: "narrow text-center" },
        { key: "name", label: capitalize(this.$t("name")) },
        { key: "role", label: capitalize(this.$t("role")) },
        { key: "performing", label: capitalize(this.$t("performing")) },
        { key: "mechanical", label: capitalize(this.$t("mechanical")) },
        { key: "source", label: capitalize(this.$t("source")) },
      ],
      partyTypes,
      partyRolesVerbose,
      rightCodes,
      levels,
    }
  },
  computed:{
    ...mapGetters("user", ["isSociety"]),
    items () {
      let shares = this.showDistribution ? this.shares.distribution : this.shares.original

      // Set up the dictionary for chains: We assign a letter to each
      // publisher share if the share contains Chains. e.g.:  { 1: A, 2: B, ... }
      let dictionary = shares
        .filter(s => partyRolesMap[s.role] === PUBLISHER && s.chains.length)
        .reduce((dictionary, share, idx) => {
          dictionary[share.id] = String.fromCharCode(97 + idx).toUpperCase()
          return dictionary
        }, {})

      each(shares, (share) => {
        // Levels
        if (partyRolesMap[share.role] === CONTRIBUTOR) {
          share.level = levels.CONTRIBUTOR
          share.chainDisplay = this.getContributorChainDisplay(share, dictionary)
        } else if (partyRolesMap[share.role] === PUBLISHER && !share.parent) {
          // AM shares can be either at publisher or sub-publisher level
          // depending on whether they have a parent or not.
          share.level = levels.PUBLISHER
          share.chainDisplay = this.getPublisherChainDisplay(share, dictionary)
        } else {
          share.level = levels.SUBPUBLISHER
        }
        // Affiliation
        share.perAffiliation = this.getAffiliation(share.party, rightCodes.PER)
        share.mecAffiliation = this.getAffiliation(share.party, rightCodes.MEC)
      })

      return shares
    },
    performingTotals () {
      if (!this.shares.originalTotals) return
      return this.showDistribution ? this.shares.distributionTotals.performing : this.shares.originalTotals.performing
    },
    mechanicalTotals () {
      if (!this.shares.originalTotals) return
      return this.showDistribution ? this.shares.distributionTotals.mechanical : this.shares.originalTotals.mechanical
    },
    fields () {
      let excludeFields = ["source"]
      if (this.lite || !this.isSociety) return this.defaultFields.filter(field => !excludeFields.includes(field.key))
      return this.defaultFields
    }
  },
  methods: {
    getSourceDisplay (source) {
      return source.sourceType.toLowerCase() === workSourceTypes.SINGLEVIEW ? source.sourceId : source.sourceType
    },
    workStatusForRight (rightType) {
      let workStatuses = this.workStatuses.filter(s => s.rightType === rightType)
      let workStatus = workStatuses.length ? workStatuses[0] : {}
      if([COMPLETE, CONFLICT, INCOMPLETE].includes(workStatus.status)) return this.getWorkStatusFlag(workStatus.status)
    },
    getWorkStatusFlag (status) {
      return {
        [COMPLETE]: { icon: ["fas", "check-circle"], title: this.$t("complete"), color: "green" },
        [INCOMPLETE]: { icon: ["fas", "exclamation-triangle"], title: this.$t("incomplete"), color: "orange" },
        [CONFLICT]: { icon: ["fas", "exclamation-circle"], title: this.$t("conflict"), color: "red" },
      }[status]
    },
    getLinkClass (item) {
      const isSubPublisher = get(item, "level") === levels.SUBPUBLISHER
      return isSubPublisher ? "text-green" : "text-info"
    },
    getRowClass (item) {
      const isSubPublisher = get(item, "level") === levels.SUBPUBLISHER
      let visible = !this.showDistribution & isSubPublisher ? "d-none" : ""
      let color = isSubPublisher? "text-green" : ""
      return `${visible} ${color}`
    },
    getAffiliation (party, code) {
      let affiliations = party.affiliations || []
      affiliations = affiliations.filter(a => a.rightType.code == code)
      affiliations = affiliations.filter(a => a.societyCisacCode)
      return affiliations.map(a => `${a.societyName} (${a.societyCisacCode})`).join(", ")
    },
    getContributorChainDisplay (share, dictionary) {
      // For a contributor, we'll display a letter for each "chained" publisher share as defined
      // in the share-id-to-letter dictionary.
      return share.chains.length ? share.chains.map(s => get(dictionary, s)).filter(s => Boolean(s)).sort().join(", ") : "-"
    },
    getPublisherChainDisplay (share, dictionary) {
      // For a publisher, we'll display a letter identifying the publisher share as defined
      // in the share-id-to-letter dictionary.
      return get(dictionary, share.id, "-")
    },
  },
  props: {
    shares: Object,
    showDistribution: {
      type:Boolean,
      default: false,
    },
    lite: {
      type:Boolean,
      default: false,
    },
    inSingleView: {
      type:Boolean,
      default: false,
    },
    workStatuses: {
      type: Array,
      default: () => ([])
    },
  }
}
</script>
