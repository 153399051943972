<template>
  <list-layout :title="$t('recordings')" :count="count">
    <template v-slot:search>
      <div class="d-flex align-items-center">
        <!-- Work Search -->
        <search-input
          class="w-25 pr-3 pb-3"
          :placeholder="$t('search_by_name') | capitalize"
          :value="productSearch"
          :label="$t('recording')"
          @search="onProductSearch"
        />
      </div>
    </template>

    <v-server-table
      name="productsList"
      ref="productsList"
      :columns="columns"
      :options="options"
      @loaded="onLoaded"
      @pagination="onPagination"
    >
      <template v-slot:name="{ row }">
        <router-link
          :to="{ name: 'products-detail', params: { id: row.id } }"
          class="text-info"
        >
          {{ row.name }}
        </router-link>
      </template>

      <template v-slot:type="{ row }">
        <span class="text-primary">
          {{ getTypeDisplay(row) | capitalize }}
        </span>
      </template>
    </v-server-table>
  </list-layout>
</template>

<script>
import { getProductTypeDisplay, getRecordingTypeDisplay, productTypes } from "@/constants"
import { capitalize } from "lodash"
import { get } from "lodash"
import { listRouteMixin } from "@/utils/mixins"


export default {
  name: "ProductsList",
  mixins: [listRouteMixin],
  data () {
    return {
      count: 0,
      productSearch: "",
      columns: ["name", "type", "code", "artist"],
      options: {
        headings: {
          title: capitalize(this.$t("name")),
          type: capitalize(this.$t("type")),
          code: capitalize(this.$t("code")),
          artist: capitalize(this.$t("artist")),
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          return this.$api.products.productsList(queryParams)
        }
      },
    }
  },
  methods: {
    getTypeDisplay (product) {
      return product.type == productTypes.RECORDING ? getRecordingTypeDisplay(product.recordingType) : getProductTypeDisplay(product.type)
    },
    onProductSearch (value) {
      this.productSearch = value
    },
    getData () {
      this.$refs.productsList.getData()
    },
    onLoaded ({ data }) {
      this.count = data.count
      this.status = data.status
      this.summary = data.summary
    },
  },
  watch: {
    productSearch (value) {
      this.applyFilters("fuzzy_search", value)
    }
  }
}
</script>
