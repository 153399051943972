import { API } from "./utils"


export const usages = {
  usageFilesList: (params) => API.get("/usages/files/", { params }),
  usageFilesGenerateReport: (data) => API.post("/usages/files/generate_unmatched_report/", data),
  usageFilesDelete: (id) => API.delete(`/usages/files/${id}/`),
  usageFileErrorsList: (id, params) => API.get(`/usages/files/${id}/errors/`, { params }),
  usageFileTags: (params) => API.get("/usages/tags/", { params }),
  usageFileTagsCreate: (data) => API.post("/usages/tags/", data),
  usageFileSheetUsagesList: (id, params=null) => API.get(`/usages/sheets/${id}/usages/`, { params }),
  uniqueUsagesList: (params) => API.get("/usages/unique-usages/", { params }),
  uniqueUsagesCandidatesList: (id, params) => API.get(`/usages/unique-usages/${id}/candidates/`, { params }),
  uniqueUsagesMarkReviewed: (id, data={}) => API.post(`/usages/unique-usages/${id}/review/`, data),
  uniqueUsagesMatch: (id, data) => API.post(`/usages/unique-usages/${id}/match/`, data),
  uniqueUsagesUnmatch: (id, data={}) => API.post(`/usages/unique-usages/${id}/unmatch/`, data),
}
