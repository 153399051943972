<template>
  <b-modal
    id="upload-file-modal"
    size="lg"
    hide-header
    hide-footer
  >
    <div class="container px-4 pt-4">
      <!-- Header -->
      <div class="d-flex w-100 justify-content-center">
        <h3>{{ title | capitalize }}</h3>
      </div>
      <Dropzone class="my-3" @cancel-upload="onCancel" :uploadUrl="uploadUrl" @success-upload="onSuccess" />
    </div>
    <!-- Header -->

  </b-modal>
</template>

<script>
import Dropzone from "./Dropzone"

export default {
  name: "UploadFileModal",
  components: {
    Dropzone,
  },
  methods: {
    onCancel () {
      this.$bvModal.hide("upload-file-modal")
    },
    onSuccess (file) {
      this.$emit("pro-rata-file-upload", file)
    }
  },
  props: {
    title: String,
    uploadUrl: String,
  },
}
</script>
