<template>
  <div>
    <div class="d-flex px-4 justify-content-between align-items-center">
      <h5>{{ $t("candidates") | capitalize }}</h5>
      <!-- Create Work -->
      <b-button
        :to="{ name: 'works-create'}"
        variant="outline-secondary"
      >
        {{ $t("create_work") | capitalize }}
      </b-button>
    </div>
    <div class="d-flex px-4 py-2 align-items-center">
      <!-- Work Search -->
      <search-input
        :placeholder="$t('search_by_title_or_alternate_titles') | startcase"
        :value="filters.titleSearch"
        @search="onTitleSearch"
        class="pr-2 flex-grow-1"
      />

      <!-- Code Search -->
      <search-input
        :placeholder="$t('search_by_iswc_atlas_id_or_work_code') | upperfirst"
        :value="filters.codeSearch"
        @search="onCodeSearch"
        class="pr-2 flex-grow-1"
      />

      <!-- Party name search -->
      <search-input
        :placeholder="$t('search_by_party_name') | startcase"
        :value="filters.ipNameSearch"
        @search="onIPNameSearch"
        class="pr-2 flex-grow-1"
      />
      <!-- Party number search -->
      <search-input
        :placeholder="$t('search_by_party_number') | startcase"
        :value="filters.ipNumberSearch"
        @search="onIPNumberSearch"
        class="pr-2 flex-grow-1"
      />
      <!-- Performer search -->
      <search-input
        :placeholder="$t('search_by_performer') | startcase"
        :value="filters.performerSearch"
        @search="onPerformerSearch"
        class="flex-grow-1"
      />
    </div>

    <!-- Candidates Table -->
    <v-server-table
      :columns="columns"
      :options="options"
      ref="candidates"
    >
      <!-- Title -->
      <template v-slot:title="{ row }">
        <div>
          <router-link
            :to="{ name: 'works-detail', params: { id: row.id }}"
            class="text-info"
          >
            {{ row.title }}
          </router-link>
          <div
            v-for="(title, index) in getTitleVariants(row.titles)"
            :key="title+index"
            class="text-gray text-small"
          >
            {{ title }}
          </div>
        </div>
      </template>

      <!-- Contributors -->
      <template v-slot:contributors="{ row }">
        {{ contributorNames(row) }}
      </template>

        <!-- Performers -->
      <template v-slot:performers="{ row }">
        {{ row.performers.join(", ") }}
      </template>

      <!-- Actions -->
      <template v-slot:actions="{ row }">
        <div class="d-flex justify-content-end mr-2">
          <action
            @click="showMatchedWorkInfo(row)"
            :icon="['fas', 'exclamation-circle']"
            color="text-info"
            class="mr-3"
          />
          <action-button
            variant="outline-secondary"
            size="sm"
            class="ml-2 px-3"
            @click="match(row.id)"
          >
            {{ $t("match") | capitalize }}
          </action-button>
        </div>
      </template>
    </v-server-table>

    <!-- Candidate Info -->
    <b-modal
      :id="`candidate-modal-${element.id}`"
      :title="$t('candidate') | capitalize"
      @hidden="onHideInfoModal"
      hide-footer
      size="xl"
    >
      <work-detail :id="currentCandidate.id" :work="currentCandidate" :shares="currentCandidateShares" lite inSingleView />
    </b-modal>
  </div>
</template>

<style scoped>
  ::v-deep th:first-child {
    width: 36px !important;
  }

  ::v-deep tbody > tr > td {
    font-size: 0.9rem;
  }

</style>

<script>
import { candidatesTypes, workTitleTypes } from "@/constants"
import { capitalize } from "lodash"
import { mapMutations } from "vuex"

export default {
  name: "Candidates",
  data () {
    return {
      filters: {
        titleSearch: "",
        codeSearch: "",
        ipNameSearch: "",
        ipNumberSearch: "",
        performerSearch: "",
      },
      currentCandidate: {},
      currentCandidateShares: [],
      columns: ["filler", "title", "performers",  "contributors", "iswc", "id", "actions"],
      options: {
        perPage: 5,
        showChildRowToggler: false,
        headings: {
          filler: "",
          title: capitalize(this.$t("title")),
          performers: capitalize(this.$t("performers")),
          products: "", // placeholder
          iswc: this.$t("iswc"),
          id: this.$t("atlas_id"),
          contributors: capitalize(this.$t("contributors")),
          actions: "",
        },
        columnsClasses: {
          title: "width-large text-break px-0",
          performers: "width-medium text-break",
          products: "width-small text-break",
          contributors: "width-medium text-break",
          id: "width-small",
          iswc: "width-small",
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          let component = this.$parent.$parent

          if (component.hasSearch) {
            let params = { ...queryParams, ...component.searchQuery }
            return this.$api.repertoire.worksList(params)
          }

          return component.getCandidateRequestFunction()(component.element.id, queryParams)
        },
      }
    }
  },
  computed: {
    hasSearch () {
      return Object.values(this.filters).some(value => value && value.trim() !== "")
    },
    searchQuery () {
      const queryTerms = {
        titleSearch: "title_search",
        codeSearch: "code_search",
        ipNameSearch: "ip_name",
        ipNumberSearch: "ip_number",
        performerSearch: "performer_search",
      }
      const query = {}

      for (const [key, value] of Object.entries(this.filters)) {
        if (value) query[queryTerms[key]] = value
      }

      return query
    },
  },
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    onTitleSearch (value) {
      this.filters.titleSearch = value
    },
    onCodeSearch (value) {
      this.filters.codeSearch = value
    },
    onIPNameSearch (value) {
      this.filters.ipNameSearch = value
    },
    onIPNumberSearch (value) {
      this.filters.ipNumberSearch = value
    },
    onPerformerSearch (value) {
      this.filters.performerSearch = value
    },
    contributorNames (work) {
      return work.contributors.map(
        contributor => contributor.lastName
      ).join(", ")
    },
    getTitleVariants (titles) {
      return titles
        .filter(title => title.type !== workTitleTypes.OT)
        .map(title => title.title)
    },
    async showMatchedWorkInfo (work) {
      this.currentCandidate = work
      let workId = this.currentCandidate.id

      try {
        const [workResponse, sharesResponse] = await Promise.all([
          this.$api.repertoire.worksRetrieve(workId),
          this.$api.repertoire.worksSharesList(workId)
        ])
        this.currentCandidate = { ...workResponse.data }
        this.currentCandidateShares = { ...sharesResponse.data }
        this.$bvModal.show(`candidate-modal-${this.element.id}`)
      } catch (err) {
        let message = err?.response?.data || err?.response || err
        this.error(message)
      }
    },
    onHideInfoModal () {
      this.currentCandidate = {}
    },
    match (id) {
      let data = { workAtlasId: id }

      this.getMatchRequestFunction()(this.element.id, data)
        .then(() => {
          this.success(this.getMatchSuccessMessage())
          this.$emit("match-success", this.element.id)
        })
        .catch(err => {
          let message = err?.response?.data || err?.response || err
          this.error(message)
        })
    },
    getMatchRequestFunction () {
      return {
        [candidatesTypes.USAGE]: this.$api.usages.uniqueUsagesMatch,
        [candidatesTypes.CRD]: this.$api.crd.uniqueMWNMatch
      }[this.type]
    },
    getCandidateRequestFunction () {
      return {
        [candidatesTypes.USAGE]: this.$api.usages.uniqueUsagesCandidatesList,
        [candidatesTypes.CRD]: this.$api.crd.uniqueMWNCandidatesList
      }[this.type]
    },
    getMatchSuccessMessage () {
      return {
        [candidatesTypes.USAGE]: this.$t("usage_successfully_matched"),
        [candidatesTypes.CRD]: this.$t("crd_successfully_matched")
      }[this.type]
    },
  },
  watch: {
    filters: {
      handler () {
        this.$refs.candidates.getData()
      },
      deep: true,
    }
  },
  props: {
    element: Object,
    type: {
      type: String,
      default: candidatesTypes.USAGE
    }
  }
}
</script>
