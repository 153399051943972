import { alpha_num, confirmed, email, required } from "vee-validate/dist/rules"
import { extend, setInteractionMode } from "vee-validate"

import { i18n } from "./i18n"


const ipiBaseNumberValidator = (value) => {
  const regex = RegExp(/([A-Z]{1})-?(\d{9})-?(\d{1})/)
  return regex.test(String(value))
}

const isrcValidator = (value) => {
  const regex = RegExp(/[A-Z]{2}[A-Z0-9]{3}\d{7}/)
  return regex.test(String(value))
}

const iswcValidator = (value) => {
  //https://en.wikipedia.org/wiki/International_Standard_Musical_Work_Code

  // Format validation
  const regex = RegExp(/^\s*(?:T-?(\d{3}).?(\d{3}).?(\d{3})-?(\d))?\s*$/)
  let valid = regex.test(String(value))

  // Control digit validation
  if (valid) {
    const matched = String(value).match(regex)
    const digits = matched[1] + matched[2] + matched[3]

    const s = 1 + [...digits].map((d, i) => Number(d) * (i + 1)).reduce((x, y) => x + y)
    const c = (10 - (s % 10)) % 10

    valid = c === Number(matched[4])
  }
  return valid
}

setInteractionMode("eager")

extend("required", {
  ...required,
  message: i18n.t("this_field_is_required"),
})

extend("email", {
  ...email,
  message: i18n.t("this_field_must_be_a_valid_email"),
})

extend("confirmed", {
  ...confirmed,
  message: i18n.t("this_field_confirm_does_not_match")
})

extend("ipibasenumber", {
  validate: value => ipiBaseNumberValidator(value),
  message: i18n.t("invalid_ipi_base_number")
})

extend("isrc", {
  validate: value => isrcValidator(value),
  message: i18n.t("invalid_isrc")
})

extend("iswc", {
  validate: value => iswcValidator(value),
  message: i18n.t("invalid_iswc")
})

extend("alphaNum", {
  ...alpha_num,
  message: i18n.t("only_alpha_numeric_characters_allowed"),
})

export {
  iswcValidator
}
