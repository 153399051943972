<template>
  <ValidationObserver ref="heirForm" v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(onSubmit)" >
      <!-- Inheritance Date -->
      <date-field
        :label="$t('inheritance_date')"
        name="inheritanceDate"
        v-model="form.inheritanceDate"
        rules="required"
      />
      <!-- Split -->
      <split-input
        :label="$t('split')"
        name="split"
        v-model="form.split"
      />
      <!-- Name -->
      <div class="subsection">
        <h5>{{ $t('user') }}</h5>
      </div>
      <form-field
        v-if="createMode"
        name="userName"
        :label="$t('name')"
        :help-text="$t('user_name_field_help_text')"
        type="text"
        rules="required"
        v-model="form.user.name"
      />
      <!-- Email -->
      <form-field
        v-if="createMode"
        name="userEmail"
        :label="$t('email')"
        :help-text="$t('user_email_field_help_text')"
        type="email"
        rules="required|email"
        v-model="form.user.email"
      />
      <!-- Contact Data -->
      <div class="subsection">
        <h5>{{ $t('contact') }}</h5>
      </div>
      <form-field
        name="contactName"
        :label="$t('contact_name')"
        type="text"
        rules="required"
        v-model="form.contactName"
      />
      <form-field
        name="primaryPhone"
        :label="$t('primary_phone')"
        type="text"
        rules="required"
        v-model="form.primaryPhone"
      />
      <form-field
        name="secondaryPhone"
        :label="$t('secondary_phone')"
        type="text"
        v-model="form.secondaryPhone"
      />
      <form-field
        name="address"
        :label="$t('address')"
        type="text"
        v-model="form.address"
      />
      <form-field
        name="primaryEmail"
        :label="$t('primary_email')"
        type="email"
        rules="required|email"
        v-model="form.primaryEmail"
      />
      <form-field
        name="secondaryEmail"
        :label="$t('secondary_email')"
        type="email"
        rules="email"
        v-model="form.secondaryEmail"
      />
      <submit-button class="btn-block mt-4" variant="primary">{{ createMode ? $t("create") : $t("update") }}</submit-button>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate"
import { isNull } from "lodash"
import { mapMutations } from "vuex"

export default {
  name: "HeirForm",
  components: {
    ValidationObserver
  },
  data () {
    return {
      form: {
        user:  {
          name: "",
          email: "",
        },
        inheritanceDate: "",
        split: null,
        contactName: "",
        primaryPhone: "",
        secondaryPhone: "",
        address: "",
        primaryEmail: "",
        secondaryEmail: "",
      },
    }
  },
  computed: {
    createMode () {
      return isNull(this.heir)
    }
  },
  methods: {
    ...mapMutations("alert", ["error"]),
    onSubmit () {
      const { heirsCreate, heirsUpdate } = this.$api.parties
      const apiCall = (
        this.createMode ?
          heirsCreate(this.partyId, this.form) :
          heirsUpdate(this.partyId, this.heir.id, this.form)
      )

      apiCall
        .then(response => {
          this.$emit("success", response.statusText)
        })
        .catch(error => {
          const { data, status } = error.response

          if (status === 400) {
            if (data.message) {
              this.error(data.message)
            } else {
              this.error(this.$t("form_contains_errors"))
            }
            this.$refs.heirForm.setErrors(data)
          }
        })
    },
  },
  mounted () {
    if (this.heir) {
      this.form = this.heir
    } else {
      this.form = { ...this.form, split: 100 }
    }
  },
  props: {
    heir: Object,
    partyId: [Number, String]
  },
}
</script>