<template>
  <div>
    <b-row>
      <b-col cols="2">
        <span
          v-b-tooltip.hover.top
          :title="workTitleTypesVerbose[message.type] | capitalize"
        >
          {{ message.type }}
        </span>
      </b-col>
      <b-col>{{ message.title }}</b-col>
    </b-row>
  </div>
</template>

<script>
import { workTitleTypesVerbose } from "@/constants"

export default {
  name: "TitlesLogMessage",
  data () { return { workTitleTypesVerbose } },
  props: {
    message: Object,
  }
}
</script>