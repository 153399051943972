export default {
  accept: "ยอมรับ",
  accept_work_update: "ยอมรับการอัปเดตงาน",
  accepted: "ได้รับการยอมรับ",
  acquirer: "ผู้รับ",
  active: "Active",
  adaptor: "Adaptor",
  add: "เพิ่ม",
  add_heir: "เพิ่มทายาท",
  add_interested_party: "เพิ่มผู้มีส่วนเกี่ยวข้อง",
  add_licensee: "เพิ่มผู้รับอนุญาต",
  add_name: "เพิ่มชื่อ",
  add_party: "เพิ่มกลุ่ม",
  add_performer: "เพิ่มชื่อศิลปิน",
  add_title: "เพิ่มชื่อเพลง",
  added: "เพิ่ม",
  added_lyrics: "เพิ่มเนื้อเพลง",
  additional: "เพิ่มเติม",
  additional_names: "Additional Names",
  address: "ที่อยู่",
  administration_costs: "ค่าใช้จ่ายในการบริหาร",
  administrator: "ผู้ดูแลระบบ",
  affiliations: "สังกัด",
  agreement: "Agreement",
  agreement_conflict: "agreement conflict",
  agreement_conflicts: "agreement conflicts",
  agreement_created: "agreement created",
  agreement_deleted: "agreement deleted",
  agreement_is_in_conflict: "ข้อตกลงนี้ขัดแย้งกับข้อตกลงอื่น",
  agreement_is_inactive: "This agreement cannot be edited as it is inactive.",
  agreement_updated: "agreement updated",
  agreements: "agreements",
  agreements_uploads: "agreements uploads",
  air_date_time: "air date and time",
  album: "album",
  allocate: "allocate",
  allocate_distribution: "allocate distribution",
  allocated: "allocated",
  allocating: "allocating",
  allocation_process_successfully_triggered: "allocation process successfully triggered",
  alternate_titles: "ชื่ออื่น ๆ",
  alternative_title: "ชื่อเพลงอื่นๆ",
  amount: "จำนวน",
  an_email_with_instructions_for_reset_pass_has_been_sent: "ส่งอีเมลพร้อมคำแนะนำในการรีเซ็ตรหัสผ่านของคุณแล้ว",
  and: "และ",
  applied: "สมัครแล้ว",
  arrangement: "การเรียบเรียง",
  arranger: "ผู้เรียบเรียง",
  artist: "artist",
  assignee: "ผู้รับมอบหมาย",
  assignor: "ผู้มอบหมาย",
  at: "ที่",
  atlas_id: "Atlas ID",
  author: "ผู้แต่งเนื้อร้อง",
  automatched: "จับคู่อัตโนมัติ",
  back: "กลับ",
  back_to_distribution: "กลับสู่หน้าการจัดสรร",
  back_to_distributions: "กลับสู่หน้าการจัดสรร",
  back_to_login: "กลับไปหน้า log in",
  back_to_results: "กลับไปหน้าแสดงผล",
  bank_amount: "bank amount",
  blocked: "ถูกบล็อก",
  board_member: "board member",
  bulk_works_upload: "อัปโหลดผลงานจำนวนมาก",
  but: "แต่",
  ca_e_link: "ลิงก์ CA/E",
  cable: "cable",
  cancel: "ยกเลิก",
  candidate: "ผู้สมัคร",
  candidate_matched: "candidate was successfully matched",
  candidates: "ผู้สมัคร",
  chained_to: "chained to",
  changed: "changed",
  cinema: "โรงภาพยนตร์",
  claimed_mec: "อ้างสิทธิในงานทำซ้ำ",
  claimed_per: "อ้างสิทธิในงานเผยแพร่",
  claimed_total: "อ้างสิทธิทั้งหมด",
  clear_filters: "ล้างตัวกรอง",
  clear_selected: "ล้างตัวเลือก",
  click_to_select_file_or_drag_and_drop: "คลิกเพื่อเลือกไฟล์ หรือ ลากและวาง",
  close: "ปิด",
  close_distribution: "close distribution",
  closed: "ปิด",
  code: "รหัส",
  collection_date: "collection date",
  comment: "ความคิดเห็น",
  comment_deleted: "ความคิดเห็นถูกลบแล้ว",
  comment_saved: "ความคิดเห็นถูกบันทึกแล้ว",
  comment_updated: "ความคิดเห็นถูกแก้ไขแล้ว",
  comments: "ความคิดเห็น",
  complete: "เสร็จสมบูรณ์",
  composer: "ผู้แต่งทำนอง",
  concerts: "คอนเสิร์ต",
  confirm_allocate_distribution: "You are about to trigger allocation for all the pools in this distribution. Would you like to proceed?",
  confirm_close_distribution: "Once closed the distribution and its pools can no longer be edited or deleted. Members will not be notified until distribution is published.",
  confirm_create_agreement: "The agreement data provided would generate a conflict with the following agreement(s). Do you want to create this agreement anyways?",
  confirm_delete_agreement: "do you really want to delete this agreement",
  confirm_delete_comment: "do you really want to delete this comment",
  confirm_delete_distribution: "Do you really want to delete this distribution cycle? All settings and allocations will be lost. This action is not reversible.",
  confirm_delete_distribution_pool: "do you really want to delete this distribution pool",
  confirm_delete_heir: "do you really want to delete this heir",
  confirm_delete_interested_party: "do you wish to proceed in deleting this interested party",
  confirm_delete_pro_rata_file: "do you really want to delete this pro-rata file",
  confirm_delete_usage_file: "This usage file and its related data will be deleted. Do you really want to delete it",
  confirm_delete_user: "do you really want to delete this user",
  confirm_delete_works: "do you really want to delete this work",
  confirm_demerge_work: "confirm demerge work",
  confirm_email: "ยืนยันอีเมล",
  confirm_generate_crd_report: "Do you want to request CRD reports for this distribution? You'll be able to download the reports when they are generated.",
  confirm_member_work_update: "Your changes will be reviewed by a society admin before being applied.",
  confirm_publish_distribution: "This action makes distribution reports available to the members. Do you wish to continue?",
  confirm_undo_allocation: "do you want to undo allocations? All allocations in this pool will be removed.",
  confirm_undo_distribution_allocation: "Do you want to undo allocations for all pools in this distribution? All allocations in these pools will be removed.",
  confirm_unmatch_usage: "do you really want to unmatch this usage",
  confirm_update: "ยืนยันการอัปเดต",
  conflict: "ข้อขัดแย้ง",
  conflict_detected: "ตรวจพบข้อขัดแย้ง",
  conflict_detected_user_action_created: "A conflict has been detected and a user action has been created",
  conflict_with_existing_work: "the work data you provided conflicts with an existing work, and a user action to solve this conflict was created",
  conflicting_agreement: "ข้อตกลงที่ขัดแย้งกัน",
  contact: "ติดต่อ",
  contact_name: "ชื่อผู้ติดต่อ",
  contact_updated: "ข้อมูลการติดต่อถูกแก้ไขแล้ว",
  continue: "ดำเนินการต่อ",
  contributor: "นักแต่งเพลง",
  contributor_roles: "บทบาทนักแต่งเพลง",
  contributors: "นักแต่งเพลง",
  copy: "คัดลอก",
  copy_configuration_from: "copy configuration from",
  copyright_free: "copyright free",
  country: "ประเทศ",
  crd_files: "CRD files",
  crd_matching: "CRD matching",
  crd_pool: "CRD pool",
  crd_reports: "CRD reports",
  crd_reports_confirmation: "Confirm Download CRD Reports",
  crd_successfully_matched: "crd successfully matched",
  create: "create",
  create_agreement: "create agreement",
  create_crd_distribution_pool: "create CRD distribution pool",
  create_digital_distribution_pool: "create PRONTO distribution pool",
  create_distribution: "create distribution",
  create_distribution_pool: "create distribution pool",
  create_new_user_for: "create a new user for",
  create_non_ipi_party: "เพิ่มชื่อด้วยตัวเอง",
  create_pool: "create pool",
  create_recording: "create recording",
  create_vod_distribution_pool: "Create VOD distribution pool",
  create_work: "สร้างงาน",
  created: "สร้าง",
  created_by: "สร้างโดย",
  creation_date: "วันที่สร้าง",
  currency: "สกุลเงิน",
  current: "ปัจจุบัน",
  date_of_birth: "วันเกิด",
  date_of_death: "วันเสียชีวิต",
  dates: "วันที่",
  day: "วัน",
  declare_new_work: "สร้างเพลงใหม่",
  decline: "ปฎิเสธ",
  decline_work_update: "ปฎิเสธการแก้ไข",
  declined: "ถูกปฎิเสธ",
  deductibles: "สามารถลดหย่อนได้ / สามารถหักได้",
  delete: "ลบ",
  delete_agreement: "ลบข้อตกลง",
  delete_distribution: "delete distribution",
  delete_distribution_pool: "delete distribution pool",
  delete_heir: "delete heir",
  delete_interested_party: "ลบผู้มีส่วนเกี่ยวข้อง",
  delete_pro_rata_file: "delete current file",
  delete_usage_file: "delete usage file",
  delete_user: "delete user",
  delete_work: "delete work",
  deleted: "deleted",
  demerge: "demerge",
  demerge_message: "The following source will be demerged from this work",
  demerged: "demerged",
  demerged_work: "demerged work",
  description: "description",
  detach_all_files: "detach all files",
  detach_file: "detach file",
  detach_sheet: "detach sheet",
  different_spelling: "Different Spelling",
  digital_files: "digital files",
  digital_pool: "PRONTO pool",
  distributable: "distributable",
  distributable_net: "distributable net",
  distribution: "distribution",
  distribution_errors: "distribution errors",
  distribution_pool_successfully_created: "distribution pool successfully created",
  distribution_pool_successfully_deleted: "distribution pool successfully deleted",
  distribution_pool_successfully_edited: "distribution pool successfully edited",
  distribution_successfully_closed: "distribution successfully closed",
  distribution_successfully_created: "distribution successfully created",
  distribution_successfully_deleted: "distribution successfully deleted",
  distribution_successfully_edited: "distribution successfully edited",
  distribution_successfully_published: "distribution successfully published",
  distributions: "การจัดสรรค่าสิทธิ",
  do_you_want_to_accept_this_update: "คุณต้องการยอมรับการอัปเดตนี้หรือไม่?",
  do_you_want_to_continue: "คุณต้องการดำเนินการต่อหรือไม่?",
  do_you_want_to_decline_this_update: "คุณต้องการปฏิเสธการอัปเดตนี้หรือไม่?",
  domestic_pool: "domestic pool",
  done: "done",
  download_crd_reports: "download CRD reports",
  download_current_file: "download current file",
  download_prorata_file_template: "download pro-rata file template",
  download_qa_reports: "download QA reports",
  download_template: "download template",
  download_works_list: "ดาวน์โหลดรายชื่อเพลง",
  dsp: "DSP",
  duplicates_not_allowed: "duplicates not allowed",
  duration: "duration",
  edit: "edit",
  edit_crd_distribution_pool: "edit CRD distribution pool",
  edit_digital_distribution_pool: "edit PRONTO distribution pool",
  edit_distribution: "edit distribution",
  edit_distribution_pool: "edit distribution pool",
  edit_shares: "edit shares",
  edit_tags: "edit tags",
  edit_vod_distribution_pool: "Edit VOD distribution pool",
  edit_work: "แก้ไขข้อมูล",
  edited: "edited",
  editing_multiple_tags_warning: "The following tags will be applied to all selected files. If no tag is specified, any existing tags will be removed from the files.",
  email: "email",
  end: "สิ้นสุด",
  end_date: "วันสิ้นสุดสัญญา",
  error: "error",
  errors: "errors",
  events: "เหตุการณ์",
  exclude: "exclude",
  existing_agreements: "existing agreements",
  extra_search_title: "extra search title",
  field: "field",
  file_errors: "ข้อผิดพลาดของไฟล์",
  file_not_attached: "file not attached",
  file_successfully_uploaded: "file successfully uploaded",
  filename: "filename",
  files: "files",
  first_line_of_text: "first line of text",
  first_name: "first name",
  for: "for",
  form_contains_errors: "the form contains errors. please verify all the information provided",
  formal_title: "formal title",
  from: "from",
  full_name: "full name",
  fund_id: "fund ID",
  funds: "funds",
  general: "general",
  general_users: "ผู้ใช้งานทั่วไป",
  generate_UP1_report: "Generate UP1 report",
  go_to: "ไปที่",
  go_to_agreement_conflict: "ไปที่ข้อตกลงที่ขัดแย้ง",
  go_to_detail_page: "ไปที่หน้ารายละเอียด",
  go_to_user_action: "go to user action",
  go_to_work: "go to work",
  go_to_works_matching_user_action: "ไปที่งานที่ตรงกับการดำเนินการของผู้ใช้",
  gross_amount: "gross",
  has_files: "has files",
  heir_deleted: "heir deleted",
  heirs: "heirs",
  hide_reviewed: "hide reviewed",
  high: "high",
  history: "ประวัติ",
  holding_reference: "อ้างอิงการถือครอง",
  inactive: "inactive",
  include: "include",
  include_exclude: "include/exclude",
  income_participant: "รายได้ของผู้มีส่วน",
  incoming_work: "incoming work",
  incomplete: "incomplete",
  incorrect_title: "incorrect title",
  ingested: "ingested",
  ingestion_stats: "ingestion stats",
  inheritance_date: "วันที่รับมรดก",
  interested_parties: "ผู้มีส่วนเกี่ยวข้อง",
  interested_party: "ผู้มีส่วนเกี่ยวข้อง",
  interested_party_name: "ชื่อนักแต่ง/ พับลิชเชอร์",
  interested_party_number: "หมายเลข IPI ของผู้มีส่วนเกี่ยวข้อง",
  invalid_ipi_base_number: "Invalid IPI base number",
  invalid_isrc: "ISRC ไม่ถูกต้อง",
  invalid_iswc: "invalid ISWC",
  ip_matching_stats: "สถิติการจับคู่ IP",
  ipi_base_number: "หมายเลขประจำตัวนักแต่งเพลง",
  ipi_information: "ข้อมูล IPI",
  ipi_name_number: "หมายเลข IPI",
  ipi_number: "หมายเลข IPI",
  ipi_status: "สถานะ IPI",
  ipi_status_1: "Self-referenced valid IP",
  ipi_status_2: "Purchase",
  ipi_status_3: "Logical deletion with cross-reference",
  ipi_status_4: "Total logical deletion without cross-reference",
  isrc: "ISRC",
  iswc: "ISWC",
  item: "รายการ",
  iteration: "iteration",
  karaoke: "คาราโอเกะ",
  landed: "ลงสู่หน้าที่กำหนดแล้ว",
  landing_db_ingestion: "การนำเข้าข้อมูล",
  language: "ภาษา",
  last_name: "นามปากกา",
  last_run: "last run",
  latest: "ล่าสุด",
  legal_name: "ชื่อ-นามสุกล",
  less: "less",
  licensee: "ผู้รับอนุญาต",
  link: "link",
  link_recording: "link recording",
  linked: "linked",
  linked_to_existing_work: "งานดังกล่าวเชื่อมโยงกับงานที่มีอยู่แล้ว",
  linked_work: "งานที่เชื่อมโยง",
  login: "เข้าสู่ระบบ",
  logout: "ออกจากระบบ",
  low: "low",
  lyrical_adaptations: "การดัดแปลงเนื้อเพลง",
  mandates: "ข้อกำหนด/ ข้อตกลง",
  manually_created: "manually created",
  manually_linked: "manually linked",
  manually_matched: "manually matched",
  mark_as_reviewed: "mark as reviewed",
  marked_for_deletion: "marked for deletion",
  match: "match",
  matched: "matched",
  matched_by: "matched by",
  matched_work: "matched work",
  matching_stats: "matching stats",
  mechanical: "สิทธิทำซ้ำ",
  medium: "medium",
  member: "Member",
  member_reports: "member reports",
  member_reports_download: "download distribution reports",
  member_reports_processing: "processing distribution reports",
  member_reports_request: "request distribution reports",
  member_work_matching_message: "This work registration is a potential duplicate and is pending review by your society administrators.",
  member_work_update_notice: "You'll be able to track any pending changes in the “work updates” tab within the work's page.",
  membership_status: "membership status",
  merge: "merge",
  merge_works: "merge works",
  merging_more_than_three_works_not_allowed: "merging more than three works is not allowed",
  merging_not_allowed_for_non_domestic_works: "merging not allowed for non domestic works",
  metadata: "ฐานข้อมูลเชิงลึก",
  modification: "การปรับเปลี่ยน",
  modified_lyrics: "modified lyrics",
  modified_version: "modified version",
  month: "เดือน",
  more: "more",
  more_information: "ข้อมูลเพิ่มเติม",
  music_added_to_text: "เพิ่มเพลงลงในข้อความ",
  music_arrangement: "การเรียบเรียงดนตรี",
  music_video: "music video",
  my_profile: "ข้อมูลสมาชิก",
  my_works: "เพลงของฉัน",
  name: "ชื่อ",
  name_search: "ค้นหาชื่อ",
  names: "ชื่อ",
  nationalities: "สัญชาติ",
  net_distributable_amount: "ยอดสุทธิ",
  new_lyrics_added: "เพิ่มเนื้อเพลงใหม่",
  new_music_added: "เพิ่มเพลงใหม่",
  new_work_created: "สร้างงานใหม่สำเร็จแล้ว",
  no: "เลขที่",
  no_chained_shares: "no chained shares",
  no_crd_reports: "ไม่มีรายงาน CRD",
  no_distribution_reports_for_member: "You are not entitled to any payment in this distribution cycle, so reports are disabled",
  no_files: "ไม่มีไฟล์",
  no_lyrics: "ไม่มีเนื้อเพลง",
  no_member_reports: "ไม่มีรายงานสมาชิก",
  no_parties_found: "ไม่พบข้อมูลผู้มีส่วนร่วม",
  no_payments_for_you: "you are not entitled to any payment in this distribution cycle, so reports are disabled",
  no_performers_for_this_work: "ไม่มีศิลปินสำหรับงานเพลงนี้",
  no_qa_reports: "QA reports not available",
  no_shares: "ไม่มีส่วนแบ่ง",
  no_sources_found: "no sources found for this work",
  no_stats_info_available: "ไม่มีสถิติ",
  no_work_report: "ไม่มีรายงานการงาน",
  non_ipi: "ไม่มี IPI",
  not_current: "not current",
  nothing_has_been_selected: "ไม่พบสิ่งที่เลือก",
  on: "on",
  only_alpha_numeric_characters_allowed: "อนุญาตให้ใช้เฉพาะตัวเลขและตัวอักษรเท่านั้น",
  only_domestic: "เฉพาะงานในประเทศเท่านั้น",
  only_with_usages: "เฉพาะที่มีการใช้งานเท่านั้น",
  original: "Original",
  original_lyrics: "เนื้อเพลงดั้งเดิม",
  original_music: "เพลงดั้งเดิม",
  original_publisher: "พับลิชเชอร์",
  original_sound_track: "original sound track",
  original_title: "ชื่อดั้งเดิม",
  original_title_translated: "แปลชื่อดั้งเดิมแล้ว",
  other_reference: "การอ้างอิงอื่น",
  override_distribution: "override distribution",
  overview: "ภาพรวม",
  own_works: "งานของตนเอง",
  part_title: "part title",
  parties: "ผู้มีส่วนร่วม",
  party: "นักแต่ง",
  password: "รหัสผ่าน",
  patronym: "นามปากกา",
  payees: "ผู้รับเงิน",
  pending: "pending",
  pending_allocation: "pending allocation",
  pending_for_validation: "pending for validation",
  pending_updates: "รอการอัปเดต",
  per_and_mec: "PER and MEC",
  per_duration: "per duration",
  per_play: "per play",
  per_weighted_play: "per weighted play",
  percent: "percent",
  performer: "ชื่อศิลปิน",
  performers: "ศิลปิน",
  performing: "สิทธิเผยแพร่",
  period: "ระยะเวลา",
  play_count: "play count",
  plays: "จำนวนการใช้งาน",
  please_check_your_inbox: "กรุณาตรวจสอบกล่องจดหมายของคุณ",
  pool: "pool",
  pool_must_be_created_before_attaching: "the distribution pool must be created before attaching a pro-rata file",
  pools: "pools",
  preferred: "preferred",
  preferred_iswc: "preferred iswc",
  primary: "หลัก",
  primary_address: "ที่อยู่หลัก",
  primary_email: "อีเมลหลัก",
  primary_phone: "เบอร์โทรศัพท์หลัก",
  priority: "ความสำคัญ",
  privacy_policy: "นโยบายความเป็นส่วนตัว",
  pro_rata_equal: "pro-rata - equal",
  pro_rata_file_deleted: "pro-rata file successfully deleted",
  pro_rata_percentage: "pro-rata - % per payee",
  processing: "กำลังประมวลผล",
  processing_crd_reports: "processing CRD reports",
  products_not_found: "ไม่มีข้อมูลการบันทึกเสียง",
  pronto_pre_distribution: "pronto pre-distribution",
  proprietary_id: "Proprietary ID",
  pseudonym_groups: "นามปากกากลุ่ม",
  pseudonym_of_individuals: "นามปากกาของบุคคล",
  public_domain: "public domain",
  publish: "publish",
  publish_distribution: "publish distribution",
  published: "ถูกเผยแพร่",
  published_date: "วันที่เผยแพร่",
  publisher: "พับลิชเชอร์",
  publisher_name: "ชื่อพับลิชเชอร์",
  publisher_roles: "บทบาทของพับลิชเชอร์",
  qa_reports: "รายงาน QA",
  queued_for_processing: "added to processing queue",
  radio: "วิทยุ",
  reason: "เหตุผล",
  reconciled: "reconciled",
  reconciliation: "reconciliation",
  recording: "recording",
  recording_conflict: "recording conflict",
  recording_conflict_help_text: "The recording on top is currently linked to the following works. Select one work, and it will be removed from the rest.",
  recording_conflicts: "recording conflicts",
  recording_details: "รายละเอียดการบันทึก",
  recording_release: "recording release",
  recording_successfully_created: "สร้างการบันทึกสำเร็จแล้ว",
  recording_successfully_linked: "เชื่อมโยงการบันทึกสำเร็จแล้ว",
  recording_successfully_unlinked: "ยกเลิกการลิงก์การบันทึกสำเร็จแล้ว",
  recording_title: "ชื่อการบันทึก",
  recording_type: "recording type",
  recordings: "การบันทึก",
  refresh: "refresh",
  register: "ลงทะเบียน",
  remitting_society_distribution_identifier: "remitting society distribution id",
  remove: "ลบ",
  repertoire_admin: "ผู้ดูแลระบบงานเพลง",
  replaced_lyrics: "เนื้อเพลงถูกแก้ไขแล้ว",
  report_generation_confirmation: "ยืนยันการสร้างรายงาน",
  report_generation_has_successfully_been_initiated: "การสร้างรายงานสำเร็จแล้ว",
  request_changes: "ขอเปลี่ยนแปลง",
  request_member_reports: "ขอรายงานสมาชิก",
  request_reports_success: "The reports have been requested and will be sent to your inbox as soon as they are ready",
  request_sent: "ส่งคำขอแล้ว รายงานจะพร้อมใช้งานในไม่ช้า",
  request_work_report: "ขอรายงานงาน",
  requested_by: "แก้ไขโดย",
  reset_password: "รีเซ็ตรหัสผ่าน",
  resolution: "resolution",
  resolved_by: "แก้ไขโดย",
  restricted_title: "ชื่อที่ถูกจำกัด",
  right_types: "ประเภทของสิทธิ",
  rights: "สิทธิ",
  ring_back_tone: "ring back tone",
  role: "บทบาท",
  rule: "กฎ",
  rules: "กฎ",
  rules_change_warning: "This rules change will detach any files attached to the pool. Do you want to continue",
  save: "บันทึก",
  search: "ค้นหา",
  search_and_select: "ค้นหาและเลือก",
  search_by: "ค้นหาโดย",
  search_by_artist: "ศิลปิน",
  search_by_atlas_id: "หมายเลข Altas ID",
  search_by_filename: "filename",
  search_by_ipi_number: "ตามหมายเลข IPI",
  search_by_iswc_atlas_id_or_work_code: "ISWC, รหัส ATLAS, Mis@Asia หรือ DIVA",
  search_by_name: "ชื่อ",
  search_by_name_or_complete_ipi_number: "ชื่อนักแต่ง หรือหมายเลข IPI",
  search_by_party_id: "รหัสกลุ่ม",
  search_by_party_name: "ชื่อผู้แต่งหรือพับลิชเชอร์",
  search_by_party_number: "หมายเลขกลุ่ม",
  search_by_performer: "ชื่อศิลปิน",
  search_by_title: "ค้นหาชื่อเพลง",
  search_by_title_or_alternate_titles: "ชื่อเพลง",
  search_existing_works: "ค้นหางานที่มีอยู่",
  search_ipi_db: "ค้นหาฐานข้อมูล IPI",
  search_non_ipi_parties: "ค้นหากลุ่มที่ไม่มี IPI",
  search_results: "ผลการค้นหา",
  secondary_email: "อีเมลสำรอง",
  secondary_phone: "เบอร์โทรศัพท์สำรอง",
  select_agreement: "เลือกสัญญา",
  select_candidate: "เลือกนักแต่ง",
  select_date_range: "เลือกช่วงวันที่",
  select_option: "เลือกตัวเลือก",
  select_priority: "เลือกตามลำดับความสำคัญ",
  select_product_type: "เลือกประเภทผลิตภัณฑ์",
  select_recording_type: "เลือกประเภทการบันทึก",
  select_this_work: "เลือกงานนี้",
  select_use_type: "เลือกประเภทการใช้งาน",
  selected: "เลือกแล้ว",
  selected_files: "เลือกไฟล์",
  sender: "ผู้ส่ง",
  sender_currency: "สกุลเงินของผู้ส่ง",
  server_error_message: "something went wrong, please contact the administrator.",
  service: "บริการ",
  set_as_preferred_iswc: "ตั้งเป็น iswc ที่ต้องการ",
  share: "ส่วนแบ่ง",
  shares: "ส่วนแบ่ง",
  show_distribution: "show the distribution key",
  show_members_only: "show members only",
  show_non_ipi_only: "Show non IPI only",
  show_source_metadata: "show source metadata",
  signup_confirmation: "คุณลงชื่อสมัครใช้งานสำเร็จแล้ว เรากำลังตรวจสอบการสมัครของคุณ โปรดรอให้บัญชีของคุณเปิดใช้งาน",
  single_view_ingestion: "single view ingestion",
  society: "องค์กร",
  society_admin: "ผู้ดูแลระบบองค์กร",
  sound_recording: "สิ่งบันทึกเสียง",
  source: "แหล่งที่มา",
  sources: "แหล่งที่มา",
  specific: "เฉพาะเจาะจง",
  split: "แยก",
  standardized_spelling: "การสะกดมาตรฐาน",
  start: "เริ่ม",
  start_date: "วันเซ็นสัญญา",
  stats: "สถิติ",
  status: "สถานะ",
  sub_arranger: "ผู้เรียบเรียงย่อย",
  sub_author: "ผู้ประพันธ์ย่อย",
  submit_for_review: "ส่งเพื่อตรวจสอบ",
  submitted_by: "ส่งมาโดย",
  substituted_publisher: "พับลิชเชอร์ทดแทน",
  synchronisation: "สิทธิทำซ้ำประกอบภาพเคลื่อนไหว",
  tag_filters: "ตัวกรองแท็ก",
  tagging_was_successful: "การแท็กสำเร็จ",
  termination_date: "วันสิ้นสุดสัญญา",
  territories: "ราชอาณาจักร",
  territory: "ราชอาณาจักร",
  the_following_errors_have_to_fixed_for_allocation_to_run: "the following errors have to fixed for allocation to run",
  this_field_confirm_does_not_match: "this field confirmation does not match",
  this_field_is_required: "โปรดระบุ",
  this_field_must_be_a_valid_email: "this field must be a valid email",
  title: "ชื่อเพลง",
  to: "ถึง",
  total: "ทั้งหมด",
  total_unique: "ทั้งหมดที่ไม่ซ้ำ",
  transaction: "ธุรกรรม",
  translated_lyrics: "เนื้อเพลงที่แปล",
  translator: "ผู้แปล",
  tv: "โทรทัศน์",
  type: "ประเภท",
  unallocated: "unallocated",
  undo_allocation: "undo allocation",
  undo_allocation_success: "undo allocation success",
  unlink: "unlink",
  unmatch: "unmatch",
  unmatch_confirmation: "unmatch confirmation",
  unmatched: "unmatched",
  unspecified: "unspecified",
  up1_report_confirmation: "a report for the following files will be generated and a link to download the report will be sent to this email address",
  update: "อัปเดต",
  update_user: "อัปเดตผู้ใช้",
  upload: "ที่อัปโหลด",
  upload_date: "วันที่อัปโหลด",
  upload_pro_rata_file: "อัปโหลดไฟล์การแบ่งตามส่วน",
  uploaded_by: "อัปโหลดโดย",
  uploader: "ผู้อัปโหลด",
  usage_file_successfully_marked_for_deletion: "ทำเครื่องหมายไฟล์การใช้งานเพื่อลบ สำเร็จแล้ว",
  usage_files: "ไฟล์การใช้งาน",
  usage_period: "ระยะเวลาการใช้งาน",
  usage_successfully_matched: "จับคู่การใช้งานสำเร็จแล้ว",
  usage_successfully_unmatched: "ยกเลิกการจับคู่สำเร็จแล้ว",
  usages_matching: "การจับคู่การใช้งาน",
  use_type: "ประเภทการใช้งาน",
  user: "ผู้ใช้",
  user_action: "การดำเนินการของผู้ใช้",
  user_actions: "การดำเนินการของผู้ใช้",
  user_email: "อีเมลผู้ใช้",
  user_email_field_help_text: "อีเมลนี้จะใช้ในการเข้าสู่ระบบแอปพลิเคชัน",
  user_name: "ชื่อผู้ใช้",
  user_name_field_help_text: "ชื่อนี้ระบุผู้ใช้ที่สามารถเข้าสู่แอปพลิเคชันได้",
  user_updated: "อัปเดตผู้ใช้แล้ว",
  user_with_provided_email_doesnt_exist: "ไม่มีผู้ใช้ที่มีอีเมลดังกล่าว",
  users: "ผู้ใช้งาน",
  view: "มุมมอง",
  view_in_works_database: "ดูในฐานข้อมูลงาน",
  view_user_action: "ดูการดำเนินการของผู้ใช้",
  view_work: "ดูงาน",
  vod_files: "VOD Files",
  vod_pool: "VOD Pool",
  warning: "คำเตือน",
  week: "สัปดาห์",
  wex_remitted_royalty_amount: "WEX remitted royalty amount",
  which_work_do_you_want_to_keep: "คุณต้องการเก็บงานชิ้นใดไว้?",
  with_up: "allocate to UP",
  without_up: "do not allocate to UP",
  work: "ชื่อเพลง",
  work_code: "รหัส",
  work_codes: "รหัสงาน",
  work_detail: "ข้อมูลงาน",
  work_edit_to_be_reviewed: "your request for changes has been successfully submitted, and will be verified by your society admins.",
  work_id: "รหัสประจำตัวงาน",
  work_matching: "work matching",
  work_matching_stats: "สถิติการจับคู่งาน",
  work_registration: "การลงทะเบียนเพลง",
  work_registration_conflict: "this work registration is a potential duplicate, click on the link to go to the user actions page.",
  work_registration_created: "the work was successfully created, click the link below to see details.",
  work_registration_details: "work registration details",
  work_registration_linked: "this work registration was identified as a duplicate and was linked to an existing work, click on the link to view the details.",
  work_registration_to_be_reviewed: "your work registration has been successfully submitted, and will be verified by your society admins.",
  work_report: "work report",
  work_selected: "เลือกงานแล้ว",
  work_successfully_created: "the work was successfully created",
  work_successfully_deleted: "ลบงานสำเร็จแล้ว",
  work_successfully_demerged: "แยกงานออกสำเร็จแล้ว",
  work_successfully_updated: "อัปเดตงานสำเร็จแล้ว",
  work_title: "ชื่อเพลง",
  work_type: "ประเภทงาน",
  work_update_accepted: "งานได้รับการแก้ไขแล้ว",
  work_update_declined: "ปฏิเสธการแก้ไขงาน",
  work_updates: "อัปเดตเพลง",
  works: "ชื่อเพลง",
  works_list_for_party_will_be_emailed: "A file containing all the works for this party is being generated and a link to download will be sent via email once it is ready.",
  works_list_for_you_will_be_emailed: "A file containing all your works is being generated and a link to download will be sent via email once it is ready.",
  works_matching: "works matching",
  works_search_by: "ค้นหางานภายใต้พับลิชเชอร์ ตามชื่อ ชื่ออื่นๆ หรือ ISWC",
  works_single_view: "works single view",
  works_successfuly_merged: "works successfully merged",
  works_with_non_ipi_ips: "works with non-IPI IPs",
  wrong_role_for_party: "wrong role for party",
  yes: "ใช่",
  your_email: "อีเมลของคุณ",
}
