<template>
  <b-table
    :items="items"
    :fields="fields"
    borderless
    striped
    show-empty
  >
    <template v-slot:cell(ipiNameNumber)="{ item }">
      {{ item.party.ipiNameNumber }}
    </template>
    <template v-slot:cell(name)="{ item }">
      <div class="d-flex">
        <b-col class="pl-0">
          <span>{{ item.party.name }}</span>
        </b-col>
      </div>
    </template>

    <!-- Performing -->
      <template v-slot:cell(performing)="{ index, item }">
        <div class="d-flex align-items-center">
          <split-input
            :name="`payee-performing-${index}`"
            :value="item.performing"
            max="100"
            @input="onPerformingInput($event, item, index)"
            class="narrow"
            :disabled="!canEdit(item)"
          />
          <div class="ml-2 mb-3">%</div>
        </div>
      </template>

      <!-- Mechanical -->
      <template v-slot:cell(mechanical)="{ index, item }">
        <div class="d-flex align-items-center">
          <split-input
            :name="`payee-mechanical-${index}`"
            :value="item.mechanical"
            max="100"
            @input="onMechanicalInput($event, item, index)"
            class="narrow"
            :disabled="!canEdit(item)"
          />
          <div class="ml-2 mb-3">%</div>
        </div>
      </template>
  </b-table>
</template>

<style lang="scss" scoped>
  ::v-deep .narrow {
    width: 90px !important;
  }

  ::v-deep .left-pad {
    padding-left: 1.8rem;
  }

  ::v-deep th:first-child {
    width: 450px;
    white-space: nowrap;
  }

  .table ::v-deep td {
    padding-bottom: 0 !important;
  }
</style>


<script>
import { capitalize } from "lodash"

export default {
  name: "PayeeSharesEditTable",
  data () {
    return {
      fields: [
        { key: "ipiNameNumber", label: this.$t("ipi_name_number"), class: "narrow", },
        { key: "name", label: capitalize(this.$t("name")), class: "w-50" },
        { key: "performing", label: capitalize(this.$t("performing")), class: ["narrow", "left-pad"] },
        { key: "mechanical", label: capitalize(this.$t("mechanical")), class: "narrow" },
      ],
    }
  },
  computed: {
    items () {
      return [ ...this.payeeShares ]
    }
  },
  methods: {
    canEdit (item) {
      return item.party.isMember || item.party.nonIpi
    },
    onMechanicalInput (value, item, index) {
      this.onSplitInput(value, item, index, "mechanical")
    },
    onPerformingInput (value, item, index) {
      this.onSplitInput(value, item, index, "performing")
    },
    onSplitInput (value, item, index, rightsType) {
      if (value !== item[rightsType]) {
        item[rightsType] = value

        this.items.splice(index, 1, { ...item })
        this.$emit("edited", this.items)
      }
    },
  },
  props: {
    payeeShares: {
      type: Array,
      default: () => [],
    },
  }
}
</script>
