import { API } from "./utils"


export const agreements = {
  agreementsCreate: (data, params=null) => API.post("/agreements/", data, { params }),
  agreementsList: (params = null) => API.get("/agreements/", { params }),
  agreementsRetrieve: id => API.get(`/agreements/${id}/`),
  agreementsUpdate: (id, data) => API.patch(`/agreements/${id}/`, data),
  agreementsDelete: (id) => API.delete(`/agreements/${id}/`),
  agreementsFilesList: (params) => API.get("/agreements/uploaded-files/", { params }),
  agreementsFileErrorsList: (id, params) => API.get(`/agreements/uploaded-files/${id}/errors/`, { params }),
  landingAgreementsList: (id, params=null) => API.get(`/agreements/uploaded-files/${id}/landing-agreements/`, { params }),
}
