<template>
  <list-layout :title="distribution.name" :show-refresh="true">
    <template v-slot:title>
      <div class="d-flex align-items-baseline">
        <!-- Title -->
        <h3 class="mr-4">
          {{ distribution.name }}
        </h3>
        <!-- Status -->
        <DistributionStatus :distributionStatus="distribution.status"/>
      </div>
    </template>

    <template v-slot:subtitle>
      <p>{{ distribution.code }}</p>
      <div v-if="distribution.description" class="text-gray">
        <span>{{ distribution.description }}</span>
      </div>
      <div class="d-flex align-items-baseline text-gray">
        <span>{{ $t("from") | capitalize }}:</span>
        <date-time-cell class="pl-1 font-weight-bold" :dateTime="distribution.startDate" dateOnly/>
      </div>
      <div class="d-flex align-items-baseline text-gray">
        <span>{{ $t("to") | capitalize }}:</span>
        <date-time-cell class="pl-1 font-weight-bold" :dateTime="distribution.endDate" dateOnly/>
      </div>
      <div v-if="distribution.lastRun" class="d-flex align-items-baseline text-gray pt-3">
        <span>{{ $t("last_run") | capitalize }}:</span>
        <date-time-cell class="px-1 font-weight-bold" :dateTime="distribution.lastRun" dateOnly/>
        <span>({{ $t("iteration") | capitalize }} {{ distribution.latestVersion }})</span>
      </div>
      <div>
        <span class="badge badge-secondary pool-count-badge mt-3 p-1">{{ poolCountText }}</span>
      </div>
    </template>

    <template v-slot:actions>
      <!-- Actions -->
      <div v-if="distribution.id">
        <b-button
          variant="outline-secondary"
          size="sm"
          class="m-1"
          :to="{ name: 'distributions-list' }"
        >
          {{ $t("back_to_distributions") | capitalize }}
        </b-button>
        <b-button
          v-if="isOpen"
          variant="outline-secondary"
          size="sm"
          class="m-1"
          :to="{ name: 'distributions-edit' }"
        >
          {{ $t("edit_distribution") | capitalize }}
        </b-button>
        <b-button
          v-if="isOpen && !distribution.allPoolsAllocated"
          :disabled="isDistributionAllocating"
          variant="outline-secondary"
          size="sm"
          class="m-1"
          @click="onClickAllocate"
        >
          {{ $t("allocate_distribution") | capitalize }}
        </b-button>
        <b-button
          v-if="isOpen && !distribution.allPoolsUnallocated"
          :disabled="isDistributionAllocating"
          variant="outline-secondary"
          size="sm"
          class="m-1"
          @click="onClickUnallocate"
        >
          {{ $t("undo_allocation") | capitalize }}
        </b-button>
        <b-button
          v-if="isOpen"
          :disabled="distribution.status !== distributionStatuses.ALLOCATED"
          variant="outline-secondary"
          size="sm"
          class="m-1"
          @click="onClickClose"
        >
          {{ $t("close_distribution") | capitalize }}
        </b-button>
        <b-button
          v-if="distribution.status == distributionStatuses.CLOSED"
          variant="outline-secondary"
          size="sm"
          class="m-1"
          @click="onClickPublish"
        >
          {{ $t("publish_distribution") | capitalize }}
        </b-button>
        <b-dropdown
          v-if="isOpen"
          variant="secondary"
          id="create-pool-dropdown"
          :text="$t('create_pool') | capitalize"
          size="sm"
          class="m-1"
        >
          <b-dropdown-item :to="{ name: 'pools-create', query: { type: poolsFormType.DOMESTIC }}">{{ $t('domestic_pool') | upperfirst }}</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'pools-create', query: { type: poolsFormType.CRD }}">{{ $t('crd_pool') | upperfirst }} </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'pools-create', query: { type: poolsFormType.DIGITAL }}">{{ $t('digital_pool') | upperfirst }} </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'pools-create', query: { type: poolsFormType.VOD }}">{{ $t('vod_pool') | upperfirst }} </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>

    <!-- Pools Table -->
    <v-server-table
      :columns="columns"
      :options="options"
      @loaded="onLoaded"
      @pagination="onPagination"
      ref="pools"
    >
      <!-- Name -->
      <template v-slot:name="{ row }">  
        <router-link
          class="text-info"
          :to="{ name: 'pools-detail', params: { id, pid: row.id }, query: { type: getFileType(row) }}"
        >
          {{ row.name }}
        </router-link>
        <p>{{ row.code }}</p>
        <div v-if="row.startDate" class="d-flex align-items-baseline text-gray mb-1">
          <span>{{ $t("from") | capitalize }}:</span>
          <date-time-cell class="pl-1 font-weight-bold" :dateTime="row.startDate" :dateOnly="true"/>
        </div>
        <div v-if="row.endDate" class="d-flex align-items-baseline text-gray mb-1">
          <span>{{ $t("to") | capitalize }}:</span>
          <date-time-cell class="pl-1 font-weight-bold" :dateTime="row.endDate" :dateOnly="true"/>
        </div>
      </template>

      <!-- Use Type -->
      <template v-slot:overview="{ row }">
        <p v-if="row.rightType">{{ getRightTypeDisplay(row.rightType) | capitalize }}</p>
        <p v-if="row.useType">{{ getUseTypeDisplay(row.useType) }}</p>
        <p class="text-gray">{{ getPoolRulesDisplay(row) }}</p>
        <p v-if="row.hasFiles" class="text-gray">
          <fa-icon class="icon" size="lg" :icon="['fas','check']"/>
           {{ $t("has_files") | capitalize }}
        </p>
        <p v-else class="text-gray">
          <fa-icon class="icon" size="lg" :icon="['fas','times']"/>
           {{ $t("no_files") | capitalize }}
        </p>
        <div v-if="row.lastRun" class="d-flex align-items-baseline text-gray">
          <span>{{ $t("last_run") | capitalize }}:</span>
          <date-time-cell class="pl-1 font-weight-bold" :dateTime="row.lastRun" dateOnly/>
        </div>
      </template>

      <!-- Funds -->
      <template v-slot:funds="{ row }">
        <FundsCell :funds="row.funds" :percentages="row.fundsPercentages" />
      </template>

      <!-- Status -->
      <template v-slot:status="{ row }">
        <DistributionStatus :distributionStatus="row.status"/>
      </template>

      <!-- Stats -->
      <template v-slot:stats="{ row }">
        <DistributionStats :stats="row.stats" />
      </template>

      <!-- Actions -->
      <template v-slot:actions="{ row }">
        <div class="d-flex flex-row justify-content-end align-items-center" v-if="!isDistributionAllocating">
          <!-- Undo allocation -->
           <action
            v-if="canUndoAllocation(row)"
            :title="$t('undo_allocation')"
            @click="onUndoAllocation(row.id)"
            :icon="['fa', 'history']"
          />
          <modal
            :id="'undo-allocation-confirmation-modal-' + row.id"
            :title="$t('undo_allocation') | capitalize"
            :okTitle="$t('yes') | capitalize"
            :cancelTitle="$t('no') | capitalize"
            @ok="onConfirmUndoPoolAllocation(row.id)"
          >
            <div>{{ $t("confirm_undo_allocation") | capitalize }}</div>
          </modal>
          <!-- Delete -->
          <action
            v-if="isPoolOpen(row)"
            :title="$t('delete')"
            @click="onDelete(row.id)"
            :icon="['fas', 'trash']"
          />
          <modal
            :id="'delete-confirmation-modal-' + row.id"
            :title="$t('delete_distribution_pool') | capitalize"
            :okTitle="$t('yes') | capitalize"
            :cancelTitle="$t('no') | capitalize"
            @ok="onConfirmDelete(row.id)"
          >
            <div>{{ $t("confirm_delete_distribution_pool") | capitalize }}?</div>
          </modal>
        </div>
      </template>

    </v-server-table>
    <modal
      id="allocate-confirmation-modal"
      :title="$t('allocate_distribution') | capitalize"
      :okTitle="$t('yes') | capitalize"
      :cancelTitle="$t('no') | capitalize"
      @ok="onConfirmAllocateDistribution"
    >
      <div>{{ $t("confirm_allocate_distribution") }}</div>
    </modal>
    <modal
      id="distribution-errors-modal"
      :title="$t('distribution_errors') | capitalize"
      hide-footer
    >
      <p>{{ $t("the_following_errors_have_to_fixed_for_allocation_to_run") | capitalize }}</p>
      <div v-for="pool in distributionValidationErrors" :key="pool.id">
        <router-link
          class="text-info"
          :to="{ name: 'pools-detail', params: { id: distribution.id, pid: pool.id }, query: { type: getFileType(pool) }}"
        >
          {{ pool.name }}
        </router-link>
        <ul>
          <li v-for="error in pool.errors" :key="error">{{ $t(error) | capitalize }}</li>
        </ul>
      </div>
    </modal>
    <modal
      id="close-confirmation-modal"
      :title="$t('close_distribution') | capitalize"
      @ok="onConfirmCloseDistribution"
    >
      <div>{{ $t("confirm_close_distribution") }}</div>
    </modal>
    <modal
      id="publish-confirmation-modal"
      :title="$t('publish_distribution') | capitalize"
      @ok="onConfirmPublishDistribution"
    >
      <div>{{ $t("confirm_publish_distribution") }}</div>
    </modal>
    <modal
      id="undo-allocation-confirmation-modal"
      :title="$t('undo_allocation') | capitalize"
      :okTitle="$t('yes') | capitalize"
      :cancelTitle="$t('no') | capitalize"
      @ok="onConfirmUndoDistributionAllocation()"
    >
      <div>{{ $t("confirm_undo_distribution_allocation") }}</div>
    </modal>
    <router-view name="modal" :id="id" :initial="distribution"></router-view>
  </list-layout>
</template>

<script>
import { capitalize, get, upperFirst } from "lodash"
import { distributionPoolRuleTypes, distributionStatuses, getDistributionPoolRuleTypes, getDistributionStatusDisplay, getRightTypeDisplay, poolsFormType } from "@/constants"
import { mapActions, mapMutations, mapState } from "vuex"
import DistributionStats from "./DistributionStats"
import DistributionStatus from "./DistributionStatus"
import FundsCell from "./FundsCell"
import { listRouteMixin } from "@/utils/mixins"

const {
  CRD,
  VOD,
  PRORATA_EQUAL,
  PRORATA_PERCENTAGE,
  WITH_UP,
  WITHOUT_UP,
  PER_PLAY,
  PER_DURATION,
  PRE_DISTRIBUTION_FILE,
  PER_WEIGHTED_PLAY,
} = distributionPoolRuleTypes

const levelOneTypes = [CRD, VOD, PRORATA_EQUAL, PRORATA_PERCENTAGE, WITH_UP, WITHOUT_UP, PRE_DISTRIBUTION_FILE]
const levelTwoTypes = [PER_DURATION, PER_PLAY, PER_WEIGHTED_PLAY]

export default {
  name: "DistributionsDetail",
  mixins: [listRouteMixin],
  components: { FundsCell, DistributionStats, DistributionStatus },
  data () {
    return {
      distributionStatuses,
      getDistributionPoolRuleTypes,
      distributionValidationErrors: [],
      count: 0,
      distribution: {
        name: "",
        description: "",
        status: "1",
      },
      columns: ["name", "overview", "funds", "stats", "status", "actions"],
      options: {
        headings: {
          name: capitalize(this.$t("name")),
          overview: capitalize(this.$t("overview")),
          funds: capitalize(this.$t("funds")),
          stats: capitalize(this.$t("stats")),
          status: capitalize(this.$t("status")),
          actions: "",
        },
        columnsClasses: {
          name: "w-20",
          overview: "w-25",
          funds: "w-20",
          stats: "w-25",
          status: "w-5",
          actions: "w-5",
        },
        responseAdapter ({ data }) {
          let component = this.$parent.$parent.$parent

          data.results.map(row => {
            row["funds"] = {
              fundInternalId: row.fundInternalId,
              grossAmount: row.grossAmount,
              deductibles: row.deductibles,
              administrationCosts: row.administrationCosts,
              netDistributableAmount: row.netDistributableAmount,
            }
            row["fundsPercentages"] = {
              deductibles: row.deductiblesPercent,
              administrationCosts: row.administrationCostsPercent,
            }
            row["distributionPoolRules"] = component.transformRules(row.distributionPoolRules)
          })
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          let { params, query } = this.$route
          queryParams = { ...queryParams, ...query }
          this.page = Number(get(queryParams, "page", 1))

          return this.$api.distributions.distributionPoolsList(params.id, queryParams)
        }
      },
      poolsFormType,
      getRightTypeDisplay
    }
  },
  computed: {
    ...mapState("consts", ["useTypesHash"]),
    isDistributionAllocating () {
      const { status } = this.distribution
      return status === distributionStatuses.PENDING_ALLOCATION || status === distributionStatuses.ALLOCATING
    },
    poolCountText () {
      return `${ this.count } ${ this.count == 1 ? this.$t("pool") : this.$t("pools") }`
    },
    isOpen () {
      return ![distributionStatuses.CLOSED, distributionStatuses.PUBLISHED].includes(this.distribution.status)
    }
  },
  methods: {
    getDistributionStatusDisplay,
    ...mapActions("alert", ["error", "success"]),
    ...mapMutations("consts", ["setUseTypes"]),
    getUseTypeDisplay (code) {
      return `${code} - ${this.useTypesHash[code]?.name}`
    },
    onLoaded ({ data }) {
      this.distribution = data.context
      this.count = data.count
    },
    getData () {
      this.$refs.pools.getData()
    },
    onDelete (id) {
      this.$bvModal.show(`delete-confirmation-modal-${id}`)
    },
    onUndoAllocation (id) {
      this.$bvModal.show(`undo-allocation-confirmation-modal-${id}`)
    },
    onConfirmDelete (id) {
      this.$api.distributions.distributionPoolsDelete(this.distribution.id, id)
        .then(() => {
          this.success(this.$t("distribution_pool_successfully_deleted"))
          this.getData()
        }).catch(error => {
          if (error.response.data && error.response.data.detail) this.error(error.response.data.detail)
        })
    },
    onConfirmUndoPoolAllocation (id) {
      this.$api.distributions.distributionsPoolsUnallocate(this.distribution.id, id)
        .then(() => {
          this.success(this.$t("undo_allocation_success"))
          this.getData()
        }).catch(error => {
          if (error.response.data && error.response.data.detail) this.error(error.response.data.detail)
        })
    },
    onClickAllocate () {
      this.$bvModal.show("allocate-confirmation-modal")
    },
    onClickUnallocate () {
      this.$bvModal.show("undo-allocation-confirmation-modal")
    },
    onClickClose () {
      this.$bvModal.show("close-confirmation-modal")
    },
    onClickPublish () {
      this.$bvModal.show("publish-confirmation-modal")
    },
    onConfirmAllocateDistribution () {
      const { id } = this.distribution
      this.$api.distributions.distributionsAllocate(id)
        .then(() => {
          this.getData()
          this.success(this.$t("allocation_process_successfully_triggered"))
        }).catch(err => {
          let { data } = err.response

          if (data.errors && data.errors.length) {
            this.distributionValidationErrors = data.errors
          } else {
            let message = data?.message || data
            this.error(message)
          }
        })
    },
    onConfirmUndoDistributionAllocation () {
      const { id } = this.distribution
      this.$api.distributions.distributionsUnallocate(id)
        .then(() => {
          this.getData()
          this.success(this.$t("undo_allocation_success"))
        }).catch(err => {
          let { data } = err.response

          if (data.errors && data.errors.length) {
            this.distributionValidationErrors = data.errors
          } else {
            let message = data?.message || data
            this.error(message)
          }
        })
    },
    onConfirmCloseDistribution () {
      const { id } = this.distribution
      this.$api.distributions.distributionsClose(id)
        .then(() => {
          this.getData()
          this.success(this.$t("distribution_successfully_closed"))
        }).catch(err => {
          let message = err.response.data?.message || err.response.data || err.response
          this.error(message)
        })
    },
    onConfirmPublishDistribution () {
      const { id } = this.distribution
      this.$api.distributions.distributionsPublish(id)
        .then(() => {
          this.getData()
          this.success(this.$t("distribution_successfully_published"))
        }).catch(err => {
          let message = err.response.data?.message || err.response.data || err.response
          this.error(message)
        })
    },
    isPoolOpen (row) {
      return ![distributionStatuses.CLOSED, distributionStatuses.PUBLISHED].includes(row.status)
    },
    canUndoAllocation (row) {
      return [distributionStatuses.ALLOCATED, distributionStatuses.ERROR].includes(row.status)
    },
    transformRules (rules) {
      let levelOneMatches = rules.find(r => levelOneTypes.includes(r.type))
      let levelTwoMatches = rules.find(r => levelTwoTypes.includes(r.type))
      return {
        levelOne: levelOneMatches ? levelOneMatches.type : "",
        levelTwo: levelTwoMatches ? levelTwoMatches.type : null
      }
    },
    getPoolRulesDisplay (row) {
      const { levelOne, levelTwo } = row.distributionPoolRules
      const levelOneRules = this.getDistributionPoolRuleTypes(levelOne)
      const levelTwoRules = levelTwo ? `, ${this.getDistributionPoolRuleTypes(levelTwo)}` : ""
      return upperFirst(`${levelOneRules}${levelTwoRules}`)
    },
    getFileType (row) {
      let distributionRules = row.distributionPoolRules.levelOne ?  row.distributionPoolRules :  this.transformRules(row.distributionPoolRules)

      if(distributionRules.levelOne === CRD) {
        return poolsFormType.CRD
      }
      if(distributionRules.levelOne === VOD) {
        return poolsFormType.VOD
      }
      if(distributionRules.levelOne === PRE_DISTRIBUTION_FILE) {
        return poolsFormType.DIGITAL
      }
      return  poolsFormType.DOMESTIC
    },
  },
  watch: {
    distributionValidationErrors (errors) {
      if (errors.length) this.$bvModal.show("distribution-errors-modal")
    },
  },
  mounted () {
    // Set UseTypes
    this.$api.rights.useTypesList()
      .then(response => this.setUseTypes(response.data))
  },
  props: {
    id: String
  }
}
</script>

<style scoped>
  .pool-count-badge {
    font-size: 15px;
  }
</style>
