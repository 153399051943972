<template>
  <v-server-table
    name="productsList"
    ref="productsList"
    :columns="columns"
    :options="options"
    @loaded="onLoaded"
  >
    <template v-slot:name="{ row }">
      {{ row.name }}
    </template>

    <template v-slot:type="{ row }">
      <span class="text-primary">
        {{ getTypeDisplay(row) | capitalize }}
      </span>
    </template>

    <template v-slot:actions="{ row }">
      <b-button
        class="mr-2 float-right"
        variant="outline-secondary"
        @click="link(row.id)"
        v-if="!row.works.includes(workId)"
      >
        {{ $t("link") | capitalize }}
      </b-button>
    </template>
  </v-server-table>
</template>

<style lang="scss" scoped>
  ::v-deep .table td {
    vertical-align: middle;
  }
</style>

<script>
import { capitalize, get } from "lodash"
import { getProductTypeDisplay, getRecordingTypeDisplay, productTypes } from "@/constants"
import { EventBus } from "@/utils/event-bus.js"
import { mapActions } from "vuex"

export default {
  name: "ProductsTable",
  data () {
    return {
      count: 0,
      columns: ["name", "type", "code", "artist", "actions"],
      options: {
        perPage: 5,
        headings: {
          title: capitalize(this.$t("name")),
          type: capitalize(this.$t("type")),
          code: capitalize(this.$t("code")),
          artist: capitalize(this.$t("artist")),
          actions: "",
        },
        requestKeys: { query: "fuzzy_search" },
        sendInitialRequest: false,
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          return this.$api.products.productsList(queryParams)
        },
      },
    }
  },
  methods: {
    ...mapActions("alert", ["success", "error"]),
    getTypeDisplay (product) {
      return product.type == productTypes.RECORDING ? getRecordingTypeDisplay(product.recordingType) : getProductTypeDisplay(product.type)
    },
    onLoaded ({ data }) {
      this.count = data.count
      this.status = data.status
      this.summary = data.summary
      if (this.count) this.$refs.productsList.$el.scrollIntoView({ behavior: "smooth" })
    },
    link (id) {
      EventBus.$emit("link-product", id)
    },
  },
  watch: {
    searchTerm (value) {
      this.$refs.productsList.setFilter(value)
    }
  },
  mounted () {
    if (this.$refs.productsList) EventBus.$on("products-updated", this.$refs.productsList.getData)
  },
  props: {
    searchTerm: {
      type: String,
      default: ""
    },
    workId: [String, Number]
  }
}
</script>
