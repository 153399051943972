<template>
  <list-layout
    :title="$t('bulk_works_upload')"
    :count="count"
    :filters="filters"
    :show-refresh="true"
  >
    <!-- Status -->
    <template v-slot:status>
      <div>
        <status
          :text="$t('landed')"
          :name="fileStatuses.LANDED"
          :count="summary.landed"
          :icon="['fas', 'check-circle']"
          color="green"
          :active="filters.status === fileStatuses.LANDED"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('ingested')"
          :name="fileStatuses.INGESTED"
          :count="summary.ingested"
          :icon="['fas', 'check-circle']"
          color="green"
          :active="filters.status == fileStatuses.INGESTED"
          clickable
          @click="onStatusClick"
        />
      </div>
      <div class="mr-3">
        <status
          :text="$t('pending')"
          :name="fileStatuses.PENDING"
          :count="summary.pending"
          :icon="['fas', 'question-circle']"
          color="orange"
          :active="filters.status == fileStatuses.PENDING"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('processing')"
          :name="fileStatuses.PROCESSING"
          :count="summary.processing"
          :icon="['fas', 'question-circle']"
          color="orange"
          :active="filters.status == fileStatuses.PROCESSING"
          clickable
          @click="onStatusClick"
        />
      </div>
      <div class="mr-3 align-self-start">
        <status
          :text="$t('error')"
          :name="fileStatuses.ERROR"
          :count="summary.error"
          :icon="['fas', 'exclamation-circle']"
          color="red"
          :active="filters.status == fileStatuses.ERROR"
          clickable
          @click="onStatusClick"
        />
      </div>
    </template>

    <!-- Download template btn -->
    <template v-slot:actions>
      <div>
        <b-button
          :href="$config.ATLAS_REPERTOIRE_TEMPLATE_URL"
          variant="outline-primary"
        >
          {{ $t("download_template") | startcase }}
        </b-button>
      </div>
    </template>

    <template v-slot:search>
      <b-row class="my-3" align-v="start">
        <b-col cols="4">
          <!-- Filename search -->
          <div>
            <label for="filename"> {{ $t("filename") | capitalize }}:</label>
            <search-input
              :value="filters.fuzzySearch"
              @search="onFileSearch"
              :placeholder="$t('search_by_filename') | capitalize"
              :input-attr="{id: 'filename'}"
            />
          </div>
        </b-col>
        <b-col cols="4">
          <!-- Uploader search -->
          <div>
            <label for="uploader"> {{ $t("uploader") | capitalize }}:</label>
            <party-search
              :party-type="PUBLISHER"
              :value="filters.uploaderSearch"
              :member="true"
              @input="onUploaderSearch"
              :input-attr="{id: 'uploader'}"
            />
          </div>
        </b-col>
        <b-col cols="4">
          <!-- Date picker -->
          <div>
            <label for="upload_date"> {{ $t("upload_date") | capitalize }}:</label>
            <date-picker
              v-model="dateRange"
              :placeholder="$t('select_date_range') | capitalize"
              range
              :format="$config.DATE_FORMAT"
              input-class="form-control"
              class="w-100 form-group"
              :input-attr="{id: 'upload_date'}"
            />
          </div>
        </b-col>
      </b-row>
    </template>

    <!-- Table -->
    <template v-slot:table>
      <v-server-table
        :columns="columns"
        :options="options"
        :ref="tableRef"
        @loaded="onLoaded"
        @pagination="onPagination"
      >
        <!-- File Name -->
        <template v-slot:filename="{ row }">
          <b-row>
            <b-col>
              <router-link
                :to="{ name: 'landing-works-list', params: { id: row.id }}"
                class="text-info"
              >
                {{ row.filename }}
              </router-link>
              <div class="text-gray">
                <small>
                  <template v-if="!isEmpty(row.metadata)">
                    <b>{{ $t("creation_date") | capitalize }}: </b> {{ parseDate(row.metadata.creationDate) }} <br>
                    <b>{{ $t("sender") | capitalize }}: </b> {{ row.metadata.senderName }} <br>
                  </template>
                </small>
              </div>
            </b-col>
          </b-row>
        </template>

        <!-- Upload date -->
        <template v-slot:uploadDate="{ row }">
          <date-time-cell :dateTime="row.uploadDate"/>
        </template>

        <!-- Uploader -->
        <template v-slot:uploader="{ row }">
          <small>
            <b>{{ $t("user") | capitalize }}: </b> {{ row.uploader.name }} <br>
            <b>{{ $t("party") | capitalize }}: </b><span>{{ row.uploader.party.name }}</span>
          </small>
        </template>

        <!-- Status -->
        <template v-slot:status="{ row }">
          <status
            v-if="row.status === fileStatuses.PENDING"
            :text="$t('pending')"
            :icon="['fas', 'question-circle']"
            color="orange"
          />
          <status
            v-if="row.status === fileStatuses.PROCESSING"
            :text="$t('processing')"
            :icon="['fas', 'question-circle']"
            color="orange"
          />
          <status
            v-if="row.status === fileStatuses.LANDED"
            :text="$t('landed')"
            :icon="['fas', 'check-circle']"
            color="green"
          />
          <status
            v-if="row.status === fileStatuses.INGESTED "
            :text="$t('ingested')"
            :icon="['fas', 'check-circle']"
            color="green"
          />
          <status
            v-if="row.status === fileStatuses.ERROR"
            :text="$t('error')"
            :icon="['fas', 'exclamation-circle']"
            color="red"
            :active="true"
            @click="showErrors(row, 'file')"
            clickable
          />
        </template>

        <!-- Child Rows -->
        <template v-slot:child_row="{ row }">
          <row-extra-info :key="row.id">
            <b-row class="text-gray mb-2">
              <b-col v-if="row.stats.landingDbIngestion" cols="3">{{ $t('landing_db_ingestion')| capitalize }}</b-col>
              <b-col v-if="row.stats.singleViewIngestion" cols="8">{{ $t('single_view_ingestion')| capitalize }}</b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div class="mr-3">
                  <ingestion-stats :stats="row.stats.landingDbIngestion" @show-errors="showErrors(row)" />
                </div>
              </b-col>
              <b-col cols="8">
                <b-row>
                  <b-col cols="4">{{ $t("total_unique") | capitalize }}</b-col>
                  <b-col class="text-gray" cols="8">{{ row.stats.singleViewIngestion.totalUniqueCount }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4">{{ $t("linked") | capitalize }}</b-col>
                  <b-col class="text-gray" cols="8">{{ row.stats.singleViewIngestion.linkedCount }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4">{{ $t("created") | capitalize }}</b-col>
                  <b-col class="text-gray" cols="8">{{ row.stats.singleViewIngestion.createdCount }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4">{{ $t("pending_for_validation") | capitalize }}</b-col>
                  <b-col class="text-gray" cols="8">{{ row.stats.singleViewIngestion.pendingForValidationCount }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4">{{ $t("manually_linked") | capitalize }}</b-col>
                  <b-col class="text-gray" cols="8">{{ row.stats.singleViewIngestion.manuallyLinkedCount }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4">{{ $t("manually_created") | capitalize }}</b-col>
                  <b-col class="text-gray" cols="8">{{ row.stats.singleViewIngestion.manuallyCreatedCount }}</b-col>
                </b-row>
              </b-col>
            </b-row>
          </row-extra-info>
        </template>

        <!-- Actions -->
        <template v-slot:actions="{ row }">
          <div class="d-flex justify-content-end">
            <action
              @click="showExtraInfo(row)"
              v-if="hasStats(row)"
              :title="$t('more_information')"
              :icon="['fas', !isOpen(row.id) ?  'angle-down': 'angle-up']"
            />
          </div>
        </template>
      </v-server-table>
    </template>

    <FileErrorsModal
      :item="selectedFile"
      :columns="['itemSeq', 'title', 'field', 'type', 'reason']"
      :onlyShowGlobalErrors="onlyShowGlobalErrors"
      :errorsListOptions="errorsListOptions"
    />
  </list-layout>
</template>

<script>
import { capitalize, get } from "lodash"
import { fileStatuses, partyTypes } from "@/constants"
import FileErrorsModal from "@/components/FileErrorsModal"
import { isEmpty } from "lodash"
import { listRouteMixin } from "@/utils/mixins"
import moment from "moment"
import { parseDate } from "@/utils/dates"

export default {
  name: "RepertoireFilesList",
  mixins: [listRouteMixin],
  components: {
    FileErrorsModal,
  },
  methods: {
    isEmpty,
    parseDate (date) {
      return date ? parseDate(date, this.$config.ISO_DATE_FORMAT) : ""
    },
    showExtraInfo ({ id }) {
      if (this.isOpen(id)) {
        this.openChildRows = this.openChildRows.filter(openRowId => id !== openRowId)
      } else {
        this.openChildRows.push(id)
      }
      this.$refs[this.tableRef].toggleChildRow(id)
    },
    showErrors (item, level) {
      this.onlyShowGlobalErrors = ["file", "sheet"].includes(level)
      this.selectedFile = item
      this.$bvModal.show("file-errors-modal")
    },
    hasStats (item) {
      return Object.keys(item.stats).length && (item.stats.landingDbIngestion || item.stats.singleViewIngestion)
    },
    isOpen (id) {
      return this.openChildRows.includes(id)
    },
    onFileSearch (value) {
      this.filters.fuzzySearch = value
    },
    onUploaderSearch (value) {
      this.filters.uploaderSearch = value
    },
    onLoaded ({ data }) {
      this.count = data.count
      this.status = data.status
      this.summary = data.summary
    },
    getData () {
      this.$refs[this.tableRef].getData()
    },
    onStatusClick (status) {
      this.filters.status = status
      this.applyFilters("status", status)
    },
  },
  data () {
    return {
      count: 0,
      dateRange: [],
      fileStatuses,
      selectedFile: {},
      filters: {
        status: "",
        fuzzySearch: "",
        to: "",
        from: "",
        uploaderSearch: ""
      },
      onlyShowGlobalErrors: false,
      summary: {},
      openChildRows: [],
      tableRef: "repertoireFilesList",
      columns: ["filename", "uploadDate", "uploader", "status", "actions"],
      options: {
        showChildRowToggler: false,
        headings: {
          filename: capitalize(this.$t("filename")),
          uploadDate: capitalize(this.$t("upload_date")),
          uploader: capitalize(this.$t("uploaded_by")),
          status: capitalize(this.$t("status")),
          actions: "",
        },
        requestKeys: { query: "fuzzy_search" },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))

          return this.$api.repertoire.repertoireFilesList(queryParams)
        }
      },
      errorsListOptions: {
        requestFunction (params) {
          return this.$api.repertoire.repertoireFileErrorsList(this.url, params)
        }
      },
      PUBLISHER: partyTypes.PUBLISHER,
    }
  },
  watch: {
    dateRange (val) {
      this.filters.from = val[0] ? this.parseDate(val[0]) : null
      this.filters.to = val[1] ? this.parseDate(val[1]) : null

      this.applyFilters("to", this.filters.to)
      this.applyFilters("from", this.filters.from)
    },
    "filters.fuzzySearch" (value) {
      this.applyFilters("fuzzy_search", value)
    },
    "filters.uploaderSearch" (value) {
      this.applyFilters("uploader_search", value?.id || null)
    }
  },
  mounted () {
    this.setFilters()

    this.filters.uploaderSearch = null //We don't set uploader search by query params since it has to load the name from API service

    const { from, to } = this.filters
    const fromParsed = moment(from, this.$config.ISO_DATE_FORMAT, true)
    const toParsed = moment(to, this.$config.ISO_DATE_FORMAT, true)

    if(fromParsed.isValid() && toParsed.isValid() && fromParsed <= toParsed) {
      const fromDate = new Date(from)
      const toDate = new Date(to)
      this.dateRange = [fromDate, toDate]
    } else {
      this.dateRange = [null, null]
    }
  },
}
</script>

<style lang="scss" scoped>
  .error-link {
    color: var(--blue);
    cursor: pointer;
  }
</style>
