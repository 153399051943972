<template>
  <b-table
    :items="payeeShares"
    :fields="fields"
    borderless
    striped
    show-empty
  >
    <!-- IPI name number -->
    <template v-slot:cell(ipiNameNumber)="{ item }">
      {{ item.party.ipiNameNumber }}
    </template>

    <!-- Name -->
    <template v-slot:cell(name)="{ item }">
      <div class="d-flex">
        <span>{{ item.party.name }}</span>
      </div>
    </template>

    <!-- Mechanical -->
      <template v-slot:cell(mechanical)="{ item }">
        {{ item.mechanical || "0.00" }}%
      </template>

      <!-- Performing -->
      <template v-slot:cell(performing)="{ item }">
        {{ item.performing || "0.00" }}%
      </template>
  </b-table>
</template>

<style lang="scss" scoped>
  ::v-deep .narrow {
    width: 65px !important;
  }

  ::v-deep .table td {
    padding-top: 0;
    padding-bottom: 0;
  }
</style>


<script>
import { capitalize } from "lodash"

export default {
  name: "PayeeSharesEditTable",
  data () {
    return {
      fields: [
        { key: "ipiNameNumber", label: this.$t("ipi_name_number"), class: "narrow" },
        { key: "name", label: capitalize(this.$t("name")) },
        { key: "performing", label: capitalize(this.$t("performing")) },
        { key: "mechanical", label: capitalize(this.$t("mechanical")) },
      ],
    }
  },
  props: {
    payeeShares: {
      type: Array,
      default: () => [],
    },
  }
}
</script>
