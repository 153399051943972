<template>
  <div>
    <type-and-value-input
      :item="item"
      :itemTypes="workTitleTypesVerbose"
      @input="onInput"
      upper
      rules="required"
      :name="`title-${title.index}`"
    />
  </div>
</template>

<script>
import { workTitleTypesVerbose } from "@/constants"

export default {
  name: "WorkTitle",
  props: {
    title: Object
  },
  data () {
    delete workTitleTypesVerbose.OT
    return {
      workTitleTypesVerbose,
    }
  },
  computed: {
    item () {
      return {
        value: this.title.title,
        type: this.title.type,
        ...this.title.id && { id: this.title.id }
      }
    },
  },
  methods: {
    convertItemToTitle (item) {
      let { type, value } = item
      return {
        type,
        title: value,
        ...this.item.id && { id: this.item.id }
      }
    },
    onInput (item) {
      this.$emit("input", this.convertItemToTitle(item), this.title.index)
    }
  }
}
</script>
