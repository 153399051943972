import { API } from "./utils"


export const distributions = {
  distributionsList: (params = null) => API.get("/distributions/", { params }),
  distributionsCreate: (data) => API.post("/distributions/", data),
  distributionsCopy: (id, data) => API.post(`/distributions/${id}/copy/`, data),
  distributionsUpdate: (id, data) => API.put(`/distributions/${id}/`, data),
  distributionsDelete: (id) => API.delete(`/distributions/${id}/`),
  distributionsAllocate: (id, data=null) => API.post(`/distributions/${id}/allocate/`, data),
  distributionsClose: (id, data=null) => API.post(`/distributions/${id}/close/`, data),
  distributionsPublish: (id, data=null) => API.post(`/distributions/${id}/publish/`, data),
  distributionPoolsList: (id, params = null) => API.get(`/distributions/${id}/pools/`, { params }),
  distributionPoolsRetrieve: (dId, pId, type="") => API.get(`/distributions/${dId}/pools/${pId}/?type=${type}`),
  distributionPoolsCreate: (id, data, type="") =>API.post(`/distributions/${id}/pools/?type=${type}`, data),
  distributionPoolsDelete: (dId, pId) => API.delete(`/distributions/${dId}/pools/${pId}/`),
  distributionPoolsUpdate: (dId, pId, data, type="") => API.put(`/distributions/${dId}/pools/${pId}/?type=${type}`, data),
  distributionsPoolProRataTemplate: (dId, pId, ruleType) => API.get(`/distributions/${dId}/pools/${pId}/pro_rata_template/?type=${ruleType}`, { responseType: "blob" }),
  distributionPoolsDownloadProRataFile: (dId, pId) => API.get(`/distributions/${dId}/pools/${pId}/pro_rata_file/`),
  distributionsPoolProRataDelete: (dId, pId) => API.delete(`/distributions/${dId}/pools/${pId}/pro_rata_file/`),
  distributionsDownloadQAReports: (id) => API.get(`/distributions/${id}/qa_reports/`),
  distributionsPoolsUnallocate: (dId, pId) => API.post(`/distributions/${dId}/pools/${pId}/unallocate/`),
  distributionsDownloadMembersReport: (id) => API.get(`/distributions/${id}/member_reports/`),
  distributionsDownloadCRDReports: (id, data=null) => API.post(`/distributions/${id}/crd_reports/`, data),
  distributionsRequestQAWorkRevenuesReports: (id, data) => API.post(`/distributions/${id}/qa_work_revenues_report/`, data),
  distributionsRequestQAMemberReports: (id, data) => API.post(`/distributions/${id}/qa_member_reports/`, data),
  distributionsUnallocate: (id, data=null) => API.post(`/distributions/${id}/unallocate/`, data),
}
