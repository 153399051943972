<template>
  <div class="d-flex flex-column align-items-center justify-content-center h-75">
    <fa-icon size="5x" class="text-muted" :icon="['fas', 'exclamation-circle']"/>
    <h1 class="mt-4 text-muted">Unauthorized</h1>
  </div>
</template>

<script>
export default {
  name: "Unauthorized",
}
</script>