<template>
  <ul class="timeline">
    <li class="mx-0 my-3 px-2" v-for="date in Object.keys(eventsByDate)" :key="date">
      <p class="font-weight-bold mb-2">{{ date }}</p>
      <b-row class="bg-gray mx-2 p-1 mb-2" v-for="(event, index) in eventsByDate[date]" :key="event.actionTime+index">
        <!-- COLUMN 1: Date, user, event type and field -->
        <b-col cols="3">
          <span>{{ parseDate(event.actionTime, TIME_FORMAT) }} - {{ event.username }}</span>
          <span> {{ getLogTypeDisplay(event.actionType) }}</span>
          <span> {{ getFieldName(event) }}</span>
        </b-col>
        <!-- COLUMN 2: Previous state for changes or addition/deletion info -->
        <b-col cols="4">
          <div class="bg-red px-2 pb-1" v-if="showPrevious(event)">
            <component
              v-bind:is="getLogComponent(event)"
              :message="getLogMessage(event, 'previous')"
            />
          </div>
          <span v-else>
            <div class="bg-green px-2 pb-1" v-if="isAddition(event)">
              <component
                v-bind:is="getLogComponent(event)"
                :message="getLogMessage(event)"
              />
            </div>
            <div class="bg-red px-2 pb-1" v-if="isDeletion(event)">
              <component
                v-bind:is="getLogComponent(event)"
                :message="getLogMessage(event)"
              />
            </div>
          </span>
        </b-col>
        <!-- COLUMN 3: Current state for changes (empty for additions/deletions) -->
        <b-col cols="4">
          <div class="bg-green px-2 pb-1" v-if="isChange(event)">
            <component
              v-bind:is="getLogComponent(event)"
              :message="getLogMessage(event, 'current')"
            />
          </div>
        </b-col>
      </b-row>
    </li>
  </ul>
</template>

<style scoped>
  .bg-gray {
    background: var(--background-gray);
  }

  ul.timeline {
    list-style-type: none;
    position: relative;
  }

  ul.timeline::before {
    content: ' ';
    background: var(--background-gray);
    display: inline-block;
    position: absolute;
    left: 27px;
    width: 2px;
    height: 100%;
    z-index: 400;
    margin-top: 5px;
  }

  ul.timeline > li::before {
    content: ' ';
    background: var(--secondary);
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    left: 20px;
    width: 16px;
    height: 16px;
    z-index: 400;
    margin-top: 5px;
  }

  .bg-green {
    background-color: var(--green-light);
  }

  .bg-red {
    background-color: var(--red-light);
  }
</style>

<script>
import { BaseLogMessage, CodeLogMessage, ISWCLogMessage, SharesLogMessage, TitleLogMessage } from "@/components/Logs"
import { getLogTypeDisplay, logActionTypes } from "@/constants"
import { parseDate } from "@/utils/dates"


const TIME_FORMAT = "HH:mm:ss"

export default {
  name: "VerticalTimeline",
  data () {
    return {
      TIME_FORMAT,
      logActionTypes,
    }
  },
  computed: {
    eventsByDate () {
      const dates = {}
      this.events.forEach(event => {
        let eventDate = parseDate(event.actionTime, this.$config.DATE_FORMAT)
        if (dates[eventDate]) {
          dates[eventDate] = [ ...dates[eventDate], event ]
        } else {
          dates[eventDate] = [event]
        }
      })
      return dates
    },
  },
  methods: {
    isAddition (event) {
      return event.changeMessage && event.actionType === logActionTypes.ADDITION
    },
    isDeletion (event) {
      return event.changeMessage && event.actionType === logActionTypes.DELETION
    },
    isChange (event) {
      return event.changeMessage && event.actionType === logActionTypes.CHANGE
    },
    showPrevious (event) {
      return this.isChange(event) && "previous" in this.getLogMessage(event)
    },
    getFieldName (event) {
      return Object.keys(event.changeMessage)[0]
    },
    getLogMessage (event, key = null) {
      const field = this.getFieldName(event)
      let message = event.changeMessage[field]  

      // If key (previous or current) is present, get the message
      // in that specific key.
      return key ? message[key] : message
    },
    getLogComponent (event) {
      const field = this.getFieldName(event)

      if (field == "shares") return SharesLogMessage
      if (field == "payeeShares") return SharesLogMessage
      if (field == "title") return TitleLogMessage
      if (field == "code") return CodeLogMessage
      if (field == "iswc") return ISWCLogMessage

      return BaseLogMessage
    },
    getLogTypeDisplay,
    parseDate,
  },
  props: {
    events: {
      type: Array,
      default: () => [],
    },
  },
}
</script>