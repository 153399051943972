<template>
  <work-detail-layout
    :title="$t('agreement_conflict') | capitalize"
    :badgeText="getStatusDisplay(userAction.status)"
    :badgeVariant="badgeVariant"
  >

    <!-- Top - Related Object -->
    <template v-slot:top>
      <div>
        <h4>{{ $t('conflicting_agreement') | capitalize }}</h4>
        <AgreementCard
          :agreement="userAction.relatedObject"
          :userActionStatus="userAction.status"
        />
      </div>
    </template>

    <!-- Bottom - Conflicting Agreements -->
    <template
      v-slot:bottom
      v-if="userAction.conflictingAgreements && userAction.conflictingAgreements.length"
    >
      <div>
        <h4>{{ $t('existing_agreements') | capitalize }}</h4>
        <AgreementCard
          :agreement="agreement"
          v-for="agreement in userAction.conflictingAgreements"
          :key="agreement.id"
          :userActionStatus="userAction.status"
        />
      </div>
    </template>
  </work-detail-layout>
</template>

<script>
import { getUserActionStatusDisplay, userActionStatuses } from "@/constants"
import AgreementCard from "./AgreementCard"
import { mapMutations } from "vuex"

export default {
  name: "AgreementConflictsDetail",
  components: {
    AgreementCard,
  },
  data () {
    return {
      userAction: {},
      statuses: userActionStatuses,
    }
  },
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    getStatusDisplay (status) {
      return getUserActionStatusDisplay(status)
    },
  },
  computed: {
    badgeVariant () {
      return this.userAction.status == this.statuses.DONE ? "success" : "warning"
    }
  },
  props: {
    id: [String, Number],
  },
  async mounted () {
    let response = await this.$api.userActions.agreementConflictsRetrieve(this.id)
    this.userAction = response.data
  }
}
</script>
