<template>
  <b-modal
    :title="$t('request_member_reports') | upperfirst"
    v-bind="$attrs"
    hide-footer
  >
      <ValidationObserver ref="memberReportsForm" v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onAcceptModal)" >
        <form-field
          name="ipiNumber"
          :label="$t('ipi_name_number')"
          type="text"
          rules="required"
          v-model="selectedIPI"
        />
        <div class="d-flex justify-content-center">
          <submit-button variant="secondary" class="mx-2">
            {{ $t("accept") | capitalize }}
          </submit-button>
          <b-button variant="primary" @click="closeModal" class="mx-2">
            {{ $t("cancel") | capitalize }}
          </b-button>
        </div>
        </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { ValidationObserver } from "vee-validate"

export default {
  name: "MemberReportModal",
  components: { ValidationObserver },
  data () {
    return {
      selectedIPI: null
    }
  },
  methods: {
    onAcceptModal () {
      this.$emit("ok", this.selectedIPI)
      this.closeModal()
    },
    closeModal () {
      this.$bvModal.hide((this.$attrs.id))
      this.selectedIPI = null
    },
  },
}
</script>
