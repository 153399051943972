<template>
  <div>
    <!-- Heading -->
    <div class="heading d-flex justify-content-between">
      <!-- Heading -->
      <h3>{{ $t("merge_works") | capitalize }}</h3>
      <!-- Action buttons -->
      <div>
        <b-button @click="cancel" variant="outline-secondary">{{ $t("cancel") | capitalize }}</b-button>
        <b-button
          v-if="works && works.length"
          @click="merge"
          class="ml-2"
        >
          {{ $t("merge") | capitalize }}
        </b-button>
      </div>
    </div>
    <!-- Message -->
    <div class="mb-2">{{ $t("which_work_do_you_want_to_keep") | capitalize }}?</div>
    <!-- Merge Selection -->
    <div class="d-flex items-container">
      <!-- Work "Card" -->
      <div class="mr-2" v-for="work in works" :key="work.id">
        <b-row class="mb-1">
          <b-col>
            <b-form-radio
              :id="`radio-${work.id}`"
              v-model="primary.id"
              class="ml-2"
              :value="work.id"
              @change="setPrimary(work)"
            >
              <span :class="{ 'text-info': isPrimary(work) }">{{ $t("primary") | capitalize }}</span>
            </b-form-radio>
          </b-col>
        </b-row>
        <work-detail
          :class="{ 'info-light': isPrimary(work) }"
          class="p-2"
          :id="work.id"
          :work="work"
          :shares="work.shares"
          inSingleView
          lite
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.items-container {
  flex-wrap: nowrap;
  overflow-x: auto;
}

::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--info);
  background-color: var(--info);
}

.info-light {
  background-color: var(--info-light);
}
</style>

<script>
import { capitalize, get } from "lodash"
import { mapActions, mapMutations } from "vuex"

export default {
  name: "WorksMerge",
  data () {
    return {
      works: [],
      primary: {},
    }
  },
  computed: {
    nonDomesticWorks () {
      return this.works.filter(work => !work.domestic)
    },
  },
  methods: {
    ...mapActions("selection", ["clearSelection"]),
    ...mapMutations("alert", ["success", "error"]),
    setPrimary (work) {
      this.primary = work
    },
    isPrimary (work) {
      return this.primary.id === work.id
    },
    getNonDomesticWorksMessage () {
      if (this.nonDomesticWorks.length) {
        let nonDomesticTitles = this.nonDomesticWorks.map(work => work.title).join(", ")
        let warning = capitalize(this.$t("merging_not_allowed_for_non_domestic_works"))
        return`${warning}:\n ${nonDomesticTitles}`
      }
    },
    merge () {
      if (this.nonDomesticWorks.length) return this.error(this.getNonDomesticWorksMessage())
      let primary = this.primary.id
      let secondary = this.works.filter(work => work.id !== this.primary.id).map(work => work.id)
      this.$api.repertoire.worksMerge({ primary, secondary }).then(({ data }) => {
        this.success(this.$t("works_successfuly_merged"))
        this.$router.push({ name: "works-detail", params: { id: data.mergedWork } }).catch(() => {})
      })
    },
    cancel () {
      this.$router.push({ name: "works-list" }).catch(() => {})
    },
  },
  mounted () {
    let selectedWorksIds = get(this.$route.query, "works", "")
    let selectedWorksIdsList = selectedWorksIds.split(",")
    if (selectedWorksIdsList.length > 3) {
      selectedWorksIdsList = []
      this.error(capitalize(this.$t("merging_more_than_three_works_not_allowed")))
    }
    for (let id of selectedWorksIdsList) {
      this.$api.repertoire.worksRetrieve(id).then(response => {
        let work = response.data
        this.$api.repertoire.worksSharesList(id).then(response => {
          let shares = response.data
          work.shares = shares
          this.works = [ ...this.works, work ]
          this.primary = this.works.length ? this.works[0] : null
        }).catch(error => this.error(error.response.data))
      }).catch(error => this.error(error.response.data))
    }
  },
  props: {},
}
</script>
