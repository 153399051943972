<template>
  <div>
    <!-- Heading -->
    <div class="d-flex align-items-center justify-content-between mb-4">
      <!-- Title -->
      <template v-if="title">
        <h3>
          {{ title | startcase }} <template v-if="count">({{ count | formatNumber }})</template>
        </h3>
      </template>
      <!-- Actions -->
      <slot name="actions"></slot>
    </div>
    <!-- Tabs -->
    <b-card no-body class="card-container">
      <b-tabs v-model="tabIndex" card justified lazy>
        <b-tab
          v-for="(tab, index) in tabs"
          :key="index"
          class="p-0"
        >
          <!-- Tab Title -->
          <template v-slot:title>
            <slot name="title" :tab="tab" :index="index"></slot>
          </template>

          <!-- Tab Content -->
          <b-card-text>
            <slot name="content" :tab="tab" :index="index"></slot>
          </b-card-text>

        </b-tab>

        <!-- Static Tabs -->
        <slot name="static-tabs"></slot>
      </b-tabs>
    </b-card>
    
  </div>
</template>

<style lang="scss" scoped>
  ::v-deep .card-header {
    padding: 0 9px 12px 10px;
    background-color: transparent;
  }

  ::v-deep .card-body {
    padding: 15px;
  }

  ::v-deep .tab-content {
    border-bottom: 1px solid $gray-dark;
    border-left: 1px solid $gray-dark;
    border-right: 1px solid $gray-dark;

    .pagination {
      margin-bottom: 0;

      * {
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
  }

  ::v-deep .nav-item {
    text-align: left;
    width: auto;
    flex: 0 0 auto;

    & .active {
      border-top: 3px solid var(--secondary);
    }

    & .nav-link {
      padding: 10px 15px;
    }

    & .nav-link:not(.active) {
      color: var(--blue);
    }

    & .nav-link.disabled {
      color: var(--gray);
    }

    & .nav-link.active {
      color: var(--primary);
    }
  }

  ::v-deep .nav-tabs {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .card-container {
    border: none;
  }
</style>

<script>
export default {
  name: "TabsLayout",
  data: () => {
    return {
      tabIndex: 0,
    }
  },
  props: {
    title: String,
    count: [String, Number],
    value: null,
    tabs: {
      type: Array,
      required: true,
      default: () => ([]),
    }
  },
  watch: {
    // Handles internal model changes.
    tabIndex (index) {
      this.$emit("change", { index, tab: this.tabs[index] })
    },
    // Handles external model changes.
    value (index) {
      this.tabIndex = index
    }
  },
  mounted () {
    if (this.value) {
      this.tabIndex = this.value
    }
  }
}
</script>