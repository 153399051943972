import { capitalize, get, lowerCase, startCase, upperCase, upperFirst } from "lodash"
import { formatNumber, localeString } from "@/utils/numbers"
import Vue from "vue"

// String filters
Vue.filter("capitalize", capitalize)
Vue.filter("lowercase", lowerCase)
Vue.filter("uppercase", upperCase)
Vue.filter("upperfirst", upperFirst)
Vue.filter("startcase", startCase)

// Number filters
Vue.filter("formatNumber", formatNumber)
Vue.filter("localestring", localeString)

Vue.filter("get", get)
