function arrayToHash (array, key="id") {
  return array.reduce((hash, item) => {
    hash[item[key]] = item
    return hash
  }, {})
}

const state = {
  // Right Types and Use Types
  rightTypes: [],
  rightTypesHash: {},
  useTypes: [],
  useTypesHash: {},
  // Countries
  countries: [],
  countriesHash: {},
  // Languages
  languages: [],
  languagesHash: {},

  // Societies
  societies: [],
  societiesHash: {},
}

const mutations = {
  setRightTypes (state, rightTypes) {
    state.rightTypes = rightTypes
    state.rightTypesHash = arrayToHash(state.rightTypes, "code")
  },
  setUseTypes (state, useTypes) {
    state.useTypes = useTypes
    state.useTypesHash = arrayToHash(state.useTypes, "code")
  },
  setCountries (state, countries) {
    state.countries = countries
    state.countriesHash = arrayToHash(countries)
  },
  setLanguages (state, languages) {
    state.languages = languages
    state.languagesHash = arrayToHash(languages)
  },
  setSocieties (state, societies) {
    state.societies = societies
    state.societiesHash = arrayToHash(societies)
  },
}

export const consts = {
  namespaced: true,
  state,
  mutations
}
