<template>
  <v-server-table
    name="nonIpiList"
    ref="nonIpiList"
    :columns="columns"
    :options="options"
    @loaded="onLoaded"
    @pagination="onPagination"
  >
    <!-- Title -->
    <template v-slot:title="{ row }">
      <router-link
        :to="{ name: 'works-detail', params: { id: row.work.id } }"
        class="text-info"
      >
        {{ row.work.title }}
      </router-link>
    </template>

    <!-- Atlas ID -->
    <template v-slot:id="{ row }">
      <span class="text-primary">
        {{ row.work.id }}
      </span>
    </template>

    <!-- ISWC -->
    <template v-slot:iswc="{ row }">
      <span class="text-primary">
        {{ row.work.iswc }}
      </span>
    </template>

    <!-- Contributors -->
    <template v-slot:contributors="{ row }">
      {{ contributorNames(row.work) }}
    </template>

    <!-- Status -->
    <template v-slot:status="{ row }">
      <status
        v-if="row.status === statuses.DONE"
        :text="$t('done')"
        :icon="['fas', 'check-circle']"
        color="green"
      />
      <status
        v-else-if="row.status === statuses.PENDING"
        :text="$t('pending')"
        :icon="['fas', 'question-circle']"
        color="orange"
      />
    </template>
  </v-server-table>
</template>

<script>
import { capitalize, get } from "lodash"
import { listRouteMixin } from "@/utils/mixins"
import { userActionStatuses } from "@/constants"

export default {
  name: "NonIpiList",
  mixins: [listRouteMixin],
  data () {
    return {
      statuses: userActionStatuses,
      columns: ["title", "id", "iswc", "contributors", "status"],
      options: {
        headings: {
          title: capitalize(this.$t("title")),
          id: "Atlas ID",
          iswc: this.$t("iswc"),
          contributors: capitalize(this.$t("contributors")),
          status: capitalize(this.$t("status")),
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          return this.$api.userActions.nonIpiList(queryParams)
        }
      },

    }
  },
  methods: {
    getData () {
      this.$refs.nonIpiList.getData()
    },
    onLoaded ({ data }) {
      this.$emit("loaded", data.count)
    },
    contributorNames (work) {
      return work.contributors.map(
        contributor => contributor.lastName
      ).join(", ")
    },
  },
  watch: {
    distribution (value) {
      this.applyFilters("distribution", value)
    },
    codeSearch (value) {
      this.applyFilters("code", value)
    },
    titleSearch (value) {
      this.applyFilters("title", value)
    },
    status (value) {
      this.applyFilters("status", value ? value.value : null)
    },
    showDomesticOnly (showDomesticOnly) {
      this.applyFilters("domestic_only", showDomesticOnly)
    },
    showWithUsagesOnly (showWithUsagesOnly) {
      this.applyFilters("with_usages", showWithUsagesOnly)
    },
    currentFilter (filter) {
      let [type, value] = filter
      this.applyFilters(type, value)
    }
  },
  props: {
    distribution: String,
    codeSearch: String,
    titleSearch: String,
    status: Object,
    showDomesticOnly: {
      type: Boolean,
      default: true,
    },
    showWithUsagesOnly: Boolean,
    currentFilter: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>
