<template>
    <div>
      <div class="mb-4">
        <b-row align-v="center">
          <b-col cols="8">
            <div class="d-flex">
            <h3 class="mr-3">
              {{ pool.name }}
            </h3>
            <!-- Status -->
            <status
              v-if="!isOpen"
              :text="getDistributionStatusDisplay(pool.status) | capitalize"
              :icon="['fas', 'check-circle']"
              color="green"
            />
            <status
              v-else-if="pool.status === distributionStatuses.ERROR"
              :text="getDistributionStatusDisplay(pool.status) | capitalize"
              :icon="['fas', 'exclamation-circle']"
              color="red"
            />
            <status
              v-else
              :text="getDistributionStatusDisplay(pool.status) | capitalize"
              :icon="['fas', 'question-circle']"
              color="orange"
            />
            </div>
          </b-col>
          <b-col cols="4">
            <!-- Action Buttons -->
            <div class="d-flex justify-content-end my-3">
              <b-button
                class="mr-2"
                variant="outline-secondary"
                @click="$router.go(-1)"
              >
                {{ $t("back_to_distribution") | capitalize }}
              </b-button>
              <b-button
              v-if="pool.status === distributionStatuses.ALLOCATED"
                class="mr-2"
                variant="outline-secondary"
                @click="onUndoAllocation"
              >
                {{ $t("undo_allocation") | capitalize }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>


      <!-- 1st Row -->
      <b-row>
        <!-- Name -->
        <b-col cols="6">
          <label>{{ $t('name') | capitalize }}:</label>
          <p class="text-gray">{{ getFieldText(pool.name) }}</p>
        </b-col>
        <!-- Code -->
        <b-col cols="3">
          <label>{{ $t('code') | capitalize }}:</label>
          <p class="text-gray">{{ getFieldText(pool.code) }}</p>
        </b-col>
        <!-- Fund ID -->
        <b-col cols="3">
          <label>{{ $t('fund_id') | capitalize }}:</label>
          <p class="text-gray">{{ getFieldText(pool.fundInternalId) }}</p>
        </b-col>
      </b-row>

      <!-- 2nd Row -->
      <b-row>
        <!-- Use Type -->
        <b-col cols="6">
          <label>{{ $t('use_type') | capitalize }}:</label>
          <p v-if="pool.useType" class="text-gray">{{ pool.useType.code }} - {{ pool.useType.name }}</p>
          <p v-else>-</p>
        </b-col>
        <!-- Period -->
        <b-col cols="3">
          <label>{{ $t('start_date') | capitalize }}:</label>
          <date-time-cell class="text-gray" :dateTime="pool.startDate" :dateOnly="true"/>
        </b-col>
        <b-col cols="3">
          <label>{{ $t('end_date') | capitalize }}:</label>
          <date-time-cell class="text-gray" :dateTime="pool.endDate" :dateOnly="true"/>
        </b-col>
      </b-row>

      <!-- 3rd Row -->
      <b-row>
        <!-- Gross -->
        <b-col>
          <label>{{ $t('bank_amount') | capitalize }}:</label>
          <p class="text-gray">{{ getFieldNumber(pool.grossAmount) }}</p>
        </b-col>
        <!-- Deductible -->
        <b-col>
          <label>{{ $t('deductibles') | capitalize }}:</label>
          <p class="text-gray">{{ getFieldNumber(pool.deductibles) }}</p>
        </b-col>
        <!-- Admin Costs -->
        <b-col cols="3">
          <label>{{ $t('administration_costs') | capitalize }}:</label>
          <p class="text-gray">{{ getFieldNumber(pool.administrationCosts) }}</p>
        </b-col>
        <b-col cols="3">
          <!-- Net -->
          <label>{{ $t('net_distributable_amount') | capitalize }}:</label>
          <p class="text-gray">{{ getFieldNumber(pool.netDistributableAmount) }}</p>
        </b-col>
      </b-row>

      <!-- 4th row -->
      <b-row>
        <!-- Rule -->
        <b-col>
          <label>{{ $t('rule') | capitalize }}:</label>
          <p class="text-gray">
              {{ this.getDistributionPoolRuleTypes(this.pool.distributionPoolRules.levelOne) | capitalize }}
            <span class="text-gray" v-if="this.pool.distributionPoolRules.levelTwo">
               - {{ this.getDistributionPoolRuleTypes(this.pool.distributionPoolRules.levelTwo) }}
               </span>
          </p>
        </b-col>
      </b-row>

      <!-- 5th row -->
      <b-row>
        <!-- Files -->
        <b-col>
          <label>{{ $t('files') | capitalize }}:</label>
          <!-- Prorata files -->
          <div v-if="isProRataBased">
             <p class="text-gray"><fa-icon size="md" :icon="['fa', 'file']" class="mx-2" />{{ pool.proRataFile | capitalize }}</p>
            <b-button
              @click="downloadProRataFile"
            >
              {{ $t("download_current_file") | capitalize }}
            </b-button>
          </div>
          <!-- Usage files -->
          <div v-if="isUsageBased">
            <div v-for="usageFile in pool.usageFiles" :key="usageFile.id">
              <p class="text-gray">{{ usageFile.filename }}</p>
              <div v-for="sheet in usageFile.sheets" :key="sheet.id">
                <fa-icon size="md" :icon="['fa', 'table']" class="sheet-icon mx-2" />
                <router-link
                  :to="{ 'name': 'usages-list', 'params': { 'id': sheet.id } }"
                  class="text-info"
                >
                  {{ sheet.name }}
                </router-link>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <modal
        id="undo-allocation-confirmation-modal"
        :title="$t('undo_allocation') | capitalize"
        :okTitle="$t('yes') | capitalize"
        :cancelTitle="$t('no') | capitalize"
        @ok="onConfirmUndoAllocation()"
      >
        <div>{{ $t("confirm_undo_allocation") | capitalize }}</div>
      </modal>
    </div>
</template>

<style scoped>
  label {
    font-weight: bold;
    font-size: 1.1rem;
  }

  p {
    font-size: 1rem;
  }
</style>

<script>
import { distributionPoolRuleTypes, distributionStatuses, getDistributionPoolRuleTypes, getDistributionStatusDisplay } from "@/constants"
import { mapMutations, mapState } from "vuex"
import { clickOnUrl } from "@/services/utils"
import { formatNumber } from "@/utils/numbers"

const {
  PRORATA_EQUAL,
  PRORATA_PERCENTAGE,
  WITH_UP,
  WITHOUT_UP,
  PER_PLAY,
  PER_DURATION
} = distributionPoolRuleTypes

const levelOneTypes = [ PRORATA_EQUAL, PRORATA_PERCENTAGE, WITH_UP, WITHOUT_UP ]
const levelTwoTypes = [ PER_DURATION, PER_PLAY ]

const usageBasedRuleTypes = [ WITH_UP, WITHOUT_UP ]
const proRataBasedRuleTypes = [PRORATA_EQUAL, PRORATA_PERCENTAGE]

export default {
  name: "DomesticPoolsDetail",
  data () {
    return {
      getDistributionPoolRuleTypes,
      levelOneTypes,
      levelTwoTypes,
      distributionStatuses,
      getDistributionStatusDisplay,
      pool: {
        name: "",
        fundInternalId: "",
        useType: {},
        startDate: null,
        endDate: null,
        grossAmount: null,
        deductibles: null,
        administrationCosts: null,
        netDistributableAmount: null,
        distributionPoolRules: {
          levelOne: this.pid ? null : WITH_UP,
          levelTwo: this.pid ? null : PER_DURATION,
        },
        sheets: [],
        proRataFile: "",
      },
    }
  },
  computed: {
    ...mapState("consts", ["useTypes", "useTypesHash"]),
    isUsageBased () {
      return usageBasedRuleTypes.includes(this.pool.distributionPoolRules.levelOne)
    },
    isProRataBased () {
      return proRataBasedRuleTypes.includes(this.pool.distributionPoolRules.levelOne)
    },
    isOpen () {
      return ![distributionStatuses.CLOSED, distributionStatuses.PUBLISHED].includes(this.pool.status)
    }
  },
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    ...mapMutations("consts", ["setUseTypes"]),
    transformRules (rules) {
      let levelOneMatches = rules.find(r => levelOneTypes.includes(r.type))
      let levelTwoMatches = rules.find(r => levelTwoTypes.includes(r.type))
      return {
        levelOne: levelOneMatches ? levelOneMatches.type : "",
        levelTwo: levelTwoMatches ? levelTwoMatches.type : null
      }
    },
    async loadData () {
      const response = await this.$api.distributions.distributionPoolsRetrieve(this.id, this.pid)
      this.pool = {
        ...response.data,
        useType: this.useTypesHash[response.data.useType],
        distributionPoolRules: this.transformRules(response.data.distributionPoolRules),
      }
    },
    downloadProRataFile () {
      this.$api.distributions.distributionPoolsDownloadProRataFile(this.id, this.pid)
        .then(({ data }) => {
          const { url } = data
          clickOnUrl(url)
        })
        .catch((err) => {
          let message = err.response.data?.message || err.response.data || err.response
          this.error(message)
        })
    },
    getFieldText (field) {
      return field || "-"
    },
    getFieldNumber (field) {
      return field ? formatNumber(field) : "-"
    },
    onUndoAllocation () {
      this.$bvModal.show("undo-allocation-confirmation-modal")
    },
    onConfirmUndoAllocation () {
      this.$api.distributions.distributionsPoolsUnallocate(this.id, this.pid)
        .then(() => {
          this.success(this.$t("undo_allocation_success"))
          this.$emit("reload-data")
        }).catch(error => {
          if (error.response.data && error.response.data.detail) this.error(error.response.data.detail)
        })
    },
  },
  async mounted () {
    // Set UseTypes
    let response = await this.$api.rights.useTypesList()
    this.setUseTypes(response.data)

    // Retrieve a pool if a pool ID is passed as prop
    if (this.pid) {
      this.loadData()
    }
  },
  props: {
    id: String, // Distrbution ID
    pid: String, // Pool ID
  }
}
</script>
