<template>
<div class="container px-0">
  <div class="dropzone-container mb-5">
    <!-- Dropzone -->
    <vue2-dropzone
      ref="dropzone"
      id="dropzone"
      class="dropzone"
      :options="options"
      :use-custom-slot="true"
      @vdropzone-removed-file="onFileRemoved"
      @vdropzone-file-added="onFileAdded"
    >
      <div class="dropzone-custom-content">
        <div v-if="!fileAdded">
          <span class="upload-icon">
            <i class="far fa-file-upload" />
          </span>
          <div>{{ $t('click_to_select_file_or_drag_and_drop') | capitalize }}</div>
        </div>
      </div>
    </vue2-dropzone>
  </div>

  <!-- Actions -->
  <div class="d-flex w-100 justify-content-end mb-0">
    <b-button
      @click="onUploadClick"
      :disabled="!fileAdded"
    >
      <b-spinner v-if="loading" variant="light" small></b-spinner>
      <span v-else>{{ $t('upload') | capitalize }}</span>
    </b-button>
    <b-button
      class="ml-2"
      variant="outline-secondary"
      @click="onCancelUpload"
    >
      <span>{{ $t('cancel') | capitalize }}</span>
    </b-button>
  </div>
</div>
</template>

<style lang="scss" scoped>
.dropzone-container {
  padding: 10px;
  background-color: var(--background-gray);
  border-radius: 4px;
}

.dropzone {
  border: 1px dashed $gray-darker;
  height: 200px;
}

.dropzone-custom-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

::v-deep .dz-error-mark,
::v-deep .dz-success-mark {
  display: none;
}

::v-deep .dz-filename,
::v-deep .dz-preview,
::v-deep .dz-file-preview {
  text-align: center;
}

::v-deep .dz-remove {
  color: var(--info);
}
</style>

<script>
import { mapActions } from "vuex"
import { postFile } from "@/services/utils"

export default {
  name: "Dropzone",
  data () {
    return {
      fileAdded: false,
      reset: false,
      loading: false,
      options: {
        url: "empty",
        autoProcessQueue: false,
        addRemoveLinks: true,
        maxFiles: 1
      }
    }
  },
  methods: {
    ...mapActions("alert", ["error", "success"]),
    onFileAdded () {
      const dropzone = this.$refs.dropzone
      const file = dropzone.getQueuedFiles()[0]
      if (file) {
        dropzone.removeFile(file)
      }
      this.fileAdded = true
    },
    onFileRemoved () {
      this.fileAdded = false
    },
    onUploadClick () {
      const dropzone = this.$refs.dropzone
      const file = dropzone.getAcceptedFiles()[0]
      this.uploadFile(file)
    },
    uploadFile (file) {
      if (file) {
        this.loading = true
        postFile(file, this.uploadUrl)
          .then((data) => {
            this.success(this.$t("file_successfully_uploaded"))
            this.$bvModal.hide("upload-file-modal")
            this.reset = true
            this.loading = false
            const proRataFile = data?.data?.context?.proRataFile
            this.$emit("success-upload", proRataFile)
          })
          .catch((err) => {
            let message = err?.response?.data?.message ||  err?.response?.data || err?.response || err
            this.error(message)
            this.loading = false
          })
      } else {
        this.error(this.$t("file_not_attached"))
      }
    },
    onCancelUpload () {
      this.$emit("cancel-upload")
    }
  },
  watch: {
    reset (value) {
      if (value) this.$refs.dropzone.removeAllFiles()
    }
  },
  props: {
    uploadUrl: String,
  }
}
</script>
