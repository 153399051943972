<template>
  <list-layout :title="$t('parties')" :count="count" :filters="filters">
    <!-- Search and Filters -->
    <template v-slot:search>
      <div>
        <div class="d-flex align-items-center">
          <!-- Name Search -->
          <search-input
            id="party-name-search"
            class="w-30 pr-3 mb-3"
            :placeholder="$t('search_by_name') | capitalize"
            :value="filters.name"
            :label="$t('name')"
            @search="onPartyNameSearch"
          />
          <!-- IPI Number Search -->
          <search-input
            id="party-code-search"
            class="w-20 pr-3 mb-3"
            :placeholder="$t('search_by_ipi_number')"
            :value="filters.code"
            :label="$t('ipi_number')"
            @search="onPartyCodeSearch"
          />
          <!-- Type Filter -->
          <multi-select-field
            v-model="filters.type"
            name="partyType"
            :options="partyTypeOptions"
            :label="$t('type')"
            choiceLabel="display"
            track-by="value"
            :showLabels="false"
            class="w-25 pr-3"
          />
          <!-- IPI Status Filter -->
          <multi-select-field
            v-model="filters.ipiStatus"
            name="ipiStatus"
            :options="ipiStatusOptions"
            :label="$t('ipi_status')"
            choiceLabel="display"
            track-by="value"
            :showLabels="false"
            class="w-25"
          />
        </div>
        <div class="d-flex flex-grow-1 justify-content-end my-2">
          <!-- Only Members -->
          <b-form-checkbox v-model="filters.member" switch class="mr-3">
            {{ $t("show_members_only") | capitalize }}
          </b-form-checkbox>

          <!-- Only non-IPI -->
          <b-form-checkbox v-model="filters.nonIpi" switch>
            {{ $t("show_non_ipi_only") }}
          </b-form-checkbox>
        </div>
      </div>
    </template>

    <!-- Table -->
    <template v-slot:table>
      <b-skeleton-table
        v-if="loadingTable"
        :rows="10"
        :columns="6"
        :table-props="{ bordered: false, striped: true }"
      />
      <v-server-table
        :class="loadingTable ? 'd-none' : ''"
        :columns="columns"
        :options="options"
        ref="parties"
        @loaded="onLoaded"
        @loading="onLoading"
        @pagination="onPagination"
      >
        <!-- Membership -->
        <template v-slot:membership="{ row }">
          <fa-icon v-if="row.membershipStatus" size="lg" class="member-icon" :icon="['fa', 'user']" />
        </template>

        <!-- Name -->
        <template v-slot:name="{ row }">
          <router-link
            class="text-info"
            :to="{ name: 'parties-detail', params: { id: row.id } }"
          >
            {{ row.name ? row.name : row.ipiNameNumber }}
          </router-link>
          <p v-if="row.nameLocal" class="text-gray text-small" >{{ row.nameLocal }}</p>
        </template>

        <!-- Membership Status -->
        <template v-slot:membershipStatus="{ row }">
          {{ $t(row.membershipStatus) | capitalize }}
        </template>

        <!-- Affiliation -->
        <template v-slot:affiliation="{ row }">
          {{ getAffiliationDisplay(row.affiliations) }}
        </template>

        <!-- Type -->
        <template v-slot:type="{ row }">
          <span class="text-gray">
            {{ row.type }}
          </span>
        </template>

        <!-- Non-IPI -->
        <template v-slot:isIpi="{ row }">
          <span class="text-gray">
            <status v-if="row.nonIpi" :icon="['fas', 'times']" />
            <status v-else :icon="['fas', 'check']" color="green" />
          </span>
        </template>

        <!-- Created At -->
        <template v-slot:createdAt="{ row }">
          <date-time-cell :dateTime="row.createdAt"/>
        </template>

        <!-- IPI Status -->
        <template v-slot:ipiStatus="{ row }">
          {{ row.ipiStatus }}
        </template>

        <!-- Users -->
        <template v-slot:userCount="{ row }">
          <template v-if="row.isMember">
            <router-link :to="{ name: 'users-list', params: { id: row.id } }">
              <item-count :icon="['fas', 'users']" :count="row.userCount" />
            </router-link>
          </template>
        </template>
      </v-server-table>
    </template>

  </list-layout>
</template>

<style lang="scss">
  .membership-column {
    width: 20px;
  }

  .ipi-status-column {
    text-align: center;
  }

  .member-icon {
    color: $red;
  }
</style>

<script>
import { capitalize, get } from "lodash"
import { listRouteMixin } from "@/utils/mixins"
import { partyTypes } from "@/constants"

export default {
  name: "PartiesList",
  mixins: [listRouteMixin],
  data () {
    return {
      count: 0,
      loadingTable: false,
      summary: { active: 0, pending: 0, inactive: 0 },
      options: {
        headings: {
          membership: "",
          name: capitalize(this.$t("name")),
          membershipStatus: capitalize(this.$t("membership_status")),
          ipiNameNumber: this.$t("ipi_name_number"),
          isIpi: "IPI",
          type: capitalize(this.$t("type")),
          createdAt: capitalize(this.$t("created")),
          ipiStatus: capitalize(this.$t("ipi_status")),
          userCount: capitalize(this.$t("users")),
        },
        columnsClasses: {
          membership: "membership-column",
          ipiStatus: "ipi-status-column"
        },
        requestKeys: { query: "name" },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          return this.$api.parties.partiesList(queryParams)
        }
      },
      partyTypeOptions: [
        { value: partyTypes.SOCIETY, display: this.$t("society") },
        { value: partyTypes.CONTRIBUTOR, display: this.$t("contributor") },
        { value: partyTypes.PUBLISHER, display: this.$t("publisher") },
      ],
      ipiStatusOptions: [
        { value: "1", display: "1 - " + this.$t("ipi_status_1")  },
        { value: "2", display: "2 - " + this.$t("ipi_status_2") },
        { value: "3", display: "3 - " + this.$t("ipi_status_3") },
        { value: "4", display: "4 - " + this.$t("ipi_status_4") },
      ],
      filters: {
        member: false,
        nonIpi: false,
        name: "",
        code: "",
        type: "",
        ipiStatus: null,
      },
    }
  },
  computed: {
    columns () {
      let cols = [
        "membership",
        "name",
        "ipiNameNumber",
        "affiliation",
        "isIpi",
        "ipiStatus",
        "type",
        "createdAt",
      ]

      if (this.onlyMembers) {
        cols.splice(2, 0, "membershipStatus")
        cols.push("userCount")
      } else {
        cols = cols.filter(c => c !== "membershipStatus" || c !== "userCount")
      }

      return cols
    },
    onlyMembers () {
      return this.filters.member
    }
  },
  methods: {
    onLoading () {
      this.loadingTable = true
    },
    onLoaded ({ data }) {
      this.loadingTable = false
      this.count = data.count
      this.summary = data.summary
    },
    onPartyNameSearch (value) {
      this.filters.name = value
    },
    onPartyCodeSearch (value) {
      this.filters.code = value
    },
    getData () {
      this.$refs.parties.getData()
    },
    getAffiliationDisplay (affiliations) {
      return [...new Set(affiliations.map(a => a.societyName))].join(", ")
    }
  },
  watch: {
    "filters.name" (value) {
      this.applyFilters("name", value)
    },
    "filters.code" (value) {
      this.applyFilters("code", value)
    },
    "filters.type" (value) {
      this.applyFilters("type", value ? value.value : null)
    },
    "filters.member" (value) {
      this.applyFilters("member", value || null)
    },
    "filters.nonIpi" (value) {
      this.applyFilters("non_ipi", value || null)
    },
    "filters.ipiStatus" (value) {
      this.applyFilters("ipi_status", value ? value.value : null)
    },
  },
  mounted () {
    this.setFilters()

    const memberQuery = get(this.$route.query, "member", "")
    if (!memberQuery || memberQuery === "false") this.filters.member = false

    const ipiStatusQuery = get(this.$route.query, "ipi_status", "")
    this.filters.ipiStatus = this.ipiStatusOptions.find((option) => option.value === ipiStatusQuery)

    const partyTypeQuery = get(this.$route.query, "type", "")
    this.filters.type = this.partyTypeOptions.find((option) => option.value === partyTypeQuery)
  }
}
</script>
