<!-- Based On: https://codesandbox.io/s/validation-components-bootstrapvue-usdwv -->
<template>
  <ValidationProvider :vid="$attrs.name" :name="$attrs.name" :rules="rules" v-slot="{ errors }">
    <transition name="fade" mode="out-in">
      <b-form-group
        v-bind="$attrs"
        :invalid-feedback="errors[0]"
        :label="null"
      >
        <label :for="$attrs.name" v-if="$attrs.label">
          {{ $attrs.label | capitalize }}: <span v-if="isRequired" class="text-danger">*</span>
        </label>
        <b-form-select
          :id="$attrs.name"
          v-model="innerValue"
          v-bind="$attrs"
          :state="errors[0] ? false : null"
        />
        <b-form-text v-if="helpText">{{ helpText }}</b-form-text>
      </b-form-group>
    </transition>
  </ValidationProvider>
</template>

<style lang="scss" scoped>
  label {
    font-weight: bold;
    font-size: 0.85rem;
  }
</style>

<script>
import { ValidationProvider } from "vee-validate"

export default {
  name: "FormSelect",
  components: {
    ValidationProvider
  },
  props: {
    rules: {
      type: [Object, String],
      default: ""
    },
    // must be included in props
    value: {
      type: null
    },
    helpText: String
  },
  data: () => ({
    innerValue: ""
  }),
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit("input", newVal)
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal
    }
  },
  computed: {
    isRequired () {
      return this.rules ? this.rules.split("|").includes("required") : false
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>
