<template>
  <multi-select-field
    v-model="society"
    name="society"
    :options="societyOptions"
    :label="$t('society') | capitalize"
    choiceLabel="display"
    track-by="value"
    :showLabels="true"
    @remove="onRemoveOption"
    :rules="rules"
  />
</template>

<script>
import { cisacSocieties } from "@/constants"

const mappedSocieties = Object.entries(cisacSocieties).map(([key, value]) => ({ value: key, display: `${key} - ${value}` }))

export default {
  name: "SocietySelect",
  data () {
    return {
      society: null,
      societyOptions: mappedSocieties
    }
  },
  watch: {
    value (newVal) {
      this.society = this.getSocietyByCode(newVal)
    },
    society (newValue) {
      this.$emit("search", newValue?.value)
    }
  },
  methods: {
    getSocietyByCode (value) {
      return mappedSocieties.find((mappedSociety) => mappedSociety.value === value)
    },
    onRemoveOption () {
      this.society = null
    }
  },
  props: {
    value: {
      type: null
    },
    rules: {
      type: [Object, String],
      default: ""
    },
  },
  mounted () {
    if (this.value) {
      this.society = this.getSocietyByCode(this.value)
    }
  },
}
</script>
