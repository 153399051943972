<template>
  <component :is="poolComponent" :id="id" :pid="pid" :type="type" @reload-data="loadData"/>
</template>

<script>
import PoolsDetailPage from "./PoolsDetailPage"
import PoolsFormPage from "./PoolsFormPage"
import { distributionStatuses } from "@/constants"

export default {
  name: "PoolsPage",
  components: { PoolsDetailPage, PoolsFormPage },
  data () {
    return {
      poolData: {},
    }
  },
  computed: {
    isEditable () {
      return [distributionStatuses.UNALLOCATED, distributionStatuses.ERROR].includes(this.poolData.status)
    },
    poolComponent () {
      return this.isEditable ? PoolsFormPage : PoolsDetailPage
    }
  },
  methods: {
    async loadData () {
      const response = await this.$api.distributions.distributionPoolsRetrieve(this.id, this.pid, this.type)
      this.poolData = response.data
    }
  },
  async mounted () {
    await this.loadData()
  },
  props: {
    id: String, // Distribution ID
    pid: String, // Pool ID
    type: String,
  }
}
</script>
