<template>
<div id="popover-container" v-click-outside="onPopoverClose">
  <b-button
    id="popover-button-sync"
    variant="outline-info"
    @click="$emit('click')"
    pill
  >
    <fa-icon class="mr-3" :icon="icon" />
    <span>{{ title }}</span>
  </b-button>

  <b-popover
    :show.sync="popoverShow"
    target="popover-button-sync"
    placement="bottomleft"
    ref="popover"
    container="popover-container"
  >
    <template v-slot:title>
      <b-button @click="onPopoverClose" class="close" aria-label="Close">
        <span class="d-inline-block" aria-hidden="true">&times;</span>
      </b-button>
      {{ popoverTitle }}
    </template>

    <b-form-group>
      <b-form-radio-group
        id="radio-group-1"
        v-model="selectedItem"
        :options="options"
        name="radio-options"
        stacked
      />
    </b-form-group>

    <!-- Toggle filters -->
    <slot name="toggle-filters"></slot>

    <b-button
      variant="secondary"
      class="mb-2"
      size="sm"
      @click="onClearClick"
      block
    >
      {{ $t('clear_filters') }}
    </b-button>
  </b-popover>
</div>

</template>

<style lang="scss" scoped>
  ::v-deep .popover-body {
    padding: 10px 30px;
  }

  .close {
    line-height: 0.8rem;
    color: var(--gray) !important;

    &:hover {
      color: var(--gray-dark) !important;
      background-color: var(--light-gray) !important;
    }
  }
</style>

<script>
export default {
  name: "Filters",
  props: {
    title: {
      type: String,
      default: "Filter"
    },
    popoverTitle: {
      type: String,
      default: "Filter"
    },
    icon: {
      type: Array,
      default: () => ["fas", "sliders-h"],
    },
    options: Array,
    selected: String,
  },
  data () {
    return {
      popoverShow: false,
      selectedItem: "",
    }
  },
  watch: {
    selected (value) {
      this.selectedItem = value
    },
    selectedItem (value) {
      this.$emit("change", value)
    }
  },
  methods: {
    onPopoverClose () {
      this.popoverShow = false
    },
    onClearClick () {
      this.selectedItem = ""
      this.$emit("clear")
    }
  },
}
</script>