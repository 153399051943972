<template>
  <list-layout
    :title="$t('distributions')"
    :count="count"
    :filters="filters"
    :show-refresh="true"
  >
    <!-- Actions -->
    <template v-slot:actions>
      <div>
        <b-button @click="onCreate" variant="secondary">
          {{ $t("create_distribution") | startcase }}
        </b-button>
      </div>
    </template>

    <!-- Status Filters -->
    <template v-slot:status>
      <div class="mr-3">
        <status
          :text="$t('unallocated')"
          :name="distributionStatuses.UNALLOCATED"
          :count="summary.unallocated"
          :icon="['fas', 'question-circle']"
          color="orange"
          :active="filters.status == distributionStatuses.UNALLOCATED"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('pending_allocation')"
          :name="distributionStatuses.PENDING_ALLOCATION"
          :count="summary.pendingAllocation"
          :icon="['fas', 'pause-circle']"
          color="orange"
          :active="filters.status == distributionStatuses.PENDING_ALLOCATION"
          clickable
          @click="onStatusClick"
        />
      </div>
      <div class="mr-3">
        <status
          :text="$t('allocating')"
          :name="distributionStatuses.ALLOCATING"
          :count="summary.allocating"
          :icon="['fas', 'sync-alt']"
          color="info"
          :active="filters.status == distributionStatuses.ALLOCATING"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('allocated')"
          :name="distributionStatuses.ALLOCATED"
          :count="summary.allocated"
          :icon="['fas', 'check-circle']"
          color="info"
          :active="filters.status == distributionStatuses.ALLOCATED"
          clickable
          @click="onStatusClick"
        />
      </div>
      <div class="mr-3 align-self-start">
        <status
          :text="$t('closed')"
          :name="distributionStatuses.CLOSED"
          :count="summary.closed"
          :icon="['fas', 'check-circle']"
          color="green"
          :active="filters.status == distributionStatuses.CLOSED"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('published')"
          :name="distributionStatuses.PUBLISHED"
          :count="summary.published"
          :icon="['fas', 'check-circle']"
          color="green"
          :active="filters.status == distributionStatuses.PUBLISHED"
          clickable
          @click="onStatusClick"
        />
      </div>
      <div class="mr-3 align-self-start">
          <status
          :text="$t('error')"
          :name="distributionStatuses.ERROR"
          :count="summary.error"
          :icon="['fas', 'exclamation-circle']"
          color="red"
          :active="filters.status == distributionStatuses.ERROR"
          clickable
          @click="onStatusClick"
        />
      </div>
    </template>

    <!-- Search -->
    <template v-slot:search>
      <b-row class="mb-2">
        <b-col cols="sm-4">
          <search-input
            :value="filters.name"
            :placeholder="$t('search_by_name') | capitalize"
            @search="onNameSearch"
          />
        </b-col>
      </b-row>
    </template>

    <!-- Table -->
    <template v-slot:table>
      <v-server-table
        :columns="columns"
        :options="options"
        ref="distributions"
        @loaded="onLoaded"
        @pagination="onPagination"
      >
        <!-- Name and Description -->
        <template v-slot:name="{ row }">
          <div>
            <router-link
              :to="{ name: 'distributions-detail', params: { id: row.id }}"
              class="text-info"
            >
              {{ row.name }}
            </router-link>
            <p class="mb-2">{{ row.code }}</p>
            <div class="text-gray">
              <p class="m-0">{{ row.description }}</p>
              <div class="d-flex align-items-baseline text-gray mb-1">
                <span>{{ $t("from") | capitalize }}:</span>
                <date-time-cell class="pl-1 font-weight-bold" :dateTime="row.startDate" :dateOnly="true"/>
              </div>
              <div class="d-flex align-items-baseline text-gray mb-1">
                <span>{{ $t("to") | capitalize }}:</span>
                <date-time-cell class="pl-1 font-weight-bold" :dateTime="row.endDate" :dateOnly="true"/>
              </div>
              <p>{{ poolCountText(row) }}</p>
              <div v-if="row.lastRun" class="d-flex align-items-baseline text-gray">
                <span>{{ $t("last_run") | capitalize }}:</span>
                <date-time-cell class="px-1 font-weight-bold" :dateTime="row.lastRun" dateOnly/>
                <span>({{ $t("iteration") | capitalize }} {{ row.latestVersion }})</span>
              </div>
            </div>
          </div>
        </template>

        <!-- Funds -->
        <template v-slot:funds="{ row }">
          <FundsCell :funds="row.funds" />
        </template>

        <!-- Stats -->
        <template v-slot:stats="{ row }">
          <DistributionStats :stats="row.stats" />
        </template>

        <!-- Status -->
        <template v-slot:status="{ row }">
          <DistributionStatus :distributionStatus="row.status"/>
        </template>

        <!-- Actions -->
        <template v-slot:actions="{ row }">
          <div>
            <!-- Distribution Actions -->
            <div class="d-flex flex-row justify-content-end align-items-center">
              <!-- Delete -->
              <action
                v-if="isDistributionOpen(row)"
                :title="$t('delete')"
                @click="onDelete(row)"
                :icon="['fas', 'trash']"
              />
              <!-- Copy -->
              <action
                :title="$t('copy')"
                @click="onCopy(row)"
                :icon="['fas', 'copy']"
              />
              <!-- Allocate -->
              <action
                v-if="isDistributionOpen(row) && !row.allPoolsAllocated"
                :title="$t('allocate')"
                @click="onClickAllocate(row)"
                :icon="['fas', 'funnel-dollar']"
                :disabled="requestInProgress === row.id || isAllocating(row)"
              />
              <!-- Undo Allocation -->
              <action
                v-if="isDistributionOpen(row) && !row.allPoolsUnallocated"
                :title="$t('undo_allocation')"
                @click="onClickUnallocate(row)"
                :icon="['fa', 'history']"
                :disabled="requestInProgress === row.id || isAllocating(row)"
              />
              <!-- Close -->
              <action
                v-if="isDistributionOpen(row)"
                :title="$t('close')"
                @click="onClickClose(row)"
                :icon="['fas', 'check-double']"
                :disabled="requestInProgress === row.id || row.status !== distributionStatuses.ALLOCATED"
              />
              <!-- Publish -->
              <action
                v-if="row.status === distributionStatuses.CLOSED"
                :title="$t('publish')"
                @click="onClickPublish(row)"
                :icon="['fas', 'upload']"
                :disabled="requestInProgress === row.id"
              />
            </div>

            <!-- Report Actions -->
            <b-row class="mt-4">
              <b-col>
                <!-- QA Report Actions -->
                <b-row>
                  <b-col class="d-flex justify-content-end align-items-center text-gray text-right">
                    <span>{{ $t('qa_reports') }}</span>
                    <div class="d-flex justify-content-end ml-3">
                      <!-- Generate report -->
                      <action
                        v-if="!isDistributionOpen(row) || row.status == distributionStatuses.ALLOCATED"
                        :title="$t('download_qa_reports')"
                        @click="onDownloadReports(row, reportTypes.QA)"
                        :icon="['fas', 'file-download']"
                        :disabled="requestInProgress === row.id"
                      />
                      <fa-icon
                        v-else
                        :icon="['fas', 'file']"
                        :title="$t('no_qa_reports')"
                        class="gray mx-2"
                      />
                    </div>
                  </b-col>
                </b-row>
                <!-- CRD Report Actions -->
                <b-row>
                  <b-col class="d-flex justify-content-end align-items-center text-gray text-right">
                    <span>{{ $t('crd_reports') }}</span>
                    <div class="d-flex justify-content-end ml-3">
                      <!-- Generate report -->
                      <fa-icon
                        v-if="showCRDProcessing(row)"
                        :title="$t('processing_crd_reports')"
                        :icon="['fas', 'sync-alt']"
                        class="mx-2"
                      />
                      <action
                        v-else-if="showCRDDownload(row)"
                        :title="$t('download_crd_reports')"
                        @click="onDownloadCRDReport(row)"
                        :icon="['fas', 'file-download']"
                      />
                      <fa-icon
                        v-else
                        :icon="['fas', 'file']"
                        :title="$t('no_crd_reports')"
                        class="gray mx-2"
                      />
                    </div>
                  </b-col>
                </b-row>
                 <!-- Work Report Actions -->
                <b-row>
                  <b-col class="d-flex justify-content-end align-items-center text-gray text-right">
                    <span>{{ $t('work_report') | capitalize }}</span>
                    <div class="d-flex justify-content-end ml-3">
                      <!-- Generate report -->
                      <action
                        v-if="!isDistributionOpen(row) || row.status == distributionStatuses.ALLOCATED"
                        :title="$t('request_work_report')"
                        @click="displayWorkReportsModal(row)"
                        :icon="['fas', 'file-download']"
                        :disabled="requestInProgress === row.id"
                      />
                      <fa-icon
                        v-else
                        :icon="['fas', 'file']"
                        :title="$t('no_work_report')"
                        class="gray mx-2"
                      />
                    </div>
                    <WorkReportModal
                      :id="`work-reports-modal-${row.id}`"
                      @ok="onWorkReportModalAccept(row, $event)"
                    />
                  </b-col>
                </b-row>

                <!-- Member Report Actions -->
                <b-row>
                  <b-col class="d-flex justify-content-end align-items-center text-gray text-right">
                    <span>{{ $t('member_reports') | capitalize }}</span>
                    <div class="d-flex justify-content-end ml-3">
                      <!-- Generate report -->
                      <action
                        v-if="!isDistributionOpen(row) || row.status == distributionStatuses.ALLOCATED"
                        :title="$t('request_member_reports')"
                        @click="displayMemberReportsModal(row)"
                        :icon="['fas', 'file-download']"
                        :disabled="requestInProgress === row.id"
                      />
                      <fa-icon
                        v-else
                        :icon="['fas', 'file']"
                        :title="$t('no_member_reports')"
                        class="gray mx-2"
                      />
                    </div>
                    <MemberReportModal
                      :id="`member-reports-modal-${row.id}`"
                      @ok="onMemberReportModalAccept(row, $event)"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </template>
      </v-server-table>
    </template>
    <modal
      id="distribution-errors-modal"
      :title="$t('distribution_errors') | capitalize"
      hide-footer
      @hidden="resetSelectionState"
    >
      <p>{{ $t("the_following_errors_have_to_fixed_for_allocation_to_run") | capitalize }}</p>
      <div v-for="pool in distributionValidationErrors" :key="pool.id">
        <router-link
          class="text-info"
          :to="{ name: 'pools-detail', params: { id: selectedDistribution.id, pid: pool.id }, query: { type: getFileType(pool) }}"
        >
          {{ pool.name }}
        </router-link>
        <ul>
          <li v-for="error in pool.errors" :key="error">{{ $t(error) | capitalize }}</li>
        </ul>
      </div>
    </modal>
    <modal
      id="delete-confirmation-modal"
      :title="$t('delete_distribution') | capitalize"
      :okTitle="$t('yes') | capitalize"
      :cancelTitle="$t('no') | capitalize"
      @ok="onConfirmDelete"
      @hidden="resetSelectionState"
    >
      <div>{{ $t("confirm_delete_distribution") }}</div>
    </modal>
    <modal
      id="allocate-confirmation-modal"
      :title="$t('allocate_distribution') | capitalize"
      :okTitle="$t('yes') | capitalize"
      :cancelTitle="$t('no') | capitalize"
      @ok="onConfirmAllocateDistribution"
      @hidden="resetSelectionState"
    >
      <div>{{ $t("confirm_allocate_distribution") }}</div>
    </modal>
    <modal
      id="undo-allocation-confirmation-modal"
      :title="$t('undo_allocation') | capitalize"
      :okTitle="$t('yes') | capitalize"
      :cancelTitle="$t('no') | capitalize"
      @ok="onConfirmUndoAllocation()"
    >
      <div>{{ $t("confirm_undo_distribution_allocation") }}</div>
    </modal>
    <modal
      id="close-confirmation-modal"
      :title="$t('close_distribution') | capitalize"
      @ok="onConfirmCloseDistribution"
      @hidden="resetSelectionState"
    >
      <div>{{ $t("confirm_close_distribution") }}</div>
    </modal>
    <modal
      id="publish-confirmation-modal"
      :title="$t('publish_distribution') | capitalize"
      @ok="onConfirmPublishDistribution"
      @hidden="resetSelectionState"
    >
      <div>{{ $t("confirm_publish_distribution") }}</div>
    </modal>
    <modal
      id="crd-report-modal"
      :title="$t('crd_reports_confirmation') | capitalize"
      @ok="onCRDReportConfirmation"
      @hidden="resetSelectionState"
    >
      <div>{{ $t("confirm_generate_crd_report") }}</div>
    </modal>
    <router-view name="modal" :initial="selectedDistribution"></router-view>
  </list-layout>
</template>

<style lang="scss" scoped>
  .gray {
    color: var(--muted-gray);
  }
</style>

<script>
import { capitalize, get } from "lodash"
import { distributionPoolRuleTypes, distributionStatuses, getDistributionStatusDisplay, poolsFormType } from "@/constants"
import DistributionStats from "./DistributionStats"
import DistributionStatus from "./DistributionStatus"
import { EventBus } from "@/utils/event-bus.js"
import FundsCell from "./FundsCell"
import MemberReportModal from "./MemberReportModal"
import WorkReportModal from "./WorkReportModal"
import { clickOnUrl } from "@/services/utils"
import { listRouteMixin } from "@/utils/mixins"
import { mapActions } from "vuex"
import { reportStatuses } from "@/constants"

const reportTypes = {
  QA: "QA",
  CRD: "CRD",
  WORK: "WORK",
  MEMBER: "MEMBER",
}

export default {
  name: "DistributionsList",
  mixins: [listRouteMixin],
  components: { FundsCell, DistributionStats, DistributionStatus, MemberReportModal, WorkReportModal },
  data () {
    return {
      distributionStatuses,
      reportStatuses,
      reportTypes,
      selectedDistribution: {},
      requestInProgress: null,
      crdReportProcessing: [],
      distributionValidationErrors: [],
      filters: {
        name: "",
        status: "",
      },
      count: 0,
      summary: {},
      columns: ["name", "funds", "stats", "status", "actions"],
      options: {
        headings: {
          name: capitalize(this.$t("name")),
          funds: capitalize(this.$t("funds")),
          stats: capitalize(this.$t("stats")),
          status: capitalize(this.$t("status")),
          actions: "",
        },
        responseAdapter ({ data }) {
          let component = this.$parent.$parent.$parent
          component.requestInProgress = null
          component.crdReportProcessing = []

          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          return this.$api.distributions.distributionsList(queryParams)
        }
      },
    }
  },
  methods: {
    ...mapActions("alert", ["success", "error"]),
    getDistributionStatusDisplay,
    isAllocating (row) {
      return row.status === distributionStatuses.PENDING_ALLOCATION || row.status === distributionStatuses.ALLOCATING
    },
    onStatusClick (status) {
      this.filters.status = status
      this.applyFilters("status", status)
    },
    onNameSearch (value) {
      this.filters.name = value
    },
    resetSelectionState () {
      this.selectedDistribution = {}
      this.distributionValidationErrors = []
    },
    getData () {
      this.$refs.distributions.getData()
    },
    onLoaded ({ data }) {
      this.count = data.count
      this.summary = data.summary
    },
    onCopy (row) {
      this.selectedDistribution = row
      this.$router.push({ name: "distributions-create" })
    },
    onCreate () {
      this.selectedDistribution = {}
      this.$router.push({ name: "distributions-create" })
    },
    onClickAllocate (row) {
      this.selectedDistribution = row
      this.$bvModal.show("allocate-confirmation-modal")
    },
    onClickUnallocate (row) {
      this.selectedDistribution = row
      this.$bvModal.show("undo-allocation-confirmation-modal")
    },
    onClickClose (row) {
      this.selectedDistribution = row
      this.$bvModal.show("close-confirmation-modal")
    },
    onClickPublish (row) {
      this.selectedDistribution = row
      this.$bvModal.show("publish-confirmation-modal")
    },
    getReportRequest (type) {
      return {
        [reportTypes.CRD]: "distributionsDownloadCRDReports",
        [reportTypes.QA]: "distributionsDownloadQAReports",
        [reportTypes.MEMBER]: "distributionsRequestQAMemberReports",
        [reportTypes.WORK]: "distributionsRequestQAWorkRevenuesReports",
      }[type]
    },
    onDownloadReports (row, type, payload) {
      this.selectedDistribution = row
      this.$api.distributions[this.getReportRequest(type)](this.selectedDistribution.id, payload)
        .then(({ data }) => {
          if (this.isReportSentByEmail(type)) {
            this.success(this.$t("request_reports_success"))
          } else {
            const { url } = data
            if (url) clickOnUrl(url)
            else this.success(this.$t("request_sent"))
          }
        })
        .catch((err) => {
          let message = err.response.data?.message || err.response.data || err.response
          this.error(message)

        })
    },
    onConfirmAllocateDistribution () {
      const { id } = this.selectedDistribution
      this.requestInProgress = id
      this.$api.distributions.distributionsAllocate(id)
        .then(() => {
          this.resetSelectionState()
          this.getData()
          this.success(this.$t("allocation_process_successfully_triggered"))
        }).catch(err => {
          let { data } = err.response

          if (data.errors && data.errors.length) {
            this.distributionValidationErrors = data.errors
          } else {
            let message = data?.message || data
            this.error(message)
          }

          this.requestInProgress = null
        })
    },
    onConfirmUndoAllocation () {
      const { id } = this.selectedDistribution
      this.requestInProgress = id
      this.$api.distributions.distributionsUnallocate(id)
        .then(() => {
          this.resetSelectionState()
          this.getData()
          this.success(this.$t("undo_allocation_success"))
        }).catch(err => {
          let { data } = err.response

          if (data.errors && data.errors.length) {
            this.distributionValidationErrors = data.errors
          } else {
            let message = data?.message || data
            this.error(message)
          }

          this.requestInProgress = null
        })
    },
    onConfirmCloseDistribution () {
      const { id } = this.selectedDistribution
      this.requestInProgress = id
      this.$api.distributions.distributionsClose(id)
        .then(() => {
          this.resetSelectionState()
          this.getData()
          this.success(this.$t("distribution_successfully_closed"))
        }).catch(err => {
          this.resetSelectionState()
          this.requestInProgress = null
          let message = err.response.data?.message || err.response.data || err.response
          this.error(message)
        })
    },
    onConfirmPublishDistribution () {
      const { id } = this.selectedDistribution
      this.requestInProgress = id
      this.$api.distributions.distributionsPublish(id)
        .then(() => {
          this.resetSelectionState()
          this.getData()
          this.success(this.$t("distribution_successfully_published"))
        }).catch(err => {
          this.resetSelectionState()
          this.requestInProgress = null
          let message = err.response.data?.message || err.response.data || err.response
          this.error(message)
        })
    },
    onDelete (row) {
      this.selectedDistribution = row
      this.$bvModal.show("delete-confirmation-modal")
    },
    onConfirmDelete () {
      const { id } = this.selectedDistribution
      this.$api.distributions.distributionsDelete(id)
        .then(() => {
          this.success(this.$t("distribution_successfully_deleted"))
          this.resetSelectionState()
          this.getData()
        }).catch(error => {
          this.resetSelectionState()
          this.requestInProgress = null
          if (error.response.data && error.response.data.detail) this.error(error.response.data.detail)
        })
    },
    poolCountText (row) {
      return `${ row.poolCount } ${ row.poolCount == 1 ? this.$t("pool") : this.$t("pools") }`
    },
    showCRDProcessing (row) {
      return row.crdReportsStatus === reportStatuses.IN_QUEUE || row.crdReportsStatus === reportStatuses.PROCESSING || this.crdReportProcessing.includes(row.id)
    },
    showCRDDownload (row) {
      return [distributionStatuses.ALLOCATED, distributionStatuses.CLOSED, distributionStatuses.PUBLISHED].includes(row.status)
    },
    isDistributionOpen (row) {
      return ![distributionStatuses.CLOSED, distributionStatuses.PUBLISHED].includes(row.status)
    },
    displayWorkReportsModal (row) {
      this.$bvModal.show(`work-reports-modal-${row.id}`)
    },
    onWorkReportModalAccept (row, workId) {
      const payload = {
        "work_atlas_id" : workId
      }
      this.onDownloadReports(row, reportTypes.WORK, payload)
    },
    isReportSentByEmail (type) {
      return [reportTypes.WORK, reportTypes.MEMBER].includes(type)
    },
    displayMemberReportsModal (row) {
      this.$bvModal.show(`member-reports-modal-${row.id}`)
    },
    onMemberReportModalAccept (row, ipiNumber) {
      const payload = {
        "ipi_name_number" : ipiNumber
      }
      this.onDownloadReports(row, reportTypes.MEMBER, payload)
    },
    onDownloadCRDReport (row) {
      this.selectedDistribution = row
      if (this.selectedDistribution.crdReportsStatus === reportStatuses.NON_EXISTENT) {
        this.$bvModal.show("crd-report-modal")
      } else {
        this.onDownloadReports(row, reportTypes.CRD, {})
      }
      
    },
    onCRDReportConfirmation () {
      this.onDownloadReports(this.selectedDistribution, reportTypes.CRD, {})
      this.crdReportProcessing.push(this.selectedDistribution.id)
    },
    getFileType (row) {
      let rules = row.distributionPoolRules

      const {
        CRD,
        PRORATA_EQUAL,
        PRORATA_PERCENTAGE,
        WITH_UP,
        WITHOUT_UP,
        PRE_DISTRIBUTION_FILE,
      } = distributionPoolRuleTypes

      const levelOneTypes = [CRD, PRORATA_EQUAL, PRORATA_PERCENTAGE, WITH_UP, WITHOUT_UP, PRE_DISTRIBUTION_FILE]

      let levelOneRule = rules.find(r => levelOneTypes.includes(r.type))

      if(levelOneRule.type === CRD) {
        return poolsFormType.CRD
      }
      if(levelOneRule.type === PRE_DISTRIBUTION_FILE) {
        return poolsFormType.DIGITAL
      }
      return  poolsFormType.DOMESTIC
    },
  },
  watch: {
    "filters.name" (value) {
      this.applyFilters("name", value)
    },
    distributionValidationErrors (errors) {
      if (errors.length) this.$bvModal.show("distribution-errors-modal")
    },
  },
  mounted () {
    EventBus.$on("reset", this.resetSelectionState)
  }
}
</script>
