<template>
  <b-nav-form @submit.prevent>
    <label :for="$attrs.name" v-if="$attrs.label">
      {{ $attrs.label | startcase }}:
    </label>
    <b-input-group class="search-input">
      <b-input-group-prepend>
        <span class="input-group-text">
          <fa-icon :icon="['fas', 'search']"/>
        </span>
      </b-input-group-prepend>
      <b-form-input
        :placeholder="placeholder"
        :value="value"
        @input="onSearchInput"
        :formatter="formatter"
        ref="searchInput"
        :size="$attrs.size"
      />
    </b-input-group>
  </b-nav-form>
</template>

<style lang="scss" scoped>
  label {
    font-weight: bold;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }

  li.form-inline > form,
  input { width: 100%; }

  .search-input {
    width: 100%;
    margin: 0;

    &.input-group:focus-within {
      border: 1px solid $info;
      border-radius: 0.25rem;
      box-shadow: 0 0 2px 0 $info;
      outline: 0;

      & * {
        border: none;
        box-shadow: none;
      }
    }

    span.input-group-text { border-right: none; }

    input.form-control { border-left: none; }
  }
</style>

<script>
import { debounce } from "lodash"
import { fomatterMixin } from "@/utils/mixins"

export default {
  name: "SearchInput",
  mixins: [fomatterMixin],
  methods: {
    onSearchInput: debounce( function (value) { this.$emit("search", value) }, 500),
    formatter (value, event) {
      this.setCorrectTextCursorPosition(event)
      return value.toUpperCase()
    }
  },
  props: {
    placeholder: {
      type: String,
      default: "search",
    },
    value: {
      type: String
    }
  },
}
</script>
