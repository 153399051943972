var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"py-3 px-3 h-100",attrs:{"no-body":""}},[_c('b-row',[_c('b-col',{staticClass:"d-flex"},[_c('search-input',{staticClass:"w-40 mr-2 mb-3",attrs:{"value":_vm.fileSearch,"placeholder":_vm._f("capitalize")(_vm.$t('search_by_filename'))},on:{"search":_vm.onFileSearch}}),(_vm.fileType == _vm.selectableFileTypes.USAGES)?_c('div',[_c('b-button',{staticClass:"form-control filters-popover",attrs:{"id":"popover-target-1","variant":"outline-info"},on:{"click":_vm.onShowFiltersPopover}},[_c('fa-icon',{staticClass:"popover-icon icon",attrs:{"icon":['fa', 'sliders-h']}}),_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t("tag_filters")))+" ")],1),_c('b-popover',{attrs:{"target":"popover-target-1","show":_vm.showFilterPopover,"placement":"top"},on:{"update:show":function($event){_vm.showFilterPopover=$event}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t("tag_filters")))+" ")]),_c('b-button',{staticClass:"py-0 px-2",attrs:{"aria-label":"close","variant":"outline-secondary"},on:{"click":function($event){_vm.showFilterPopover = !_vm.showFilterPopover}}},[_c('span',{staticClass:"d-inline-block font-weight-bolder",attrs:{"aria-hidden":"true"}},[_vm._v("×")])])],1)]},proxy:true}],null,false,679141731)},[_c('TagFilters',{attrs:{"initFilters":_vm.camelcaseKeys(_vm.tagFilters)},on:{"filter":_vm.onTagsFilter}}),_c('a',{staticClass:"text-info float-right pb-3 pr-3",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.clearFilters($event)}}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t("clear_filters")))+" ")])],1)],1):_vm._e()],1)],1),_c('v-server-table',{ref:_vm.tableRef,attrs:{"columns":_vm.columns,"options":_vm.options},on:{"loaded":_vm.onLoaded},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex"},[((row.sheets && row.sheets.length) || _vm.fileOnlySelection)?_c('b-checkbox',{attrs:{"value":row.id},on:{"change":function($event){return _vm.onChange($event, row)}},model:{value:(_vm.filesWithAllSheetsSelected),callback:function ($$v) {_vm.filesWithAllSheetsSelected=$$v},expression:"filesWithAllSheetsSelected"}}):_vm._e()],1)]}},{key:"filename",fn:function(ref){
var row = ref.row;
return [_c('b-row',[_c('b-col',[_c('div',[_vm._v(_vm._s(row.filename))]),_c('div',{staticClass:"mt-2"},_vm._l((row.tags),function(tag,index){return _c('tag',{key:("tag_" + (row.id) + "_" + index),attrs:{"text":tag.value,"icon":_vm.getTagIcon(tag)}})}),1)])],1)]}},{key:"period",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"font-8"},[_vm._v(" "+_vm._s(_vm.getUsagePeriodDisplay(row))+" ")])]}},{key:"child_row",fn:function(ref){
var row = ref.row;
return [(!_vm.fileOnlySelection && row.sheets.length)?_c('FileSheetSelector',{key:row.id,attrs:{"items":row.sheets,"file":row,"selected":_vm.selectedSheets,"parentTable":_vm.tableRef}}):_vm._e()]}}])},[_c('template',{slot:"h__selection"},[_c('div',{staticClass:"d-flex"},[_c('b-checkbox',{on:{"change":_vm.onPageFilesSelect},model:{value:(_vm.options.allFilesAndSheetsSelected),callback:function ($$v) {_vm.$set(_vm.options, "allFilesAndSheetsSelected", $$v)},expression:"options.allFilesAndSheetsSelected"}})],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }