import { render, staticRenderFns } from "./AgreementConflictsDetail.vue?vue&type=template&id=11d1e183&"
import script from "./AgreementConflictsDetail.vue?vue&type=script&lang=js&"
export * from "./AgreementConflictsDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports