<template>
  <multi-select-field
    v-bind="$attrs"
    v-model="innerValue"
    :options="options"
    track-by="value"
    choiceLabel="value"
    :multiple="false"
    :allowEmpty="true"
    :show-no-results="false"
    :placeholder="placeholder"
    :searchable="true"
    :internal-search="false"
    :errors="innerErrors"
    @search-change="searchExistingTags"
    :select-label="'select'"
  />
</template>

<script>
import { debounce } from "lodash"


export default {
  name: "TagSearchSelect",
  data () {
    return {
      options: [],
      innerValue: "",
      innerErrors: []
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit("input", newVal)
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal
    },
    errors (newVal) {
      this.innerErrors = newVal
    }
  },
  methods: {
    reset () {
      this.innerValue = null
      this.innerErrors = []
    },
    setInitial () {
      if (!this.innerValue && this.value) this.innerValue = this.value
    },
    searchExistingTags: debounce( function (query) {
      let searchQuery = query.trim()
      this.innerErrors = []
      if (!searchQuery) return
      this.$api.usages.usageFileTags({ search: searchQuery, type: this.tagType })
        .then(({ data }) => {
          this.options = [ ...data.results, { type: this.tagType, value: searchQuery } ]
        })
        .catch((error) => {
          this.innerErrors = [error.response.data.message]
        })
    }, 1000)
  },
  props: {
    value: {
      type: null
    },
    tagType: [String, Number],
    errors: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: "",
    }
  },
  mounted () {
    if (this.value) this.innerValue = this.value
  }
}
</script>
