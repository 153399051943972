<template>
  <div class="container d-flex flex-column">
    <b-img class="mb-3 mx-auto atlas-logo" :src="require('@/assets/atlas_logo_magenta.jpg')" />
    <b-card>
      <b-card-text>
        <LoginForm v-if="!isResetPassword" />
        <ResetForm v-if="isResetPassword" />
      </b-card-text>
    </b-card>
    <b-img class="bmat-logo m-2" :src="require('@/assets/bmat_logo_magenta.jpg')" />
  </div>
</template>

<style lang="scss" scoped>
  .container {
    padding-top: 7%;
    width: 100%;
    max-width: 500px;
  }

  .bmat-logo {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  ::v-deep .card-header {
    padding: 0 9px 12px 9px;
  }

  ::v-deep .card-body {
    padding: 35px;
  }

  ::v-deep .nav-item {
    width: 50%;
    text-align: center;

    & .active {
      border-top: 3px solid var(--secondary);
    }
  }

  ::v-deep .btn-block {
    font-weight: bold;
    text-transform: uppercase;
  }
</style>

<script>
import LoginForm from "./LoginForm"
import ResetForm from "./ResetForm"

export default {
  components: {
    LoginForm,
    ResetForm,
  },
  data () {
    return {
      isResetPassword: false
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.isResetPassword = to.name === "reset-password")
  }
}
</script>
