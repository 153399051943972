var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{staticClass:"sheets-table",attrs:{"items":_vm.items,"fields":_vm.fields,"thead-class":"d-none","striped":"","borderless":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('b-col',[_c('fa-icon',{staticClass:"sheet-icon",attrs:{"size":"lg","icon":['fa', 'table']}}),_c('router-link',{staticClass:"text-info",attrs:{"to":{ 'name': 'usages-list', 'params': { 'id': item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"cell(usageType)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.getUsageTypeDisplay(item.usageType))))])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [(item.status === _vm.fileStatuses.PENDING)?_c('status',{attrs:{"text":_vm.$t('pending'),"icon":['fas', 'question-circle'],"color":"orange"}}):_vm._e(),(item.status === _vm.fileStatuses.PROCESSING)?_c('status',{attrs:{"text":_vm.$t('processing'),"icon":['fas', 'question-circle'],"color":"orange"}}):_vm._e(),(item.status === _vm.fileStatuses.LANDED)?_c('status',{attrs:{"text":_vm.$t('landed'),"icon":['fas', 'check-circle'],"color":"green"}}):_vm._e(),(item.status === _vm.fileStatuses.INGESTED)?_c('status',{attrs:{"text":_vm.$t('ingested'),"icon":['fas', 'check-circle'],"color":"green"}}):_vm._e(),(item.status === _vm.fileStatuses.MATCHED)?_c('status',{attrs:{"text":_vm.$t('matched'),"icon":['fas', 'check-circle'],"color":"green"}}):_vm._e(),(item.status === _vm.fileStatuses.ERROR)?_c('status',{attrs:{"text":_vm.$t('error'),"icon":['fas', 'exclamation-circle'],"color":"red","active":true,"clickable":""},on:{"click":function($event){return _vm.showErrors(item, 'sheet')}}}):_vm._e()]}},{key:"cell(ingestionStats)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mx-1"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t("landing_db_ingestion"))))]),_c('ingestion-stats',{staticClass:"mb-2",attrs:{"stats":item.stats.landingDbIngestion},on:{"show-errors":function($event){return _vm.showErrors(item, 'row', _vm.fileIngestionStatsTypes.LANDING_DB_INGESTION)}}}),_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t("single_view_ingestion"))))]),_c('ingestion-stats',{attrs:{"stats":item.stats.singleViewIngestion},on:{"show-errors":function($event){return _vm.showErrors(item, 'row', _vm.fileIngestionStatsTypes.SINGLE_VIEW_INGESTION)}}})],1)]}},{key:"cell(matchingStats)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mx-1"},[_c('matching-stats',{attrs:{"stats":item.stats.matching}})],1)]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-content-end align-items-center"},[(item.distributions.length)?_c('action',{attrs:{"title":_vm.$t('distributions'),"icon":['fa', 'file-invoice-dollar']},on:{"click":function($event){return _vm.onShowDistributions(item.id)}}}):_vm._e(),(item.distributions)?_c('modal',{attrs:{"id":'distributions-modal-' + item.id,"title":_vm._f("capitalize")(_vm.$t('distributions')),"cancelTitle":_vm._f("capitalize")(_vm.$t('close')),"hide-header":"","hide-footer":""}},[_c('b-container',[_c('h3',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t("distributions"))))]),_vm._l((item.distributions),function(distribution){return _c('div',{key:distribution.id},[_c('router-link',{staticClass:"text-info row px-1 mx-0",attrs:{"to":{ name: 'distributions-detail', params: { id: distribution.id }}}},[_vm._v(" "+_vm._s(distribution.name)+" ")]),_c('ul',_vm._l((distribution.distributionPools),function(pool){return _c('li',{key:pool.id},[_c('router-link',{staticClass:"text-info row px-1 mx-0",attrs:{"to":{ name: 'pools-detail', params: { id: distribution.id, pid: pool.id }}}},[_vm._v(" "+_vm._s(pool.name)+" ")])],1)}),0)],1)}),_c('div',{staticClass:"float-right mt-3 mb-2"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.onCloseDistributionsModal(item.id)}}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t("close")))+" ")])],1)],2)],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }