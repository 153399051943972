import { API } from "./utils"


export const userActions = {
  agreementConflictsRetrieve: id => API.get(`/user-actions/agreement/${id}/`),
  agreementConflictsList: (params = null) => API.get("/user-actions/agreement/", { params }),
  agreementConflictsSelectAgreement: (id, data) => API.post(`/user-actions/agreement/${id}/select_agreement/`, data),
  worksMatchingList: (params = null) => API.get("/user-actions/works-matching/", { params }),
  worksMatchingRetrieve: id => API.get(`/user-actions/works-matching/${id}/`),
  worksMatchingCreateWork: id => API.post(`/user-actions/works-matching/${id}/create_work/`),
  worksMatchingSelectWork: (id, data) => API.post(`/user-actions/works-matching/${id}/select_work/`, data),
  nonIpiList: (params = null) => API.get("/user-actions/non_ipi/", { params }),
  recordingConflictsList: (params = null) => API.get("/user-actions/recording/", { params }),
  recordingConflictsSelectWork: (id, data) => API.post(`/user-actions/recording/${id}/select_work/`, data),
  recordingConflictsRetrieve: id => API.get(`/user-actions/recording/${id}/`),
  sharesActionsList: (params = null) => API.get("/user-actions/shares/", { params }),
  relatedObjectRetrieve: url => API.get(url),
  workRegistrationList: (params = null) => API.get("/user-actions/work-registration/", { params }),
  workRegistrationRetrieve: id => API.get(`/user-actions/work-registration/${id}/`),
  workRegistrationAccept: (id, data) => API.post(`/user-actions/work-registration/${id}/accept/`, data),
  workRegistrationDecline: (id, data) => API.post(`/user-actions/work-registration/${id}/decline/`, data),
  workUpdateList: (params = null) => API.get("/user-actions/work-update/", { params }),
}
