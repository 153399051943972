<template v-slot:top>
  <div>
    <div class="d-flex justify-content-end mt-4 mb-2" v-if="userActionStatus !== userActionStatuses.DONE">
      <!-- Buttons -->
      <b-button size="sm" class="float-right ml-2" @click="goToAgreementDetail">
        <fa-icon
          class="mr-1"
          size="sm"
          :icon="['fas', 'pen']"
        />
        {{ $t("edit") | capitalize }}
      </b-button>
    </div>

    <!-- CSS class defined in project.scss -->
    <div class="work-details-container">
      <b-table-lite :fields="agreementFields" :items="[agreement]" responsive borderless v-if="agreement">
        <!-- Agreement ID -->
        <template v-slot:cell(atlas_id)="{ item }">
          {{ item.id }}
        </template>

        <!-- Assignor -->
        <template v-slot:cell(assignor)="{ item }">
          {{ item.assignor.name }}
        </template>

        <!-- Assignee -->
        <template v-slot:cell(assignee)="{ item }">
          {{ item.assignee.name }}
        </template>

        <!-- Right Types -->
        <template v-slot:cell(rightTypes)="{ item }">
          {{ getRightTypesDisplay(item.rightTypes) }}
        </template>
      </b-table-lite>

      <!-- MORE INFO -->
      <b-table-lite :fields="extraFields" :items="[agreement]" responsive borderless v-if="agreement">
        <!-- Territory -->
        <!-- TODO: display territory -->

         <!-- Type -->
        <template v-slot:cell(type)="{ item }">
          {{ getAgreementTypeDisplay(item.type) }}
        </template>

        <!-- Include/Eclude -->
        <template v-slot:cell(exclude)="{ item }">
          <div v-if="item.type === agreementTypes.SPECIFIC">
            <span class="mx-3">{{ getInclusionFlagDisplay(item.inclusionFlag) }}</span>
          </div>
          <div v-else class="ml-3">-</div>
        </template>

        <!-- Works -->
        <template v-slot:cell(works)="{ item }">
          <div v-if="item.type === agreementTypes.SPECIFIC && item.works.length">
            <p class="text-small mb-1" v-for="work in item.works" :key="work.id">{{ work.title }}</p>
          </div>
          <div v-else class="ml-3">-</div>
        </template>
      </b-table-lite>
    </div>
  </div>
</template>

<script>
import { agreementTypes, getAgreementTypeDisplay, inclusionFlags, userActionStatuses } from "@/constants"
import { mapMutations, mapState } from "vuex"
import { capitalize } from "lodash"


export default {
  name: "AgreementCard",
  data () {
    return {
      userActionStatuses,
      agreementTypes,
      agreementFields: [
        { key: "atlas_id", label: "Atlas ID" },
        { key: "assignor", label: capitalize(this.$t("assignor")) },
        { key: "assignee", label: capitalize(this.$t("assignee")) },
        { key: "share", label: capitalize(this.$t("share")) },
        { key: "rightTypes", label: capitalize(this.$t("rights")) },
        { key: "startDate", label: capitalize(this.$t("start_date")) },
        { key: "endDate", label: capitalize(this.$t("end_date")) },
      ],
      extraFields: [
        { key: "territory", label: capitalize(this.$t("territory")) },
        { key: "type", label: capitalize(this.$t("type")) },
        { key: "exclude", label: capitalize(this.$t("include_exclude")) },
        { key: "works", label: capitalize(this.$t("works")) },
      ],
      getAgreementTypeDisplay
    }
  },
  computed: {
    ...mapState("consts", ["countriesHash"]),
  },
  methods: {
    ...mapMutations("consts", ["setCountries"]),
    getRightTypesDisplay (rightTypes) {
      return rightTypes.join(", ")
    },
    getInclusionFlagDisplay (inclusionFlag) {
      return {
        [inclusionFlags.INCLUDE]: this.$t("include"),
        [inclusionFlags.EXCLUDE]: this.$t("exclude"),
      }[inclusionFlag]
    },
    goToAgreementDetail () {
      this.$router.push({ name: "agreements-update", params: { agreementId: this.agreement.id } }).catch(() => {})
    }
  },
  async mounted () {
    let response = await this.$api.countries.countriesList()
    this.setCountries(response.data)
  },
  props: {
    agreement: Object,
    userActionStatus: String,
  }
}
</script>
