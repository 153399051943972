<template>
  <div>
    <b-table
      :items="items"
      :fields="fields"
      borderless
      striped
      show-empty
    >
      <template v-slot:head()="item">
        <div class="head mb-2">
          {{ item.label }}
        </div>
      </template>

      <!-- Chain -->
      <template v-slot:cell(chain)="{ index, item }">
        <multi-select
          :options="usedLetters"
          :searchable="false"
          :hideSelected="true"
          :showLabels="false"
          :value="item.chains"
          placeholder
          :multiple="true"
          @input="onChainInput($event, index, item)"
          v-if="item.party.type === CONTRIBUTOR && usedLetters.length && canEdit(item)"
        />
        <div class="d-flex justify-content-center pt-2" v-else>{{ item.chains ? item.chains.join(", ") : "-" }}</div>
      </template>

      <!-- Role -->
      <template v-slot:cell(role)="{ index, item }">
        <!-- Role selector -->
        <multi-select-field
          id="role"
          :value="getRoleOption(item.role)"
          name="role"
          :options="roleOptions"
          choiceLabel="label"
          group-values="values"
          group-label="type"
          :group-select="false"
          @input="onRoleClick(index, $event, item)"
          :allowEmpty="false"
          :placeholder="$t('select_option') | startcase"
        />
      </template>

      <!-- Interested Party -->
      <template v-slot:cell(interestedParty)="{ index, item }">
        <div class="d-flex justify-content-between align-items-start">
          <!-- Party Info -->
          <div class="mx-2">
            <p v-if="item.party.name">
              {{ displayPartyName(item) }}<br />
              <span class="small-text text-gray">
                {{ item.party.nameType }} {{ item.party.ipiNameNumber }}
              </span>
            </p>
            <template v-else>
              <p class="text-gray">-</p>
              <p v-if="getPartyFormError(index).length" class="text-danger">{{ getPartyFormError(index).join(", ") | capitalize }}</p>
            </template>
          </div>
          <!-- Edit -->
          <action
            v-if="canEdit(item)"
            :title="$t('edit')"
            :icon="['fas', 'pen']"
            color="primary"
            @click="onEdit(index)"
            class="mt-2"
          />
        </div>
      </template>

      <!-- Performing -->
      <template v-slot:cell(performing)="{ index, item }">
        <div class="d-flex align-items-center">
          <split-input
            :name="`performing-${index}`"
            :value="item.performing"
            :max="getSplitMax(item)"
            @input="onPerformingInput($event, item, index)"
            class="narrow"
            :disabled="!canEdit(item)"
          />
          <div class="ml-2 mb-3">%</div>
        </div>
      </template>

      <!-- Mechanical -->
      <template v-slot:cell(mechanical)="{ index, item }">
        <div class="d-flex align-items-center">
          <split-input
            :name="`mechanical-${index}`"
            :value="item.mechanical"
            @input="onMechanicalInput($event, item, index)"
            class="narrow"
            :disabled="!canEdit(item)"
            max="100"
          />
          <div class="ml-2 mb-3">%</div>
        </div>
      </template>

      <!-- Actions -->
      <template v-slot:cell(actions)="{ index, item }">
        <div class="d-flex align-items-center pt-2">
          <!-- Delete -->
          <action
            v-if="canEdit(item)"
            :title="$t('delete')"
            :icon="['fas', 'trash']"
            color="primary"
            @click="onDelete(index)"
          />
        </div>
        <modal
          :id="'confirmation-modal-' + index"
          :title="$t('delete_interested_party') | startcase"
          :okTitle="$t('yes') | capitalize"
          :cancelTitle="$t('no') | capitalize"
          @ok="onConfirmDelete(item, index)"
        >
          <div>{{ $t("confirm_delete_interested_party") | capitalize }}?</div>
        </modal>
      </template>
    </b-table>
    <CreateParty
      ref="createPartyModal"
      @on-accept="addShareForParty"
    />
  </div>
</template>

<style lang="scss" scoped>

  .table ::v-deep td {
    padding-bottom: 0 !important;
  }

</style>

<script>
import {
  contributorRoles as contributorRoleDefinitions,
  contributorRolesVerbose,
  partyRolesMap,
  partyTypes,
  publisherRoles as publisherRoleDefinitions,
  publisherRolesVerbose,
  workFieldActionTypes
} from "@/constants"
import { get, startCase, values } from "lodash"
import CreateParty from "./CreateParty"
import { mapGetters } from "vuex"

const { ADD, CHAIN, EDIT, REMOVE } = workFieldActionTypes
const { CONTRIBUTOR, PUBLISHER } = partyTypes

const contributorRoles = Object.keys(contributorRolesVerbose)
const publisherRoles = Object.keys(publisherRolesVerbose)

let contributorRolesOptions = []
for (let code in contributorRolesVerbose) {
  contributorRolesOptions.push({ name: code, label: `${code} - ${contributorRolesVerbose[code]}` })
}

let publisherRolesOptions = []
for (let code in publisherRolesVerbose) {
  publisherRolesOptions.push({ name: code, label: `${code} - ${publisherRolesVerbose[code]}` })
}

export default {
  name: "SharesEditTable",
  components: {
    CreateParty,
  },
  data () {
    return {
      fields: [
        { key: "chain", label: this.$t("ca_e_link"), class: "w-5" },
        { key: "role", label: startCase(this.$t("role")), class: "w-20" },
        { key: "interestedParty", label: startCase(this.$t("interested_party")), class:" w-40" },
        { key: "performing", label: startCase(this.$t("performing")), class: "w-15" },
        { key: "mechanical", label: startCase(this.$t("mechanical")), class: "w-15" },
        { key: "actions", label: "", class: "w-5" },
      ],
      usedLetters: [],
      items: [],
      deletedItems: [],
      contributorRoles,
      contributorRolesVerbose,
      publisherRoles,
      publisherRolesVerbose,
      CONTRIBUTOR,
      PUBLISHER,
      searchQuery: "",
      selectedPartyInput: null,
      selectedParty: {},
    }
  },
  computed: {
    ...mapGetters("user", ["isSociety"]),
    roleOptions () {
      let cOptions = [...contributorRolesOptions]
      let pOptions = [...publisherRolesOptions]

      if (!this.isSociety) {
        const allowedRolesForMembers = [
          contributorRoleDefinitions.A,
          contributorRoleDefinitions.AR,
          contributorRoleDefinitions.C,
          contributorRoleDefinitions.CA,
          publisherRoleDefinitions.E,
        ]
        cOptions = cOptions.filter(r => allowedRolesForMembers.includes(r.name))
        pOptions = pOptions.filter(r => allowedRolesForMembers.includes(r.name))
      }

      return [
        {
          type: startCase(this.$t("contributor_roles")),
          values: cOptions
        },
        {
          type: startCase(this.$t("publisher_roles")),
          values: pOptions
        },
      ]
    }
  },
  methods: {
    ...mapGetters("user", ["canManagePartyResource"]),
    canEdit (item) {
      return item.canEdit || item.action === ADD || !item.party.name
    },
    getRoleOption (role) {
      let code = role && role.name || role
      const options = [ ...contributorRolesOptions, ...publisherRolesOptions]
      let result = options.find(option => {
        return option.name === code
      })
      return result
    }, 
    onChainInput (chains, index, item) {
      let share = { ...item, chains: [ ...chains ] }
      if (!share.action) share.action = CHAIN
      this.items.splice(index, 1, { ...share })
      this.setChainActionOnRelatedShares(share)
      this.$emit("edited", this.items)
    },
    setChainActionOnRelatedShares (share) {
      // Marks the related publisher shares with the CHAIN action
      this.items.forEach((item, index) => {
        if (item.party.type === PUBLISHER) {
          const publisherChain = item.chains.length ? item.chains[0] : null

          if (publisherChain && share.chains.includes(publisherChain)) {
            if (!item.action) item.action = CHAIN
            this.items.splice(index, 1, { ...item })
          }
        }
      })
    },
    onRoleClick (index, value, share) {
      const role = value.name
      if (share.role !== role) {

        // Clear party when switching between contributor and publisher roles
        if (this.isPublisher(role) !== this.isPublisher(share.role) || this.isContributor(role) !== this.isContributor(share.role)) {
          share.party = {}
        }

        //If we switch from publisher role to contributor role, we delete the chains
        if (this.isPublisher(share.role) && this.isContributor(role)) {
          share.chains.forEach(id => this.removeLetter(id))
          share.chains = []
        }

        share.role = role
        if (!share.action) share.action = EDIT
        this.items.splice(index, 1, { ...share })
        this.$emit("edited", this.items)
      }
    },
    onMechanicalInput (value, item, index) {
      this.onSplitInput(value, item, index, "mechanical")
    },
    onPerformingInput (value, item, index) {
      this.onSplitInput(value, item, index, "performing")
    },
    onSplitInput (value, item, index, rightsType) {
      if (value !== item[rightsType]) {
        item[rightsType] = value
        if (item.action !== ADD) item.action = EDIT

        this.items.splice(index, 1, { ...item })
        this.$emit("edited", this.items)
      }
    },
    removeLetter (id) {
      // Remove the letter from the used letters list.
      this.usedLetters = this.usedLetters.filter(chain => chain !== id)

      // Remove the letter from all items that used it.
      this.items = this.items.map(share => ({ ...share, chains: share.chains.filter(chain => chain !== id) }))
    },
    onDelete (id) {
      this.$bvModal.show(`confirmation-modal-${id}`)
    },
    onEdit (index) {
      this.selectedParty = this.items[index]
      this.selectedPartyInput = index
      this.$refs.createPartyModal.setFormData(this.selectedParty)
      this.$bvModal.show("edit-party-modal")
    },
    onConfirmDelete (item, index) {
      let share = { ...item, chains: [], action: REMOVE }

      // Cleanup chain letters if the removed party is a publisher
      if (item.party.type === PUBLISHER) item.chains.forEach(id => this.removeLetter(id))

      if (share.id) this.deletedItems = [ ...this.deletedItems, share ]
      this.items.splice(index, 1)
      this.$emit("edited", this.items)
      this.$emit("deleted", this.deletedItems)
    },
    addShareForParty (newParty) {
      let currentItem = this.items[this.selectedPartyInput]
      const isCurrentPartyEmpty = !currentItem.party || Object.keys(currentItem.party).length == 0

      const oldPartyIsContributor = currentItem.party.type === CONTRIBUTOR

      //If we switch types, we delete the assigned chains
      if (currentItem.party.type !== newParty.type) {
        //If old party was a publisher and we change it to contributor, we need to delete all the chain letters for that old publisher.
        if(currentItem.party.type === PUBLISHER && newParty.type == CONTRIBUTOR) {
          currentItem.chains.forEach(id => this.removeLetter(id))
        }
        currentItem.chains = []
      }

      currentItem.party = newParty
      let action = currentItem.id ? EDIT : ADD
      let mechanical = action === EDIT ? currentItem.mechanical : "0.00"
      let performing = action === EDIT ? currentItem.performing : "0.00"
      let isPublisher = currentItem.party.type === PUBLISHER

      let share = {
        action,
        role: currentItem.role,
        mechanical,
        performing,
        chains: currentItem.chains,
        id: currentItem.id,
        party: currentItem.party,
        canEdit: currentItem.canEdit
      }
      this.items.splice(this.selectedPartyInput, 1, share)
      this.searchQuery = ""
      this.selectedPartyInput = null

      // If this is a publisher and the party is empty, set the next chain letter in the list
      // of chain letters and push it to the list of used chains.
      if ((isCurrentPartyEmpty || oldPartyIsContributor) && isPublisher) {
        const letter = this.getNextLetter()
        share.chains = [letter]
        this.usedLetters.push(letter)
      }

      this.$emit("edited", this.items)
    },
    displayPartyName (share) {
      if (!share || !share.party || Object.keys(share.party).length === 0 || !share.party.name) return ""

      return share.party.affiliations?.length ?
        `${share.party.name} (${[ ...new Set(share.party.affiliations.map(a => a.societyName))].join(", ")})`
        : share.party.name
    },
    getPartyFormError (index) {
      let errors = this.formErrors[`party-${index}`]
      return errors && errors.length ? [errors]: []
    },
    getPartyType (role, share) {
      if (partyRolesMap[share.role] !== partyRolesMap[role]) share.party = null
      return partyRolesMap[share.role]
    },
    getSplitMax (item) {
      return item.party.type === PUBLISHER ? "50" : "100"
    },
    getNextLetter () {
      const current = [...this.usedLetters].sort().pop()
      return current ? String.fromCharCode(current.charCodeAt(0) + 1) : "A"
    },
    setInitial () {
      let shares = this.shares
      // Set up the dictionary for chains: We assign a letter to each
      // publisher share if the share contains Chains. e.g.:  { 1: A, 2: B, ... }
      let dictionary = shares
        .filter(s => partyRolesMap[s.role] === PUBLISHER)
        .reduce((dictionary, share, idx) => {
          dictionary[share.id] = String.fromCharCode(97 + idx).toUpperCase()
          return dictionary
        }, {})

      shares.forEach(share => {
        if (partyRolesMap[share.role] === CONTRIBUTOR) {
          // For a contributor, we'll display a letter for each "chained" publisher share as defined
          // in the share-id-to-letter dictionary.
          share.chains = share.chains.map(s => get(dictionary, s)).filter(s => !!s).sort()
        } else {
          // For a publisher, we'll display a letter identifying the publisher share as defined
          // in the share-id-to-letter dictionary.
          share.chains = [get(dictionary, share.id, "-")]
        }
      })

      this.items = shares
      this.usedLetters = values(dictionary)
    },
    isPublisher (role) {
      return publisherRoles.includes(role)
    },
    isContributor (role) {
      return contributorRoles.includes(role)
    }
  },
  props: {
    shares: Array,
    newShares: Array,
    formErrors: Object,
  },
  watch: {
    shares () {
      if (!this.items.length) this.setInitial()
    },
    newShares (shares) {
      let newShare = shares[shares.length-1]
      this.items = [ ...this.items, newShare ]
    },
  },
  mounted () {
    this.setInitial()
  }
}
</script>
