<template>
  <v-server-table
    name="agreementConflicts"
    ref="agreementConflicts"
    :columns="columns"
    :options="options"
    @loaded="onLoaded"
    @pagination="onPagination"
  >
    <!-- Atlas ID -->
    <template v-slot:agreementAtlasId="{ row }">
      <div>{{ row.relatedObject.id }}</div>
    </template>

    <!-- Assignor -->
    <template v-slot:assignor="{ row }">
      <router-link
          :to="{ name: 'parties-detail', params: { id: row.relatedObject.assignor.id } }"
          class="text-info"
        >
        {{ row.relatedObject.assignor.name }}
      </router-link>
      <div
        class="text-small text-gray"
      >
        {{ row.relatedObject.assignor.ipiNameNumber }}
      </div>
    </template>

    <!-- Assignee -->
    <template v-slot:assignee="{ row }">
      <router-link
        :to="{ name: 'parties-detail', params: { id: row.relatedObject.assignee.id } }"
        class="text-info"
      >
        {{ row.relatedObject.assignee.name }}
      </router-link>
      <div
        class="text-small text-gray"
      >
        {{ row.relatedObject.assignee.ipiNameNumber }}
      </div>
    </template>

    <!-- Period -->
    <template v-slot:period="{ row }">
      <!-- Start date-->
      <template v-if="row.relatedObject.startDate">
        <span class="text-gray text-small">{{ $t("from") }}</span>
        <date-time-cell :dateTime="row.relatedObject.startDate" date-only/>
      </template>
      <!-- End date-->
      <template v-if="row.relatedObject.endDate">
        <span class="text-gray text-small">{{ $t("to") }}</span>
        <date-time-cell :dateTime="row.relatedObject.endDate" date-only/>
      </template>
      <!-- Termination date-->
      <template v-if="row.relatedObject.terminationDate">
        <span class="text-gray text-small">{{ $t("termination_date") }}</span>
        <date-time-cell :dateTime="row.relatedObject.terminationDate" date-only/>
      </template>
    </template>

     <!-- Type -->
      <template v-slot:type="{ row }">
        {{ getAgreementTypeDisplay(row.relatedObject.type) }}
      </template>

      <!-- Right Types -->
      <template v-slot:rightTypes="{ row }">
        {{ getRightTypesDisplay(row.relatedObject.rightTypes) }}
      </template>

    <!-- Status -->
    <template v-slot:status="{ row }">
      <status
        v-if="row.status === statuses.DONE"
        :text="$t('done')"
        :icon="['fas', 'check-circle']"
        color="green"
      />
      <status
        v-else-if="row.status === statuses.PENDING"
        :text="$t('pending')"
        :icon="['fas', 'question-circle']"
        color="orange"
      />
    </template>

    <template v-slot:actions="{ row }">
      <router-link :to="{ name: 'actions-agreement-detail', params: { id: row.id } }">
        <action :title="$t('view')" :icon="['far', 'eye']" />
      </router-link>
  </template>
  </v-server-table>
</template>

<script>
import { capitalize, get } from "lodash"
import { getAgreementTypeDisplay, userActionStatuses } from "@/constants"
import { listRouteMixin } from "@/utils/mixins"

export default {
  name: "AgreementConflictsList",
  mixins: [listRouteMixin],
  data () {
    return {
      statuses: userActionStatuses,
      columns: ["agreementAtlasId", "assignor", "assignee", "period", "type", "rightTypes", "status", "actions"],
      options: {
        headings: {
          agreementAtlasId: "",
          assignor: capitalize(this.$t("assignor")),
          assignee: capitalize(this.$t("assignee")),
          period: capitalize(this.$t("period")),
          type: capitalize(this.$t("type")),
          rightTypes: capitalize(this.$t("rights")),
          status: capitalize(this.$t("status")),
          actions: "",
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          return this.$api.userActions.agreementConflictsList(queryParams)
        }
      },
    }
  },
  methods: {
    getAgreementTypeDisplay,
    getData () {
      this.$refs.agreementConflicts.getData()
    },
    onLoaded ({ data }) {
      this.$emit("loaded", data.count)
    },
    getRightTypesDisplay (rightTypes) {
      return rightTypes.join(", ")
    },
  },
  watch: {
    distribution (value) {
      this.applyFilters("distribution", value)
    },
    status (value) {
      this.applyFilters("status", value ? value.value : null)
    },
    showWithUsagesOnly (showWithUsagesOnly) {
      this.applyFilters("with_usages", showWithUsagesOnly)
    },
    currentFilter (filter) {
      let [type, value] = filter
      this.applyFilters(type, value)
    }
  },
  props: {
    distribution: String,
    status: Object,
    showWithUsagesOnly: Boolean,
    currentFilter: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>
