<template>
  <div v-if="!isEmpty">
    <b-row align-v="center">
      <b-col cols="5" class="text-gray">{{ $t('distributable_net') | capitalize }}</b-col>
      <b-col cols="7">
        <span>{{ netDistributable | formatNumber }}</span>
      </b-col>
    </b-row>
     <b-row align-v="center">
      <b-col cols="5" class="text-gray">{{ $t('distributable') | capitalize }}</b-col>
      <b-col cols="7">
        <stats-bar :value="netDistributable" :maximum="totalNet" :label="displayLabelPercentage(netDistributable, totalNet)"/>
      </b-col>
    </b-row>
    <b-row align-v="center">
      <b-col cols="5" class="text-gray">{{ $t('matched') | capitalize }}</b-col>
      <b-col cols="7">
        <stats-bar :value="matched" :maximum="totalUsages" :label="displayLabelPercentage(matched, totalUsages)" />
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <p class="text-gray">{{ $t('no_stats_info_available') | capitalize }}</p>
  </div>
</template>
<script>
import { formatNumber } from "@/utils/numbers"

export default {
  name: "DistributionStats",
  data () {
    return {
      netDistributable: 0.0,
      netNonDistributable: 0.0,
      matched: 0,
      unmatched: 0,
    }
  },
  computed: {
    isEmpty () {
      return this.stats && Object.keys(this.stats).length == 0
    },
    totalUsages () {
      return this.matched + this.unmatched
    },
    totalNet () {
      return this.netDistributable + this.netNonDistributable
    },
  },
  methods: {
    displayLabelPercentage (value, maximum) {
      if (maximum == 0) return "0.00%"

      return `${formatNumber(((value / maximum) * 100).toFixed(2))}%`
    },
    setData () {
      if (!this.isEmpty) {
        const stats = this.stats.summary ? this.stats.summary : this.stats
        const { allocationStats, matchingStats } = stats

        if (allocationStats) {
          this.netDistributable = parseFloat(allocationStats.netDistributable)
          this.netNonDistributable = parseFloat(allocationStats.netNonDistributable)
        }

        if (matchingStats) {
          this.matched = matchingStats.matched
          this.unmatched = matchingStats.unmatched
        }
      }
    }
  },
  watch: {
    stats: {
      handler () {
        this.setData()
      },
      deep: true
    }
  },
  mounted () {
    this.setData()
  },
  props: {
    stats: Object,
  }

}
</script>
