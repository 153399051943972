<template>
  <div>
    <div class="mb-4">
      <b-row align-v="center">
        <b-col cols="8">
          <div class="d-flex">
          <h3 class="mr-3">
            {{ pool.name }}
          </h3>
          <!-- Status -->
          <status
            v-if="!isOpen"
            :text="getDistributionStatusDisplay(pool.status) | capitalize"
            :icon="['fas', 'check-circle']"
            color="green"
          />
          <status
            v-else-if="pool.status === distributionStatuses.ERROR"
            :text="getDistributionStatusDisplay(pool.status) | capitalize"
            :icon="['fas', 'exclamation-circle']"
            color="red"
          />
          <status
            v-else
            :text="getDistributionStatusDisplay(pool.status) | capitalize"
            :icon="['fas', 'question-circle']"
            color="orange"
          />
          </div>
        </b-col>
        <b-col cols="4">
          <!-- Action Buttons -->
          <div class="d-flex justify-content-end my-3">
            <b-button
              class="mr-2"
              variant="outline-secondary"
              @click="$router.go(-1)"
            >
              {{ $t("back_to_distribution") | capitalize }}
            </b-button>
            <b-button
            v-if="pool.status === distributionStatuses.ALLOCATED"
              class="mr-2"
              variant="outline-secondary"
              @click="onUndoAllocation"
            >
              {{ $t("undo_allocation") | capitalize }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- 1st Row -->
    <b-row>
      <b-col cols="6">
        <!-- Name -->
        <label>{{ $t('name') | capitalize }}:</label>
        <p class="text-gray">{{ getFieldText(pool.name) }}</p>
      </b-col>
      <b-col cols="6">
        <!-- Society -->
        <label>{{ $t('society') | capitalize }}:</label>
        <p class="text-gray">{{ getFieldText(getSocietyDisplay(pool.society)) }}</p>
      </b-col>
    </b-row>

    <!-- 2nd Row -->
    <b-row>
      <!-- Code -->
      <b-col cols="3">
        <label>{{ $t('code') | capitalize }}:</label>
        <p class="text-gray">{{ getFieldText(pool.code) }}</p>
      </b-col>
      <!-- Fund ID -->
      <b-col cols="3">
        <label>{{ $t('fund_id') | capitalize }}:</label>
        <p class="text-gray">{{ getFieldText(pool.fundInternalId) }}</p>
      </b-col>
      <!-- Period -->
      <b-col cols="3">
        <label>{{ $t('start_date') | capitalize }}:</label>
        <date-time-cell class="text-gray" :dateTime="pool.startDate" :dateOnly="true"/>
      </b-col>
      <b-col cols="3">
        <label>{{ $t('end_date') | capitalize }}:</label>
        <date-time-cell class="text-gray" :dateTime="pool.endDate" :dateOnly="true"/>
      </b-col>
    </b-row>

    <!-- 3rd Row -->
    <b-row>
      <!-- Gross -->
      <b-col cols="3">
        <label>{{ $t('bank_amount') | capitalize }}:</label>
        <p class="text-gray">{{ getFieldNumber(pool.grossAmount) }}</p>
      </b-col>
      <!-- Deductible -->
      <b-col cols="3">
        <label>{{ $t('deductibles') | capitalize }}:</label>
        <p class="text-gray">{{ getFieldNumber(pool.deductibles) }}</p>
      </b-col>
      <!-- Admin Costs -->
      <b-col cols="3">
        <label>{{ $t('administration_costs') | capitalize }}:</label>
        <p class="text-gray">{{ getFieldNumber(pool.administrationCosts) }}</p>
      </b-col>
      <!-- Net -->
      <b-col cols="3">
        <label>{{ $t('net_distributable_amount') | capitalize }}:</label>
        <p class="text-gray">{{ getFieldNumber(pool.netDistributableAmount) }}</p>
      </b-col>
    </b-row>

    <!-- 4th row -->
    <b-row>
      <!-- Files -->
      <b-col>
        <label>{{ $t('files') | capitalize }}:</label>
        <div v-for="crdFile in pool.crdFiles" :key="crdFile.id">
          <p class="text-gray"><fa-icon size="md" :icon="['fa', 'file']" class="mx-2" />{{ crdFile.filename }}</p>
        </div>
      </b-col>
    </b-row>
    <modal
      id="undo-allocation-confirmation-modal"
      :title="$t('undo_allocation') | capitalize"
      :okTitle="$t('yes') | capitalize"
      :cancelTitle="$t('no') | capitalize"
      @ok="onConfirmUndoAllocation()"
    >
      <div>{{ $t("confirm_undo_allocation") | capitalize }}</div>
    </modal>
  </div>
</template>

<style scoped>
  label {
    font-weight: bold;
    font-size: 1.1rem;
  }

  p {
    font-size: 1rem;
  }
</style>

<script>
import { distributionStatuses, getDistributionStatusDisplay, poolsFormType } from "@/constants"
import { cisacSocieties } from "@/constants"
import { formatNumber } from "@/utils/numbers"

export default {
  name: "CRDPoolsDetail",
  data () {
    return {
      distributionStatuses,
      getDistributionStatusDisplay,
      pool: {
        name: "",
        fundInternalId: "",
        startDate: null,
        endDate: null,
        grossAmount: null,
        deductibles: null,
        administrationCosts: null,
        netDistributableAmount: null,
        files: [],
      },
    }
  },
  computed: {
    isOpen () {
      return ![distributionStatuses.CLOSED, distributionStatuses.PUBLISHED].includes(this.pool.status)
    }
  },
  methods: {
    getFieldText (field) {
      return field || "-"
    },
    getFieldNumber (field) {
      return field ? formatNumber(field) : "-"
    },
    getSocietyDisplay (code) {
      const societyName = cisacSocieties[code]
      return societyName ? `${code} - ${societyName}` : ""
    },
    onUndoAllocation () {
      this.$bvModal.show("undo-allocation-confirmation-modal")
    },
    onConfirmUndoAllocation () {
      this.$api.distributions.distributionsPoolsUnallocate(this.id, this.pid)
        .then(() => {
          this.success(this.$t("undo_allocation_success"))
          this.$emit("reload-data")
        }).catch(error => {
          if (error.response.data && error.response.data.detail) this.error(error.response.data.detail)
        })
    },
    async loadData () {
      const response = await this.$api.distributions.distributionPoolsRetrieve(this.id, this.pid, poolsFormType.CRD)
      this.pool = {
        ...response.data,
      }
    },
  },
  async mounted () {
    // Retrieve a pool if a pool ID is passed as prop
    if (this.pid) {
      this.loadData()
    }
  },
  props: {
    id: String, // Distrbution ID
    pid: String, // Pool ID
  }
}
</script>
