<template>
  <div>
    <div v-for="(iswc, i) in iswcs" :key="i" class="mb-2">
      <b-row>
        <b-col>
          <p class="my-0">{{ iswc.value }}</p>
          <small class="my-0">{{ $t("preferred") | capitalize }}: {{ iswc.preferred }}</small>
        </b-col>
        <b-col cols="3">{{ getWorkFieldActionTypesDisplay(iswc.action) | capitalize }}</b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { getWorkFieldActionTypesDisplay } from "@/constants"

export default {
  name: "ISWCUpdate",
  methods: {
    getWorkFieldActionTypesDisplay
  },
  props: {
    iswcs: Array,
  }
}
</script>