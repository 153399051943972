// Styles
import "@/styles/project.scss"

//Filters
import "@/utils/filters"

// Forms validators
import "@/utils/form-validation"

/* eslint-disable sort-imports */
// Components
import App from "./App"
import {
  ActionButton,
  DateField,
  DateTimeCell,
  DistributionFilter,
  TypeAndValueInput,
  FilesSelector,
  Filters,
  FormField,
  FormSelect,
  FormTextArea,
  IPISearch,
  IngestionStats,
  ItemCount,
  LanguageSelector,
  MatchingStats,
  Modal,
  MultiSelectField,
  PageSection,
  PartySearch,
  PayeeSharesTable,
  RadioPillToggle,
  RowExtraInfo,
  SearchInput,
  SelectionSummary,
  SharesTable,
  SideBar,
  SocietySelect,
  SocietySelector,
  SplitInput,
  Status,
  StatsBar,
  SubmitButton,
  Action,
  Tag,
  TopBar,
  UploadFileModal,
  VerticalTimeline,
  WorksTableLite,
} from "./components"

import { Detail } from "@/pages/WorksDetail"

import { ClientTable, ServerTable } from "vue-tables-2"

// Layouts
import {
  ListLayout,
  LoggedInLayout,
  TabsLayout,
  WorkDetailLayout,
} from "./layouts"

// Utils
import { API } from "./services/api"
import { store } from "./store"
import { Authentication, i18n, router } from "./utils"
/* eslint-enable */

// Third-parties
import { config, dom, library } from "@fortawesome/fontawesome-svg-core"
import {
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faArrowLeft,
  faArrowUp,
  faBan,
  faBarcode,
  faBroadcastTower,
  faBullhorn,
  faCalendarAlt,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faCloudUploadAlt,
  faComments,
  faCopy,
  faDatabase,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faFile,
  faFileAlt,
  faFileDownload,
  faFileImport,
  faFileInvoiceDollar,
  faFunnelDollar,
  faHandPaper,
  faHandshake,
  faHistory,
  faIdCard,
  faLevelUpAlt,
  faList,
  faListAlt,
  faMusic,
  faPauseCircle,
  faPen,
  faPlus,
  faPlusCircle,
  faPowerOff,
  faQuestionCircle,
  faSearch,
  faSlidersH,
  faSort,
  faSortDown,
  faSortUp,
  faSyncAlt,
  faTable,
  faTimes,
  faTimesCircle,
  faTrash,
  faUndo,
  faUnlink,
  faUpload,
  faUser,
  faUsers,
  faVideo,
} from "@fortawesome/free-solid-svg-icons"
import { faClock, faEye, faTrashAlt, faUserCircle } from "@fortawesome/free-regular-svg-icons"
import BootstrapVue from "bootstrap-vue"
import DatePicker from "vue2-datepicker"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import Multiselect from "vue-multiselect"
import Vue from "vue"
import VueRouter from "vue-router"
import { faCreativeCommonsPd } from "@fortawesome/free-brands-svg-icons"
import { get } from "lodash"
// FontAwesome Icons

import vClickOutside from "v-click-outside"

import vue2Dropzone from "vue2-dropzone"

// Configuration from Environment Variables
Vue.prototype.$config = {
  VERSION: get(process.env, "VUE_APP_VERSION"),
  DATE_FORMAT: get(process.env, "VUE_APP_DATE_FORMAT", "DD-MM-YYYY"),
  ISO_DATE_FORMAT: "YYYY-MM-DD",
  MONTH_YEAR_DATE_FORMAT: "MMM-YYYY",
  ATLAS_REPERTOIRE_TEMPLATE_URL: get(process.env, "VUE_APP_ATLAS_REPERTOIRE_TEMPLATE_URL"),
  ATLAS_AGREEMENTS_TEMPLATE_URL: get(process.env, "VUE_APP_ATLAS_AGREEMENTS_TEMPLATE_URL"),
}

/** Third-party plugins and components **/

Vue.use(BootstrapVue) // We encourage to use VueBootstrap components versus classes.
Vue.use(VueRouter)
Vue.use(vClickOutside)

let options = {
  perPage: 10,
  filterable: true,
  skin: "table table-striped",
  sortable: [],
  sortIcon: {
    base: "fas",
    is: "fa-sort",
    up: "fa-sort-up",
    down: "fa-sort-down"
  },
  texts: {
    filterPlaceholder: `${i18n.t("search")}...`,
  },
  pagination: {
    dropdown: false,
  }
}
Vue.use(ClientTable, options, false, "bootstrap4")
Vue.use(ServerTable, options, false, "bootstrap4")

// FontAwesome Icons. Read the docs, specially:
// https://github.com/FortAwesome/vue-fontawesome#why-use-the-concept-of-a-library
// Check available icons: https://fontawesome.com/v5/search?o=r&m=free
library.add(
  faAngleUp,
  faAngleDown,
  faAngleRight,
  faArrowUp,
  faArrowLeft,
  faBan,
  faBarcode,
  faBroadcastTower,
  faBullhorn,
  faCalendarAlt,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faClock,
  faCloudUploadAlt,
  faComments,
  faCopy,
  faCreativeCommonsPd,
  faDatabase,
  faExchangeAlt,
  faFile,
  faFileAlt,
  faFileDownload,
  faFileInvoiceDollar,
  faQuestionCircle,
  faLevelUpAlt,
  faList,
  faListAlt,
  faMusic,
  faExclamationCircle,
  faExclamationTriangle,
  faFunnelDollar,
  faHandPaper,
  faHandshake,
  faFileImport,
  faIdCard,
  faHistory,
  faEye,
  faTable,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faUnlink,
  faUserCircle,
  faUser,
  faUsers,
  faPauseCircle,
  faPen,
  faPowerOff,
  faPlus,
  faPlusCircle,
  faSearch,
  faSlidersH,
  faSort,
  faSortDown,
  faSortUp,
  faSyncAlt,
  faUndo,
  faUpload,
  faVideo,
)

/**
 * The following lines solve the issue of showing icons for sortable columns in tables.
 * This solution is presented here: https://github.com/matfish2/vue-tables-2/issues/441
 *
 * Setting this config so that Vue-tables-2 will be able to replace sort icons with chevrons
 * https://fontawesome.com/how-to-use/with-the-api/setup/configuration
 */
config.autoReplaceSvg = "nest"

/**
 * Allows DOM to change <i> tags to SVG for more features like layering
 * https://fontawesome.com/how-to-use/on-the-web/styling/layering
 */
dom.watch()

Vue.component("fa-icon", FontAwesomeIcon)

// https://github.com/mengxiong10/vue2-datepicker
Vue.component("date-picker", DatePicker)
// https://vue-multiselect.js.org/
Vue.component("multi-select", Multiselect)

Vue.component("vue2-dropzone", vue2Dropzone)

/** Our plugins and components **/

// Authentication plugin. Exposes this.$auth.
Vue.use(Authentication)
// API plugin. Exposes this.$api.
Vue.use(API)

// Components
Vue.component("action", Action)
Vue.component("action-button", ActionButton)
Vue.component("date-field", DateField)
Vue.component("date-time-cell", DateTimeCell)
Vue.component("work-detail", Detail)
Vue.component("distribution-filter", DistributionFilter)
Vue.component("files-selector", FilesSelector)
Vue.component("filters", Filters)
Vue.component("form-field", FormField)
Vue.component("form-select", FormSelect)
Vue.component("form-textarea", FormTextArea)
Vue.component("party-search", PartySearch)
Vue.component("ipi-search", IPISearch)
Vue.component("ingestion-stats", IngestionStats)
Vue.component("item-count", ItemCount)
Vue.component("language-selector", LanguageSelector)
Vue.component("matching-stats", MatchingStats)
Vue.component("modal", Modal)
Vue.component("multi-select-field", MultiSelectField)
Vue.component("page-section", PageSection)
Vue.component("payee-shares-table", PayeeSharesTable)
Vue.component("radio-pill-toggle", RadioPillToggle)
Vue.component("row-extra-info", RowExtraInfo)
Vue.component("search-input", SearchInput)
Vue.component("selection-summary", SelectionSummary)
Vue.component("shares-table", SharesTable)
Vue.component("side-bar", SideBar)
Vue.component("society-select", SocietySelect)
Vue.component("society-selector", SocietySelector)
Vue.component("split-input", SplitInput)
Vue.component("stats-bar", StatsBar)
Vue.component("status", Status)
Vue.component("submit-button", SubmitButton)
Vue.component("tag", Tag)
Vue.component("top-bar", TopBar)
Vue.component("type-and-value-input", TypeAndValueInput)
Vue.component("file-upload-modal", UploadFileModal)
Vue.component("vertical-timeline", VerticalTimeline)
Vue.component("works-table-lite", WorksTableLite)

// Layouts
Vue.component("tabs-layout", TabsLayout)
Vue.component("logged-in-layout", LoggedInLayout)
Vue.component("list-layout", ListLayout)
Vue.component("work-detail-layout", WorkDetailLayout)

export const app = new Vue({
  el: "#app",
  router,
  store,
  i18n,
  render: h => h(App)
})
