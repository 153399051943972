<template>
  <div>
    <h3>
        {{ title }}
    </h3>

    <div v-if="conflictExists">
      <div>{{ $t('confirm_create_agreement') | capitalize }}</div>
      <ul>
        <li v-for="agreement in conflictingAgreements" :key="agreement.id">
          <router-link
            :to="{ name: 'agreements-update', params: { agreementId: agreement.id } }"
            target="_blank"
          >
            {{ agreement.id }}
          </router-link>
        </li>
      </ul>
      <div class="d-flex justify-content-end">
        <b-button variant="secondary" class="mr-2" @click="onForceCreate">
          {{ $t('create') }}
        </b-button>
      </div>
    </div>

    <component v-show="!conflictExists" :is="formComponent" v-bind="formProps" @submit="onSubmit"></component>
  </div>
</template>

<script>
import { capitalize, get } from "lodash"
import { mapActions, mapMutations } from "vuex"
import AgreementsCreate from "./AgreementsCreate"
import AgreementsUpdate from "./AgreementsUpdate"

export default {
  name: "AgreementsDetail",
  components: {
    AgreementsCreate,
    AgreementsUpdate,
  },
  data () {
    return {
      conflictExists: false,
      conflictingAgreements: [],
      forceCreate: false,
      formComponent: null,
      formProps: {},
      formData: null,
      agreementId: "",
    }
  },
  methods: {
    ...mapActions("alert", ["success", "error"]),
    ...mapMutations("consts", ["setRightTypes"]),
    onForceCreate () {
      this.conflictExists	 = false
      this.forceCreate = true
      this.onSubmit(this.formData)
    },
    onCancel () {
      this.conflictExists	 = false
      this.$router.go(-1)
    },
    createAgreement (data) {
      let params = null

      if (this.forceCreate) params = { force: true }

      this.$api.agreements.agreementsCreate(data, params)
        .then(() => {
          this.success(this.$t("agreement_created"))
          this.goBack()
        })
        .catch(error => {
          let data = error.response.data
          if (data.conflict) {
            this.conflictingAgreements = data.agreements
            this.conflictExists = true
            return
          }
          if (data.detail) this.error(data.detail)
          this.formProps = { ...this.formProps, errors: data }
        })
    },
    updateAgreement (data) {
      this.$api.agreements.agreementsUpdate(this.agreementId, data).then(() => {
        this.success(this.$t("agreement_updated"))
        this.goBack()
      }).catch(error => {
        if (error.response.data && error.response.data.detail) this.error(error.response.data.detail)
        this.formProps = { ...this.formProps, errors: error.response.data }
      })
    },
    onSubmit (data) {
      this.formData = data

      if (this.agreementId) {
        // Update
        this.updateAgreement(data)
      } else {
        // Create
        this.createAgreement(data)
      }
    },
    goBack () {
      this.$router.go(-1)
    },
  },
  computed: {
    title () {
      let agreement = get(this.formProps, "agreement")

      if (agreement) {
        return `${ capitalize(this.$t("agreement")) } ${ agreement.id }`
      } else {
        return capitalize(this.$t("create_agreement"))
      }
    }
  },
  mounted () {
    // Create
    if (this.$route.name === "agreements-create") {
      this.formComponent = "AgreementsCreate"
    }

    // Update
    if (this.$route.name === "agreements-update") {
      let { agreementId } = this.$route.params
      this.agreementId = agreementId

      this.formComponent = "AgreementsUpdate"
      this.$api.agreements.agreementsRetrieve(agreementId)
        .then(response => {
          this.formProps = { agreement: response.data }
        })
    }

    this.$api.rights.rightsList()
      .then(response => this.setRightTypes(response.data))
  },
}
</script>
