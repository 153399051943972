<template>
  <div>
    <div v-for="(share, i) in shares" :key="i" class="mb-2">
      <b-row>
        <b-col>
          <b-row class="flex-nowrap">
            <b-col cols="1">{{ share.chains.join(", ") || "-" }}</b-col>
            <b-col>
              <p class="my-0">{{ share.party.name }}</p>
              <small class="my-0 d-block">{{ share.party.ipiNameNumber }}</small>
              <small class="my-0">{{ $t("role") | capitalize }}: {{ share.role }}</small>
            </b-col>
            <b-col cols="3">PER: {{ share.performing }}</b-col>
            <b-col cols="3">MEC: {{ share.mechanical }}</b-col>
          </b-row>
        </b-col>
        <b-col cols="3">{{ getWorkFieldActionTypesDisplay(share.action) | capitalize }}</b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { getWorkFieldActionTypesDisplay } from "@/constants"

export default {
  name: "SharesUpdate",
  methods: {
    getWorkFieldActionTypesDisplay,
  },
  props: {
    shares: Array,
  }
}
</script>