var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.userActionStatus !== _vm.userActionStatuses.DONE)?_c('div',{staticClass:"d-flex justify-content-end mt-4 mb-2"},[_c('b-button',{staticClass:"float-right ml-2",attrs:{"size":"sm"},on:{"click":_vm.goToAgreementDetail}},[_c('fa-icon',{staticClass:"mr-1",attrs:{"size":"sm","icon":['fas', 'pen']}}),_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t("edit")))+" ")],1)],1):_vm._e(),_c('div',{staticClass:"work-details-container"},[(_vm.agreement)?_c('b-table-lite',{attrs:{"fields":_vm.agreementFields,"items":[_vm.agreement],"responsive":"","borderless":""},scopedSlots:_vm._u([{key:"cell(atlas_id)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"cell(assignor)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.assignor.name)+" ")]}},{key:"cell(assignee)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.assignee.name)+" ")]}},{key:"cell(rightTypes)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRightTypesDisplay(item.rightTypes))+" ")]}}],null,false,394647003)}):_vm._e(),(_vm.agreement)?_c('b-table-lite',{attrs:{"fields":_vm.extraFields,"items":[_vm.agreement],"responsive":"","borderless":""},scopedSlots:_vm._u([{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAgreementTypeDisplay(item.type))+" ")]}},{key:"cell(exclude)",fn:function(ref){
var item = ref.item;
return [(item.type === _vm.agreementTypes.SPECIFIC)?_c('div',[_c('span',{staticClass:"mx-3"},[_vm._v(_vm._s(_vm.getInclusionFlagDisplay(item.inclusionFlag)))])]):_c('div',{staticClass:"ml-3"},[_vm._v("-")])]}},{key:"cell(works)",fn:function(ref){
var item = ref.item;
return [(item.type === _vm.agreementTypes.SPECIFIC && item.works.length)?_c('div',_vm._l((item.works),function(work){return _c('p',{key:work.id,staticClass:"text-small mb-1"},[_vm._v(_vm._s(work.title))])}),0):_c('div',{staticClass:"ml-3"},[_vm._v("-")])]}}],null,false,2960412028)}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }