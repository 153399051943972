<template>
  <form-field
    type="number"
    step="0.01"
    min="0"
    :max="max"
    v-bind="{ ...$attrs, ...$props }"
    :customFormatter="formatter"
    @input="onInput"
    lazyFormatter
    required
    :value="value"
  />
</template>

<script>


export default {
  name: "SplitInput",
  methods: {
    formatter (value) {
      return Number(value).toFixed(2)
    },
    onInput (value) {
      this.$emit("input", value)
    },
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    value: [String, Number],
    max: {
      type: [String, Number],
      default: "100",
    },
  },
}
</script>