<template>
  <b-table :items="items" :fields="visibleFields" thead-class="d-none" striped borderless class="sheets-table">
    <!-- Name -->
    <template v-slot:cell(name)="{ item }">
      <b-col class="d-flex align-items-center ml-2">
        <b-checkbox
          v-if="!isSelectedTable"
          v-model="selectedSheets"
          :value="item.id"
          class="ml-0 pl-0"
          @change="onChange($event, item)"
        />
        <fa-icon size="lg" :icon="['fa', 'table']" class="sheet-icon mx-2" />
        <router-link
          :to="{ 'name': 'usages-list', 'params': { 'id': item.id } }"
          class="text-info"
        >
          {{ item.name }}
        </router-link>
      </b-col>
    </template>
    <template v-slot:cell(action)="{ item }">
      <action
        v-if="isSelectedTable"
        :title="$t('detach_sheet')"
        @click="onChange($event, item)"
        :icon="['fas', 'times']"
        color="red"
      />
    </template>
  </b-table>
</template>

<style lang="scss">
  .sheet-icon {
    color: $info;
  }

  .stats {
    font-size: 0.8rem;
  }

  .sheets-table {
    border-bottom: 1px solid $gray-dark;
    font-size: 0.9rem;
    font-weight: 300;
    color: var(--gray);
  }

  .selection-column {
    width: 55px !important;
    padding: 0;
  }
</style>

<script>
import { fileStatuses, getUsageTypeDisplay } from "@/constants"
import { EventBus } from "@/utils/event-bus.js"


export default {
  name: "FileSheetSelector",
  data () {
    return {
      fileStatuses,
      selectedSheets: [],
      fields: [
        { key: "selection", class: "selection-column"  },
        { key: "name", class: "text-break pl-3" },
        { key: "action", class: "selection-column" },
      ],
    }
  },
  computed: {
    isSelectedTable () {
      return this.parentTable === "selectedTable"
    },
    visibleFields () {
      let hiddenField = this.isSelectedTable ? "selection" : "action"
      return this.fields.filter(f => f.key !== hiddenField)
    }
  },
  methods: {
    getUsageTypeDisplay,
    onChange (value, item) {
      if (this.isSelectedTable) return EventBus.$emit("detach-sheet", item, this.file)
      EventBus.$emit("select-sheet", value, this.file)
    },
  },
  watch: {
    selected (value) {
      this.selectedSheets = value
    }
  },
  mounted () {
    this.selectedSheets = this.selected
  },
  props: {
    file: Object,
    items: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
    parentTable: String
  }
}
</script>