const state = {
  search: null,
  filters: {},
}

const mutations = {
  setSearch (state, search) {
    state.search = search
  },
  setFilters (state, filters) {
    state.filters = filters
  },
  clear (state) {
    state.search = null
    state.filters = {}
  }
}

const actions = {
  clearFilters ({ commit }) {
    commit("clear")
  },
  addFilter ({ commit, state }, filter) {
    let filters = { ...state.filters, ...filter }
    commit("setFilters", filters)
  },
}

export const filters = {
  namespaced: true,
  state,
  actions,
  mutations
}