<template>
  <page-section :title="$t('sources') | capitalize" collapsible>
    <template v-slot:body>
      <div v-if="sources.length">
        <b-table-lite :fields="fields" :items="sources" borderless>
          <template v-slot:cell(source)="{ item }">
            <!-- File Source -->
            <template v-if="fileSourceTypes.includes(item.sourceType)">
              <router-link
                v-if="item.canView"
                class="text-info"
                :to="{ name: 'landing-works-list', params: { id: item.repertoireFile.id } }"
              >
                {{ item.repertoireFile.filename }}
              </router-link>
              <span v-else>
                {{ item.repertoireFile.filename }}
              </span>
              ({{ getWorkSourceTypesDisplay(item.sourceType) }})
              <date-time-cell :dateTime="item.repertoireFile.uploadDate"/>
            </template>
            <!-- Other Source Types -->
            <template v-else>
              {{ getWorkSourceTypesDisplay(item.sourceType) }}
            </template>
          </template>

          <!-- Actions -->
          <template v-slot:cell(actions)="{ item }">
            <!-- Demerge -->
            <b-button
              class="mr-2 float-right"
              variant="outline-secondary"
              v-if="sources.length > 1 && canEdit"
              @click="onDemerge(item)"
            >
              {{ $t("demerge") | capitalize }}
            </b-button>

            <!-- View Source (Landing Work) -->
            <action
              v-if="item.canView"
              @click="displayMetadataInfo(item)"
              :title="$t('show_source_metadata')"
              :icon="['far', 'eye']"
            />
            <b-modal
              :id="'show-source-metadata-modal-' + item.id"
              :title="$t('show_source_metadata') | capitalize"
              hide-footer
              size="xl"
            >
               <work-detail :work="landingWork" :shares="shares" lite />
            </b-modal>
          </template>
        </b-table-lite>

        <modal
          id="demerge-confirmation-modal"
          :title="$t('confirm_demerge_work') | capitalize"
          @ok="onConfirmDemerge"
          @hidden="onConfirmDemergeHidden"
        >
          <div v-if="selectedSource">
            <p>{{ $t("demerge_message") | capitalize }}</p>
            <span class="font-weight-bold">{{ selectedSource.title }}</span>
            <p>
              {{ $t("from") | capitalize }}:
              <span class="font-weight-bold">{{ getDemergeSource() }}</span>
            </p>
          </div>
        </modal>

        <modal
          id="demerged-work-modal"
          :title="$t('demerged_work') | capitalize"
          @hide="resetWork"
          hide-footer
        >
          <div v-if="demergedWorkId">
            {{ $t("work_successfully_demerged") | capitalize }}
            <router-link
              @click.native="onGoToMergedWork"
              :to="{ name: 'works-detail', params: { id: demergedWorkId }}"
              class="text-info"
            >
              {{ $t("go_to_work") }}
            </router-link>
          </div>
        </modal>
      </div>
      <div v-else class="text-center">
        <p>{{ $t("no_sources_found") }}</p>
      </div>
    </template>
  </page-section>
</template>

<script>
import { getWorkSourceTypesDisplay, workSourceTypes } from "@/constants"
import { mapActions, mapState } from "vuex"
import { EventBus } from "@/utils/event-bus.js"
import { capitalize } from "lodash"
import { landingWorkTransformations } from "@/utils/mixins"

export default {
  name: "Sources",
  mixins: [landingWorkTransformations],
  data () {
    return {
      workSourceTypes,
      fields: [
        { key: "title", label: capitalize(this.$t("title")) },
        { key: "iswc", label: "ISWC" },
        { key: "proprietaryId", label: capitalize(this.$t("proprietary_id")) },
        { key: "party", label: capitalize(this.$t("submitted_by")) },
        { key: "source", label: capitalize(this.$t("source")) },
        { key: "actions", label: "" },
      ],
      selectedSource: null,
      demergedWorkId: null,
      landingWork: {},
      shares: {}
    }
  },
  computed: {
    ...mapState("filters", ["search"]),
    ...mapState("consts", ["languages"]),
    fileSourceTypes () { return [workSourceTypes.CWR_V2_1, workSourceTypes.ATLAS_SWR_V0_1] } 
  },
  methods: {
    ...mapActions("alert", ["success", "error"]),
    getWorkSourceTypesDisplay,
    resetWork () {
      EventBus.$emit("reset-work", this.workId)
    },
    onGoToMergedWork () {
      this.$bvModal.hide("demerged-work-modal")
    },
    getLanguage (code) {
      let language = this.languages.filter(lang => lang.code === code).pop()
      return language?.name
    },
    getDemergeSource () {
      let source = ""

      if (this.fileSourceTypes.includes(this.selectedSource.sourceType)) {
        source = this.selectedSource.repertoireFile.filename
      } else {
        source = getWorkSourceTypesDisplay(this.selectedSource.sourceType)
      }

      return source
    },
    onDemerge (item) {
      this.selectedSource = { ...item }
      this.$bvModal.show("demerge-confirmation-modal")
    },
    onConfirmDemerge () {
      let data = { sourceId: this.selectedSource.id }
      this.$api.repertoire.worksDemerge(this.workId, data)
        .then(({ data }) => {
          this.demergedWorkId = data.workId
          this.$bvModal.show("demerged-work-modal")
        }).catch(err => {
          this.error(err?.response?.data)
        })
    },
    onConfirmDemergeHidden () {
      this.selectedSource = null
    },
    displayMetadataInfo (item) {
      this.$api.repertoire.landingWorksRetrieve(item.id)
        .then(({ data }) => {
          this.landingWork = this.transformLandingWork(data)
          this.shares = this.getShares(this.landingWork)
          this.$bvModal.show(`show-source-metadata-modal-${item.id}`)
        }).catch(error => {
          if (error.response.data && error.response.data.detail) this.error(error.response.data.detail)
        })
    },
  },
  props: {
    sources: {
      type: Array,
      default: () => [],
    },
    workId: [String, Number],
    canEdit: Boolean,
  }
}
</script>
