import Vue from "vue"

import { agreements } from "./agreements.service"
import { auth } from "./auth.service"
import { countries } from "./countries.service"
import { crd } from "./crd.service"
import { digital } from "./digital.service"
import { distributions } from "./distributions.service"
import { parties } from "./parties.service"
import { products } from "./products.service"
import { repertoire } from "./repertoire.service"
import { rights } from "./rights.service"
import { shares } from "./shares.service"
import { societies } from "./societies.service"
import { usages } from "./usages.service"
import { userActions } from "./useractions.service"
import { users } from "./users.service"
import { vod } from "./vod.service"

let api = new Vue({
  data () {
    return {
      agreements,
      auth,
      countries,
      crd,
      digital,
      distributions,
      parties,
      products,
      repertoire,
      rights,
      shares,
      societies,
      usages,
      userActions,
      users,
      vod,
    }
  },
})

export const API = {
  install (Vue) {
    Vue.prototype.$api = api
  }
}
