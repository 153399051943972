<template>
  <b-badge pill class="mr-2 tag text-primary text-wrap">
    <fa-icon class="mx-1" :icon="icon"/>
    {{ text }}
  </b-badge>
</template>

<style lang="scss" scoped>
  .tag {
    background-color: var(--info-light);
  }
</style>
<script>
export default {
  name: "Tag",
  props: {
    text: String,
    icon: Array,
  },
}
</script>