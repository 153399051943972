var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-server-table',{ref:"nonIpiList",attrs:{"name":"nonIpiList","columns":_vm.columns,"options":_vm.options},on:{"loaded":_vm.onLoaded,"pagination":_vm.onPagination},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"text-info",attrs:{"to":{ name: 'works-detail', params: { id: row.work.id } }}},[_vm._v(" "+_vm._s(row.work.title)+" ")])]}},{key:"id",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(row.work.id)+" ")])]}},{key:"iswc",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(row.work.iswc)+" ")])]}},{key:"contributors",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.contributorNames(row.work))+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.status === _vm.statuses.DONE)?_c('status',{attrs:{"text":_vm.$t('done'),"icon":['fas', 'check-circle'],"color":"green"}}):(row.status === _vm.statuses.PENDING)?_c('status',{attrs:{"text":_vm.$t('pending'),"icon":['fas', 'question-circle'],"color":"orange"}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }