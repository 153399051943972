<template>
  <list-layout :title="$t('distributions') | capitalize" :count="count">
    <template v-slot:table>
      <v-server-table
        :columns="columns"
        :options="options"
        ref="distributions"
        @loaded="onLoaded"
        @pagination="onPagination"
      >
        <!-- Name -->
        <template v-slot:name="{ row }">
          {{ row.name }}
        </template>

         <!-- Published Date -->
        <template v-slot:publishedDate="{ row }">
          <date-time-cell :dateTime="row.publishedDate" />
        </template>

        <!-- Actions -->
         <template v-slot:actions="{ row }">
          <div class="d-flex flex-row justify-content-end align-items-center">
            <!-- Generate report -->
            <action
              v-if="row.reportStatus === reportStatuses.NON_EXISTENT"
              :title="row.hasAllocations ? $t('member_reports_request') : $t('no_payments_for_you')"
              @click="onDownloadMemberReport(row)"
              :icon="['fas', 'file']"
            />
            <fa-icon
              v-else-if="row.reportStatus === reportStatuses.IN_QUEUE"
              :title="$t('queued_for_processing')"
              :icon="['far', 'clock']"
              class="mx-2"
            />
            <fa-icon
              v-else-if="row.reportStatus === reportStatuses.PROCESSING"
              :title="$t('member_reports_processing')"
              :icon="['fas', 'sync-alt']"
              class="mx-2"
            />
            <action
              v-else-if="row.reportStatus === reportStatuses.GENERATED"
              :title="$t('member_reports_download')"
              @click="onDownloadMemberReport(row)"
              :icon="['fas', 'file-download']"
            />
          </div>
        </template>
      </v-server-table>
    </template>

    <b-modal
      id="info-modal"
      hide-header
      size="lg"
      centered
      ok-only
      :okTitle="$t('close') | capitalize"
      class="p-4 d-flex justify-content-center"
    >
      <b-container class="text-center mt-4">
        <div>{{ $t("no_distribution_reports_for_member") | capitalize }}</div>
      </b-container>
    </b-modal>
  </list-layout>
</template>

<style lang="scss" scoped>
  .gray {
    color: var(--muted-gray);
  }
</style>

<script>
import { get, startCase } from "lodash"
import { clickOnUrl } from "@/services/utils"
import { listRouteMixin } from "@/utils/mixins"
import { mapMutations } from "vuex"
import { reportStatuses } from "@/constants"

export default {
  name: "MemberDistributionsList",
  mixins: [listRouteMixin],
  data () {
    return {
      count: 0,
      columns: ["name", "publishedDate", "actions"],
      options: {
        headings: {
          name: startCase(this.$t("name")),
          publishedDate: startCase(this.$t("published_date")),
          actions: "",
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          return this.$api.distributions.distributionsList(queryParams)
        }
      },
      reportStatuses,
    }
  },
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    getData () {
      this.$refs.distributions.getData()
    },
    onLoaded ({ data }) {
      this.count = data.count
    },
    onDownloadMemberReport (row) {
      if (!row.hasAllocations) {
        this.$bvModal.show("info-modal")
        return
      }
      this.$api.distributions.distributionsDownloadMembersReport(row.id)
        .then(({ data }) => {
          if (data.url) clickOnUrl(data.url)
          else this.success(this.$t("request_sent"))
        })
        .catch((err) => {
          let message = err.response.data?.message || err.response.data || err.response
          this.error(message)
        })
    },
  }
}
</script>
