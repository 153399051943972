<template>
  <tabs-layout
    :tabs="tabs"
    @change="onTabChange"
    v-model="tabIndex"
    :title="$t('user_actions')"
    :key="tabs.toString()"
  >
    <!-- Tab Title -->
    <template v-slot:title="{ tab }">
      {{ getTabTitle(tab) | capitalize }}
      <b-badge class="ml-2 text-white" pill>
        {{ getActionsCount(tab) }}
      </b-badge>
    </template>

    <!-- Tab Content -->
    <template v-slot:content>
      <list-layout>
        <template v-slot:filters>
          <div class="d-flex align-items-center justify-content-between w-100 mx-3">
            <!-- Search -->
            <div class="d-flex w-50">
              <!-- Title Search -->
              <search-input
                class="w-50 pr-1"
                v-if="displayFilter(FILTERS.WORK_TITLE)"
                :placeholder="$t('search_by_title') | capitalize"
                :label="$t('title') | capitalize"
                :value="titleSearch"
                @search="onTitleSearch"
              />
              <!-- Code Search -->
              <search-input
                class="w-50 pr-1"
                v-if="displayFilter(FILTERS.WORK_CODE)"
                :placeholder="$t('search_by_iswc_atlas_id_or_work_code') | upperfirst"
                :label="$t('work_code') | capitalize"
                :value="codeSearch"
                @search="onCodeSearch"
              />
            </div>

            <div class="d-flex align-items-center justify-content-end mr-2">
              <!-- Only Domestic -->
              <b-form-checkbox v-if="displayFilter(FILTERS.SHOW_DOMESTIC)" v-model="showDomesticOnly" switch>
                {{ $t("only_domestic") | capitalize }}
              </b-form-checkbox>

              <!-- Only With Usages -->
              <b-form-checkbox v-if="displayFilter(FILTERS.SHOW_USAGE_ONLY)" v-model="showWithUsagesOnly" class="ml-2" switch>
                {{ $t("only_with_usages") | capitalize }}
              </b-form-checkbox>
            </div>
          </div>
        </template>

        <template v-slot:search>
          <div v-if="displayFilter(FILTERS.TAG)" class="mx-3">
            <TagFilters @filter="onTagsFilter" :initFilters="initialTagFilters" />
          </div>
          <div class="d-flex flex-wrap mx-3">
            <!--Distribution -->
            <distribution-filter
              v-if="displayFilter(FILTERS.DISTRIBUTION)"
              name="distribution"
              :label="$t('distribution') | capitalize"
              v-model="distribution"
              choiceLabel="name"
              :placeholder="$t('search_and_select') | capitalize"
              class="pr-1 pl-0 col-sm-3"
            />

            <!--Right type -->
            <multi-select-field
              id="rightType"
              v-if="displayFilter(FILTERS.RIGHTS)"
              v-model="rightType"
              :allowEmpty="true"
              name="rightType"
              :options="rightTypeOptions"
              :label="$t('rights')"
              choiceLabel="display"
              track-by="value"
              :showLabels="false"
              class="pr-1 pl-0 col-sm-3"
            />

            <!-- Reason -->
            <multi-select-field
              id="reason"
              v-if="displayFilter(FILTERS.REASON)"
              v-model="reason"
              :allowEmpty="true"
              name="reason"
              :options="reasonOptions"
              :label="$t('reason')"
              choiceLabel="display"
              track-by="value"
              :showLabels="false"
              class="pr-1 pl-0 col-sm-3"
            />

            <!-- Status -->
            <multi-select-field
              id="status"
              v-if="displayFilter(FILTERS.STATUS)"
              class="pr-1 pl-0 col-sm-3"
              v-model="status"
              name="status"
              :options="userActionStatusOptions"
              :label="$t('status')"
              choiceLabel="display"
              track-by="value"
              :showLabels="false"
            />

            <!--Resolution -->
            <multi-select-field
              id="resolution"
              v-if="status && status.value === userActionStatuses.DONE && displayFilter(FILTERS.RESOLUTION)"
              v-model="resolution"
              :allowEmpty="true"
              name="resolution"
              :options="resolutionOptions"
              :label="$t('resolution')"
              choiceLabel="display"
              track-by="value"
              :showLabels="false"
              class="pr-1 pl-0 col-sm-3"
            />
          </div>
        </template>
        <!-- Lists -->
        <template v-slot:table>
          <component
            v-bind:is="currentComponent"
            :codeSearch="codeSearch"
            :titleSearch="titleSearch"
            :showDomesticOnly="showDomesticOnly"
            :status="status"
            :currentFilter="currentFilter"
            :showWithUsagesOnly="showWithUsagesOnly"
            :rightType="rightType"
            :reason="reason"
            :resolution="resolution"
            :distribution="distribution"
            @loaded="setCount"
          ></component>
        </template>
      </list-layout>
    </template>
  </tabs-layout>
</template>

<script>
import { AgreementConflictsList, NonIpiList, RecordingConflictsList, SharesActionsList } from "."
import { get, startCase } from "lodash"
import { getRightTypeDisplay, getSharesActionReasonDisplay, getUserActionResolutionTypeDisplay, getUserActionStatusDisplay, rightCodes, sharesActionReasons, userActionResolutionTypes, userActionStatuses } from "@/constants"
import TagFilters from "../Usages/TagFilters.vue"
import WorkRegistrationList from "./WorkRegistration/WorkRegistrationList"
import WorkUpdatesList  from "./WorkUpdatesList"
import WorksMatchingList  from "./WorksMatching/WorksMatchingList"

import { mapGetters } from "vuex"

const AGREEMENT = "agreement"
const WORKS_MATCHING = "worksMatching"
const SHARES = "shares"
const RECORDING = "recording"
const WORK_REGISTRATION = "workRegistration"
const WORK_UPDATES = "workUpdate"
const NON_IPI = "nonIpi"

const FILTERS = {
  WORK_TITLE: "work title",
  WORK_CODE: "work code",
  SHOW_DOMESTIC: "domestic",
  SHOW_USAGE_ONLY: "usage only",
  STATUS: "status",
  TAG: "tag",
  RIGHTS: "rights",
  REASON: "reason",
  DISTRIBUTION: "distribution",
  RESOLUTION: "resolution",
}

export default {
  name: "UserActionsTabs",
  data: () => {
    return {
      tabIndex: 0,
      tabs: [ SHARES, WORK_REGISTRATION, WORK_UPDATES ],
      status: null,
      showDomesticOnly: true,
      showWithUsagesOnly: false,
      count: {
        WORKS_MATCHING: "-",
        SHARES: "-",
        AGREEMENT: "-",
        RECORDING: "-",
        WORK_REGISTRATION: "-",
        WORK_UPDATES: "-",
        NON_IPI: "-",
      },
      currentFilter: [],
      codeSearch: "",
      titleSearch: "",
      distribution: "",
      licensee: "",
      useType: "",
      priority: "",
      usagePeriod: "",
      rightType: null,
      reason: null,
      resolution: null,
      FILTERS,
      userActionStatuses
    }
  },
  components: {
    AgreementConflictsList,
    NonIpiList,
    WorksMatchingList,
    RecordingConflictsList,
    SharesActionsList,
    TagFilters,
    WorkRegistrationList,
    WorkUpdatesList,
  },
  methods: {
    resetFilters () {
      this.showDomesticOnly = true
      this.showWithUsagesOnly = false
      this.codeSearch = ""
      this.titleSearch = ""
      this.distribution = ""
      this.licensee = ""
      this.useType = ""
      this.priority = ""
      this.usagePeriod = ""
      this.status = null
      this.rightType = null
      this.reason = null
      this.resolution = null
    },
    getActionsCount (type) {
      if (type === AGREEMENT) return this.count.AGREEMENT
      if (type === WORKS_MATCHING) return this.count.WORKS_MATCHING
      if (type === SHARES) return this.count.SHARES
      if (type === RECORDING) return this.count.RECORDING
      if (type === WORK_REGISTRATION) return this.count.WORK_REGISTRATION
      if (type === WORK_UPDATES) return this.count.WORK_UPDATES
      if (type === NON_IPI) return this.count.NON_IPI
      else return 0
    },
    getTabTitle (type) {
      return {
        [AGREEMENT]: this.$t("agreement_conflicts"),
        [WORKS_MATCHING]: this.$t("works_matching"),
        [SHARES]: this.$t("shares"),
        [RECORDING]: this.$t("recording_conflicts"),
        [WORK_REGISTRATION]: this.$t("work_registration"),
        [WORK_UPDATES]: this.$t("work_updates"),
        [NON_IPI]: this.$t("works_with_non_ipi_ips"),
      }[type]
    },
    onTabChange ({ index }) {
      if (this.tabIndex !== index) {
        this.tabIndex = index
        this.resetFilters()
        this.$router.replace(this.getRoute(index))
          .catch(() => {})
      }
    },
    setCount (count) {
      if (this.type === AGREEMENT) this.count.AGREEMENT = count
      if (this.type === WORKS_MATCHING) this.count.WORKS_MATCHING = count
      if (this.type === SHARES) this.count.SHARES = count
      if (this.type === RECORDING) this.count.RECORDING = count
      if (this.type === WORK_REGISTRATION) this.count.WORK_REGISTRATION = count
      if (this.type === WORK_UPDATES) this.count.WORK_UPDATES = count
      if (this.type === NON_IPI) this.count.NON_IPI = count
    },
    getRoute (index) {
      return {
        name: "actions-tabs",
        params: { type: this.tabs[index] },
        query: this.getRouteQuery(index),
      }
    },
    getUserActionStatusValue (value) {
      return this.userActionStatusOptions.find((item) => value === item.value)
    },
    getRouteQuery (index) {
      const sharesIndex = this.tabs.indexOf(SHARES)
      const worksMatchingIndex = this.tabs.indexOf(WORKS_MATCHING)
      const nonIPIIndex = this.tabs.indexOf(NON_IPI)
      const agreementsIndex = this.tabs.indexOf(AGREEMENT)
      const workUpdatesIndex = this.tabs.indexOf(WORK_UPDATES)
      const workRegistration = this.tabs.indexOf(WORK_REGISTRATION)
      let query = {}

      if ([worksMatchingIndex, nonIPIIndex, agreementsIndex, workRegistration, workUpdatesIndex].includes(index)) {
        query = { ...query, status: this.status }
      }
      if ([ nonIPIIndex, sharesIndex ].includes(index)) {
        query = { ...query, domestic_only: this.showDomesticOnly }
      }
      if ([ sharesIndex, agreementsIndex, nonIPIIndex ].includes(index)) {
        query = { ...query, with_usages: this.showWithUsagesOnly }
      }
      if (this.titleSearch && [ sharesIndex, worksMatchingIndex, nonIPIIndex, workUpdatesIndex ].includes(index)) {
        query = { ...query, title: this.titleSearch }
      }
      if (this.codeSearch && [ sharesIndex, worksMatchingIndex, nonIPIIndex, workUpdatesIndex ].includes(index)) {
        query = { ...query, code: this.codeSearch }
      }
      if (this.reason && [ sharesIndex ].includes(index)) {
        query = { ...query, reason: this.reason.value }
      }
      if (this.distribution && [ sharesIndex, agreementsIndex, nonIPIIndex ].includes(index)) {
        query = { ...query, distribution: this.distribution }
      }
      if (this.rightType && [ sharesIndex ].includes(index)) {
        query = { ...query, right_type: this.rightType.value }
      }
      if (this.resolution && [ sharesIndex, agreementsIndex, nonIPIIndex ].includes(index)) {
        query = { ...query, resolution: this.resolution.value }
      }

      return query
    },
    setInitialReason (value) {
      let option = this.reasonOptions.filter(u => u.value === value)
      return option.length === 1 ? option[0] : null
    },
    setInitialRightType (value) {
      let option = this.rightTypeOptions.filter(u => u.value === value)
      return option.length === 1 ? option[0] : null
    },
    setInitialResolution (value) {
      let option = this.resolutionOptions.filter(u => u.value === value)
      return option.length === 1 ? option[0] : null
    },
    onTagsFilter (type, value) {
      this.currentFilter = [type, value]
    },
    onCodeSearch (value) {
      this.codeSearch = value
    },
    onTitleSearch (value) {
      this.titleSearch = value
    },
    displayFilter (filter) {
      const sharesIndex = this.tabs.indexOf(SHARES)
      const worksMatchingIndex = this.tabs.indexOf(WORKS_MATCHING)
      const nonIPIIndex = this.tabs.indexOf(NON_IPI)
      const agreementsIndex = this.tabs.indexOf(AGREEMENT)
      const workUpdatesIndex = this.tabs.indexOf(WORK_UPDATES)
      const workRegistration = this.tabs.indexOf(WORK_REGISTRATION)

      const tabs = {
        [FILTERS.WORK_TITLE]: [ sharesIndex, worksMatchingIndex, nonIPIIndex, workRegistration, workUpdatesIndex ],
        [FILTERS.WORK_CODE]: [ sharesIndex, worksMatchingIndex, nonIPIIndex, workUpdatesIndex ],
        [FILTERS.SHOW_DOMESTIC]: this.isSociety ? [ nonIPIIndex, sharesIndex ] : [] ,
        [FILTERS.SHOW_USAGE_ONLY]: this.isSociety ? [ sharesIndex, agreementsIndex, nonIPIIndex ] : [],
        [FILTERS.TAG]:  this.isSociety ? [ sharesIndex, agreementsIndex, nonIPIIndex ] : [],
        [FILTERS.RIGHTS]: [ sharesIndex ],
        [FILTERS.REASON]: this.isSociety ? [ sharesIndex ] : [],
        [FILTERS.DISTRIBUTION]:  this.isSociety ? [ sharesIndex, agreementsIndex, nonIPIIndex ] : [],
        [FILTERS.STATUS]: [worksMatchingIndex, nonIPIIndex, agreementsIndex, workRegistration, workUpdatesIndex],
        [FILTERS.RESOLUTION]: [workRegistration],
      }[filter]

      return tabs.includes(this.tabIndex)
    },
  },
  computed: {
    ...mapGetters("user", ["isSociety"]),
    currentComponent () {
      return {
        [AGREEMENT]: "AgreementConflictsList",
        [WORKS_MATCHING]: "WorksMatchingList",
        [SHARES]: "SharesActionsList",
        [RECORDING]: "RecordingConflictsList",
        [WORK_REGISTRATION]: "WorkRegistrationList",
        [WORK_UPDATES]: "WorkUpdatesList",
        [NON_IPI]: "NonIpiList",
      }[this.type]
    },

    initialTagFilters () {
      return {
        licensee: this.licensee,
        useType: this.useType,
        priority: this.priority,
        usagePeriod: this.usagePeriod,
      }
    },
    reasonOptions () {
      const options = []
      for (let reason in sharesActionReasons) {
        const value = sharesActionReasons[reason]
        options.push({ value, display: startCase(getSharesActionReasonDisplay(value)) })
      }
      return options
    },
    rightTypeOptions () {
      const options = []
      for (let rights in rightCodes) {
        const value = rightCodes[rights]
        options.push({ value, display: startCase(getRightTypeDisplay(value)) })
      }
      return options
    },
    resolutionOptions () {
      const options = []
      const allowedResolutionTypes = {}

      if (this.type === WORK_REGISTRATION) {
        for (let key in userActionResolutionTypes) {
          if ([userActionResolutionTypes.ACCEPTED, userActionResolutionTypes.DECLINED].includes(userActionResolutionTypes[key])) {
            allowedResolutionTypes[key] = userActionResolutionTypes[key]
          }
        }
      }

      for (let resolution in allowedResolutionTypes) {
        const value = allowedResolutionTypes[resolution]
        options.push({ value, display: startCase(getUserActionResolutionTypeDisplay(value)) })
      }
      return options
    },
    userActionStatusOptions () {
      return Object.values(userActionStatuses).map(value => ({ value, display: startCase(getUserActionStatusDisplay(value)) }))
    }
  },
  watch: {
    isSociety (isSociety) {
      this.tabs = isSociety ? [ WORKS_MATCHING, SHARES, AGREEMENT, RECORDING, WORK_REGISTRATION, WORK_UPDATES, NON_IPI ] : [ SHARES, WORK_REGISTRATION, WORK_UPDATES ]
      this.tabIndex = this.tabs.indexOf(this.type)
    },
    status (status) {
      if (!status !== userActionStatuses.DONE)  {
        this.resolution = null
      }
    }
  },
  mounted () {
    this.tabs = this.isSociety ? [ WORKS_MATCHING, SHARES, AGREEMENT, RECORDING, WORK_REGISTRATION, WORK_UPDATES, NON_IPI ] : [ SHARES, WORK_REGISTRATION, WORK_UPDATES ]
    let defaultRoute = {
      name: "actions-tabs",
      params: { type: this.isSociety ? WORKS_MATCHING : SHARES },
      query: { status: null }
    }
    if (!this.tabs.includes(this.type)) {
      this.$router.replace(defaultRoute)
    } else {
      this.distribution = get(this.$route.query, "distribution", "")
      const status = get(this.$route.query, "status", "")
      this.status = status ? this.getUserActionStatusValue(status) : null
      this.showDomesticOnly = get(this.$route.query, "domestic_only", "true") === "true"
      this.showWithUsagesOnly = get(this.$route.query, "with_usages", "false") === "true"
      this.codeSearch = get(this.$route.query, "code", "")
      this.titleSearch = get(this.$route.query, "title", "")
      this.licensee = get(this.$route.query, "licensee", "")
      this.useType = get(this.$route.query, "use_type", "")
      this.priority = get(this.$route.query, "priority", "")
      this.usagePeriod = get(this.$route.query, "usage_period", "")
      this.reason = this.setInitialReason(get(this.$route.query, "reason", ""))
      this.rightType = this.setInitialRightType(get(this.$route.query, "right_type", ""))
      this.resolution = this.setInitialResolution(get(this.$route.query, "resolution", ""))
      this.tabIndex = this.tabs.indexOf(this.type)
    }
  },
  props: ["type"]
}
</script>
