var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('list-layout',{attrs:{"title":_vm.party,"count":_vm.count},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('h4',[_vm._v(_vm._s(_vm._f("startcase")(_vm.$t("users"))))])]},proxy:true},{key:"status",fn:function(){return [_c('status',{attrs:{"text":_vm.$t('active'),"count":_vm.summary.active,"icon":['fas', 'check-circle'],"color":"green"}}),_c('status',{attrs:{"text":_vm.$t('pending'),"count":_vm.summary.pending,"icon":['fas', 'question-circle'],"color":"orange"}}),_c('status',{attrs:{"text":_vm.$t('blocked'),"count":_vm.summary.inactive,"icon":['fas', 'exclamation-circle'],"color":"red"}})]},proxy:true},(_vm.isAdmin)?{key:"actions",fn:function(){return [_c('div',[_c('b-button',{staticClass:"ml-3",attrs:{"to":{ name: 'users-create'},"variant":"outline-primary"}},[_c('fa-icon',{staticClass:"mr-2",attrs:{"icon":['fas', 'plus-circle']}}),_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t("create")))+" ")],1)],1)]},proxy:true}:null,{key:"table",fn:function(){return [_c('v-server-table',{ref:"users",attrs:{"columns":_vm.columns,"options":_vm.options},on:{"loaded":_vm.onLoaded,"pagination":_vm.onPagination},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"text-info",attrs:{"to":{ name: 'users-update', params: { uid: row.id } }}},[_vm._v(" "+_vm._s(row.name)+" ")])]}},{key:"role",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-gray"},[_vm._v(" "+_vm._s(row.role)+" ")])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.status === 'active')?_c('status',{attrs:{"text":_vm.$t('active'),"icon":['fas', 'check-circle'],"color":"green"}}):_vm._e(),(row.status === 'pending')?_c('status',{attrs:{"text":_vm.$t('pending'),"icon":['fas', 'question-circle'],"color":"orange"}}):_vm._e(),(row.status === 'inactive')?_c('status',{attrs:{"text":_vm.$t('inactive'),"icon":['fas', 'exclamation-circle'],"color":"red"}}):_vm._e()]}},{key:"createdAt",fn:function(ref){
var row = ref.row;
return [_c('date-time-cell',{attrs:{"dateTime":row.createdAt}})]}},(_vm.isAdmin)?{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-content-end"},[(!_vm.isCurrentUser(row.id))?_c('action',{attrs:{"title":_vm._f("lowercase")(_vm.$t('delete')),"icon":['fas', 'trash']},on:{"click":function($event){return _vm.onDelete(row.id)}}}):_vm._e(),_c('modal',{attrs:{"id":'confirmation-modal-' + row.id,"title":_vm.$t('delete_user'),"okTitle":_vm._f("capitalize")(_vm.$t('yes')),"cancelTitle":_vm._f("capitalize")(_vm.$t('no'))},on:{"ok":function($event){return _vm.onConfirmDelete(row.id)}}},[_c('div',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t("confirm_delete_user")))+"?")])])],1)]}}:null],null,true)})]},proxy:true}],null,true)},[_c('router-view',{attrs:{"name":"modal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }