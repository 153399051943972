<template>
  <div class="d-flex flex-column">
    <div class="heading d-flex justify-content-between mb-2">
      <!-- Heading -->
      <div>
        <slot name="title">
          <h3>
            {{ title | startcase }} <template v-if="count">({{ count | formatNumber }})</template>
          </h3>
        </slot>

        <!-- Sub-Title -->
        <slot name="subtitle"></slot>
      </div>

      <!-- Actions -->
      <slot name="actions"></slot>
    </div>

    <div v-if="showFiltersStatus" class="d-flex align-items-center justify-content-end my-2">
      <!-- Status Summary -->
      <slot name="status"></slot>

      <!-- Filters -->
      <slot name="filters"></slot>
    </div>

    <!-- Search -->
    <div v-if="showSearch" class="my-2">
      <slot name="search"></slot>
    </div>

    <!-- Refresh list and clear filters button -->
    <div v-if="filters || showRefresh" class="d-flex align-items-center justify-content-end my-1">
      <ClearFiltersButton
        v-if="filters"
        :filters="filters"
      />
      <action
        v-if="showRefresh"
        :title="$t('refresh') | startcase"
        :text="$t('refresh') | startcase"
        :icon="['fas', 'sync-alt']"
        @click="refreshList()"
      />
    </div>
    <!-- Count -->
    <slot name="count"></slot>

    <!-- Table -->
    <div class="mt-3">
      <slot name="table"></slot>
    </div>

    <!-- Default -->
    <slot></slot>
  </div>
</template>

<script>
import ClearFiltersButton from "@/components/ClearFiltersButton"

export default {
  name: "ListLayout",
  components: {
    ClearFiltersButton
  },
  data () {
    return {
      showFiltersStatus: false,
      showSearch: false,
    }
  },
  props: {
    title: String,
    count: [String, Number],
    showRefresh: {
      type: Boolean,
      default: false,
    },
    filters: Object
  },
  methods: {
    refreshList () {
      this.$parent.getData()
    }
  },
  mounted () {
    this.showFiltersStatus = !!this.$slots.filters || !!this.$slots.status
    this.showSearch = !!this.$slots.search
  },
}
</script>
