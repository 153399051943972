import { isDefaultDateRange, parseDate } from "@/utils/dates"
import { usageFileTagTypes } from "@/constants"

const fileSelectionMixin = {
  computed: {
    pageSheetIds () {
      let allSheetIds = []
      if (!this.fileOnlySelection) {
        this.files.forEach(f => allSheetIds.push(f.sheets.map(s => s.id)))
      }
      return [].concat(...allSheetIds)
    },
    pageFileIds () {
      return this.files.map(f => f.id)
    }
  },
  methods: {
    getUsagePeriodDisplay (row) {
      let { usagePeriodEnd, usagePeriodStart } = row
      if (isDefaultDateRange(usagePeriodStart, usagePeriodEnd)) return ""
      usagePeriodStart = this.parseDate(usagePeriodStart, this.$config.DATE_FORMAT)
      usagePeriodEnd = this.parseDate(usagePeriodEnd, this.$config.DATE_FORMAT)
      return `${usagePeriodStart} / ${usagePeriodEnd}`
    },
    getTagIcon (tag) {
      return {
        [usageFileTagTypes.PRIORITY]: ["fas", "exclamation-triangle"],
        [usageFileTagTypes.USE_TYPE]: ["fas", "list"],
        [usageFileTagTypes.LICENSEE]: ["fas", "broadcast-tower"]
      }[tag.type]
    },
    parseDate (date) {
      return parseDate(date, this.$config.DATE_FORMAT)
    },
    parseTime (date) {
      return parseDate(date, "h:mm")
    },
    checkAllSelected (allIds, selected) {
      if (!this.fileOnlySelection) {
        let allFilesAndSheetsSelected = allIds.length && selected.length && allIds.every(i => selected.includes(i))
        this.options.allFilesAndSheetsSelected = allFilesAndSheetsSelected
      } else {
        let allFilesSelected = allIds.length && selected.length && allIds.every(i => selected.includes(i))
        this.options.allFilesAndSheetsSelected = allFilesSelected
      }
      
    },
  },
  watch: {
    files (data) {
      if (this.fileOnlySelection) {
        this.checkAllSelected(this.pageFileIds, this.selectedFiles)
      } else {
        this.checkAllSelected(this.pageSheetIds, this.selectedSheets)
      }

      // open all child rows
      const openRows = data.map(element => element.id)
      this.$refs[this.tableRef].$refs.table.openChildRows = openRows
    },
    selectedSheets (values) {
      this.$nextTick(() => {
        if (values) {
          this.filesWithAllSheetsSelected = this.files.filter(f => f.sheets.every(s => values.includes(s.id))).map(f => f.id)
          this.checkAllSelected(this.pageSheetIds, values)
        }
      })
    },
    selectedFiles (values) {
      this.$nextTick(() => {
        if (values && this.fileOnlySelection) {
          this.filesWithAllSheetsSelected = this.files.filter(f => values.includes(f.id)).map(f => f.id)
          this.checkAllSelected(this.pageFileIds, values)
        }
      })
    },
  },
  props: {
    selectedSheets: {
      type: Array,
      default: () => []
    },
    selectedFiles: {
      type: Array,
      default: () => []
    },
    fileOnlySelection: {
      type: Boolean,
      default: false
    }
  }
}

export default fileSelectionMixin