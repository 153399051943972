<template>
  <div>
    <slot name="heading"></slot>
    <slot name="subHeading"></slot>

    <work-detail-layout>
      <!-- Top: Work Details -->
      <template v-slot:top>
        <!-- CSS class defined in project.scss -->
        <Metadata :work="work" :inSingleView="inSingleView" />
      </template>

      <template v-slot:content>
        <list-layout :title="$t('interested_parties') | startcase">
          <template v-slot:filters>
            <!-- Toggle original and distribution keys -->
            <div class="d-flex mr-2" v-if="inSingleView && canViewDistributionKey">
              <label for="distribution_switch" class="ml-auto mb-0">{{ $t("show_distribution") | startcase }}</label>
              <b-form-checkbox
                id="distribution_switch"
                v-model="showDistribution"
                switch
                class="ml-2"
              >
              </b-form-checkbox>
            </div>
          </template>

          <!-- Shares Table -->
          <template v-slot:table>
            <shares-table
              :shares="shares"
              :chains="work.chains"
              :showDistribution="showDistribution"
              :lite="lite"
              :inSingleView="inSingleView"
              :workStatuses="workStatuses"
            />
          </template>
          <router-view name="share"></router-view>
        </list-layout>

        <!-- Payee Shares Table -->
        <div class="my-3" v-if="work.payeeShares && work.payeeShares.length">
          <h3>{{ $t("payees") | capitalize }}</h3>
            <payee-shares-table
              :payeeShares="work.payeeShares"
            />
        </div>

        <!-- Performers -->
        <div class="my-5">
          <h3>{{ $t("performers") | capitalize }}</h3>
          <div class="work-details-container p-4">
            <div v-if="performers && performers.length">
              <p v-for="(performer, index) in performers" :key="`${performer}-${index}`" class="my-1">{{ performer }}</p>
              <span class="text-info clickable" v-if="performersAreTruncated" @click="togglePerformers"> {{ collapsePerformers ? $t("less") : $t("more") }}</span>
            </div>
            <div v-else>{{ $t("no_performers_for_this_work") | capitalize }}</div>
          </div>
        </div>

        <template v-if="!lite">
          <!-- Type -->
          <div class="my-3">
            <h3>{{ $t("type") | capitalize }}</h3>
            <div class="work-details-container p-4">
              <div>
                <p class="my-1" v-if="work">
                  {{ getTypeDisplay(work) | capitalize }}
                </p>
              </div>
            </div>
          </div>

          <Sources
            v-if="inSingleView"
            :sources="work.sources"
            :workId="id"
            :canEdit="work.canEdit"
          />
          <Products
            v-if="inSingleView"
            :editMode="work.canEdit"
            :workId="id"
          />
          <LogsTabs
            v-if="inSingleView"
            :workId="id"
            :canEdit="work.canEdit"
          />
        </template>
      </template>

      <template v-slot:bottom>
        <slot name="extra"></slot>
      </template>
    </work-detail-layout>
  </div>
</template>

<style lang="scss" scoped>
  .clickable {
    cursor: pointer;
  }
</style>

<script>
import {
  getAdaptationTypeDisplay,
  getArrangementTypeDisplay,
  getWorkVersionTypeDisplay,
  workTitleTypesVerbose,
  workVersionTypes,
} from "@/constants"

import LogsTabs from "./LogsTabs"
import Metadata from "./Metadata"
import Products from "./Products"
import Sources from "./Sources"

import { mapGetters } from "vuex"

export default {
  name: "Detail",
  components: {
    Metadata,
    Products,
    LogsTabs,
    Sources,
  },
  data () {
    return {
      collapsePerformers: false,
      performersAreTruncated: false,
      showDistribution: false,
      workTitleTypesVerbose,
      workVersionTypes,
      performers: [],
    }
  },
  computed: {
    ...mapGetters("user", ["isSociety", "isPublisher"]),
    workStatuses () {
      if (this.inSingleView) return this.work.statuses
      return []
    },
    canViewDistributionKey () {
      return this.isSociety || this.isPublisher
    }
  },
  methods: {
    getTypeDisplay (work) {
      let typeText = getWorkVersionTypeDisplay(work.versionType)
      if (work.versionType === workVersionTypes.ORI) return typeText
      let modifications = [getArrangementTypeDisplay(work.arrangement), getAdaptationTypeDisplay(work.adaptation)]
      modifications = modifications.filter(text => text).join(", ")
      return `${typeText}: ${modifications}`
    },
    getPerformers () {
      const maxPerformers = 8
      let performers = this.work.performers || []
      if (performers.length > maxPerformers && !this.collapsePerformers) {
        performers = performers.slice(0, maxPerformers)
        this.performersAreTruncated = true
      }
      this.performers = [...performers]
    },
    togglePerformers () {
      this.collapsePerformers = !this.collapsePerformers
    },
  },
  watch: {
    work () {
      this.getPerformers()
    },
    collapsePerformers () {
      this.getPerformers()
    }
  },
  props: {
    id: [String, Number],
    work: {
      type: Object,
      default: () => ({ type: null })
    },
    shares: {
      type: Object,
      default: () => ({ original: [], distribution: [] })
    },
    inSingleView: {
      type: Boolean,
      default: false
    },
    lite: { // only show Metadata and Shares sections
      type: Boolean,
      default: false
    }
  }
}
</script>
