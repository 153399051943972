<template>
  <b-row align-v="center">
    <b-col cols="9">
      <b-progress height="1.5rem" :max="maximum" class="my-1" >
        <b-progress-bar :value="value" variant="secondary" />
      </b-progress>
    </b-col>
    <b-col cols="3">
      <b-row align-v="center" class="text-left">
        <span>{{ label }}</span>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "StatsBar",
  props: {
    value: Number,
    label: String,
    maximum: Number
  }

}
</script>
