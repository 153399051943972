<template>
  <ValidationObserver ref="usersCreateForm" v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(onSubmit)" >
      <!-- Name -->
      <form-field
        name="name"
        :label="$t('name')"
        type="text"
        rules="required"
        v-model="form.name"
      />

      <!-- Email -->
      <form-field
        name="email"
        :label="$t('email')"
        type="email"
        rules="required|email"
        v-model="form.email"
      />

      <!-- Role -->
      <multi-select-field
        v-if="isSocietyUser"
        name="role"
        :label="$t('role')"
        rules="required"
        v-model="form.role"
        :options="ROLES"
        choiceLabel="label"
        track-by="name"
        :allowEmpty="false"
        :hideSelected="true"
        placeholder
      />
      <submit-button class="btn-block mt-4" variant="primary">{{ $t("create") | capitalize }}</submit-button>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex"
import { ValidationObserver } from "vee-validate"
import { clone } from "lodash"
import { userRoles } from "@/constants"

const {
  MEMBER,
  REPERTOIRE_ADMIN,
  SOCIETY_ADMIN,
  BOARD_MEMBER,
} = userRoles

export default {
  name: "UsersCreateForm",
  components: {
    ValidationObserver,
  },
  data () {
    return {
      form: {
        name: "",
        email: "",
        role: null
      },
    }
  },
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    onSubmit () {
      let data = clone(this.form)
      data.role = this.form.role ? this.form.role.name : MEMBER

      this.$api.users.usersCreate(this.$route.params.id, data)
        .then(response => {
          this.success(response.statusText)
          this.$bvModal.hide("user-create-modal")
        })
        .catch(error => {
          const { data, status } = error.response

          if (status === 400) {
            this.error(this.$t("form_contains_errors"))
            this.$refs.usersCreateForm.setErrors(data)
          }
        })
    },
  },
  computed: {
    ...mapGetters("user", ["isAdmin"]),
    ...mapState("user", ["user"]),
    isSocietyUser () {
      return this.party && this.isAdmin && this.party.id == this.user.party.id
    },
    ROLES () {
      return [
        { label: this.$t("society_admin"), name: SOCIETY_ADMIN },
        { label: this.$t("repertoire_admin"), name: REPERTOIRE_ADMIN },
        { label: this.$t("board_member"), name: BOARD_MEMBER },
      ]
    }
  },
  props: {
    party: {
      type: Object,
      default: () => ({ id: null })
    },
  },
}
</script>