<template>
  <list-layout
    :title="$t('vod_files')"
    :count="count"
    :show-refresh="true"
    :filters="filters"
  >

    <!-- Search and Filters -->
    <template v-slot:search>
      <!-- Search -->
      <b-row class="my-3" align-v="start">
        <b-col cols="4">
          <!-- File Search -->
          <search-input
            :value="filters.fuzzySearch"
            @search="onFileSearch"
            :placeholder="$t('search_by_filename') | capitalize"
            :label="$t('filename') | capitalize"
            class="form-group pr-0"
          />
        </b-col>
        <b-col cols="4">
          <!-- DSP Filter -->
          <multi-select-field
            id="dsp"
            class="pr-3 w-20"
            v-model="filters.dsp"
            name="dsp"
            :options="dspOptions"
            label="DSP"
            choiceLabel="display"
            track-by="value"
            :showLabels="false"
          />
        </b-col>
        <b-col cols="4">
          <!-- Upload Date -->
          <div>
            <label for="upload_date"> {{ $t("upload_date") | capitalize }}:</label>
            <date-picker
              v-model="uploadDateRange"
              :placeholder="$t('select_date_range') | capitalize"
              range
              :format="$config.DATE_FORMAT"
              input-class="form-control"
              class="w-100 form-group"
              :input-attr="{id: 'upload_date'}"
            />
          </div>
        </b-col>
      </b-row>
    </template>

     <!-- Status -->
    <template v-slot:status>
      <div>
        <status
          :text="$t('matched')"
          :name="fileStatuses.MATCHED"
          :count="summary.matched"
          :icon="['fas', 'check-circle']"
          color="green"
          :active="filters.status === fileStatuses.MATCHED"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('ingested')"
          :name="fileStatuses.INGESTED"
          :count="summary.ingested"
          :icon="['fas', 'check-circle']"
          color="green"
          :active="filters.status == fileStatuses.INGESTED"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('landed')"
          :name="fileStatuses.LANDED"
          :count="summary.landed"
          :icon="['fas', 'check-circle']"
          color="green"
          :active="filters.status === fileStatuses.LANDED"
          clickable
          @click="onStatusClick"
        />
      </div>
      <div class="mr-3 align-self-start">
        <status
          :text="$t('processing')"
          :name="fileStatuses.PROCESSING"
          :count="summary.processing"
          :icon="['fas', 'question-circle']"
          color="orange"
          :active="filters.status == fileStatuses.PROCESSING"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('pending')"
          :name="fileStatuses.PENDING"
          :count="summary.pending"
          :icon="['fas', 'question-circle']"
          color="orange"
          :active="filters.status == fileStatuses.PENDING"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('error')"
          :name="fileStatuses.ERROR"
          :count="summary.error"
          :icon="['fas', 'exclamation-circle']"
          color="red"
          :active="filters.status == fileStatuses.ERROR"
          clickable
          @click="onStatusClick"
        />
      </div>
    </template>

    <!-- Table -->
    <template v-slot:table>
      <v-server-table
        :columns="columns"
        :options="options"
        :ref="tableRef"
        @loaded="onLoaded"
        @pagination="onPagination"
      >
        <!-- Filename -->
        <template v-slot:filename="{ row }">
          <div>
            <p class="m-0">{{ row.filename }}</p>
            <p class="text-gray">
              <small>
                {{ $t("uploaded_by") | capitalize }} "{{ row.uploader.name }}"
              </small>
            </p>
          </div>
        </template>

        <!-- Upload date -->
        <template v-slot:uploadDate="{ row }">
          <date-time-cell :dateTime="row.uploadDate"/>
        </template>

        <!-- DSP -->
         <template v-slot:society="{ row }">
          <div>
            <p v-if="row.dsp" class="m-0">{{ row.dsp }}</p>
            <p v-else>-</p>
          </div>
        </template>

        <!-- Status -->
        <template v-slot:status="{ row }">
          <status
            v-if="row.status === fileStatuses.PENDING"
            :text="$t('pending')"
            :icon="['fas', 'question-circle']"
            color="orange"
          />
          <status
            v-if="row.status === fileStatuses.PROCESSING"
            :text="$t('processing')"
            :icon="['fas', 'question-circle']"
            color="orange"
          />
          <status
            v-if="row.status === fileStatuses.LANDED"
            :text="$t('landed')"
            :icon="['fas', 'check-circle']"
            color="green"
          />
          <status
            v-if="row.status === fileStatuses.INGESTED "
            :text="$t('ingested')"
            :icon="['fas', 'check-circle']"
            color="green"
          />
          <status
            v-if="row.status === fileStatuses.MATCHED "
            :text="$t('matched')"
            :icon="['fas', 'check-circle']"
            color="green"
          />
          <status
            v-if="row.status === fileStatuses.ERROR"
            :text="$t('error')"
            :icon="['fas', 'exclamation-circle']"
            color="red"
            :active="true"
            @click="showErrors(row, 'file')"
            clickable
          />
        </template>

        <!-- Actions -->
        <template v-slot:actions="{ row }">
          <div class="d-flex justify-content-end">
            <action
              @click="showExtraInfo(row)"
              :title="$t('more_information')"
              :icon="['fas', !isOpen(row.id) ?  'angle-down': 'angle-up']"
            />
          </div>
        </template>

         <!-- Child Rows -->
        <template v-slot:child_row="{ row }">
          <row-extra-info :key="row.id">
            <b-row class="mb-2 small">
              <b-col cols="3" class="pr-5" v-if="!isEmpty(row.metadata)">
                <p class="text-gray">{{ $t('metadata') | capitalize }}</p>
                <template>
                  <b-row v-if="row.metadata.senderCurrency">
                    <b-col cols="8" class="text-left">{{ $t("sender_currency") | capitalize }}</b-col>
                    <b-col class="text-right text-gray pr-0" cols="4">{{ row.metadata.senderCurrency  }}</b-col>
                  </b-row>
                  <b-row v-if="row.metadata.remittingSocietyDistributionIdentifier">
                    <b-col cols="8" class="text-left">{{ $t("remitting_society_distribution_identifier") | capitalize }}</b-col>
                    <b-col class="text-right text-gray pr-0" cols="4">{{ row.metadata.remittingSocietyDistributionIdentifier }}</b-col>
                  </b-row>
                  <b-row v-if="row.metadata.wexRemittedRoyaltyAmount">
                    <b-col cols="8" class="text-left">{{ $t("wex_remitted_royalty_amount") }}</b-col>
                    <b-col class="text-right text-gray pr-0" cols="4">{{ row.metadata.wexRemittedRoyaltyAmount }}</b-col>
                  </b-row>
                </template>
              </b-col>
              <b-col v-if="row.stats.landingDbIngestion" cols="2" class="pr-5 mr-1 text-left">
                <p class="text-gray">{{ $t('ingestion_stats') | capitalize }}</p>
                <ingestion-stats :stats="row.stats.landingDbIngestion" @show-errors="showErrors(row)" />
              </b-col>
              <b-col v-if="row.stats.matching" cols="3" class="pr-5 mr-1">
                <p class="text-gray">{{ $t('work_matching_stats') | capitalize }}</p>
                <matching-stats :stats="row.stats.matching" />
              </b-col>
              <b-col v-if="row.stats.mipMatching" cols="3" class="pr-5 mr-1">
                <p class="text-gray">{{ $t('ip_matching_stats') | upperfirst }}</p>
                <matching-stats :stats="row.stats.mipMatching" />
              </b-col>
            </b-row>
          </row-extra-info>
        </template>

      </v-server-table>
    </template>

    <FileErrorsModal :item="selectedItem" onlyShowGlobalErrors />
  </list-layout>
</template>

<style lang="scss" scoped>
  .font-8 {
    font-size: 0.8rem;
  }

  .stats {
    font-size: 0.8rem;
  }
</style>

<script>
import { capitalize, get } from "lodash"
import { cisacSocieties, dsps, fileStatuses, getDspDisplay } from "@/constants"
import FileErrorsModal from "@/components/FileErrorsModal"
import { isEmpty } from "lodash"
import { listRouteMixin } from "@/utils/mixins"
import { mapState } from "vuex"
import moment from "moment"
import { parseDate } from "@/utils/dates"


export default {
  name: "VODFilesList",
  components: {
    FileErrorsModal,
  },
  mixins: [listRouteMixin],
  data () {
    return {
      columns: ["filename", "uploadDate", "dsp", "status", "actions"],
      viewingSelection: false,
      selectedItem: {},
      openChildRows: [],
      tableRef: "vodFilesListRef",
      options: {
        skin: "table",
        allSelected: false,
        showChildRowToggler: false,
        headings: {
          filename: capitalize(this.$t("filename")),
          uploadDate: capitalize(this.$t("upload_date")),
          dsp: "DSP",
          status: capitalize(this.$t("status")),
          actions: "",
        },
        requestKeys: { query: "fuzzy_search" },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))

          return this.$api.vod.vodFilesList(queryParams)
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
      },
      summary: {},
      count: 0,
      filters: {
        fuzzySearch: "",
        dsp: null,
        status: null,
        to: null,
        from: null
      },
      dspOptions: [
        { value: dsps.AMAZON, display: getDspDisplay(dsps.AMAZON) },
        { value: dsps.APPLETV, display: getDspDisplay(dsps.APPLETV) },
        { value: dsps.DISNEY, display: getDspDisplay(dsps.DISNEY) },
        { value: dsps.NETFLIX, display: getDspDisplay(dsps.NETFLIX) },
      ],
      uploadDateRange: [],
      fileStatuses,
      cisacSocieties
    }
  },
  methods: {
    isEmpty,
    onLoaded ({ data }) {
      this.count = data.count
      this.status = data.status
      this.summary = { ...data.summary }
    },
    onFileSearch (value) {
      this.filters.fuzzySearch = value
    },
    onSocietySearch (value) {
      this.filters.remittingSociety = value
    },
    onUploadDateSearch (value) {
      this.filters.uploadDate = value
    },
    onStatusClick (status) {
      this.filters.status = status
    },
    getData () {
      this.$refs[this.tableRef].getData()
    },
    parseDate (date) {
      return parseDate(date, this.$config.ISO_DATE_FORMAT)
    },
    parseTime (date) {
      return parseDate(date, "h:mm")
    },
    setInitialUploadDateRange () {
      const { from, to } = this.filters

      const fromIsValid = moment(from, this.$config.ISO_DATE_FORMAT, true).isValid()
      const toIsValid = moment(to, this.$config.ISO_DATE_FORMAT, true).isValid()

      if(fromIsValid && toIsValid) {
        const fromDate = new Date(from)
        const toDate = new Date(to)
        this.from = fromDate
        this.to = toDate

        this.uploadDateRange = [fromDate, toDate]
      }
    },
    isOpen (id) {
      return this.openChildRows.includes(id)
    },
    showExtraInfo ({ id }) {
      if (this.isOpen(id)) {
        this.openChildRows = this.openChildRows.filter(openRowId => id !== openRowId)
      } else {
        this.openChildRows.push(id)
      }
      this.$refs[this.tableRef].toggleChildRow(id)
    },
    showErrors (item) {
      this.selectedItem = item
      this.$bvModal.show("file-errors-modal")
    },
    getDspValue (value) {
      return this.dspOptions.find((item) => value === item.value)
    },
  },
  watch: {
    "filters.fuzzySearch" (value) {
      this.applyFilters("fuzzy_search", value)
    },
    "filters.dsp" (value) {
      this.applyFilters("dsp", value ? value.value : null)
    },
    uploadDateRange (value) {
      this.from = value[0] ? this.parseDate(value[0]) : null
      this.to = value[1] ? this.parseDate(value[1]) : null

      this.applyFilters("to", this.to)
      this.applyFilters("from", this.from)
    },
    "filters.status" (value) {
      this.applyFilters("status", value)
    },
    "filters.to" (value) {
      if (!value) this.uploadDateRange = []
    }
  },
  mounted () {
    this.setFilters()
    this.setInitialUploadDateRange()
    const dspQuery = get(this.$route.query, "dsp", "")
    this.filters.dsp = dspQuery ? this.getDspValue(dspQuery) : ""
  },
  computed: {
    ...mapState("user", ["waiting"]),
  },
}
</script>

<style scoped>
  label {
    font-weight: bold;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }
</style>
