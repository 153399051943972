<template>
  <b-button v-bind="$attrs" type="submit" :disabled="waiting">
    <slot v-if="!waiting"></slot>
    <template v-else>
      <b-spinner variant="light" small></b-spinner>
    </template>
  </b-button>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "SubmitButton",
  computed: {
    ...mapState("user", ["waiting"]),
  }
}
</script>
