<template>
  <div class="px-1">
    <b-row v-show="showProductSearch">
      <b-col class="d-flex">
        <search-input
          ref="productSearch"
          id="product-search"
          class="w-25 pr-3 mb-3 ml-3"
          :placeholder="$t('recording_title') | startcase"
          :value="productSearchTerm"
          @search="onProductSearch"
        />
        <div>
          <b-button
            class="mr-2 mb-3 py-2"
            variant="outline-secondary"
            @click="onCancelProductAdd"
          >
            {{ $t("cancel") | capitalize }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <div v-if="showProductSearch">
      <ProductsTable
        v-show="productSearchTerm"
        :searchTerm="productSearchTerm"
        ref="searchResults"
        :workId="workId"
      />
    </div>
  </div>  
</template>

<script>
import ProductsTable from "./ProductsTable"

export default {
  name: "ProductsSearch",
  components: {
    ProductsTable,
  },
  data () {
    return {
      productSearchTerm: "",
    }
  },
  methods: {
    onCancelProductAdd () {
      this.productSearchTerm = ""
      this.$emit("search-cancel")
    },
    onProductSearch (searchTerm) {
      this.productSearchTerm = searchTerm
    },
  },
  watch: {
    showProductSearch (show) {
      if (show) {
        this.$nextTick(() => {
          this.$refs.productSearch.$refs.searchInput.focus()
        })
      }
    }
  },
  props: {
    workId: [String, Number],
    showProductSearch: {
      type: Boolean,
      default: false,
    }
  },
}
</script>