const state = {
  type: null,
  message: null
}

const mutations = {
  success (state, message) {
    state.type = "success"
    state.message = message
  },
  error (state, message) {
    state.type = "danger"
    state.message = message
  },
  clear (state) {
    state.type = null
    state.message = null
  }
}

const actions = {
  success ({ commit }, message) {
    commit("success", message)
  },
  error ({ commit }, message) {
    commit("error", message)
  },
  clearAlert ({ commit }) {
    commit("clear")
  }
}

const getters = {
  visible: state => {
    return state.message != null
  },
}

export const alert = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
