<template>
  <div class="d-flex flex-nowrap justify-content-start align-items-center button-group-wrapper">
      <b-form-radio-group
        v-model="selected"
        :options="options"
        button-variant="outline-secondary"
        name="radio-btn-outline"
        buttons
        @input="onInput"
      ></b-form-radio-group>
  </div>
</template>

<style lang="scss" scoped>
  .button-group-wrapper::before,
  .button-group-wrapper::after {
    content: "";
    flex: 1 1 auto;
    border-bottom: solid 1px $gray-dark;
  }

  ::v-deep .btn-group-toggle {
    flex: 0 1 auto;
  }
</style>

<script>
export default {
  name: "RadioPillToggle",
  data () {
    return {
      selected: null,
    }
  },
  methods: {
    onInput () {
      this.$emit("selected", this.selected)
    }
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
  },
  mounted () {
    this.selected = this.options[0].value
  }
}
</script>