<template>
  <div>
    <span>{{ message.value }}</span>
    <span class="text-gray"> - {{ message.preferred ? $t("preferred") : $t("additional") }}</span>
  </div>
</template>

<script>
export default {
  name: "ISWCLogMessage",
  props: {
    message: Object,
  }
}
</script>