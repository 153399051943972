<template>
  <div>
    <type-and-value-input
      :item="item"
      :itemTypes="codeTypes"
      :name="`code-${code.index}`"
      @input="onInput"
      upper
    />
  </div>
</template>

<script>

export default {
  name: "WorkCode",
  props: {
    code: Object,
    codeTypes: Array,
  },
  data () {
    return {}
  },
  computed: {
    item () {
      // For representation, the type is transformed to upper case.
      return {
        value: this.code.value,
        type: this.code.type.toUpperCase(),
        ...this.code.id && { id: this.code.id }
      }
    },
  },
  methods: {
    // For storage, the type is transformed to lower case.
    onInput (item) {
      item = { ...item, type: item.type.toLowerCase() }
      this.$emit("input-work-code", item, this.code.index)
    }
  },
}
</script>
