import {
  AgreementConflictsDetail,
  RecordingConflictsDetail,
  UserActionsTabs,
  WorkRegistrationDetail,
  WorksMatchingDetail,
} from "@/pages/UserActions"

import { AgreementsDetail, AgreementsFilesList, AgreementsList, LandingAgreementsList } from "@/pages/Agreements"
import { DistributionsDetail, DistributionsListPage, DistributionsModal, PoolsFormPage, PoolsPage } from "@/pages/Distributions"
import { LandingWorksList, RepertoireFilesList } from "@/pages/RepertoireFiles"
import { PartiesDetail, PartiesList } from "@/pages/Parties"
import { UsageFilesList, UsagesList, UsagesMatchingList } from "@/pages/Usages"

import {
  UsersCreateModal,
  UsersList,
  UsersUpdateModal
} from "@/pages/Users/"

import CRDFiles from "@/pages/CRDFiles/CRDFilesList"
import CRDMatchingList from "@/pages/CRDMatchingList/CRDMatchingList"
import DigitalFilesList from "@/pages/DigitalFiles/DigitalFilesList"
import PageNotFound from "@/pages/PageNotFound"
import ProductsDetail from "@/pages/Products/ProductsDetail"
import ProductsList from "@/pages/Products/ProductsList"

import VODFilesList from "@/pages/VODFiles/VODFilesList"
import VueRouter from "vue-router"

import WelcomePage from "@/pages/Welcome/WelcomePage"

import WorkForm from "@/pages/WorksCreateAndEdit/WorkForm"
import WorksDetail from "@/pages/WorksDetail/WorksDetail"
import WorksList from "@/pages/WorksList/WorksList"
import WorksMerge from "@/pages/WorksMerge"

import { get } from "lodash"

import { store } from "@/store"


export const router = new VueRouter({
  mode: "history",
  routes: [
    // Public Paths
    {
      path: "/welcome/login",
      name: "login",
      component: WelcomePage,
      meta: { public: true }
    },
    {
      path: "/welcome/reset-password",
      name: "reset-password",
      component: WelcomePage,
      meta: { public: true }
    },
    // Private Paths
    {
      path: "/parties",
      name: "parties-list",
      component: PartiesList
    },
    {
      path: "/parties/:id",
      name: "parties-detail",
      props: true,
      component: PartiesDetail,
      children: [
        {
          path: "mandates",
          name: "parties-detail-mandates",
          component: PartiesDetail,
        },
        {
          path: "works",
          name: "parties-detail-works",
          component: PartiesDetail,
        },
        {
          path: "agreements",
          name: "parties-detail-agreements",
          component: PartiesDetail,
        },
        {
          path: "heirs",
          name: "parties-detail-heirs",
          component: PartiesDetail,
        }
      ]
    },
    {
      path: "/parties/:id/users",
      name: "users-list",
      component: UsersList,
      children: [{
        path: "create",
        name: "users-create",
        components: {
          modal: UsersCreateModal
        }
      },
      {
        path: ":uid",
        name: "users-update",
        props: true,
        components: {
          modal: UsersUpdateModal
        }
      }]
    },
    {
      path: "/actions/:type",
      name: "actions-tabs",
      props: true,
      component: UserActionsTabs,
    },
    {
      path: "/actions/works-matching/:id",
      name: "actions-works-matching-detail",
      props: true,
      component: WorksMatchingDetail,
    },
    {
      path: "/actions/agreement/:id",
      name: "actions-agreement-detail",
      props: true,
      component: AgreementConflictsDetail,
      beforeEnter: (to, from, next) => {
        // Only admins and repertoire admins can solve agreement conflicts
        let isSociety = store.getters["user/isSociety"]
        next(isSociety)
      }
    },
    {
      path: "/actions/recording/:id",
      name: "actions-recording-detail",
      props: true,
      component: RecordingConflictsDetail,
    },
    {
      path: "/actions/registration/:id",
      name: "actions-registration-detail",
      props: true,
      component: WorkRegistrationDetail,
    },
    {
      path: "/documentation-uploads",
      name: "repertoire-files-list",
      component: RepertoireFilesList,
      beforeEnter: (to, from, next) => {
        // Only admins, repertoire admins and publishers can view
        const isSociety = store.getters["user/isSociety"]
        const isPublisher = store.getters["user/isPublisher"]

        next(isSociety || isPublisher)
      }
    },
    {
      path: "/documentation-uploads/:id",
      name: "landing-works-list",
      component: LandingWorksList,
    },
    {
      path: "/works",
      name: "works-list",
      component: WorksList,
    },
    {
      path: "/works/create",
      name: "works-create",
      component: WorkForm,
    },
    {
      path: "/works/merge",
      name: "works-merge",
      component: WorksMerge,
    },
    {
      path: "/works/:id",
      name: "works-detail",
      props: true,
      component: WorksDetail,
    },
    {
      path: "/works/:id/edit",
      name: "works-edit",
      props: true,
      component: WorkForm,
    },
    {
      path: "/products",
      name: "products-list",
      component: ProductsList,
    },
    {
      path: "/products/:id",
      name: "products-detail",
      props: true,
      component: ProductsDetail,
    },
    {
      path: "/usage-files",
      name: "usage-files-list",
      component: UsageFilesList,
    },
    {
      path: "/usage-files/:id",
      name: "usages-list",
      component: UsagesList,
    },
    {
      path: "/usages-matching",
      name: "usages-matching-list",
      component: UsagesMatchingList,
    },
    {
      path: "/distributions",
      name: "distributions-list",
      component: DistributionsListPage,
      children: [{
        path: "create",
        name: "distributions-create",
        props: true,
        components: {
          modal: DistributionsModal
        }
      }]
    },
    {
      path: "/distributions/:id",
      name: "distributions-detail",
      props: true,
      component: DistributionsDetail,
      children: [{
        path: "edit",
        name: "distributions-edit",
        props: true,
        components: {
          modal: DistributionsModal
        }
      }]
    },
    {
      path: "/distributions/:id/pools/create",
      name: "pools-create",
      props: route => {
        return { ...route.query, ...route.params }
      },
      component: PoolsFormPage,
    },
    {
      path: "/distributions/:id/pools/:pid",
      name: "pools-detail",
      props: route => {
        return { ...route.query, ...route.params }
      },
      component: PoolsPage,
    },
    {
      path: "/agreements",
      name: "agreements-list",
      component: AgreementsList,
    },
    {
      path: "/agreements/create",
      name: "agreements-create",
      component: AgreementsDetail
    },
    {
      path: "/agreements/:agreementId",
      name: "agreements-update",
      component: AgreementsDetail
    },
    {
      path: "/agreements-uploads",
      name: "agreements-files-list",
      component: AgreementsFilesList,
    },
    {
      path: "/agreements-uploads/:id",
      name: "landing-agreements-list",
      component: LandingAgreementsList,
    },
    {
      path: "/digital-files",
      name: "digital-files-list",
      component: DigitalFilesList,
    },
    {
      path: "/crd-files",
      name: "crd-files-list",
      component: CRDFiles,
    },
    {
      path: "/crd-matching-list",
      name: "crd-matching-list",
      component: CRDMatchingList,
    },
    {
      path: "/vod-files",
      name: "vod-files-list",
      component: VODFilesList,
    },
    // Catch all: Page Not Found
    {
      path: "*",
      component: PageNotFound,
    },
  ]
})

function addPageQuery (to) {
  // Add ?page=1 query if "page" not present in list pages
  const { name, params, query } = to
  const page = get(query, "page")
  const listPages = [
    "users-list",
    "parties-list",
    "works-list",
    "repertoire-files-list",
    "landing-works-list",
    "agreements-files-list",
    "landing-agreements-list",
    "usage-files-list",
    "usages-list",
    "usages-matching-list",
    "crd-files-list",
    "crd-matching-list",
    "actions-tabs",
    "distributions-list",
    "vod-files-list",
  ]

  if (!page && listPages.includes(name)) {
    return { name, params, query: { ...query, page: 1 } }
  }

  return {}
}

router.beforeEach((to, from, next) => {
  const loggedIn = router.app.$auth.isAuthenticated()
  const defaultRoute = { name: "works-list" }

  let route = {}

  // Always reset the status of Page Not Found
  store.commit("user/setPageNotFound", false)
  store.commit("user/setUnauthorized", false)

  if (to.matched.some(route => route.meta.public)) {
    // 1. For PUBLIC routes...

    // if the user is already logged in, redirect to default route.
    if (loggedIn) { route = defaultRoute }
  } else {
    // 2. For PRIVATE routes...

    // a. If NOT logged in:
    if (!loggedIn) {
      // Redirect to login, keeping the requested URL for redirection.
      const redirect = to.fullPath !== "/" ? to.fullPath : undefined
      route = { name: "login", query: { redirect } }
    // b. If logged in:
    } else {
      // Redirect base path to default route
      to = to.fullPath == "/" ? defaultRoute : to

      // Add page query for list pages.
      route = addPageQuery(to)
    }
  }

  next(route)
})
