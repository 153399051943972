<template>
  <div>
    <ValidationObserver ref="commentForm" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)" class="p-2">
        <form-textarea
          name="comment"
          v-model="form.text"
          :readonly="readonly"
        />
        <submit-button v-if="!readonly">{{ $t("comment") | capitalize }}</submit-button>
        <b-button class="ml-2" v-if="form.text && !readonly" @click="onCancel">
          {{ $t("cancel") | capitalize }}
        </b-button>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate"
import { mapActions } from "vuex"

export default {
  name: "CommentForm",
  components: {
    ValidationObserver,
  },
  data () {
    return {
      form: {
        text: this.comment ? this.comment.text : "",
      },
    }
  },
  methods: {
    ...mapActions("alert", ["success", "error"]),
    onCancel () {
      if (!this.form.id) this.form.text = ""
      this.$emit("update-comments")
    },
    createComment (data) {
      this.$api.repertoire
        .worksCommentsCreate(this.workId, data)
        .then(() => {
          this.success(this.$t("comment_saved"))
          this.$emit("update-comments")
          this.reset()
        })
    },
    updateComment (data) {
      this.$api.repertoire
        .worksCommentsUpdate(this.form.id, this.workId, data)
        .then(() => {
          this.success(this.$t("comment_updated"))
          this.$emit("update-comments")
        })
    },
    onSubmit () {
      let data = {}
      data.text = this.form.text
      if (this.form.id) {
        this.updateComment(data)
      } else {
        this.createComment(data)
      }
    },
    reset () {
      this.form = { text: "" }
    }
  },
  watch: {
    comment (value) {
      this.form = value
    }
  },
  props: {
    workId: [String, Number],
    comment: Object,
    readonly: {
      type: Boolean,
      default: false
    }
  }
}
</script>
