<template>
  <div>
    <v-server-table
      :columns="columns"
      :options="options"
      ref="updates"
      @loaded="onLoaded"
    >
      <!-- Updates -->
      <template v-slot:update="{ row }">
        <b-row v-for="key in Object.keys(row.data)" :key="key">
          <b-col cols="2">{{ key | startcase }}</b-col>
          <b-col v-if="['performers', 'language'].includes(key)">
            <b-row>
              <b-col>{{ getValue(row, key) }}</b-col>
              <b-col cols="3">{{ getWorkFieldActionTypesDisplay(workFieldActionTypes.EDIT) | startcase }}</b-col>
            </b-row>
          </b-col>
          <b-col v-if="key == 'iswcs'">
            <ISWCUpdate :iswcs="row.data[key]" />
          </b-col>
          <b-col v-if="key == 'titles'">
            <TitlesUpdate :titles="row.data[key]" />
          </b-col>
          <b-col v-if="key == 'codes'">
            <CodesUpdate :codes="row.data[key]" />
          </b-col>
          <b-col v-if="key == 'shares'">
            <SharesUpdate :shares="row.data[key]" />
          </b-col>
        </b-row>
      </template>

      <!-- Created -->
      <template v-slot:created="{ row }">
        <div>
          <p>{{ row.createdBy.name }}</p>
          <date-time-cell :dateTime="row.created" />
        </div>
      </template>

      <!-- Actions -->
      <template v-slot:actions="{ row }">
        <div v-if="isAdmin" class="d-flex justify-content-center">
          <action
            :title="$t('accept')"
            :icon="['fas', 'check']"
            color="green"
            @click="onAccept(row)"
          />
          <action
            :title="$t('decline')"
            :icon="['fas', 'times']"
            color="red"
            @click="onDecline(row)"
          />
        </div>
      </template>
    </v-server-table>
    <modal
      id="accept-confirmation-modal"
      :title="$t('accept_work_update') | startcase"
      :okTitle="$t('yes') | startcase"
      :cancelTitle="$t('no') | startcase"
      @ok="onConfirmAccept"
      @hidden="resetSelectionState"
    >
      <p>{{ $t("do_you_want_to_accept_this_update") | capitalize }}?</p>
    </modal>
    <modal
      id="decline-confirmation-modal"
      :title="$t('decline_work_update') | startcase"
      :okTitle="$t('yes') | startcase"
      :cancelTitle="$t('no') | startcase"
      @ok="onConfirmDecline"
      @hidden="resetSelectionState"
    >
      <p>{{ $t("do_you_want_to_decline_this_update") | capitalize }}?</p>
    </modal>
  </div>
</template>

<script>
import { get, startCase } from "lodash"
import { getWorkFieldActionTypesDisplay, workFieldActionTypes } from "@/constants"
import { mapActions, mapGetters, mapState } from "vuex"
import CodesUpdate from "./WorkUpdates/CodesUpdate.vue"
import { EventBus } from "@/utils/event-bus.js"
import ISWCUpdate from "./WorkUpdates/ISWCUpdate.vue"
import SharesUpdate from "./WorkUpdates/SharesUpdate.vue"
import TitlesUpdate from "./WorkUpdates/TitlesUpdate.vue"

export default {
  name: "Updates",
  components: {
    CodesUpdate,
    ISWCUpdate,
    SharesUpdate,
    TitlesUpdate,
  },
  data () {
    return {
      workFieldActionTypes,
      selectedWorkUpdate: null,
      count: 0,
      columns: ["update", "created", "actions"],
      options: {
        headings: {
          update: startCase(this.$t("update")),
          created: startCase(this.$t("requested_by")),
          actions: "",
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))

          let component = this.$parent.$parent

          return this.$api.repertoire.workUpdateList(component.workId, queryParams)
        },
        columnsClasses: {
          actions: "align-center width-small px-0",
        },
      },
    }
  },
  computed: {
    ...mapGetters("user", ["isAdmin"]),
    ...mapState("consts", ["languages"]),
  },
  methods: {
    ...mapActions("alert", ["success", "error"]),
    getWorkFieldActionTypesDisplay,
    onLoaded ({ data }) {
      this.count = data.count
    },
    onAccept (row) {
      this.selectedWorkUpdate = row
      this.$bvModal.show("accept-confirmation-modal")
    },
    onDecline (row) {
      this.selectedWorkUpdate = row
      this.$bvModal.show("decline-confirmation-modal")
    },
    onConfirmAccept () {
      this.$api.repertoire.workUpdateAccept(this.workId, this.selectedWorkUpdate.id)
        .then(() => {
          this.success(this.$t("work_update_accepted"))
          this.$refs.updates.getData()
          EventBus.$emit("update-accepted", this.workId)
        })
        .catch(err => this.error(err.response.data.join("\n")))
    },
    onConfirmDecline () {
      this.$api.repertoire.workUpdateDecline(this.workId, this.selectedWorkUpdate.id)
        .then(() => {
          this.success(this.$t("work_update_declined"))
          this.$refs.updates.getData()
        })
        .catch(err => this.error(err.response.data))
    },
    resetSelectionState () {
      this.selectedWorkUpdate = null
    },
    getValue (row, key) {
      if (key == "language") {
        const language = this.languages.filter(l => l.id === row.data[key]).pop()
        return language.name
      } else if (key == "performers") {
        return row.data[key].join(", ")
      }
      return row.data[key]
    },
  },
  props: {
    workId: [String, Number],
  }
}
</script>