var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"py-3 px-3 h-100",attrs:{"no-body":""}},[_c('b-row',[_c('b-col',{staticClass:"d-flex"},[_c('div',[_c('p',{staticClass:"form-control text-secondary border-white font-weight-bolder font-11"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t("selected_files"))))])])])],1),_c('v-client-table',{ref:_vm.tableRef,attrs:{"columns":_vm.columns,"options":_vm.options,"data":_vm.files},on:{"pagination":_vm.onPagination},scopedSlots:_vm._u([{key:"filename",fn:function(ref){
var row = ref.row;
return [_c('b-row',[_c('b-col',[_c('div',[_vm._v(_vm._s(row.filename))]),_c('div',{staticClass:"mt-2"},_vm._l((row.tags),function(tag,index){return _c('tag',{key:("tag_" + (row.id) + "_" + index),attrs:{"text":tag.value,"icon":_vm.getTagIcon(tag)}})}),1)])],1)]}},{key:"period",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"font-8"},[_vm._v(" "+_vm._s(_vm.getUsagePeriodDisplay(row))+" ")])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex"},[_c('action',{attrs:{"title":_vm.$t('detach_file'),"icon":['fas', 'times'],"color":"red"},on:{"click":function($event){return _vm.onChange($event, row)}}})],1)]}},{key:"child_row",fn:function(ref){
var row = ref.row;
return [(!_vm.fileOnlySelection && row.sheets.length)?_c('FileSheetSelector',{key:row.id,attrs:{"items":row.sheets,"file":row,"selected":_vm.selectedSheets,"parentTable":_vm.tableRef}}):_vm._e()]}}])},[(_vm.files.length)?_c('template',{slot:"h__action"},[_c('div',{staticClass:"d-flex"},[_c('action',{attrs:{"title":_vm.$t('detach_all_files'),"icon":['fas', 'times'],"color":"red"},on:{"click":_vm.onDetachAll}})],1)]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }