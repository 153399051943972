<template>
  <div class="h-100">
    <top-bar />
    <div class="d-flex">
      <side-bar />
      <b-container fluid id="content">
        <slot></slot>
      </b-container>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  #content {
    margin: 50px;
    margin-left: 300px;
    position: relative;
  }
</style>

<script>

export default {
  name: "LoggedInLayout",
}
</script>
