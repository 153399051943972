var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-server-table',{ref:"workRegistration",attrs:{"name":"workRegistration","columns":_vm.columns,"options":_vm.options},on:{"loaded":_vm.onLoaded,"pagination":_vm.onPagination,"sorted":_vm.onSorted},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"text-info",attrs:{"to":{ name: 'actions-registration-detail', params: { id: row.id } }}},[_vm._v(" "+_vm._s(row.landingWork.title)+" ")])]}},{key:"created",fn:function(ref){
var row = ref.row;
return [_c('date-time-cell',{attrs:{"dateTime":row.created}})]}},{key:"createdBy",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.createdBy.name))]),_c('div',{staticClass:"text-gray"},[_vm._v(_vm._s(row.createdBy.party))])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.status === _vm.statuses.DONE)?_c('status',{attrs:{"text":_vm.$t('done'),"icon":['fas', 'check-circle'],"color":"green"}}):(row.status === _vm.statuses.PENDING)?_c('status',{attrs:{"text":_vm.$t('pending'),"icon":['fas', 'question-circle'],"color":"orange"}}):_vm._e()]}},{key:"resolvedBy",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.resolvedBy.name))]),_c('div',{staticClass:"text-gray"},[_vm._v(_vm._s(row.resolvedBy.party))])]}},{key:"resolutionType",fn:function(ref){
var row = ref.row;
return [_c('div',[(row.resolutionType)?_c('span',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.resolutionDisplay(row.resolutionType)))+" ")]):_c('span',{staticClass:"ml-4"},[_vm._v("-")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }