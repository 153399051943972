<template>
  <files-selector
    :initial="usageFiles"
    :fileOnlySelection="false"
    :fileType="selectableFileTypes.USAGES"
    :searchAndSelectColumns="searchAndSelectColumns"
    :selectedColumns="selectedColumns"
    @select-sheets="onSelectUsageSheets"
  />
</template>

<script>
import { selectableFileTypes } from "@/constants"

export default {
  name: "UsageFilesSelector",
  data () {
    return {
      selectableFileTypes,
      searchAndSelectColumns: ["selection", "filename", "period"],
      selectedColumns: ["filename", "period", "action"],
    }
  },
  methods: {
    onSelectUsageSheets (sheets) {
      this.$emit("select-usage-sheets", sheets)
    }
  },
  props: {
    usageFiles: {
      type: Array,
      default: () => []
    }
  }
}
</script>