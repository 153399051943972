<template>
  <multi-select-field
    v-bind="$attrs"
    v-model="innerValue"
    :options="options"
    choiceLabel="display"
    track-by="name"
    :allowEmpty="false"
    :show-no-results="showNoResults"
    :noResultMessage="$t('no_parties_found') | capitalize"
    :placeholder="$t('search_by_name_or_complete_ipi_number')"
    :searchable="true"
    :internal-search="false"
    :clear-on-select="true"
    :errors="innerErrors"
    @search-change="searchParty"
    @open="onOpen"
    :select-label="'select'"
  >
    <template v-slot:option="props">
      <div class="option__display">{{ props.option.display }}</div>
      <div class="option__extra"><span class="mr-2">{{ props.option.nameType }}</span><span>{{ props.option.ipiNameNumber }}</span></div>
    </template>
  </multi-select-field>
</template>

<style lang="scss" scoped>
  ::v-deep .multiselect {
    [class$="option__extra"] {
      font-size: 0.75rem;
    }
  }
</style>

<script>
import { debounce } from "lodash"

export default {
  name: "IPISearch",
  data () {
    return {
      options: [],
      innerValue: "",
      innerErrors: [],
      showNoResults: false,
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue (newVal, oldVal) {
      if (newVal) newVal.display = this.getDisplay(newVal)
      this.$emit("input", newVal)
      this.$emit("changes", { previous: oldVal, current: newVal })
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal
    },
    errors (newVal) {
      this.innerErrors = newVal
    },
    partyType (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.reset()
        this.options = []
      }
    }
  },
  methods: {
    reset () {
      this.innerValue = null
      this.innerErrors = []
    },
    onOpen () {
      if (!this.options.length) this.showNoResults = false
    },
    setInitial () {
      if (!this.innerValue && this.value) this.innerValue = this.value
    },
    getDisplay (party) {
      return party?.affiliations?.length ?
        `${party.name} (${[ ...new Set(party.affiliations.map(a => a.societyName))].join(", ")})`
        : party?.name
    },
    searchIPI: debounce( function (searchQuery) {
      this.$api.parties.ipiSearch({ search: searchQuery, party_type: this.partyType })
        .then((response) => {
          this.options = response.data.map(p => ({ display: this.getDisplay(p), ...p }))
          this.showNoResults = !this.options.length
        })
        .catch((error) => {
          this.innerErrors = [error.response.data.message]
        })
    }, 1000),
    searchParty (query) {
      let searchQuery = query.trim()
      this.innerErrors = []

      if (!searchQuery) return

      this.searchIPI(searchQuery)

    },
  },
  props: {
    value: {
      type: null
    },
    partyType: String,
    errors: {
      type: Array,
      default: () => []
    }
  },
  mounted () {
    if (this.value) this.innerValue = this.value
  }
}
</script>
