<template>
  <b-table :items="items" :fields="fields" thead-class="d-none" striped borderless class="sheets-table">
    <!-- Name -->
    <template v-slot:cell(name)="{ item }">
      <b-col>
        <fa-icon size="lg" :icon="['fa', 'table']" class="sheet-icon" />
        <router-link
          :to="{ 'name': 'usages-list', 'params': { 'id': item.id } }"
          class="text-info"
        >
          {{ item.name }}
        </router-link>
      </b-col>
    </template>

    <!-- Usage Type -->
    <template v-slot:cell(usageType)="{ item }">
      <div>{{ getUsageTypeDisplay(item.usageType) | capitalize }}</div>
    </template>

    <!-- Status -->
    <template v-slot:cell(status)="{ item }">
      <status
        v-if="item.status === fileStatuses.PENDING"
        :text="$t('pending')"
        :icon="['fas', 'question-circle']"
        color="orange"
      />
      <status
        v-if="item.status === fileStatuses.PROCESSING"
        :text="$t('processing')"
        :icon="['fas', 'question-circle']"
        color="orange"
      />
      <status
        v-if="item.status === fileStatuses.LANDED"
        :text="$t('landed')"
        :icon="['fas', 'check-circle']"
        color="green"
      />
      <status
        v-if="item.status === fileStatuses.INGESTED"
        :text="$t('ingested')"
        :icon="['fas', 'check-circle']"
        color="green"
      />
      <status
        v-if="item.status === fileStatuses.MATCHED"
        :text="$t('matched')"
        :icon="['fas', 'check-circle']"
        color="green"
      />
      <status
        v-if="item.status === fileStatuses.ERROR"
        :text="$t('error')"
        :icon="['fas', 'exclamation-circle']"
        color="red"
        :active="true"
        @click="showErrors(item, 'sheet')"
        clickable
      />
    </template>

    <!-- Ingestion Stats -->
    <template v-slot:cell(ingestionStats)="{ item }">
      <div class="mx-1">
        <div class="font-weight-bold">{{ $t("landing_db_ingestion") | capitalize }}</div>
        <ingestion-stats :stats="item.stats.landingDbIngestion" @show-errors="showErrors(item, 'row', fileIngestionStatsTypes.LANDING_DB_INGESTION)" class="mb-2"/>

        <div class="font-weight-bold">{{ $t("single_view_ingestion") | capitalize }}</div>
        <ingestion-stats :stats="item.stats.singleViewIngestion" @show-errors="showErrors(item, 'row', fileIngestionStatsTypes.SINGLE_VIEW_INGESTION)"/>
      </div>
    </template>

    <!-- Matching Stats -->
    <template v-slot:cell(matchingStats)="{ item }">
      <div class="mx-1">
        <matching-stats :stats="item.stats.matching" />
      </div>
    </template>

    <!-- Actions -->
   <template v-slot:cell(actions)="{ item }">
      <div class="d-flex flex-row justify-content-end align-items-center">
        <!-- Distributions -->
        <action
          v-if="item.distributions.length"
          :title="$t('distributions')"
          @click="onShowDistributions(item.id)"
          :icon="['fa', 'file-invoice-dollar']"
        />
        <modal
          v-if="item.distributions"
          :id="'distributions-modal-' + item.id"
          :title="$t('distributions') | capitalize"
          :cancelTitle="$t('close') | capitalize"
          hide-header
          hide-footer
        >
          <b-container>
            <h3 class="mb-2">{{ $t("distributions") | capitalize }}</h3>
            <div v-for="distribution in item.distributions" :key="distribution.id">
              <router-link
                :to="{ name: 'distributions-detail', params: { id: distribution.id }}"
                class="text-info row px-1 mx-0"
              >
                {{ distribution.name }}
              </router-link>

              <ul>
                <li v-for="pool in distribution.distributionPools" :key="pool.id">
                  <router-link
                    :to="{ name: 'pools-detail', params: { id: distribution.id, pid: pool.id }}"
                    class="text-info row px-1 mx-0"
                  >
                    {{ pool.name }}
                  </router-link>
                </li>
              </ul>
            </div>

            <div class="float-right mt-3 mb-2">
              <b-button
                class="mr-1"
                variant="secondary"
                @click="onCloseDistributionsModal(item.id)"
              >
                {{ $t("close") | capitalize }}
              </b-button>
            </div>
          </b-container>
        </modal>
      </div>
    </template>
  </b-table>
</template>

<style lang="scss">
  .sheet-icon {
    color: $info;
  }

  .stats {
    font-size: 0.8rem;
  }

  .sheets-table {
    border-bottom: 1px solid $gray-dark;
    font-size: 0.9rem;
    font-weight: 300;
    color: var(--gray);
  }

  .selection-column {
    width: 55px;
    padding: 0;
  }
</style>

<script>
import { fileIngestionStatsTypes, fileStatuses, getUsageTypeDisplay } from "@/constants"


export default {
  name: "UsageFileSheetsTable",
  data () {
    return {
      fileIngestionStatsTypes,
      fileStatuses,
      fields: [
        { key: "selection", class: "selection-column"  },
        { key: "name", class: "w-22 text-break" },
        { key: "usagePeriod", class: "w-10" },
        { key: "usageType", class: "w-8" },
        { key: "status", class: "w-8" },
        { key: "ingestionStats", class: "w-15" },
        { key: "matchingStats", class: "w-15" },
        { key: "actions" }
      ]
    }
  },
  methods: {
    getUsageTypeDisplay,
    showErrors (item, level, type) {
      this.$emit("show-errors", item, level, type)
    },
    onShowDistributions (id) {
      this.$bvModal.show(`distributions-modal-${id}`)
    },
    onCloseDistributionsModal (id) {
      this.$bvModal.hide(`distributions-modal-${id}`)
    }
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    }
  }
}
</script>
