<template>
  <component :is="poolComponent" :id="id" :pid="pid"/>
</template>

<script>
import CRDPoolsDetail from "./CRD/CRDPoolsDetail"
import DigitalPoolsDetail from "./Digital/DigitalPoolsDetail"
import DomesticPoolsDetail from "./Domestic/DomesticPoolsDetail"
import VODPoolsDetail from "./VOD/VODPoolsDetail"
import { poolsFormType  } from "@/constants"

export default {
  name: "PoolsDetailPage",
  components: { CRDPoolsDetail, DigitalPoolsDetail, DomesticPoolsDetail, VODPoolsDetail },
  computed: {
    poolComponent () {
      if (!this.type) {
        return DomesticPoolsDetail
      }
      return {
        [poolsFormType.DOMESTIC]: DomesticPoolsDetail,
        [poolsFormType.CRD]: CRDPoolsDetail,
        [poolsFormType.DIGITAL]: DigitalPoolsDetail,
        [poolsFormType.VOD]: VODPoolsDetail,
      }[this.type]
    }
  },
  props: {
    id: String, // Distribution ID
    pid: String, // Pool ID,
    type: String
  }
}
</script>
