import { capitalize, get } from "lodash"
import { i18n } from "@/utils/i18n"

const DEFAULT_MIN_DATE = new Date("0001-01-01")
const DEFAULT_MAX_DATE = new Date("9999-12-31")

const activationStatuses = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  PENDING: "pending",
}

const rightCodes = {
  MEC: "MEC",
  PER: "PER",
  SYN: "SYN",
}

const workCodeTypes = [
  "atlas",
  "wid",
  "wami",
  "filscap",
  "mct",
  "mcsc",
  "compass",
  "komca",
  "vcpmc",
  "macp",
]

const agreementTypes = {
  GENERAL: "G",
  SPECIFIC: "S",
}

const inclusionFlags = {
  EXCLUDE: "E",
  INCLUDE: "I",
}

const agreementStatuses = {
  PENDING: "1",
  PROCESSING: "2",
  APPLIED: "3",
  INACTIVE: "4",
  ERROR: "5",
}

const fileStatuses = {
  PENDING: "1",
  PROCESSING: "2",
  LANDED: "3",
  INGESTED: "4",
  ERROR: "5",
  MATCHED: "6",
  MARKED_FOR_DELETION: "7",
}

const usageTypes = {
  DURATION: "1",
  PLAY_COUNT: "2",
}

const matchingStatuses = {
  UNMATCHED: "1",
  AUTOMATCHED: "2",
  MANUALLY_MATCHED: "3",
}

const landingWorkStatuses = {
  CREATED: "1",
  LINKED: "2",
  MANUALLY_CREATED: "3",
  MANUALLY_LINKED: "4",
  PENDING:"5",
  DECLINED: "6",
  DEMERGED: "7",
}

const landingAgreementStatuses = {
  CREATED: "1",
  LINKED: "2",
  PENDING: "5",
  ERROR: "6",
}

let partyNameTypes = {
  PA: "PA",  // "Patronym"
  PP: "PP",  // "Pseudonym of individuals"
  PG: "PG",  // "Pseudonym groups"
  MO: "MO",  // "Modification"
  DF: "DF",  // "Different spelling"
  ST: "ST",  // "Standardized spelling"
  OR: "OR",  // "Other reference"
  HR: "HR",  // "Holding reference"
}

const partyTypes = {
  SOCIETY: "society",
  CONTRIBUTOR: "contributor",
  PUBLISHER: "publisher",
}


const publisherRoles = {
  // These are defined in the "CWR08-3615 - CWR Lookup Tables" CISAC document
  // under "Publisher Type"
  E: "E",    // "Original Publisher"
  SE: "SE",  // "Sub Publisher"
  AM: "AM",  // "Administrator"
  AQ: "AQ",  // "Acquirer"
  PA: "PA",  // "Income Participant"
  ES: "ES",  // "Substituted Publisher"
}

const publisherRolesVerbose = {
  [publisherRoles.E]: i18n.t("original_publisher"),
  [publisherRoles.AM]: i18n.t("administrator"),
  [publisherRoles.AQ]: i18n.t("acquirer"),
  [publisherRoles.PA]: i18n.t("income_participant"),
  [publisherRoles.ES]: i18n.t("substituted_publisher"),
}

const contributorRoles = {
  // These are defined in the "CWR08-3615 - CWR Lookup Tables" CISAC document
  // under "Writer Designation"
  AD: "AD",  // "Adaptor"
  AR: "AR",  // "Arranger"
  A: "A",    // "Author of Lyrics"
  C: "C",    // "Composer"
  CA: "CA",  // "Composer and Author of Lyrics"
  SR: "SR",  // "Sub Arranger"
  SA: "SA",  // "Sub Author"
  TR: "TR",  // "Translator"
}

const contributorRolesVerbose = {
  [contributorRoles.AD]: i18n.t("adaptor"),
  [contributorRoles.AR]: i18n.t("arranger"),
  [contributorRoles.A]: i18n.t("author"),
  [contributorRoles.C]: `${i18n.t("composer")}`,
  [contributorRoles.CA]: `${i18n.t("composer")} ${i18n.t("and")} ${i18n.t("author")}`,
  [contributorRoles.SR]: i18n.t("sub_arranger"),
  [contributorRoles.SA]: i18n.t("sub_author"),
  [contributorRoles.TR]: i18n.t("translator"),
}

const CONTRIBUTOR = partyTypes.CONTRIBUTOR
const PUBLISHER = partyTypes.PUBLISHER

const partyRolesMap = {
  // Contributor roles
  "AD": CONTRIBUTOR,  // "Adaptor"
  "AR": CONTRIBUTOR,  // "Arranger"
  "A": CONTRIBUTOR,    // "Author, Writer, Author of Lyrics"
  "C": CONTRIBUTOR,    // "Composer, Writer"
  "CA": CONTRIBUTOR,  // "Composer/Author"
  "SR": CONTRIBUTOR,  // "Sub Arranger"
  "SA": CONTRIBUTOR,  // "Sub Author"
  "TR": CONTRIBUTOR,  // "Translator"
  // Publisher roles
  "E": PUBLISHER,    // "Original Publisher"
  "AM": PUBLISHER,  // "Administrator"
  "AQ": PUBLISHER,  // "Acquirer"
  "PA": PUBLISHER,  // "Income Participant"
  "ES": PUBLISHER,  // "Substituted Publisher"
  // Sub-publisher
  "SE": null,       // "Sub Publisher"
}

const soundRecordingTypes = {
  SOUND_RECORDING: "1",
  MUSIC_VIDEO: "2",
  OST: "3",
  RBT: "4",
}

const userActionStatuses = {
  PENDING: "1",
  DONE: "3",
}

const userActionTypes = {
  WORKS_MATCHING: "1",
  WORK_REGISTRATION: "2",
  RIGHTS: "3",
  RECORDING: "4",
  AGREEMENT_CONFLICT: "5",
  NON_IPI_PARTY: "6",
}

const userRoles = {
  MEMBER: "member",
  HEIR: "heir",
  REPERTOIRE_ADMIN: "repertoire",
  SOCIETY_ADMIN: "admin",
  BOARD_MEMBER: "board",
}

const workSourceTypes = {
  API: "api",
  CWR_V2_1: "cwr_v2_1",
  CWR_V2_2: "cwr_v2_2",
  ATLAS_SWR_V0_1: "atlas_swr_v0_1",
  WID: "wid",
  WAMI: "wami",
  FILSCAP: "filscap",
  MCT: "mct",
  MACP: "macp",
  MCSC: "mcsc",
  COMPASS: "compass",
  KOMCA: "komca",
  VCPMC: "vcpmc",
  SINGLEVIEW: "singleview",
  MISASIA: "misasia",
}

const latestPeriods = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
}

const workReconciliationStatuses = {
  DONE: "1",
  PENDING: "2",
  ERROR: "3",
}

const workStatusTypes = {
  PENDING: "1",
  COMPLETE: "2",
  NO_SHARES: "3",
  CONFLICT: "4",
  INCOMPLETE: "5",
}

const workFieldActionTypes = {
  ADD: "add",
  EDIT: "edit",
  REMOVE: "remove",
  CHAIN: "chain",
}

const workTitleTypes = {
  OT: "OT",
  AT: "AT",
  TT: "TT",
  ET: "ET",
  TE: "TE",
  FT: "FT",
  IT: "IT",
  PT: "PT",
  RT: "RT",
}

const adaptationTypes = {
  NEW: "NEW",
  MOD: "MOD",
  NON: "NON",
  ORI: "ORI",
  REP: "REP",
  ADL: "ADL",
  UNS: "UNS",
  TRA: "TRA",
}

const arrangementTypes = {
  NEW: "NEW",
  ARR: "ARR",
  ADM: "ADM",
  UNS: "UNS",
  ORI: "ORI",
}

const workVersionTypes = {
  ORI: "ORI",
  MOD: "MOD",
}

const productTypes = {
  RECORDING: "1",
  RECORDING_RELEASE: "2",
}

const logActionTypes = {
  ADDITION: "1",
  CHANGE: "2",
  DELETION: "3",
}

const userActionResolutionTypes = {
  SELECT_CANDIDATE: "1",
  CREATE_WORK: "2",
  EDIT_SHARES: "3",
  SELECT_AGREEMENT: "4",
  ACCEPTED: "5",
  DECLINED: "6",
}

const usageFileTagTypes = {
  LICENSEE: "2",
  USE_TYPE: "3",
  PRIORITY: "4",
}

const tagPriorityTypes = {
  HIGH: "high",
  MEDIUM: "medium",
  LOW: "low",
}

const tagUseTypes = {
  RADIO: "radio",
  KARAOKE: "karaoke",
  TV: "tv",
  CABLE: "cable",
  CONCERTS: "concerts",
  EVENTS: "events",
  CINEMA:"cinema",
  GENERAL_USERS: "general_users",
}

const workTitleTypesVerbose = {
  [workTitleTypes.AT]: i18n.t("alternative_title"),
  [workTitleTypes.TE]: i18n.t("first_line_of_text"),
  [workTitleTypes.FT]: i18n.t("formal_title"),
  [workTitleTypes.IT]: i18n.t("incorrect_title"),
  [workTitleTypes.OT]: i18n.t("original_title"),
  [workTitleTypes.TT]: i18n.t("original_title_translated"),
  [workTitleTypes.PT]: i18n.t("part_title"),
  [workTitleTypes.RT]: i18n.t("restricted_title"),
  [workTitleTypes.ET]: i18n.t("extra_search_title"),
}

const fundUnitTypes = {
  P: "percent",
  A: "amount",
}

const sharesActionReasons = {
  NO_SHARES: "3",
  CONFLICT: "4",
  INCOMPLETE: "5",
}

const distributionStatuses = {
  UNALLOCATED: "1",
  ALLOCATING: "2",
  ALLOCATED: "3",
  CLOSED: "4",
  PENDING_ALLOCATION: "5",
  ERROR: "6",
  PUBLISHED: "7",
}

const fundTypes = {
  FUND_ID: "fundInternalId",
  GROSS_AMOUNT: "grossAmount",
  DEDUCTIBLES: "deductibles",
  ADMINISTRATION_COSTS: "administrationCosts",
  NET: "netDistributableAmount",
}

const distributionPoolRuleTypes = {
  PRORATA_EQUAL: "1",
  PRORATA_PERCENTAGE: "2",
  WITH_UP: "3",
  WITHOUT_UP: "4",
  PER_PLAY: "5",
  PER_DURATION: "6",
  PRE_DISTRIBUTION_FILE: "7",
  PER_WEIGHTED_PLAY: "8",
  CRD: "9",
  VOD: "10",
}

const reportStatuses = {
  NON_EXISTENT: "non_existent",
  IN_QUEUE: "in_queue",
  PROCESSING: "processing",
  GENERATED: "generated"
}

const digitalFileTypes = {
  PRONTO_PRE_DISTRIBUTION: "pronto_pre_distribution"
}

const selectableFileTypes = {
  DIGITAL: "digital",
  USAGES: "usages",
  CRD: "crd",
  VOD: "vod",
}

const digitalFileTypesVerbose = {
  [digitalFileTypes.PRONTO_PRE_DISTRIBUTION]: i18n.t("pronto_pre_distribution")
}

const poolsFormType = {
  DIGITAL: "digital",
  DOMESTIC: "domestic",
  CRD: "crd",
  VOD: "vod",
}

const workRegistrationResolutions = {
  CREATE: "create",
  LINK: "link",
  CONFLICT: "conflict",
  TO_BE_REVIEWED: "to_be_reviewed",
}

// Should be kept in sync with /api/core/parties/mappings.py
const cisacSocieties = {
  // Members
  "001": "ACUM",
  "002": "ADDAF",
  "004": "AGADU",
  "005": "AKM",
  "007": "APDAYC",
  "008": "APRA",
  "009": "ARTISJUS",
  "010": "ASCAP",
  "012": "AMCOS",
  "015": "APA",
  "017": "AMRA",
  "019": "BMDAV",
  "023": "BUMA",
  "024": "BURIDA",
  "026": "CASH",
  "029": "SCD",
  "030": "AMAR",
  "032": "FILSCAP",
  "035": "GEMA",
  "036": "IPRS",
  "038": "JASRAC",
  "039": "MUSICAUTOR",
  "040": "KODA",
  "048": "NCB",
  "049": "ONDA",
  "050": "OSA",
  "052": "PRS",
  "055": "SABAM",
  "058": "SACEM",
  "059": "SACM",
  "061": "SADAIC",
  "063": "SAMRO",
  "064": "SOKOJ",
  "065": "SAYCE",
  "066": "SBACEM",
  "069": "SPA",
  "072": "SGAE",
  "074": "SIAE",
  "077": "STEF",
  "078": "STEMRA",
  "079": "STIM",
  "080": "SUISA",
  "084": "SAYCO",
  "085": "SOZA",
  "086": "SICAM",
  "089": "TEOSTO",
  "090": "TONO",
  "093": "UBC",
  "094": "RAO",
  "096": "COTT",
  "097": "ZAIKS",
  "098": "ZIMURA",
  "101": "SOCAN",
  "102": "NASCAM",
  "103": "ACDAM",
  "104": "MACP",
  "106": "COMPASS",
  "107": "ACAM",
  "110": "LATGA",
  "111": "HDS-ZAMP",
  "112": "SAZAS",
  "115": "UCMR-ADA",
  "116": "EAU",
  "117": "MESAM",
  "118": "KOMCA",
  "119": "MCSC",
  "122": "AKKA-LAA",
  "126": "MCT",
  "128": "IMRO",
  "129": "SOBODAYCOM",
  "136": "ZAMP - Macédoine",
  "146": "SPAC",
  "161": "MÜST",
  "169": "COSCAP",
  "176": "JACAP",
  "189": "SOCINPRO",
  "200": "MSG",
  "201": "ABRAMUS",
  "204": "GCA",
  "219": "ASSIM",
  "226": "AACIMH",
  "227": "SGACEDOM",
  "233": "SACEMLUXEMBOURG",
  "235": "SACENC",
  "246": "VCPMC",
  "249": "PAM CG",
  "250": "AEI-GUATEMALA",
  "265": "MACA",
  "269": "WAMI",
  "273": "AMUS",
  "275": "AUTODIA",
  "283": "CAPASSO",
  "299": "SCM-COOPERATIVA",
  // RMEs
  "021": "BMI",
  "044": "MCPS",
  "071": "SESAC Inc.",
  "088": "CMRRA",
  "780": "UNISON",
  "781": "SOUNDREEF",
  "782": "NexTone",
  "783": "HEXACORP LTD",
  "784": "ESMAA ",
  "786": "AllTrack",
  "787": "ORFIUM Greece",
  // No longer in existance
  "003": "AEPI",
  "013": "AWA",
  "027": "CAPAC",
  "046": "MRS",
  "053": "PROCAN",
  "081": "SARRAL",
  "083": "SONECA",
  "087": "SPACEM",
  "092": "SOCINADA",
  "108": "CHA",
  "113": "LAA",
  "114": "AGAYC",
  "123": "COSGA",
  "135": "SADH",
  "162": "AMPAL",
  "180": "MUSIKEDITION",
  "216": "SQN",
  "241": "NICAUTOR",
  // Secondary repertoire is musical
  "209": "ARMAUTHOR NGO",
  // Provisional
  "016": "BUMDA",
  "018": "BGDA",
  "025": "SODAV",
  "033": "OMDA",
  "037": "BUBEDRA",
  "043": "MCSK",
  "045": "BBDA",
  "047": "BCDA",
  "057": "SACERAU",
  "060": "SACVEN",
  "082": "OTDAV",
  "105": "MASA ",
  "124": "COSOMA",
  "127": "ALBAUTOR",
  "130": "BUTODRA",
  "133": "ZAMCOPS",
  "140": "NGO-UACRR",
  "160": "NCIP",
  "177": "KazAK",
  "214": "ECCO",
  "223": "COSOTA",
  "234": "UPRS",
  "238": "BSCAP",
  "242": "SACIM",
  "253": "AAS",
  "257": "SAVA",
  "258": "MRCSN",
  "272": "MOSCAP",
  "277": "RSAU",
  "282": "UNAC-SA",
  "284": "COSOZA",
  "285": "GHAMRO",
  "323": "ANCO",
  "331": "COSBOTS",
  "336": "KOSCAP",
  "338": "EDEM",
  "339": "EKKI",
  "344": "SEDA",
  "345": "SIIP",
  // Related to CISAC
  "006": "BUCADA",
  "011": "AUSTRO-MECHANA (AUME)",
  "020": "SOCAN RR",
  "022": "MCSN",
  "034": "HFA",
  "062": "SADEMBRA",
  "068": "SDRM",
  "076": "ACEMLA",
  "109": "KCI",
  "125": "BNDA",
  "134": "SLPRS",
  "181": "NMPA",
  "182": "PAPPRI",
  "187": "SNAC",
  "202": "AsDAC",
  "208": "SGA",
  "215": "Kyrgyzpatent",
  "217": "ABRAC",
  "218": "ANACIM",
  "220": "ATIDA",
  "221": "SABEM",
  "222": "FONOPERU",
  "224": "SOMAS",
  "228": "ROMS",
  "239": "CMC",
  "243": "SADIA",
  "244": "SASUR",
  "261": "GAI Uz",
  "263": "SACS",
  "266": "BeAT",
  "268": "COSON",
  "286": "ODDA",
  "288": "ABYROY",
  "300": "WID Centre",
  "302": "LATINAUTOR",
  "303": "NORD-DOC",
  "304": "SONGCODE",
  "305": "IMJV",
  "307": "MIS@ASIA",
  "308": "ECAD",
  "309": "LatinNet",
  "310": "DIVA",
  "311": "MCPS-PRS Alliance",
  "313": "FastTrack DCN",
  "315": "CSI",
  "316": "CIS-Net AVI",
  "317": "INTL-REP",
  "318": "SGS",
  "319": "ICE Services AB",
  "320": "ARMONIA",
  "321": "PUBLISHERS",
  "324": "CNRCMSE",
  "330": "SINGCAPS ",
  "590": "SACEM Deal Multi territorial",
  "591": "SACEM Deal",
  "592": "BACKOFFICE",
  "635": "GEMA-US",
  "658": "SACEM-US",
  "672": "SGAE-NY",
  "700": "WIPO",
  "701": "SONY",
  "702": "BMG",
  "703": "UNIVERSAL",
  "704": "DISCOVERY",
  "705": "KOBALT",
  "707": "MusicMark",
  "708": "MLC",
  "710": "ISAN",
  "758": "SACEM-LIBAN",
  "775": "Solar EMI",
  "776": "Solar Sony",
  "777": "CELAS",
  "778": "GMR",
  "779": "Polaris Nordic",
  "785": "LEA",
  "788": "MINT",
  "789": "GDSDX",
  "790": "MESAM / MSG",
  "791": "ATLAS",
  "792": "BRIDGER",
  "793": "NMP",
  "888": "PAECOL",
  // Special Codes
  "000": "Public Domain",
  "099": "NS",
  "999": "Non-IPI",

}

const dsps = {
  AMAZON: "AMAZON",
  APPLETV: "APPLETV",
  DISNEY: "DISNEY",
  NETFLIX: "NETFLIX",
}

const candidatesTypes = {
  USAGE: "usage",
  CRD: "crd"
}

const fileIngestionStatsTypes = {
  LANDING_DB_INGESTION: "landing_db_ingestion",
  SINGLE_VIEW_INGESTION: "single_view_ingestion",
}

function getFundUnitTypesDisplay (type) {
  return get({
    [fundUnitTypes.P]: i18n.t("percent"),
    [fundUnitTypes.A]: i18n.t("amount"),
  }, type)
}

function getPartyNameTypesDisplay (type) {
  return get({
    [partyNameTypes.PA]: i18n.t("patronym"),
    [partyNameTypes.PP]: i18n.t("pseudonym_of_individuals"),
    [partyNameTypes.PG]: i18n.t("pseudonym_groups"),
    [partyNameTypes.MO]: i18n.t("modification"),
    [partyNameTypes.DF]: i18n.t("different_spelling"),
    [partyNameTypes.ST]: i18n.t("standardized_spelling"),
    [partyNameTypes.OR]: i18n.t("other_reference"),
    [partyNameTypes.HR]: i18n.t("holding_reference"),
  }, type)
}
    

function getDistributionPoolRuleTypes (type) {
  return get({
    [distributionPoolRuleTypes.CRD]: "CRD",
    [distributionPoolRuleTypes.VOD]: "VOD",
    [distributionPoolRuleTypes.PRE_DISTRIBUTION_FILE]: "PRONTO",
    [distributionPoolRuleTypes.PRORATA_EQUAL]: i18n.t("pro_rata_equal"),
    [distributionPoolRuleTypes.PRORATA_PERCENTAGE]: i18n.t("pro_rata_percentage"),
    [distributionPoolRuleTypes.WITH_UP]: i18n.t("with_up"),
    [distributionPoolRuleTypes.WITHOUT_UP]: i18n.t("without_up"),
    [distributionPoolRuleTypes.PER_PLAY]: i18n.t("per_play"),
    [distributionPoolRuleTypes.PER_DURATION]: i18n.t("per_duration"),
    [distributionPoolRuleTypes.PER_WEIGHTED_PLAY]: i18n.t("per_weighted_play"),
  }, type)
}

function getDistributionStatusDisplay (type) {
  return get({
    [distributionStatuses.UNALLOCATED]: i18n.t("unallocated"),
    [distributionStatuses.PENDING_ALLOCATION]: i18n.t("pending_allocation"),
    [distributionStatuses.ALLOCATING]: i18n.t("allocating"),
    [distributionStatuses.ALLOCATED]: i18n.t("allocated"),
    [distributionStatuses.CLOSED]: i18n.t("closed"),
    [distributionStatuses.ERROR]: i18n.t("error"),
    [distributionStatuses.PUBLISHED]: i18n.t("published"),
  }, type)
}

function getDspDisplay (type) {
  return get({
    [dsps.AMAZON]: "Amazon",
    [dsps.APPLETV]: "AppleTV",
    [dsps.DISNEY]: "Disney",
    [dsps.NETFLIX]: "Netflix",
  }, type)
}

function getFundTypesDisplay (type) {
  return get({
    [fundTypes.FUND_ID]: i18n.t("fund_id"),
    [fundTypes.GROSS_AMOUNT]: i18n.t("gross_amount"),
    [fundTypes.DEDUCTIBLES]: i18n.t("deductibles"),
    [fundTypes.ADMINISTRATION_COSTS]: i18n.t("administration_costs"),
    [fundTypes.NET]: i18n.t("net_distributable_amount"),
  }, type)
}

function getSharesActionReasonDisplay (type) {
  return get({
    [sharesActionReasons.NO_SHARES]: i18n.t("no_shares"),
    [sharesActionReasons.CONFLICT]: i18n.t("conflict"),
    [sharesActionReasons.INCOMPLETE]: i18n.t("incomplete"),
  }, type)
}

function getUserActionResolutionTypeDisplay (type) {
  return get({
    [userActionResolutionTypes.SELECT_CANDIDATE]: i18n.t("select_candidate"),
    [userActionResolutionTypes.CREATE_WORK]: i18n.t("create_work"),
    [userActionResolutionTypes.EDIT_SHARES]: i18n.t("edit_shares"),
    [userActionResolutionTypes.SELECT_AGREEMENT]: i18n.t("select_agreement"),
    [userActionResolutionTypes.ACCEPTED]: i18n.t("accepted"),
    [userActionResolutionTypes.DECLINED]: i18n.t("declined"),
  }, type)
}

function getAdaptationTypeDisplay (type) {
  return get({
    [adaptationTypes.NEW]: i18n.t("new_lyrics_added"),
    [adaptationTypes.MOD]: i18n.t("modified_lyrics"),
    [adaptationTypes.NON]: i18n.t("no_lyrics"),
    [adaptationTypes.ORI]: i18n.t("original_lyrics"),
    [adaptationTypes.REP]: i18n.t("replaced_lyrics"),
    [adaptationTypes.ADL]: i18n.t("added_lyrics"),
    [adaptationTypes.UNS]: i18n.t("unspecified"),
    [adaptationTypes.TRA]: i18n.t("translated_lyrics"),
  }, type)
}

function getArrangementTypeDisplay (type) {
  return get({
    [arrangementTypes.NEW]: i18n.t("new_music_added"),
    [arrangementTypes.ARR]: i18n.t("arrangement"),
    [arrangementTypes.ADM]: i18n.t("music_added_to_text"),
    [arrangementTypes.UNS]: i18n.t("unspecified"),
    [arrangementTypes.ORI]: i18n.t("original_music"),
  }, type)
}

function getWorkVersionTypeDisplay (type) {
  return get({
    [workVersionTypes.ORI]: i18n.t("original"),
    [workVersionTypes.MOD]: i18n.t("modified_version"),
  }, type)
}

function getAgreementTypeDisplay (type) {
  return get({
    [agreementTypes.GENERAL]: i18n.t("general"),
    [agreementTypes.SPECIFIC]: i18n.t("specific"),
  }, type)
}

function getRightTypeDisplay (type) {
  return get({
    [rightCodes.MEC]: i18n.t("mechanical"),
    [rightCodes.PER]: i18n.t("performing"),
    [rightCodes.SYN]: i18n.t("synchronisation"),
  }, type)
}

function getUserActionStatusDisplay (status) {
  return get({
    [userActionStatuses.PENDING]: i18n.t("pending"),
    [userActionStatuses.DONE]: i18n.t("done"),
  }, status)
}

function getRecordingTypeDisplay (type) {
  return get({
    [soundRecordingTypes.SOUND_RECORDING]: i18n.t("sound_recording"),
    [soundRecordingTypes.MUSIC_VIDEO]: i18n.t("music_video"),
    [soundRecordingTypes.OST]: i18n.t("original_sound_track"),
    [soundRecordingTypes.RBT]: i18n.t("ring_back_tone"),
  }, type)
}

function getProductTypeDisplay (type) {
  return get({
    [productTypes.RECORDING]: i18n.t("recording"),
    [productTypes.RECORDING_RELEASE]: i18n.t("recording_release"),
  }, type)
}

function getLogTypeDisplay (type) {
  return get({
    [logActionTypes.ADDITION]: i18n.t("added"),
    [logActionTypes.CHANGE]: i18n.t("changed"),
    [logActionTypes.DELETION]: i18n.t("deleted"),
  }, type)
}

function getFileStatusDisplay (status) {
  return get({
    [fileStatuses.PENDING]: i18n.t("pending"),
    [fileStatuses.PROCESSING]: i18n.t("processing"),
    [fileStatuses.LANDED]: i18n.t("landed"),
    [fileStatuses.INGESTED]: i18n.t("ingested"),
    [fileStatuses.ERROR]: i18n.t("error"),
    [fileStatuses.MATCHED]: i18n.t("matched"),
  }, status)
}

function getUsageTypeDisplay (type) {
  return get({
    [usageTypes.DURATION]: i18n.t("duration"),
    [usageTypes.PLAY_COUNT]: i18n.t("play_count"),
  }, type)
}

function getMatchingStatusesDisplay (status) {
  return get({
    [matchingStatuses.UNMATCHED]: i18n.t("unmatched"),
    [matchingStatuses.AUTOMATCHED]: i18n.t("automatched"),
    [matchingStatuses.MANUALLY_MATCHED]: i18n.t("manually_matched"),
  }, status)
}

function getUsageTagTypeDisplay (type) {
  return get({
    [usageFileTagTypes.LICENSEE]: i18n.t("licensee"),
    [usageFileTagTypes.USE_TYPE]: i18n.t("use_type"),
    [usageFileTagTypes.PRIORITY]: i18n.t("priority"),
  }, type)
}

function getWorkSourceTypesDisplay (value) {
  return get({
    [workSourceTypes.API]: capitalize(i18n.t("manually_created")),
    [workSourceTypes.CWR_V2_1]: "CWR V2.1",
    [workSourceTypes.CWR_V2_2]: "CWR V2.2",
    [workSourceTypes.ATLAS_SWR_V0_1]: "Atlas SWR V0.1",
    [workSourceTypes.WID]: "WID",
    [workSourceTypes.WAMI]: "WAMI",
    [workSourceTypes.FILSCAP]: "FILSCAP",
    [workSourceTypes.MCT]: "MCT",
    [workSourceTypes.KOMCA]: "KOMCA",
    [workSourceTypes.MCSC]: "MCSC",
    [workSourceTypes.COMPASS]: "COMPASS",
    [workSourceTypes.MACP]: "MACP",
    [workSourceTypes.VCPMC]: "VCPMC",
    [workSourceTypes.MISASIA]: "MIS@ASIA",
  }, value)
}

function getWorkFieldActionTypesDisplay (type) {
  return get({
    [workFieldActionTypes.ADD]: i18n.t("add"),
    [workFieldActionTypes.EDIT]: i18n.t("edit"),
    [workFieldActionTypes.REMOVE]: i18n.t("remove"),
  }, type)
}

function getTagUseTypesDisplay (type) {
  return get({
    [tagUseTypes.RADIO]: i18n.t("radio"),
    [tagUseTypes.KARAOKE]: i18n.t("karaoke"),
    [tagUseTypes.TV]: i18n.t("tv"),
    [tagUseTypes.CABLE]: i18n.t("cable"),
    [tagUseTypes.CONCERTS]: i18n.t("concerts"),
    [tagUseTypes.EVENTS]: i18n.t("events"),
    [tagUseTypes.CINEMA]: i18n.t("cinema"),
    [tagUseTypes.GENERAL_USERS]: i18n.t("general_users"),
  }, type)
}

export {
  DEFAULT_MAX_DATE,
  DEFAULT_MIN_DATE,
  activationStatuses,
  adaptationTypes,
  agreementTypes,
  agreementStatuses,
  fundUnitTypes,
  arrangementTypes,
  candidatesTypes,
  cisacSocieties,
  contributorRoles,
  contributorRolesVerbose,
  matchingStatuses,
  digitalFileTypes,
  digitalFileTypesVerbose,
  distributionPoolRuleTypes,
  distributionStatuses,
  dsps,
  inclusionFlags,
  fileIngestionStatsTypes,
  fileStatuses,
  landingWorkStatuses,
  landingAgreementStatuses,
  latestPeriods,
  logActionTypes,
  partyNameTypes,
  partyRolesMap,
  partyTypes,
  poolsFormType,
  productTypes,
  publisherRolesVerbose,
  publisherRoles,
  reportStatuses,
  rightCodes,
  selectableFileTypes,
  sharesActionReasons,
  soundRecordingTypes,
  tagPriorityTypes,
  tagUseTypes,
  usageFileTagTypes,
  usageTypes,
  userActionResolutionTypes,
  userActionStatuses,
  userActionTypes,
  userRoles,
  workCodeTypes,
  workReconciliationStatuses,
  workRegistrationResolutions,
  workFieldActionTypes,
  workSourceTypes,
  workStatusTypes,
  workTitleTypes,
  workTitleTypesVerbose,
  workVersionTypes,
  // Get Display
  getAdaptationTypeDisplay,
  getAgreementTypeDisplay,
  getFundUnitTypesDisplay,
  getArrangementTypeDisplay,
  getDistributionStatusDisplay,
  getDistributionPoolRuleTypes,
  getDspDisplay,
  getFileStatusDisplay,
  getFundTypesDisplay,
  getLogTypeDisplay,
  getPartyNameTypesDisplay,
  getProductTypeDisplay,
  getRightTypeDisplay,
  getRecordingTypeDisplay,
  getSharesActionReasonDisplay,
  getTagUseTypesDisplay,
  getUsageTagTypeDisplay,
  getUsageTypeDisplay,
  getMatchingStatusesDisplay,
  getUserActionResolutionTypeDisplay,
  getUserActionStatusDisplay,
  getWorkFieldActionTypesDisplay,
  getWorkVersionTypeDisplay,
  getWorkSourceTypesDisplay,
}
