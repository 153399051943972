<template>
  <div class="d-flex flex-column py-2 mb-4">

    <!-- Title -->
    <div class="heading" :aria-controls="`collapse-${title}`" :class="{ collapsible }">
      <slot name="title">
        <div class="d-flex justify-content-between mb-1" @click="toggleVisible">
          <h3>{{ title }}</h3>
          <fa-icon class="mr-4" :icon="icon" size="lg" v-if="collapsible" />
        </div>
      </slot>
    </div>

    <!-- Body -->
    <b-collapse class="body" :id="`collapse-${title}`" v-model="visible">
      <div>
        <slot name="body"></slot>
      </div>
    </b-collapse>
  </div>
</template>

<style lang="scss" scoped>
  .body {
    background-color: rgba(224, 224, 224, 0.45);
  }

  .collapsible {
    cursor: pointer;
  }

  .heading {
    border-bottom: 1px solid  rgba(118, 118, 118, 0.4);
  }
</style>

<script>
export default {
  name: "PageSection",
  data () {
    return {
      icon: ["fas", "angle-down"],
      visible: !this.collapsible,
    }
  },
  props: {
    collapsible: {
      type: Boolean,
      default: false,
    },
    title: String,
  },
  methods: {
    toggleVisible () {
      this.visible = !this.visible
    }
  },
  watch: {
    visible (value) {
      this.icon = value ? ["fas", "angle-up"] : ["fas", "angle-down"]
    }
  },
}
</script>
