<template>
  <div>
    <work-detail :id="work.id" :work="work" :shares="shares" inSingleView lite />
    <div class="clearfix pb-3">
      <action-button
        class="float-left"
        variant="secondary"
        size="md"
        @click="matchWork(work.id)"
      >
        {{ $t("match") | capitalize }}
      </action-button>
      <b-button v-if="isSearchResult" variant="outline-secondary" class="ml-2" @click="$emit('resetSelectedResult')">
        {{ $t("back_to_results") | capitalize }}
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Candidate",
  methods: {
    matchWork (id) {
      this.$emit("match", id)
    }
  },
  props: {
    work: Object,
    shares: Object,
    isSearchResult: {
      type: Boolean,
      default: false,
    }
  },
}
</script>
