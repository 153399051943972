<template>
  <ValidationObserver ref="tagsForm" v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(onSubmit)" >
      <h3>{{ $t("edit_tags") | capitalize }}</h3>

      <!-- Selected Filenames -->
      <div class="mb-3 bg-gray p-2">
        <div v-for="file in selectedFiles" :key="file.id" class="font-weight-bold">{{ file.filename }}</div>
      </div>

      <p v-if="selectedFiles.length > 1" class="my-2 text-danger">{{ $t("editing_multiple_tags_warning") }}</p>

      <!-- Usage Period -->
      <ValidationProvider vid="usagePeriod" name="usagePeriod" v-slot="{ errors }">
        <b-form-group>
          <label for="usage-period"> {{ $t("usage_period") | capitalize }}:</label>
          <date-picker
            name="usagePeriod"
            v-model="form.usagePeriod"
            :placeholder="$t('select_date_range') | capitalize"
            range
            :format="$config.DATE_FORMAT"
            input-class="form-control"
            :input-attr="{id: 'usage-period'}"
            class="w-100 mb-2"
            :disabled-date="setDisabledDate"
          />
          <b-form-invalid-feedback :state="!errors[0]">{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <!-- Licensee -->
      <TagSearchSelect
        name="licensee"
        :label="getUsageTagTypeDisplay(LICENSEE)"
        :tagType="LICENSEE"
        v-model="form.licensee"
        choiceLabel="name"
        :placeholder="$t('add_licensee') | capitalize"
      />

      <!-- Use Type -->
      <multi-select-field
        id="use-type"
        v-model="form.useType"
        name="useType"
        :options="useTypeOptions"
        :label="$t('use_type')"
        choiceLabel="display"
        track-by="value"
        :showLabels="false"
        :placeholder="$t('select_use_type') | capitalize"
      />

      <!-- Priority -->
      <multi-select-field
        id="priority"
        v-model="form.priority"
        name="priority"
        :options="priorityOptions"
        :label="$t('priority')"
        choiceLabel="display"
        track-by="value"
        :showLabels="false"
        :placeholder="$t('select_priority') | capitalize"
      />

      <!-- Action Buttons -->
      <div class="float-right my-3">
        <b-button
          class="mr-2"
          variant="outline-secondary"
          @click="onCancel"
        >
          {{ $t("cancel") | capitalize }}
        </b-button>
        <submit-button variant="secondary">
          {{ $t("save") | capitalize }}
        </submit-button>
      </div>

    </b-form>
  </ValidationObserver>
</template>

<style scoped>
  .bg-gray {
    background: var(--background-gray);
  }

  label {
    font-weight: bold;
    font-size: 0.85rem;
  }

  ::v-deep .mx-input-wrapper input {
    font-size: 0.85rem;
  }
</style>

<script>
import {
  DEFAULT_MAX_DATE,
  DEFAULT_MIN_DATE,
  getTagUseTypesDisplay,
  getUsageTagTypeDisplay,
  tagPriorityTypes,
  tagUseTypes,
  usageFileTagTypes
} from "@/constants"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { isDefaultDateRange, parseDate } from "@/utils/dates"
import TagSearchSelect from "./TagSearchSelect"
import { capitalize } from "lodash"
import { mapActions } from "vuex"

const { LICENSEE, PRIORITY, USE_TYPE } = usageFileTagTypes
const { HIGH, MEDIUM, LOW } = tagPriorityTypes
const { RADIO, KARAOKE, TV, CABLE, CONCERTS, EVENTS, CINEMA, GENERAL_USERS } = tagUseTypes

export default {
  name: "EditTagsForm",
  components: {
    TagSearchSelect,
    ValidationObserver,
    ValidationProvider,
  },
  data () {
    return {
      form: {
        licensee: null,
        useType: null,
        priority: null,
        usagePeriod: [],
      },
      priorityOptions: [
        { value: HIGH, display: capitalize(this.$t("high")) },
        { value: MEDIUM, display: capitalize(this.$t("medium")) },
        { value: LOW, display: capitalize(this.$t("low")) },
      ],
      useTypeOptions: [
        { value: RADIO, display: capitalize(getTagUseTypesDisplay(RADIO)) },
        { value: KARAOKE, display: capitalize(getTagUseTypesDisplay(KARAOKE)) },
        { value: TV, display: capitalize(getTagUseTypesDisplay(TV)) },
        { value: CABLE, display: capitalize(getTagUseTypesDisplay(CABLE)) },
        { value: CONCERTS, display: capitalize(getTagUseTypesDisplay(CONCERTS)) },
        { value: EVENTS, display: capitalize(getTagUseTypesDisplay(EVENTS)) },
        { value: CINEMA, display: capitalize(getTagUseTypesDisplay(CINEMA)) },
        { value: GENERAL_USERS, display: capitalize(getTagUseTypesDisplay(GENERAL_USERS)) },
      ],
      formErrors: {},
      LICENSEE,
    }
  },
  methods: {
    ...mapActions("alert", ["success", "error"]),
    getUsageTagTypeDisplay,
    setDisabledDate (date) {
      return date < DEFAULT_MIN_DATE || date > DEFAULT_MAX_DATE
    },
    getRequestData () {
      let tags = { ... this.form }
      tags.useType = tags.useType?.value || null
      tags.priority = tags.priority?.value || null
      tags.licensee = tags.licensee?.value || null
      tags.usagePeriod = tags.usagePeriod?.length ? tags.usagePeriod.map(date => date ? parseDate(date, this.$config.ISO_DATE_FORMAT): null) : null

      let files = this.selectedFiles.map(f => f.id)
      return { files, tags }
    },
    onSubmit () {
      let data = this.getRequestData()

      this.$api.usages.usageFileTagsCreate(data)
        .then(() => {
          this.success(this.$t("tagging_was_successful"))
          this.$emit("tagging-success")
        }).catch((error) => {
          let tags = error.response.data?.tags || {}
          if (Object.keys(tags).length) {
            this.formErrors = { ...tags }
            this.$refs.tagsForm.setErrors(this.formErrors)
          } else {
            this.error(error.response.data)
          }
        })
    },
    onCancel () {
      this.$emit("cancel")
    },
    getTag (tags, tagType) {
      let tag = tags.filter(t => t.type == tagType)
      return tag.length ? tag[0]: null
    },
    getInitial (tags) {
      let data = { ...this.form }
      tags.forEach(tag => {
        switch (tag.type) {
        case LICENSEE:
          data.licensee = tag
          break
        case PRIORITY:
          data.priority = this.priorityOptions.filter(p => p.value === tag.value.toLowerCase())[0]
          break
        case USE_TYPE:
          data.useType = this.useTypeOptions.filter(u => u.value === tag.value.toLowerCase())[0]
          break
        default:
          // do nothing
        }
      })
      return data
    },
    setInitialData () {
      let currentFile = this.selectedFiles[0]

      let data = this.getInitial(currentFile.tags)
      let { usagePeriodStart: start, usagePeriodEnd: end } = currentFile
      let usagePeriod = isDefaultDateRange(start, end) ? [] : [new Date(start), new Date(end)]

      this.form = {
        ...data,
        usagePeriod,
      }
    }
  },
  mounted () {
    if (this.selectedFiles.length === 1) {
      this.setInitialData()
    } 
  },
  props: {
    selectedFiles: {
      Type: Array,
      default: () => ([])
    }
  },
}
</script>
