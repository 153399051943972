var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('list-layout',{attrs:{"title":_vm.$t('agreements'),"count":_vm.count},scopedSlots:_vm._u([{key:"search",fn:function(){return [_c('search-input',{staticClass:"w-25",attrs:{"value":_vm.publisherSearch,"placeholder":_vm._f("capitalize")(_vm.$t('name_search'))},on:{"search":_vm.onPublisherSearch}})]},proxy:true},{key:"actions",fn:function(){return [(_vm.canEdit)?_c('router-link',{attrs:{"to":{ name: 'agreements-create' }}},[_c('b-button',[_vm._v(_vm._s(_vm._f("startcase")(_vm.$t("create_agreement"))))])],1):_vm._e()]},proxy:true},{key:"table",fn:function(){return [_c('v-server-table',{ref:"agreements",attrs:{"name":"agreements","columns":_vm.columns,"options":_vm.options},on:{"loaded":_vm.onLoaded,"pagination":_vm.onPagination},scopedSlots:_vm._u([{key:"assignor",fn:function(ref){
var row = ref.row;
return [(_vm.isSociety)?_c('router-link',{staticClass:"text-info",attrs:{"to":{ name: _vm.partyUrl, params: { id: row.assignor.id } }}},[_vm._v(" "+_vm._s(row.assignor.name)+" ")]):_c('div',[_vm._v(_vm._s(row.assignor.name))]),_c('div',{staticClass:"text-small text-gray"},[_vm._v(" "+_vm._s(row.assignor.ipiNameNumber)+" ")])]}},{key:"assignee",fn:function(ref){
var row = ref.row;
return [(_vm.isSociety)?_c('router-link',{staticClass:"text-info",attrs:{"to":{ name: _vm.partyUrl, params: { id: row.assignee.id } }}},[_vm._v(" "+_vm._s(row.assignee.name)+" ")]):_c('div',[_vm._v(_vm._s(row.assignee.name))]),_c('div',{staticClass:"text-small text-gray"},[_vm._v(" "+_vm._s(row.assignee.ipiNameNumber)+" ")])]}},{key:"period",fn:function(ref){
var row = ref.row;
return [(row.startDate)?[_c('span',{staticClass:"text-gray text-small"},[_vm._v(_vm._s(_vm.$t("from")))]),_c('date-time-cell',{attrs:{"dateTime":row.startDate,"date-only":""}})]:_vm._e(),(row.endDate)?[_c('span',{staticClass:"text-gray text-small"},[_vm._v(_vm._s(_vm.$t("to")))]),_c('date-time-cell',{attrs:{"dateTime":row.endDate,"date-only":""}})]:_vm._e(),(row.terminationDate)?[_c('span',{staticClass:"text-gray text-small"},[_vm._v(_vm._s(_vm.$t("termination_date")))]),_c('date-time-cell',{attrs:{"dateTime":row.terminationDate,"date-only":""}})]:_vm._e()]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getAgreementTypeDisplay(row.type))+" ")]}},{key:"rightTypes",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getRightTypesDisplay(row.rightTypes))+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex flex-row justify-content-center"},[(row.status === _vm.agreementStatuses.PENDING)?_c('status',{attrs:{"text":_vm.$t('pending'),"icon":['fas', 'question-circle'],"color":"orange"}}):_vm._e(),(row.status === _vm.agreementStatuses.PROCESSING)?_c('status',{attrs:{"text":_vm.$t('processing'),"icon":['fas', 'question-circle'],"color":"orange"}}):_vm._e(),(row.status === _vm.agreementStatuses.APPLIED)?_c('status',{attrs:{"text":_vm.$t('applied'),"icon":['fas', 'check-circle'],"color":"green"}}):_vm._e(),(row.status === _vm.agreementStatuses.INACTIVE)?_c('status',{attrs:{"text":_vm.$t('inactive'),"icon":['fas', 'exclamation-circle'],"color":"gray"}}):_vm._e(),(row.status === _vm.agreementStatuses.ERROR)?_c('status',{attrs:{"text":_vm.$t('error'),"icon":['fas', 'exclamation-circle'],"color":"red"}}):_vm._e()],1)]}},{key:"current",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex flex-row justify-content-center"},[(row.current)?_c('status',{attrs:{"icon":['fas', 'check-circle'],"color":"green"}}):_vm._e(),(!row.current)?_c('status',{attrs:{"icon":['fas', 'times-circle'],"color":"red"}}):_vm._e()],1)]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex flex-row justify-content-end align-items-center"},[(row.userAction && _vm.canEdit)?_c('router-link',{attrs:{"to":{ name: 'actions-agreement-detail', params: { id: row.userAction } }}},[_c('action',{attrs:{"title":_vm.$t('go_to_agreement_conflict'),"icon":['fas', 'exclamation-circle'],"color":"red"}})],1):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'agreements-update', params: { agreementId: row.id } }}},[_c('action',{attrs:{"title":_vm.canEdit ? _vm.$t('edit') : _vm.$t('view'),"icon":_vm.canEdit ? ['fa', 'pen'] : ['far', 'eye']}})],1),(_vm.canEdit)?_c('action',{attrs:{"title":_vm.$t('delete'),"icon":['fas', 'trash']},on:{"click":function($event){return _vm.onDelete(row.id)}}}):_vm._e(),_c('modal',{attrs:{"id":'confirmation-modal-' + row.id,"title":_vm._f("capitalize")(_vm.$t('delete_agreement')),"okTitle":_vm._f("capitalize")(_vm.$t('yes')),"cancelTitle":_vm._f("capitalize")(_vm.$t('no'))},on:{"ok":function($event){return _vm.onConfirmDelete(row)}}},[_c('div',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t("confirm_delete_agreement")))+"?")])])],1)]}}])})]},proxy:true}])},[_c('router-view',{attrs:{"name":"modal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }