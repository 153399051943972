<template>
  <work-detail-layout
    :title="$t('recording_conflict') | capitalize"
    :badgeText="getStatusDisplay(userAction.status)"
    :badgeVariant="badgeVariant"
  >
    <!-- Link to selected Work -->
    <template v-slot:subtitle>
      <router-link
        v-if="userAction.status === statuses.DONE && recording.works && recording.works.length"
        :to="{ name: 'products-detail', params: { id: recording.id }}"
        class="text-info"
      >
        {{ $t("go_to_detail_page") | capitalize }}
      </router-link>
    </template>

    <!-- Top -->
    <template v-slot:top>
      <!-- CSS class defined in project.scss -->
      <div class="work-details-container">
        <b-table-lite :fields="recordingFields" :items="[recording]" class="mb-4" borderless>
          <template v-slot:cell(type)="{ item }">
            {{ getTypeDisplay(item) | capitalize }}
          </template>
        </b-table-lite>
      </div>
    </template>

    <!-- Bottom: Works that contain the recording-->
    <template v-slot:bottom>
      <!-- Help text -->
      <em v-if="userAction.status !== statuses.DONE">
        {{ $t("recording_conflict_help_text") }}
      </em>

      <!-- Tabs -->
      <tabs-layout
        v-if="userAction.status !== statuses.DONE"
        ref="tabs"
        :tabs="tabs"
        v-model="tabIndex"
        @change="onTabChange"
      >
        <!-- Tab Title -->
        <template v-slot:title="{ tab }">
          <span>{{ tab.title }}</span>
        </template>

        <!-- Tab Content -->
        <template v-slot:content>

          <!-- Works Tabs -->
          <div class="clearfix">
            <b-button
              class="float-right m-3"
              variant="primary"
              @click="selectWork(selectedWork.id)"
            >
              {{ $t("select_this_work") }}
            </b-button>
          </div>
          <Metadata :work="selectedWork" class="mb-4" />
        </template>
      </tabs-layout>
    </template>
  </work-detail-layout>
</template>

<script>
import { capitalize, get } from "lodash"
import { getProductTypeDisplay, getRecordingTypeDisplay, getUserActionStatusDisplay, productTypes, userActionStatuses } from "@/constants"
import { Metadata } from "@/pages/WorksDetail"
import { mapMutations } from "vuex"

export default {
  name: "RecordingConflictsDetail",
  components: {
    Metadata
  },
  data () {
    return {
      tabIndex: 0,
      userAction: {},
      statuses: userActionStatuses,
      recording: {},
      works: [],
      recordingFields: [
        { key: "title", label: capitalize(this.$t("title")) },
        { key: "isrc", label: this.$t("isrc") },
        { key: "type", label: capitalize(this.$t("type")) },
        { key: "artist", label: capitalize(this.$t("artist")) },
      ],
      selectedWork: null,
    }
  },
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    getStatusDisplay (status) {
      return getUserActionStatusDisplay(status)
    },
    getTypeDisplay (product) {
      return product.type == productTypes.RECORDING ? getRecordingTypeDisplay(product.recordingType) : getProductTypeDisplay(product.type)
    },
    selectWork (workAtlasId) {
      this.$api.userActions.recordingConflictsSelectWork(this.userAction.id, { workAtlasId }).then(() => {
        this.success(this.$t("work_selected"))

        this.$api.userActions.recordingConflictsRetrieve(this.id).then(response => {
          this.userAction = response.data
          this.recording = this.userAction.recording
        })
      }).catch(error => {
        this.error(error.response)
      })
    },
    onTabChange ({ tab }) {
      if (get(tab, "work")) this.selectedWork = tab.work
    },
  },
  computed: {
    badgeVariant () {
      return this.userAction.status == this.statuses.DONE ? "success" : "warning"
    },
    tabs () {
      let tabs = this.works.map((work, index) => ({
        title: `${this.$t("work")} ${index + 1}`,
        work: work,
      }))
      return tabs
    },
  },
  props: {
    id: [String, Number],
  },
  async mounted () {
    let response = await this.$api.userActions.recordingConflictsRetrieve(this.id)
    this.userAction = response.data

    this.recording = get(this.userAction, "recording")
    this.works = get(this.userAction, "conflictingWorks", [])

    if (this.works.length) this.selectedWork = this.works[0]
  }
}
</script>
