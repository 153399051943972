<template>
  <modal
    id="edit-party-modal"
    :title="$t('add_party') | startcase"
    :okTitle="$t('accept') | capitalize"
    :cancelTitle="$t('cancel') | capitalize"
    size="lg"
    :hide-footer="true"
  >
    <ValidationObserver ref="addPartyForm" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div>
          <b-form-group>
            <b-form-radio-group
              :checked="selectedSection"
              @change="onChangeSection"
              name="section"
            >
              <b-form-radio :value="OPTIONS.SEARCH_IPI_DB">{{ $t('search_ipi_db') }}</b-form-radio>
              <b-form-radio :value="OPTIONS.SEARCH_NON_IPI_PARTIES">{{ $t('search_non_ipi_parties') }}</b-form-radio>
              <b-form-radio :value="OPTIONS.CREATE_NON_IPI_PARTY">{{ $t('create_non_ipi_party') }}</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <ipi-search
            v-if="selectedSection === OPTIONS.SEARCH_IPI_DB"
            key="party-search-ipi-db"
            :party-type="form.type"
            v-model="selectedParty"
            rules="required"
          />
          <div v-if="selectedSection === OPTIONS.SEARCH_NON_IPI_PARTIES">
            <div>{{ $t('search_by') | startcase }}:</div>
            <b-form-group>
              <b-form-radio-group
                :checked="selectedSearchField"
                @change="onChangeSearchField"
                name="searchField"
              >
                <b-form-radio :value="SEARCH_FIELD_OPTIONS.NAME">{{ $t('search_by_name') | startcase }}</b-form-radio>
                <b-form-radio :value="SEARCH_FIELD_OPTIONS.ATLAS_PARTY_ID">{{ $t('search_by_party_id') }}</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <div class="d-flex">
              <party-search
                v-if="selectedSearchField === SEARCH_FIELD_OPTIONS.NAME"
                key="party-search-non-ipi-party-name"
                :party-type="form.type"
                :non-ipi="true"
                v-model="selectedParty"
                rules="required"
                class="col mx-0 px-0"
                searchField="name"
              />
              <party-search
                v-if="selectedSearchField === SEARCH_FIELD_OPTIONS.ATLAS_PARTY_ID"
                key="party-search-non-ipi-party-id"
                :party-type="form.type"
                :non-ipi="true"
                v-model="selectedParty"
                rules="required"
                class="col mx-0 px-0"
                searchField="atlasPartyId"
              />
            </div>
          </div>
          <NonIPICreateForm
            v-if="selectedSection === OPTIONS.CREATE_NON_IPI_PARTY"
            key="party-create-non-ipi-party"
            @create-party="onCreateParty"
            :type="form.type"
            rules="required"
            :selectedParty="selectedParty"
          />
        </div>
        <div class="d-flex justify-content-center">
          <submit-button class="m-1" variant="secondary">
            {{ $t("accept") | capitalize }}
          </submit-button>
          <b-button class="m-1" variant="primary" @click="cancelClick">
            {{ $t("cancel") | capitalize }}
          </b-button>
        </div>
      </b-form>
    </ValidationObserver>
  </modal>
</template>

<script>
import {
  contributorRoles as contributorRoleTypes,
  partyNameTypes,
  partyTypes,
  publisherRoles as publisherRoleTypes,
  publisherRolesVerbose,
} from "@/constants"

import NonIPICreateForm from "./NonIPICreateForm"
import { ValidationObserver } from "vee-validate"

const { CONTRIBUTOR, PUBLISHER } = partyTypes

const OPTIONS = {
  SEARCH_IPI_DB: "search_ipi_db",
  SEARCH_NON_IPI_PARTIES: "search_non_ipi_parties",
  CREATE_NON_IPI_PARTY: "create_non_ipi_party"
}

const SEARCH_FIELD_OPTIONS = {
  ATLAS_PARTY_ID: "atlasPartyId",
  NAME: "name",
}

const publisherRoles = Object.keys(publisherRolesVerbose)

export default {
  name: "CreateParty",
  components: {
    NonIPICreateForm,
    ValidationObserver,
  },
  data () {
    return {
      form: {
        name: "",
        firstName: "",
        lastName: "",
        nameType: partyNameTypes.PA,
        ipiNameNumber: null,
        ipiBaseNumber : null,
        type: CONTRIBUTOR,
        affiliations: [],
        nonIpi: true,
      },
      selectedSection: OPTIONS.SEARCH_IPI_DB,
      selectedSearchField: SEARCH_FIELD_OPTIONS.NAME,
      OPTIONS,
      CONTRIBUTOR,
      PUBLISHER,
      SEARCH_FIELD_OPTIONS,
      selectedParty: null,
      share: {}
    }
  },
  methods: {
    onCreateParty (partyForm) {
      this.selectedParty = {
        firstName: partyForm.firstName,
        lastName: partyForm.lastName,
        name: `${partyForm.firstName} ${partyForm.lastName || ""}`,
        type: this.form.type
      }
    },
    onSubmit () {
      const { selectedParty } = this

      this.form.ipiNameNumber = selectedParty.ipiNameNumber || null
      this.form.ipiBaseNumber = selectedParty.ipiBaseNumber || null
      this.form.affiliations = selectedParty.affiliations || []
      this.form.firstName = selectedParty.firstName || ""
      this.form.lastName = selectedParty.lastName || ""
      this.setCorrectRoleForParty()
      this.form.type = selectedParty.type

      if (!selectedParty.firstName && !selectedParty.lastName) {
        this.form.lastName = selectedParty.name
      }

      this.form.name = selectedParty.name
      this.form.nonIpi = this.selectedSection !== OPTIONS.SEARCH_IPI_DB
      this.form.nameType = selectedParty.nameType || partyNameTypes.PA
      this.$emit("on-accept", { ...this.form })
      this.closeModal()

    },
    clearParty () {
      this.form.firstName = ""
      this.form.lastName = ""
      this.form.name = ""
      this.form.ipiNameNumber = null
      this.form.ipiBaseNumber = null
      this.form.affiliations = []
      this.selectedParty = null
      this.form.display = ""
    },
    cancelClick () {
      this.closeModal()
    },
    closeModal () {
      this.clearParty()
      this.$bvModal.hide("edit-party-modal")
    },
    isPublisherRole (role) {
      return publisherRoles.includes(role)
    },
    setFormData (item) {
      this.share = item
      this.form.type = this.isPublisherRole(this.share.role) ? PUBLISHER : CONTRIBUTOR
      const { party } = this.share
      if (party && Object.keys(party).length > 0) {
        const partyShare = { ...party }

        this.form = partyShare
        this.selectedParty = partyShare

        if (!party.ipiNameNumber) {
          this.selectedSection = OPTIONS.CREATE_NON_IPI_PARTY
        } else if (!party.ipiBaseNumber) {
          this.selectedSection = OPTIONS.SEARCH_NON_IPI_PARTIES
        } else {
          this.selectedSection = OPTIONS.SEARCH_IPI_DB
        }
      }
    },
    onChangeSection (newType) {
      if (newType !== this.selectedSection) {
        this.clearParty()
      }
      this.selectedSection = newType
    },
    onChangeSearchField (field) {
      if (field !== this.selectedSection) {
        this.clearParty()
      }
      this.selectedSearchField = field
    },
    setCorrectRoleForParty () {
      if (this.selectedParty.type === PUBLISHER && !this.isPublisher) this.share.role = publisherRoleTypes.E
      if (this.selectedParty.type === CONTRIBUTOR && !this.isContributor) this.share.role = contributorRoleTypes.C
    },
  },
  computed: {
    isContributor () {
      return this.form.type == CONTRIBUTOR
    },
    isPublisher () {
      return this.form.type == PUBLISHER
    }
  }
}
</script>
