<template>
  <div>
    <!-- Party and Splits-->
    <b-row>
      <b-col cols="6"><b>{{ $t("party") | capitalize }}</b></b-col>
      <b-col cols="3"><b>{{ $t("performing") | capitalize }}</b></b-col>
      <b-col cols="3"><b>{{ $t("mechanical") | capitalize }}</b></b-col>
    </b-row>
    <b-row>
      <b-col cols="6" class="d-flex flex-column">
        <span>{{ message.party.name }}</span>
        <span class="text-small">{{ message.party.ipiNameNumber }}</span>
      </b-col>
      <b-col cols="3">{{ message.performing || "0.00" }}</b-col>
      <b-col cols="3">{{ message.mechanical || "0.00" }}</b-col>
    </b-row>
    <!-- Chains-->
    <b-row class="mt-3">
      <b-col cols="6">
        <b v-if="message.chains.length">{{ $t("chained_to") | capitalize }}</b>
        <b v-else>{{ $t("no_chained_shares") | capitalize }}</b>
      </b-col>
    </b-row>
    <b-row v-for="chain in message.chains" :key="chain.ipiNameNumber">
      <b-col cols="12">{{ chain.name }} <span class="text-small">{{ chain.ipiNameNumber }}</span></b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "SharesLogMessage",
  props: {
    message: Object,
  }
}
</script>