var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('list-layout',{attrs:{"title":_vm.$t('crd_matching'),"count":_vm.count,"filters":_vm.filters},scopedSlots:_vm._u([{key:"status",fn:function(){return [_c('div',{staticClass:"mr-3"},[_c('status',{attrs:{"text":_vm.$t('automatched'),"name":_vm.matchingStatuses.AUTOMATCHED,"count":_vm.summary.automatched,"icon":['fas', 'check-circle'],"color":"green","active":_vm.filters.status == _vm.matchingStatuses.AUTOMATCHED,"clickable":""},on:{"click":_vm.onStatusClick}}),_c('status',{attrs:{"text":_vm.$t('manually_matched'),"name":_vm.matchingStatuses.MANUALLY_MATCHED,"count":_vm.summary.manuallyMatched,"icon":['fas', 'check-circle'],"color":"green","active":_vm.filters.status == _vm.matchingStatuses.MANUALLY_MATCHED,"clickable":""},on:{"click":_vm.onStatusClick}}),_c('status',{attrs:{"text":_vm.$t('unmatched'),"name":_vm.matchingStatuses.UNMATCHED,"count":_vm.summary.unmatched,"icon":['fas', 'exclamation-circle'],"color":"red","active":_vm.filters.status == _vm.matchingStatuses.UNMATCHED,"clickable":""},on:{"click":_vm.onStatusClick}})],1)]},proxy:true},{key:"search",fn:function(){return [_c('b-row',[_c('b-col',{attrs:{"cols":"sm-3 pr-0"}},[_c('search-input',{staticClass:"form-group pr-0",attrs:{"value":_vm.filters.title,"placeholder":_vm._f("capitalize")(_vm.$t('search_by_title')),"label":_vm.$t('title')},on:{"search":_vm.onTitleSearch}})],1),_c('b-col',{attrs:{"cols":"sm-3 pr-0"}},[_c('society-select',{attrs:{"value":_vm.filters.society},on:{"search":_vm.onSocietySearch}})],1)],1)]},proxy:true},{key:"table",fn:function(){return [_c('div',{staticClass:"table-container"},[_c('v-server-table',{ref:"crdMatches",attrs:{"columns":_vm.columns,"options":_vm.options},on:{"loaded":_vm.onLoaded,"pagination":_vm.onPagination},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var row = ref.row;
return [(row.work)?_c('div',{staticClass:"pl-4"},[_c('router-link',{staticClass:"text-info",attrs:{"to":{ name: 'works-detail', params: { id: row.work }}}},[_vm._v(" "+_vm._s(row.title)+" ")])],1):_c('div',{staticClass:"d-flex align-items-start"},[_c('action',{staticClass:"my-1",attrs:{"title":_vm.$t('more_information'),"icon":['fas', !_vm.isOpen(row.id) ?  'angle-right': 'angle-up']},on:{"click":function($event){return _vm.showCandidatesPanel(row)}}}),_vm._v(" "+_vm._s(row.title)+" ")],1)]}},{key:"contributors",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.contributors.join(", "))+" ")]}},{key:"interestedParties",fn:function(ref){
var row = ref.row;
return [_c('action',{attrs:{"id":"mips-toggle","icon":['far', 'eye'],"color":"text-gray"},on:{"click":function($event){return _vm.$bvModal.show(("mips-modal-" + (row.id)))}}}),_c('b-modal',{attrs:{"id":("mips-modal-" + (row.id)),"title":_vm._f("capitalize")(_vm.$t('interested_parties')),"hide-footer":"","size":"xl"}},[_c('b-table-lite',{attrs:{"striped":"","hover":"","items":row.uniqueMips,"fields":_vm.mipsTableFields},scopedSlots:_vm._u([{key:"cell(status)",fn:function(data){return [(data.item.status === _vm.matchingStatuses.AUTOMATCHED)?_c('status',{attrs:{"text":_vm.getMatchingStatusesDisplay(data.item.status),"icon":['fas', 'check-circle'],"color":"green"}}):_vm._e(),(data.item.status === _vm.matchingStatuses.MANUALLY_MATCHED)?_c('status',{attrs:{"text":_vm.getMatchingStatusesDisplay(data.item.status),"icon":['fas', 'check-circle'],"color":"green"}}):_vm._e(),(data.item.status === _vm.matchingStatuses.UNMATCHED)?_c('status',{attrs:{"text":_vm.getMatchingStatusesDisplay(data.item.status),"icon":['fas', 'exclamation-circle'],"color":"red"}}):_vm._e()]}}],null,true)})],1),_c('b-tooltip',{attrs:{"target":"mips-toggle","title":_vm.$t('interested_parties')}})]}},{key:"society",fn:function(ref){
var row = ref.row;
return [_c('div',[(row.cisacCode)?_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.cisacSocieties[row.cisacCode]))]):_c('p',[_vm._v("-")])])]}},{key:"source",fn:function(ref){
var row = ref.row;
return [(row.sources && row.sources.length === 1)?_c('div',[_vm._v(" "+_vm._s(row.sources[0].filename)+" ")]):_vm._e(),(row.sources && row.sources.length > 1)?_c('div',[_vm._v(" "+_vm._s(((row.sources.length) + " " + (_vm.$t("files"))))+" "),_c('fa-icon',{staticClass:"text-info clickable ml-2",attrs:{"size":"sm","icon":['fas', 'plus-circle']},on:{"click":function($event){return _vm.showSourcesModal(row)}}})],1):_vm._e()]}},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.status === _vm.matchingStatuses.AUTOMATCHED)?_c('status',{attrs:{"text":_vm.getMatchingStatusesDisplay(row.status),"icon":['fas', 'check-circle'],"color":"green"}}):_vm._e(),(row.status === _vm.matchingStatuses.MANUALLY_MATCHED)?_c('status',{attrs:{"text":_vm.getMatchingStatusesDisplay(row.status),"icon":['fas', 'check-circle'],"color":"green"}}):_vm._e(),(row.status === _vm.matchingStatuses.UNMATCHED)?_c('status',{attrs:{"text":_vm.getMatchingStatusesDisplay(row.status),"icon":['fas', 'exclamation-circle'],"color":"red"}}):_vm._e()]}},{key:"work",fn:function(ref){
var row = ref.row;
return [(row.work)?_c('action',{attrs:{"id":"work-toggle","icon":['fas', 'exclamation-circle'],"color":"text-info"},on:{"click":function($event){return _vm.showMatchedWorkInfo(row)}}}):_c('span',[_vm._v("-")]),_c('b-tooltip',{attrs:{"target":"work-toggle","title":_vm.$t('matched_work')}})]}},{key:"child_row",fn:function(ref){
var row = ref.row;
return [(row.status === _vm.matchingStatuses.UNMATCHED)?_c('Candidates',{key:row.id,staticClass:"w-100 mt-3",attrs:{"element":row,"type":_vm.candidatesTypes.CRD},on:{"match-success":_vm.onMatchSuccess}}):_vm._e()]}}])})],1)]},proxy:true}])},[_c('b-modal',{attrs:{"id":"crd-matching-sources-modal","title":_vm._f("capitalize")(_vm.$t('sources')),"hide-footer":""},on:{"hidden":_vm.onHideInfoModal}},[_c('p',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t('sources')))+" "+_vm._s(_vm.$t('for'))+" "+_vm._s(_vm.currentUniqueMWN.title))]),(_vm.currentUniqueMWN.sources && _vm.currentUniqueMWN.sources.length > 1)?_c('div',_vm._l((_vm.currentUniqueMWN.sources),function(source){return _c('p',{key:source.id},[_vm._v(" "+_vm._s(source.filename)+" ")])}),0):_vm._e()]),_c('b-modal',{attrs:{"id":"matched-work-modal","title":_vm._f("capitalize")(_vm.$t('matched_work')),"hide-footer":"","size":"xl"},on:{"hidden":_vm.onHideInfoModal}},[_c('p',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t('matched_work')))+" "+_vm._s(_vm.$t('for'))+" \""+_vm._s(_vm.currentUniqueMWN.title)+"\"")]),_c('work-detail',{attrs:{"id":_vm.currentWork.id,"work":_vm.currentWork,"shares":_vm.currentWorkShares,"inSingleView":"","lite":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }