<template>
  <b-nav-item-dropdown
    v-if="societies.length"
    :no-caret="societies.length == 1"
    :disabled="societies.length == 1"
  >
    <template v-slot:button-content>
      <span class="text-white mr-2">{{ society | get("name") | uppercase }}</span>
    </template>
    <b-dropdown-item
      @click="onClick(society)"
      v-for="society in societies"
      :key="society.id"
    >
      {{ society.name | uppercase }}
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { mapActions, mapState } from "vuex"

export default {
  name: "SocietySelector",
  data () {
    return {
      society: ""
    }
  },
  methods: {
    onClick: function (society) {
      this.setSociety(society)
      this.$router.go(0)
    },
    ...mapActions("user", ["setSociety"]),
  },
  computed: {
    ...mapState("user", ["user"]),
    societies () {
      return this.user.societies || []
    },
  },
  watch: {
    user (value) {
      this.society = this.societies.find(society => society.id === value.society)
    }
  },
  mounted () {
    this.society = this.societies.find(society => society.id === this.user.society)
  }
}
</script>