<template>
  <div v-if="stats">
    <b-row v-for="count in Object.keys(matchingCountStats)" :key="count">
      <template>
        <b-col cols="9" class="text-left">{{ matchingCountStats[count] | capitalize }}</b-col>
        <b-col class="text-right text-gray pr-0" cols="3">{{ stats[count] | formatNumber }}</b-col>
      </template>
    </b-row>
  </div>
  <div v-else>-</div>
</template>

<script>
export default {
  name: "MatchingStats",
  data () {
    return {
      matchingCountStats: {
        totalCount: this.$t("total"),
        automatchedCount: this.$t("automatched"),
        manuallyMatchedCount: this.$t("manually_matched"),
        unmatchedCount: this.$t("unmatched"),
      }
    }
  },
  props: {
    stats: {
      type: Object,
      default: null
    },
  },
}
</script>
