import { API } from "./utils"

export const countries = {
  countriesList: () => API.get("/countries/"),
  languagesList: () => API.get("/languages/"),
  getUserLanguage: () => Promise.resolve({
    data: {
      name: "English",
      code: "en",
    },
  }),
} 