<template>
  <b-card no-body class="py-3 px-3 h-100">
    <b-row>
      <b-col class="d-flex">
        <div>
          <p class="form-control text-secondary border-white font-weight-bolder font-11">{{ $t("selected_files") | capitalize }}</p>
        </div>
      </b-col>
    </b-row>
    <!-- Files Table -->
    <v-client-table
      :columns="columns"
      :options="options"
      :data="files"
      :ref="tableRef"
      @pagination="onPagination"
    >
      <!-- Detach all -->
      <template slot="h__action" v-if="files.length">
        <div class="d-flex">
          <action
            :title="$t('detach_all_files')"
            @click="onDetachAll"
            :icon="['fas', 'times']"
            color="red"
          />
        </div>
      </template>

      <!-- File Name -->
      <template v-slot:filename="{ row }">
        <b-row>
          <b-col>
            <div>{{ row.filename }}</div>
            <!-- Tags -->
            <div class="mt-2">
              <tag :text="tag.value" :icon="getTagIcon(tag)" v-for="(tag, index) in row.tags" :key="`tag_${row.id}_${index}`" />
            </div>
          </b-col>
        </b-row>
      </template>

      <!-- Period -->
      <template v-slot:period="{ row }">
        <span class="font-8">
          {{ getUsagePeriodDisplay(row) }}
        </span>
      </template>

      <!-- Action -->
      <template v-slot:action="{ row }">
        <div class="d-flex">
          <action
            :title="$t('detach_file')"
            @click="onChange($event, row)"
            :icon="['fas', 'times']"
            color="red"
          />
        </div>
      </template>

      <!-- Child Rows -->
      <template v-slot:child_row="{ row }">
        <FileSheetSelector v-if="!fileOnlySelection && row.sheets.length" :items="row.sheets" :file="row" :key="row.id" :selected="selectedSheets" :parentTable="tableRef" />
      </template>
    </v-client-table>
  </b-card>
</template>

<style lang="scss">
  .popover {
    max-width: 50%;
  }

  .sheet-icon {
    color: $info;
  }

  .sheets-table {
    border-bottom: 1px solid $gray-dark;
    font-size: 0.9rem;
    font-weight: 300;
    color: var(--gray);
  }

  .action-column {
    width: 55px !important;
    padding: 0;
  }

  .font-8 {
    font-size: 0.8rem;
  }

  .font-1 {
    font-size: 1rem;
  }

  .font-11 {
    font-size: 1.1rem;
  }
</style>

<script>
import FileSheetSelector from "./FileSheetSelector.vue"
import { capitalize } from "lodash"
import fileSelectionMixin from "./fileSelectionMixin"

const defaultSelectedColumns = ["filename", "action"]

export default {
  name: "SelectedFiles",
  mixins: [fileSelectionMixin],
  components: {
    FileSheetSelector,
  },
  data () {
    return {
      tableRef: "selectedTable",
      currentPage: 1,
      files: [],
      columns: defaultSelectedColumns,
      options: {
        skin: "table",
        allFilesAndSheetsSelected: true,
        perPage: 5,
        showChildRowToggler: false,
        headings: {
          filename: capitalize(this.$t("filename")),
          period: capitalize(this.$t("period")),
          action: "",
        },
        columnsClasses: {
          action: "action-column font-1",
          filename: "text-break",
        },
      },
    }
  },
  methods: {
    onPagination (page) {
      this.currentPage = page
    },
    onChange (_, row) {
      this.options.allFilesAndSheetsSelected = true
      this.$emit("detach-file", row)
    },
    onDetachAll () {
      let start = (this.currentPage - 1) * this.options.perPage
      let end = start + this.options.perPage
      let filesToDetach = this.files.slice(start, end)
      this.$emit("detach-page-files", filesToDetach)
    }
  },
  watch: {
    selectedFiles (data) {
      this.files = data
      this.options = this.files.length ? { ...this.options, pagination: { show: true } } : { ...this.options, pagination: { show: false } }
      this.$refs.selectedTable.setPage(1)
    },
  },
  mounted () {
    this.files = this.selectedFiles
    this.columns = this.selectedColumns
  },
  props: {
    selectedFiles: {
      type: Array,
      default: () => []
    },
    selectedColumns: {
      type: Array,
      default: () => defaultSelectedColumns
    },
  }
}
</script>
