<template>
  <v-server-table
    name="workUpdates"
    ref="workUpdates"
    :columns="columns"
    :options="options"
    @loaded="onLoaded"
    @pagination="onPagination"
    @sorted="onSorted"
  >
    <!-- Title -->
    <template v-slot:title="{ row }">
      <router-link
        :to="{ name: 'works-detail', params: { id: row.id } }"
        class="text-info"
      >
        {{ row.title }}
      </router-link>
    </template>

    <!-- Created -->
    <template v-slot:created="{ row }">
      <date-time-cell :dateTime="row.created" class="text-gray"/>
    </template>

    <!-- Created by -->
    <template v-slot:createdBy="{ row }">
      <div>{{ row.createdBy.name }}</div>
    </template>

    <!-- Resolved by -->
    <template v-slot:resolvedBy="{ row }">
      <div>{{ row.resolvedBy && row.resolvedBy.name }}</div>
      <date-time-cell :dateTime="row.resolved" class="text-gray"/>
    </template>

    <!-- Status -->
    <template v-slot:status="{ row }">
      <status
        v-if="row.status === statuses.DONE"
        :text="$t('done')"
        :icon="['fas', 'check-circle']"
        color="green"
      />
      <status
        v-else-if="row.status === statuses.PENDING"
        :text="$t('pending')"
        :icon="['fas', 'question-circle']"
        color="orange"
      />
    </template>
  </v-server-table>
</template>

<script>
import { get, startCase } from "lodash"
import { getUserActionResolutionTypeDisplay, userActionStatuses } from "@/constants"
import { listRouteMixin } from "@/utils/mixins"

export default {
  name: "WorkUpdatesList",
  mixins: [listRouteMixin],
  data () {
    return {
      statuses: userActionStatuses,
      columns: ["title", "iswc", "created", "createdBy", "resolvedBy", "status"],
      options: {
        headings: {
          title: startCase(this.$t("title")),
          iswc: this.$t("iswc"),
          created: startCase(this.$t("created")),
          createdBy: startCase(this.$t("requested_by")),
          resolvedBy: startCase(this.$t("resolved_by")),
          status: startCase(this.$t("status")),
        },
        sortable: ["created"],
        requestKeys: { orderBy: "order_by" },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          return this.$api.userActions.workUpdateList(queryParams)
        }
      },

    }
  },
  methods: {
    getData () {
      this.$refs.workUpdates.getData()
    },
    onLoaded ({ data }) {
      this.$emit("loaded", data.count)
    },
    onSorted (sorting) {
      this.applyFilters("order_by", sorting.column)
      this.applyFilters("ascending", sorting.ascending)
    },
    resolutionDisplay (resolution) {
      return resolution ? getUserActionResolutionTypeDisplay(resolution) : ""
    },
  },
  watch: {
    titleSearch (value) {
      this.applyFilters("title", value)
    },
    codeSearch (value) {
      this.applyFilters("code", value)
    },
    status (value) {
      this.applyFilters("status", value ? value.value : null)
    },
  },
  props: {
    titleSearch: String,
    codeSearch: String,
    status: Object,
  }
}
</script>
