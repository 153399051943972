<template>
  <b-modal
    id="user-create-modal"
    :title="modalTitle"
    size="md"
    :visible="visible"
    :hide-footer="true"
    @hidden="$router.go(-1)"
  >
    <UsersCreateForm :party="party" />
  </b-modal>
</template>

<script>
import UsersCreateForm from "./UsersCreateForm"
import { capitalize } from "lodash"

export default {
  name: "UsersCreateModal",
  components: {
    UsersCreateForm
  },
  data () {
    return {
      party: { displayName: null },
      visible: false
    }
  },
  computed: {
    modalTitle () {
      return `${capitalize(this.$t("create_new_user_for"))}: ${this.party.displayName}`
    }
  },
  mounted () {
    this.$api.parties.partiesRetrieve(this.$route.params.id)
      .then(response => {
        this.party = response.data
        this.visible = true
      })
  }
}
</script>