<template>
  <action
    :title="$t('clear_filters') | startcase"
    :text="$t('clear_filters') | startcase"
    :icon="['fas', 'times-circle']"
    @click="clearFilters()"
  />
</template>

<script>
import { listRouteMixin } from "@/utils/mixins"

export default {
  name: "ClearFiltersButton",
  mixins: [listRouteMixin],
  methods: {
    clearFilters () {
      this.clearRoute()
    },
    getData () {
      return []
    }
  },
  props: {
    filters: Object
  }
}
</script>
