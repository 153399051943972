<template>
  <!-- Table -->
  <v-server-table
    :columns="columns"
    :options="options"
    ref="works"
  >
    <!-- Actions -->
    <template v-slot:actions="{ row }">
      <div class="d-flex justify-content-end">
        <!-- Select Work -->
        <action-button
          class="float-right"
          variant="outline-secondary"
          size="sm"
          @click="$emit('select', row.id)"
        >
          {{ $t("view") }}
        </action-button>
      </div>
    </template>
  </v-server-table>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "WorksMatchingWorksList",
  data () {
    return {
      count: 0,
      columns: ["title", "id", "iswc", "actions"],
      openChildRows: [],
      options: {
        showChildRowToggler: false,
        headings: {
          title: this.$t("title"),
          id: this.$t("atlas_id"),
          iswc: this.$t("iswc"),
          actions: "",
        },
        requestKeys: { query: "title_search" },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (params) {
          if (params["title_search"]) {
            return this.$api.repertoire.worksList(params)
          } else {
            return new Promise(() => {})
          }
        }
      }
    }
  },
  methods: {
    getData () {
      this.$refs.works.getData()
    },
    showExtraInfo ({ id }) {
      if (this.isOpen(id)) {
        this.openChildRows = this.openChildRows.filter(openRowId => id !== openRowId)
      } else {
        this.openChildRows.push(id)
      }
      this.$refs.works.toggleChildRow(id)
    },
    isOpen (id) {
      return this.openChildRows.includes(id)
    },
  },
  computed: {
    ...mapState("filters", ["search"]),
  },
  watch: {
    search (search) {
      this.$refs.works.setFilter(search)
    },
  },
  mounted () {
    // This timeout avoids problems on tab change.
    setTimeout(() => this.$refs.works.setFilter(this.search), 100)
  },
}
</script>
