var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{attrs:{"items":_vm.payeeShares,"fields":_vm.fields,"borderless":"","striped":"","show-empty":""},scopedSlots:_vm._u([{key:"cell(ipiNameNumber)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.party.ipiNameNumber)+" ")]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(item.party.name))])])]}},{key:"cell(mechanical)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mechanical || "0.00")+"% ")]}},{key:"cell(performing)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.performing || "0.00")+"% ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }