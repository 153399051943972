<template>
  <component v-bind:is="listComponent"></component>
</template>

<script>
import DistributionsList from "./DistributionsList.vue"
import MemberDistributionsList from "./MemberDistributionsList.vue"
import { mapGetters } from "vuex"

export default {
  name: "DistributionsListPage",
  components: {
    DistributionsList,
    MemberDistributionsList,
  },
  computed: {
    ...mapGetters("user", ["isMember"]),
    listComponent () {
      return this.isMember ? MemberDistributionsList : DistributionsList
    },
  }
}
</script>
