<!-- Based On: https://codesandbox.io/s/validation-components-bootstrapvue-usdwv -->
<template>
  <ValidationProvider :vid="$attrs.name" :name="$attrs.name" :rules="rules" v-slot="{ errors }">
    <transition name="slide">
      <b-form-group
        v-bind="$attrs"
        :invalid-feedback="errors[0]"
        :label="null"
      >
        <label :for="$attrs.name" v-if="$attrs.label">
          {{ $attrs.label | capitalize }}: <span v-if="isRequired" class="text-danger">*</span>
        </label>
        <b-form-textarea
          v-model="innerValue"
          :placeholder="helpText"
          :state="errors[0] ? false : null"
          :readonly="readonly"
          :max-rows="512"
          trim
          :class="readonly && 'read-only'"
        ></b-form-textarea>
      </b-form-group>
    </transition>
  </ValidationProvider>
</template>

<style lang="scss" scoped>
  label {
    font-weight: bold;
    font-size: 0.85rem;
  }

  textarea {
    overflow-y: auto !important;
  }

  .form-control.read-only {
    border: none;
    outline: none;
    box-shadow: none;
  }
</style>

<script>
import { ValidationProvider } from "vee-validate"

export default {
  name: "FormTextArea",
  components: {
    ValidationProvider
  },
  props: {
    rules: {
      type: [Object, String],
      default: ""
    },
    value: {
      type: null
    },
    helpText: String,
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    innerValue: "",
  }),
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit("input", newVal)
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal
    }
  },
  computed: {
    isRequired () {
      return this.rules ? this.rules.split("|").includes("required") : false
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>
