<template>
  <b-modal
    id="file-errors-modal"
    :title="$t('file_errors') | capitalize"
    :hide-footer="true"
    size="lg"
  >
    <!-- Global Errors -->
    <template v-if="item.id && onlyShowGlobalErrors && item.statusString">
      <div :key="index" v-for="(line, index) in item.statusString.split('\n')">
        <p class="mb-1">{{ cleanupLine(line) }}</p>
      </div>
    </template>

    <!-- Row Errors -->
    <div v-if="item.id && !onlyShowGlobalErrors">
      <v-server-table
        v-show="errorCount"
        :columns="columns"
        :url="item.id"
        :options="options"
        @loaded="onLoaded"
      >
        <!-- Reason -->
        <template v-slot:reason="{ row }">
          <span>{{ getReason(row.reason) }}</span>
        </template>
      </v-server-table>
    </div>
  </b-modal>
</template>

<script>
import { capitalize, trim } from "lodash"

export default {
  name: "FileErrorsModal",
  data (){
    return {
      errorCount: 0,
      options: {
        headings: {
          itemSeq: capitalize(this.$t("item")),
          title: capitalize(this.$t("title")),
          field: capitalize(this.$t("field")),
          type: capitalize(this.$t("type")),
          reason: capitalize(this.$t("reason")),
        },
        responseAdapter ({ data }) {
          this.$parent.$parent.errorCount = data.count
          this.error_count = data.count
          return {
            data: data.results,
            count: data.count,
          }
        },
        ...this.errorsListOptions,
      }
    }
  },
  methods: {
    cleanupLine (line) {
      return trim(line, "()''")
    },
    onLoaded ({ data }) {
      this.errorCount = data.count
    },
    getReason (reason) {
      return Array.isArray(reason) ? reason.join(", ") : reason
    }
  },
  props: {
    columns: {
      type: Array,
      default: () => (["itemSeq", "field", "type", "reason"])
    },
    errorsListOptions: Object,
    item: {
      type: Object,
      default: () => ({})
    },
    onlyShowGlobalErrors: {
      type: Boolean,
      default: false,
    },
  }
}
</script>
