var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-server-table',{ref:"agreementConflicts",attrs:{"name":"agreementConflicts","columns":_vm.columns,"options":_vm.options},on:{"loaded":_vm.onLoaded,"pagination":_vm.onPagination},scopedSlots:_vm._u([{key:"agreementAtlasId",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.relatedObject.id))])]}},{key:"assignor",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"text-info",attrs:{"to":{ name: 'parties-detail', params: { id: row.relatedObject.assignor.id } }}},[_vm._v(" "+_vm._s(row.relatedObject.assignor.name)+" ")]),_c('div',{staticClass:"text-small text-gray"},[_vm._v(" "+_vm._s(row.relatedObject.assignor.ipiNameNumber)+" ")])]}},{key:"assignee",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"text-info",attrs:{"to":{ name: 'parties-detail', params: { id: row.relatedObject.assignee.id } }}},[_vm._v(" "+_vm._s(row.relatedObject.assignee.name)+" ")]),_c('div',{staticClass:"text-small text-gray"},[_vm._v(" "+_vm._s(row.relatedObject.assignee.ipiNameNumber)+" ")])]}},{key:"period",fn:function(ref){
var row = ref.row;
return [(row.relatedObject.startDate)?[_c('span',{staticClass:"text-gray text-small"},[_vm._v(_vm._s(_vm.$t("from")))]),_c('date-time-cell',{attrs:{"dateTime":row.relatedObject.startDate,"date-only":""}})]:_vm._e(),(row.relatedObject.endDate)?[_c('span',{staticClass:"text-gray text-small"},[_vm._v(_vm._s(_vm.$t("to")))]),_c('date-time-cell',{attrs:{"dateTime":row.relatedObject.endDate,"date-only":""}})]:_vm._e(),(row.relatedObject.terminationDate)?[_c('span',{staticClass:"text-gray text-small"},[_vm._v(_vm._s(_vm.$t("termination_date")))]),_c('date-time-cell',{attrs:{"dateTime":row.relatedObject.terminationDate,"date-only":""}})]:_vm._e()]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getAgreementTypeDisplay(row.relatedObject.type))+" ")]}},{key:"rightTypes",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getRightTypesDisplay(row.relatedObject.rightTypes))+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.status === _vm.statuses.DONE)?_c('status',{attrs:{"text":_vm.$t('done'),"icon":['fas', 'check-circle'],"color":"green"}}):(row.status === _vm.statuses.PENDING)?_c('status',{attrs:{"text":_vm.$t('pending'),"icon":['fas', 'question-circle'],"color":"orange"}}):_vm._e()]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"to":{ name: 'actions-agreement-detail', params: { id: row.id } }}},[_c('action',{attrs:{"title":_vm.$t('view'),"icon":['far', 'eye']}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }