<template>
  <div v-if="stats">
    <b-row v-for="count in Object.keys(ingestionCountStats)" :key="count">
      <template v-if="count === 'errorCount'">
        <b-col cols="6" class="text-left">
          {{ $t("errors") | capitalize }}
          <fa-icon
            class="text-info clickable ml-1"
            size="sm"
            :icon="['fas', 'plus-circle']"
            v-if="stats.errorCount > 0"
            @click="showErrors"
          />
        </b-col>
        <b-col class="text-right pr-0 text-gray" cols="6">
          {{ stats.errorCount }}
        </b-col>
      </template>
      <template v-else>
        <b-col cols="9" class="text-left">{{ ingestionCountStats[count] | capitalize }}</b-col>
        <b-col class="text-right pr-0 text-gray" cols="3">{{ stats[count] | formatNumber }}</b-col>
      </template>
    </b-row>
  </div>
  <div v-else>-</div>
</template>

<script>

export default {
  name: "IngestionStats",
  data () {
    return {
      ingestionCountStats: {
        totalCount: this.$t("total"),
        ingestedCount: this.$t("ingested"),
        errorCount: this.$t("error"),
      },
    }
  },
  methods: {
    showErrors () {
      this.$emit("show-errors")
    }
  },
  props: {
    stats: {
      type: Object,
      default: null
    },
  },
}
</script>
