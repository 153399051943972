<template>
  <multi-select-field
    v-bind="$attrs"
    v-model="innerValue"
    :options="options"
    track-by="id"
    choiceLabel="name"
    :multiple="false"
    :allowEmpty="true"
    :show-no-results="true"
    :placeholder="placeholder"
    :searchable="true"
    :internal-search="false"
    :errors="innerErrors"
    @search-change="searchDistributions"
    :select-label="'select'"
    @remove="onRemoveOption"
  />
</template>

<script>
import { debounce } from "lodash"

export default {
  name: "DistributionFilter",
  data () {
    return {
      options: [],
      innerValue: null,
      innerErrors: []
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue (newOption) {
      this.$emit("input", newOption?.id)
    },
    // Handles external model changes.
    value (newVal) {
      this.getOption(newVal)
    },
    errors (newVal) {
      this.innerErrors = newVal
    }
  },
  methods: {
    reset () {
      this.innerValue = null
      this.innerErrors = []
    },
    setInitial () {
      if (!this.innerValue && this.value) this.getOption(this.value)
    },
    searchDistributions: debounce( function (query) {
      const searchQuery = query.trim()
      if (!searchQuery) return
      this.innerErrors = []
      this.getDistributionList({ name: searchQuery })
    }, 1000),
    getOption (optionId) {
      const option = this.options.filter(o => o.id === optionId)
      if (option.length) this.innerValue = option[0]
      else this.innerValue = null
    },
    getDistributionList (query) {
      this.$api.distributions.distributionsList(query)
        .then(({ data }) => {
          this.options = data.results
          this.setInitial()
        })
        .catch((error) => {
          this.innerErrors = [error.response.data.message]
        })
    },
    onRemoveOption () {
      this.getDistributionList()
    }
  },
  props: {
    value: {
      type: null
    },
    errors: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: "",
    }
  },
  mounted () {
    this.getDistributionList()
  }
}
</script>
