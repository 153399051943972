<template>
  <ValidationProvider :vid="$attrs.name" :name="$attrs.name" :rules="rules" v-slot="{ errors }">
    <transition name="fade" mode="out-in">
      <b-form-group
        v-bind="$attrs"
        :invalid-feedback="errors[0]"
        :label="null"
      >
        <b-input-group>
          <template #prepend>
            <b-dropdown variant="info" class="mx-0 px-0">
              <template v-slot:button-content>
                <span class="text-white mr-1">{{ innerType | uppercase }}</span>
              </template>
              <b-dropdown-item
                v-for="type in itemTypesCodes"
                :key="type"
                @click="onClick(type)"
              >
                <span v-if="itemTypesIsArray">{{ type | uppercase }}</span>
                <span v-else>{{ type }} - {{ itemTypes[type] | capitalize }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <b-form-input
            :id="$attrs.name"
            v-model="innerValue"
            v-bind="$attrs"
            :state="errors[0] ? false : null"
            :formatter="formatter"
            :required="isRequired"
          />
        </b-input-group>
        <b-form-text v-if="helpText">{{ helpText }}</b-form-text>
      </b-form-group>
    </transition>
  </ValidationProvider>
</template>

<style scoped>
  .dropdown ::v-deep .btn {
    font-size: 0.9rem;
    padding-left: 10px;
    padding-right: 10px;
  }

  ::v-deep .dropdown-menu {
    border: 1px solid var(--light-gray);
    box-shadow: 0 2px 4px 0 var(--light-gray);
    border-radius: 5px;
    min-width: 2.5rem;
  }

  ::v-deep .form-group {
    margin-bottom: 0;
  }

  ::v-deep .dropdown-item {
    padding: 5px 20px;
    letter-spacing: 0.2px;
  }

  ::v-deep .dropdown-toggle::after {
    color: var(--white);
  }

  /* This is required because of https://github.com/twbs/bootstrap/issues/23454 */
  ::v-deep .invalid-feedback {
    display: block;
  }
</style>

<script>
import { ValidationProvider } from "vee-validate"
import { fomatterMixin } from "@/utils/mixins"

export default {
  name: "TypeAndValueInput",
  mixins: [fomatterMixin],
  components: {
    ValidationProvider
  },
  data () {
    return {
      innerType: Array.isArray(this.itemTypes) ? this.itemTypes[0] : Object.keys(this.itemTypes)[0],
      innerValue: "",
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit("input", {
        type: this.innerType,
        value: newVal,
        ...this.item.id && { id: this.item.id }
      })
    },
    innerType (newVal) {
      this.$emit("input", {
        type: newVal,
        value: this.innerValue,
        ...this.item.id && { id: this.item.id }
      })
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal
    },
    type (newVal) {
      this.innerType = newVal
    }
  },
  computed: {
    isRequired () {
      return this.rules ? this.rules.split("|").includes("required") : false
    },
    itemTypesIsArray () {
      return Array.isArray(this.itemTypes)
    },
    itemTypesCodes () {
      return this.itemTypesIsArray ? this.itemTypes : Object.keys(this.itemTypes)
    },
    value () {
      return this.item.value
    },
    type () {
      return this.item.type
    }
  },
  methods: {
    formatter (value, event) {
      this.setCorrectTextCursorPosition(event)
      return this.upper ? value.toUpperCase() : value
    },
    onClick (type) {
      this.innerType = type
    },
  },
  created () {
    if (this.item && this.item.type) {
      this.innerType = this.item.type
      this.innerValue = this.item.value
    }
  },
  props: {
    itemTypes: [Object, Array],
    rules: {
      type: [Object, String],
      default: ""
    },
    item: {
      type: Object
    },
    helpText: String,
    upper: Boolean
  },
}
</script>
