<template>
  <div>
    <b-row class="text-gray mb-2">
      <b-col cols="2">{{ $t("ipi_name_number") | startcase }}</b-col>
      <b-col cols="6">{{ $t("full_name") | startcase }}</b-col>
    </b-row>
    <b-row class="mb-1" v-for="(contributor, index) in work.contributors" :key="index">
      <b-col cols="2">{{ contributor.ipiNameNumber }}</b-col>
      <b-col cols="6">{{ contributor.fullName }}</b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "WorksListRowExtra",
  props: {
    work: Object
  }
}
</script>