<template>
  <list-layout :title="party" :count="count">
    <template v-slot:subtitle>
      <h4>{{ $t("users") | startcase }}</h4>
    </template>
    <!-- Status -->
    <template v-slot:status>
      <status :text="$t('active')" :count="summary.active" :icon="['fas', 'check-circle']" color="green" />
      <status :text="$t('pending')" :count="summary.pending" :icon="['fas', 'question-circle']" color="orange" />
      <status :text="$t('blocked')" :count="summary.inactive" :icon="['fas', 'exclamation-circle']" color="red" />
    </template>

    <!-- Actions -->
    <template v-if="isAdmin" v-slot:actions>
      <div>
        <b-button :to="{ name: 'users-create'}" variant="outline-primary" class="ml-3">
          <fa-icon class="mr-2" :icon="['fas', 'plus-circle']"/>
          {{ $t("create") | capitalize }}
        </b-button>
      </div>
    </template>

    <!-- Table -->
    <template v-slot:table>
      <v-server-table
        ref="users"
        :columns="columns"
        :options="options"
        @loaded="onLoaded"
        @pagination="onPagination"
      >
        <!-- Name -->
        <template v-slot:name="{ row }">
          <router-link
            class="text-info"
            :to="{ name: 'users-update', params: { uid: row.id } }"
          >
            {{ row.name }}
          </router-link>
        </template>

        <!-- Role -->
        <template v-slot:role="{ row }">
          <span class="text-gray">
            {{ row.role }}
          </span>
        </template>

        <!-- Status -->
        <template v-slot:status="{ row }">
          <status v-if="row.status === 'active'" :text="$t('active')" :icon="['fas', 'check-circle']" color="green" />
          <status v-if="row.status === 'pending'" :text="$t('pending')" :icon="['fas', 'question-circle']" color="orange" />
          <status v-if="row.status === 'inactive'" :text="$t('inactive')" :icon="['fas', 'exclamation-circle']" color="red" />
        </template>

        <!-- Created At -->
        <template v-slot:createdAt="{ row }">
          <date-time-cell :dateTime="row.createdAt"/>
        </template>

        <!-- Actions -->
        <template v-slot:actions="{ row }" v-if="isAdmin">
          <div class="d-flex justify-content-end">
            <action
              :title="$t('delete') | lowercase"
              v-if="!isCurrentUser(row.id)"
              @click="onDelete(row.id)"
              :icon="['fas', 'trash']"
            />

            <!-- Deletion Confirmation Modal -->
            <modal
              :id="'confirmation-modal-' + row.id"
              :title="$t('delete_user')"
              :okTitle="$t('yes') | capitalize"
              :cancelTitle="$t('no') | capitalize"
              @ok="onConfirmDelete(row.id)"
            >
              <div>{{ $t("confirm_delete_user") | capitalize }}?</div>
            </modal>
          </div>
        </template>
      </v-server-table>
    </template>
    <router-view name="modal"></router-view>
  </list-layout>
</template>

<script>
import { get, startCase } from "lodash"
import { listRouteMixin } from "@/utils/mixins"
import { mapGetters } from "vuex"

export default {
  name: "UsersList",
  mixins: [listRouteMixin],
  methods: {
    onDelete (id) {
      this.$bvModal.show(`confirmation-modal-${id}`)
    },
    onConfirmDelete (id) {
      let partyId = this.$route.params.id
      this.$api.users.usersDelete(partyId, id)
        .then(() => {
          this.$refs.users.refresh()
        })
    },
    onLoaded ({ data }) {
      this.party = data.context
      this.count = data.length
      this.summary = data.summary
    },
    getData () {
      this.$refs.users.getData()
    },
  },
  data () {
    return {
      count: 0,
      summary: { active: 0, pending: 0, inactive: 0 },
      party: "",
      columns: ["name", "email", "role", "status", "createdAt", "actions"],
      options: {
        headings: {
          name: startCase(this.$t("name")),
          email:startCase( this.$t("email")),
          role: startCase(this.$t("role")),
          status: startCase(this.$t("status")),
          createdAt: startCase(this.$t("created")),
          actions: "",
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          let { params, query } = this.$route
          queryParams = { ...queryParams, ...query }
          this.page = Number(get(queryParams, "page", 1))
          return this.$api.users.usersList(params.id, queryParams)
        }
      }
    }
  },
  computed: {
    ...mapGetters("user", ["isAdmin", "isCurrentUser"]),
  },
}
</script>
