import { API } from "./utils"

export const parties = {
  ipiSearch: (params) => API.get("/parties/ipi/search/", { params }),
  partiesList: (params=null) => API.get("/parties/", { params }),
  partiesRetrieve: (id) => API.get(`/parties/${id}/`),
  partiesWorksList: (id, params) => API.get(`/parties/${id}/works/`, { params }),
  partiesWorksReport: (id, data) => API.post(`/parties/${id}/works/report/`, data),
  membersContactUpdate: (id, data) => API.put(`/parties/members/${id}/contact/`, data),
  membersUsersList: (id) => API.get(`/parties/members/${id}/users/`),
  membersUsersCreate: (id, data) => API.post(`/parties/members/${id}/users/`, data),
  heirsCreate: (id, data) => API.post(`/parties/members/${id}/heirs/`, data),
  heirsList: (id) => API.get(`/parties/members/${id}/heirs/`),
  heirsUpdate: (id, hid, data) => API.put(`/parties/members/${id}/heirs/${hid}/`, data),
  heirsDelete: (id, hid) => API.delete(`/parties/members/${id}/heirs/${hid}/`),
}
