var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-server-table',{ref:"sharesActions",attrs:{"name":"sharesActions","columns":_vm.columns,"options":_vm.options},on:{"loaded":_vm.onLoaded,"pagination":_vm.onPagination},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"text-info",attrs:{"to":{ name: 'works-detail', params: { id: row.workId } }}},[_vm._v(" "+_vm._s(row.title)+" ")])]}},{key:"workId",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(row.workId)+" ")])]}},{key:"iswc",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(row.iswc)+" ")])]}},{key:"rightType",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(row.rightType)+" ")])]}},{key:"reason",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm._f("startcase")(_vm.getReason(row.reason)))+" ")])]}},{key:"created",fn:function(ref){
var row = ref.row;
return [_c('date-time-cell',{attrs:{"dateTime":row.created}})]}},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.status === _vm.statuses.DONE)?_c('status',{attrs:{"text":_vm.$t('done'),"icon":['fas', 'check-circle'],"color":"green"}}):(row.status === _vm.statuses.PENDING)?_c('status',{attrs:{"text":_vm.$t('pending'),"icon":['fas', 'question-circle'],"color":"orange"}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }