<template>
  <v-server-table
    name="recordingConflicts"
    ref="recordingConflicts"
    :columns="columns"
    :options="options"
    @loaded="onLoaded"
    @pagination="onPagination"
  >
    <!-- Title -->
    <template v-slot:title="{ row }">
      <router-link
        :to="{ name: 'actions-recording-detail', params: { id: row.id } }"
        class="text-info"
      >
        {{ row.recording.title }}
      </router-link>
    </template>

    <!-- ISRC -->
    <template v-slot:isrc="{ row }">
      <span class="text-primary">
        {{ row.recording.isrc }}
      </span>
    </template>

    <!-- Status -->
    <template v-slot:status="{ row }">
      <status
        v-if="row.status === statuses.DONE"
        :text="$t('done')"
        :icon="['fas', 'check-circle']"
        color="green"
      />
      <status
        v-else-if="row.status === statuses.PENDING"
        :text="$t('pending')"
        :icon="['fas', 'question-circle']"
        color="orange"
      />
    </template>
  </v-server-table>
</template>

<script>
import { capitalize, get } from "lodash"
import { listRouteMixin } from "@/utils/mixins"
import { userActionStatuses } from "@/constants"

export default {
  name: "RecordingsConflictsList",
  mixins: [listRouteMixin],
  data () {
    return {
      statuses: userActionStatuses,
      columns: ["title", "isrc", "artist", "status"],
      options: {
        headings: {
          title: capitalize(this.$t("title")),
          isrc: this.$t("isrc"),
          artist: capitalize(this.$t("artist")),
          status: capitalize(this.$t("status")),
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          return this.$api.userActions.recordingConflictsList(queryParams)
        }
      },
    }
  },
  methods: {
    getData () {
      this.$refs.recordingConflicts.getData()
    },
    onLoaded ({ data }) {
      this.$emit("loaded", data.count)
    }
  },
  watch: {
    status (value) {
      this.applyFilters("status", value ? value.value : null)
    },
  },
  props: {
    status: Object,
  }
}
</script>
