import { partyTypes, userRoles } from "../constants"
import { app } from "../main"


const state = {
  waiting: false,
  user: {
    id: null,
    role: null,
    party: { type: null }
  },
  fetched: false,
  loggedIn: false,
  pageNotFound: false,
  unauthorized: false,
}

const mutations = {
  waiting (state, waiting) {
    state.waiting = waiting
  },
  setUser (state, user) {
    state.user = user
    state.fetched = true
  },
  setLanguage (state, language) {
    state.user.language = language
  },
  setFetched (state, fetched) {
    state.fetched = fetched
  },
  setLoggedIn (state, loggedIn) {
    state.loggedIn = loggedIn
  },
  setPageNotFound (state, pageNotFound) {
    state.pageNotFound = pageNotFound
  },
  setUnauthorized (state, unauthorized) {
    state.unauthorized = unauthorized
  },
  setSociety (state, society) {
    state.user = { ...state.user, society }
  },
}

const actions = {
  setUser ({ commit, dispatch }, data) {
    commit("setUser", data)
    dispatch("setLanguage", data.language)

    let society = state.user.societies.length ? state.user.societies[0] : {}
    dispatch("setSociety", society)

    commit("setLoggedIn", true)
    commit("setFetched", true)
  },
  logout ({ commit }) {
    this._vm.$auth.logout()
    commit("setUser", {
      id: null,
      role: null,
      party: { type: null }
    })
    commit("setLoggedIn", false)
    commit("setFetched", false)
  },
  setLanguage ({ commit }, lang) {
    // Language must be a valid alpha_2 code. If null,
    // default to 'en'.
    const language = lang ? lang.toLowerCase() : "en"
    commit("setLanguage", language)
    app.$i18n.locale = language
    localStorage.setItem("language", language)
  },
  setSociety ({ commit }, society) {
    commit("setSociety", society)
    localStorage.setItem("society", society?.id)
  },
}

const getters = {
  isAdmin: state => {
    return state.user.role === userRoles.SOCIETY_ADMIN
  },
  isRepertoireAdmin: state => {
    return state.user.role === userRoles.REPERTOIRE_ADMIN
  },
  isMember: state => {
    return [userRoles.MEMBER, userRoles.HEIR].includes(state.user.role)
  },
  isContributor: state => {
    return state.user.party.type === partyTypes.CONTRIBUTOR
  },
  isPublisher: state => {
    return state.user.party.type === partyTypes.PUBLISHER
  },
  isSociety: state => {
    return state.user.party.type === partyTypes.SOCIETY
  },
  isCurrentUser: (state) => (id) => {
    return state.user.id === id
  },
  canManagePartyResource: (state) => (id) => {
    return state.user.party.id === id && state.user.role === userRoles.SOCIETY_ADMIN
  },
}
export const user = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
