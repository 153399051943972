<template>
  <v-server-table
    name="sharesActions"
    ref="sharesActions"
    :columns="columns"
    :options="options"
    @loaded="onLoaded"
    @pagination="onPagination"
  >
    <!-- Title -->
    <template v-slot:title="{ row }">
      <router-link
        :to="{ name: 'works-detail', params: { id: row.workId } }"
        class="text-info"
      >
        {{ row.title }}
      </router-link>
    </template>

    <!-- Atlas ID -->
    <template v-slot:workId="{ row }">
      <span class="text-primary">
        {{ row.workId }}
      </span>
    </template>

    <!-- ISWC -->
    <template v-slot:iswc="{ row }">
      <span class="text-primary">
        {{ row.iswc }}
      </span>
    </template>

    <!-- Right Types -->
    <template v-slot:rightType="{ row }">
      <span class="text-primary">
        {{ row.rightType }}
      </span>
    </template>

    <!-- Reason -->
    <template v-slot:reason="{ row }">
      <span class="text-primary">
        {{ getReason(row.reason) | startcase }}
      </span>
    </template>

    <!-- Created -->
    <template v-slot:created="{ row }">
      <date-time-cell :dateTime="row.created"/>
    </template>

    <!-- Status -->
    <template v-slot:status="{ row }">
      <status
        v-if="row.status === statuses.DONE"
        :text="$t('done')"
        :icon="['fas', 'check-circle']"
        color="green"
      />
      <status
        v-else-if="row.status === statuses.PENDING"
        :text="$t('pending')"
        :icon="['fas', 'question-circle']"
        color="orange"
      />
    </template>
  </v-server-table>
</template>

<script>
import { capitalize, get } from "lodash"
import { getSharesActionReasonDisplay, userActionStatuses } from "@/constants"
import { listRouteMixin } from "@/utils/mixins"

export default {
  name: "SharesActionsList",
  mixins: [listRouteMixin],
  data () {
    return {
      statuses: userActionStatuses,
      columns: ["title", "workId", "iswc", "rightType", "reason", "created", "status"],
      options: {
        headings: {
          title: capitalize(this.$t("title")),
          workId: this.$t("work_id"),
          iswc: this.$t("iswc"),
          rightType: capitalize(this.$t("rights")),
          reason: capitalize(this.$t("reason")),
          created: capitalize(this.$t("created")),
          status: capitalize(this.$t("status")),
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          return this.$api.userActions.sharesActionsList(queryParams)
        }
      },
    }
  },
  methods: {
    getData () {
      this.$refs.sharesActions.getData()
    },
    onLoaded ({ data }) {
      this.$emit("loaded", data.count)
    },
    getReason (type) {
      return getSharesActionReasonDisplay(type)
    }
  },
  watch: {
    distribution (value) {
      this.applyFilters("distribution", value)
    },
    codeSearch (value) {
      this.applyFilters("code", value)
    },
    titleSearch (value) {
      this.applyFilters("title", value)
    },
    status (value) {
      this.applyFilters("status", value ? value.value : null)
    },
    showDomesticOnly (showDomesticOnly) {
      this.applyFilters("domestic_only", showDomesticOnly)
    },
    showWithUsagesOnly (showWithUsagesOnly) {
      this.applyFilters("with_usages", showWithUsagesOnly)
    },
    reason (reason) {
      this.applyFilters("reason", reason?.value)
    },
    rightType (rightType) {
      this.applyFilters("right_type", rightType?.value)
    },
    currentFilter (filter) {
      let [type, value] = filter
      this.applyFilters(type, value)
    }
  },
  props: {
    reason: {
      type: Object,
      default: () => ({}),
    },
    rightType: {
      type: Object,
      default: () => ({}),
    },
    distribution: String,
    codeSearch: String,
    titleSearch: String,
    status: Object,
    showWithUsagesOnly: Boolean,
    showDomesticOnly: {
      type: Boolean,
      default: true,
    },
    currentFilter: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>
