import Vue from "vue"
import Vuex from "vuex"

import { alert } from "./alert.module"
import { consts } from "./consts.module"
import { filters } from "./filters.module"
import { selection } from "./selection.module"
import { user } from "./user.module"

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    alert,
    consts,
    filters,
    selection,
    user,
  }
})
