<template>
  <div>
    <span class="text-gray">{{ message.type | uppercase }} - </span>
    <span>{{ message.value }}</span>
  </div>
</template>

<script>
export default {
  name: "CodeLogMessage",
  props: {
    message: Object,
  }
}
</script>