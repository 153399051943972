<template>
  <b-sidebar no-header class="nav-container" :visible="true" width="250px" :no-close-on-route-change="true" :no-close-on-esc="true">
    <!-- MENU ITEMS -->
    <div class="d-flex flex-column">
      <b-nav vertical class="menu">
        <div v-for="section in visibleSections" :key="section.id">
          <b-nav-item
            :to="getRouteTo(section)"
            exact
          >
            <div class="d-flex align-items-center p-2">
              <div class="icon-wrapper">
                <fa-icon size="lg" :icon="section.icon" />
              </div>
              <span class="ml-4">{{ section.name | startcase }}</span>
            </div>
          </b-nav-item>
        </div>
      </b-nav>
    </div>

    <!-- FOOTER -->
    <template #footer>
      <div class="pl-3">
        <b-img
          :src="require('@/assets/atlas_logo_gray.png')"
          alt="ATLAS"
          width="120"
        />
        <b-link href="https://www.bmat.com" target="_blank" class="pl-3">
          <b-img
            :src="require('@/assets/bmat_logo.svg')"
            alt="BMAT"
            width="35"
          />
        </b-link>
        <p v-if="privacyPolicyURL">
          <b-link
            :href="privacyPolicyURL"
            target="_blank"
            class="text-muted" 
          >
            {{ $t("privacy_policy") }}
          </b-link>
        </p>
        <p class="text-muted pt-2" v-if="version">v{{ version }}</p>
      </div>
    </template>
  </b-sidebar>
</template>

<style lang="scss" scoped>
  .nav-container {
    z-index: 20;
  }

  .b-sidebar-body .nav.menu {
    margin-top: 50px;
  }

  .icon-wrapper {
    min-width: 25px;
    text-align: center;
  }

  ::v-deep .router-link-active {
    background-color: var(--info-light);
    color: $info;
  }
</style>

<script>
import { mapGetters, mapState } from "vuex"
import { get } from "lodash"

export default {
  name: "SideBar",
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["isAdmin", "isSociety", "isContributor", "isMember"]),
    version () { return this.$config.VERSION },
    privacyPolicyURL () {
      const key = get(this.user.society, "name", "none")

      const map = {
        "wami": null,
        "filscap": "https://atlas-bo-privacy-policies.s3.amazonaws.com/FILSCAP+PRIVACY+POLICY+2023.02.08.pdf",
        "mct": null
      }

      return get(map, key.toLowerCase())
    },
    sections () {
      return [
        {
          name: this.$t("my_profile"),
          id: "parties-detail",
          icon: ["fa", "id-card"],
          visible: this.isMember,
          routeParams: { id: this.user.party.id },
        },
        {
          name: this.$t("parties"),
          id: "parties-list",
          icon: ["fa", "id-card"],
          visible: this.isSociety,
        },
        {
          name: this.isSociety ? this.$t("works_single_view") : this.$t("my_works"),
          id: "works-list",
          query: this.isAdmin ? {} : { own: true },
          icon: ["fa", "database"],
          visible: true,
        },
        {
          name: this.$t("bulk_works_upload"),
          id: "repertoire-files-list",
          icon: ["fa", "cloud-upload-alt"],
          visible: !this.isContributor,
        },
        {
          name: this.$t("recordings"),
          id: "products-list",
          icon: ["fa", "barcode"],
          visible: this.isSociety,
        },
        {
          name: this.$t("user_actions"),
          id: "actions-tabs",
          routeParams: { type: "worksMatching" },
          query: { show_done: false },
          icon: ["fa", "hand-paper"],
          visible: true,
        },
        {
          name: this.$t("usage_files"),
          id: "usage-files-list",
          icon: ["fa", "list"],
          visible: this.isAdmin,
        },
        {
          name: this.$t("usages_matching"),
          id: "usages-matching-list",
          icon: ["fa", "bullhorn"],
          visible: this.isAdmin,
        },
        {
          name: this.$t("digital_files"),
          id: "digital-files-list",
          icon: ["fa", "list-alt"],
          visible: this.isAdmin,
        },
        {
          name: this.$t("crd_files"),
          id: "crd-files-list",
          icon: ["fa", "file-alt"],
          visible: this.isAdmin,
        },
        {
          name: this.$t("crd_matching"),
          id: "crd-matching-list",
          icon: ["fa", "exchange-alt"],
          visible: this.isAdmin,
        },
        {
          name: this.$t("vod_files"),
          id: "vod-files-list",
          icon: ["fa", "video"],
          visible: this.isAdmin,
        },
        {
          name: this.$t("agreements"),
          id: "agreements-list",
          icon: ["fa", "handshake"],
          visible: !this.isContributor,
        },
        {
          name: this.$t("agreements_uploads"),
          id: "agreements-files-list",
          icon: ["fa", "cloud-upload-alt"],
          visible: !this.isContributor,
        },
        {
          name: this.$t("distributions"),
          id: "distributions-list",
          icon: ["fa", "file-invoice-dollar"],
          visible: true,
        },
      ]
    },
    visibleSections () {
      return this.sections.filter(section => section.visible)
    }
  },
  methods: {
    getRouteTo (section) {
      if (this.$route.name === section.id) return { ...this.$route }
      return {
        name: section.id,
        params: section.routeParams,
        query: section.query,
      }
    }
  }
}
</script>
