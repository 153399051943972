<template>
  <page-section :title="$t('recordings') | startcase" :collapsible="false">
    <template v-slot:title v-if="editMode">
      <div v-if="isSociety" class="d-flex justify-content-between mb-3">
        <h3>{{ $t("recordings") | startcase }}</h3>
        <div>
          <b-button
            variant="secondary"
            @click="onCreateProduct"
            class="mr-2"
          >
            {{ $t("create_recording") | startcase }}
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="onShowSearch"
          >
            {{ $t("link_recording") | startcase }}
          </b-button>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div>
        <!-- Work products -->
        <div v-if="products.length">
          <b-table-lite :fields="fields" :items="products" borderless>
            <template v-slot:head()="item">
              <div class="head">
                {{ item.label | capitalize }}
              </div>
            </template>
            <template v-slot:cell(name)="{ item }">
              <router-link
                class="text-info align-middle"
                :to="{ name: 'products-detail', params: { id: item.id } }"
              >
                {{ item.name }}
              </router-link>
            </template>

            <!-- Type -->
            <template v-slot:cell(type)="{ item }">
              <span class="align-middle">{{ getTypeDisplay(item) | capitalize }}</span>
            </template>

            <!-- Actions -->
            <template v-slot:cell(actions)="{ item }" v-if="isSociety && editMode">
              <b-button
                class="mr-2 float-right"
                variant="outline-secondary"
                @click="onUnlinkProduct(item.id)"
              >
                {{ $t("unlink") | capitalize }}
              </b-button>
            </template>

          </b-table-lite>
        </div>
        <div v-else class="text-center mt-3">
          <p>{{ $t('products_not_found') | capitalize }}</p>
        </div>

        <!-- Product Search -->
        <ProductsSearch
          :workId="workId"
          :showProductSearch="showProductSearch"
          @search-cancel="onSearchCancel"
          @link-product="onLinkProduct"
        />
      </div>

      <!-- User action modal -->
      <b-modal
        id="user-action"
        :title="$t('conflict_detected') | capitalize"
        :hide-footer="true"
      >
        <b-container class="text-center">
          <span>{{ $t('conflict_detected_user_action_created') | capitalize }}</span>
          <router-link
            class="text-info"
            :to="{ name: 'actions-recording-detail', params: { id: productUserAction }}"
          >
            {{ $t("go_to_user_action") }}
          </router-link>
        </b-container>
      </b-modal>

      <!-- Products Create -->
      <b-modal id="create-product-modal" hide-header hide-footer>
        <ProductsCreateForm
          :workId="workId"
          @cancel="onProductCreateCancel"
          @create-success="onProductCreateSuccess"
        />
      </b-modal>
    </template>
  </page-section>
</template>

<script>
import { getProductTypeDisplay, getRecordingTypeDisplay, productTypes } from "@/constants"
import { mapActions, mapGetters } from "vuex"
import { EventBus } from "@/utils/event-bus.js"
import ProductsCreateForm from "./ProductsCreateForm"
import ProductsSearch from "./ProductsSearch"

export default {
  name: "Products",
  components: {
    ProductsCreateForm,
    ProductsSearch,
  },
  data () {
    return {
      fields: [
        { key: "name", label: this.$t("name") },
        { key: "type", label: this.$t("type") },
        { key: "code", label: this.$t("code") },
        { key: "artist", label: this.$t("artist") },
        { key: "actions", label: "" },
      ],
      productSearchTerm: "",
      showProductSearch: false,
      products: [],
      productUserAction: null,
    }
  },
  computed: {
    ...mapGetters("user", ["isSociety"]),
  },
  methods: {
    ...mapActions("alert", ["error", "success"]),
    getProducts () {
      this.$api.repertoire.worksProductsList(this.workId)
        .then(response => {
          this.products = response.data
        })
    },
    getTypeDisplay (product) {
      return product.type == productTypes.RECORDING ? getRecordingTypeDisplay(product.recordingType) : getProductTypeDisplay(product.type)
    },
    onShowSearch () {
      this.showProductSearch = true
    },
    onCreateProduct () {
      this.$bvModal.show("create-product-modal")
    },
    onProductCreateSuccess () {
      this.getProducts()
      this.$bvModal.hide("create-product-modal")
    },
    onProductCreateCancel () {
      this.$bvModal.hide("create-product-modal")
    },
    onSearchCancel () {
      this.showProductSearch = false
    },
    makeLinkRequest (action, productId) {
      let request = {
        link: {
          call: "productsLinkWorks",
          successMessage: this.$t("recording_successfully_linked"),
          params: [productId, { atlasIds: [this.workId] }]
        },
        unlink: {
          call: "productsUnlinkWorks",
          successMessage: this.$t("recording_successfully_unlinked"),
          params: [productId, this.workId, { atlasIds: [this.workId] }]
        }
      }[action]
      this.$api.products[request.call](...request.params)
        .then((response) => {
          if (response.data.userActions?.length) {
            let userActions = response.data.userActions
            this.productUserAction = userActions[0]?.id
            this.$bvModal.show("user-action")
          } else {
            this.getProducts()
            this.success(request.successMessage)
            EventBus.$emit("products-updated")
          }
        })
        .catch(error => {
          let data = error.response?.data
          this.error(data)
        })
    },
    onLinkProduct (productId) {
      this.makeLinkRequest("link", productId)
    },
    onUnlinkProduct (productId) {
      this.makeLinkRequest("unlink", productId)
    },
  },
  mounted () {
    EventBus.$on("link-product", this.onLinkProduct)
    this.getProducts()
  },
  props: {
    workId: [String, Number],
    editMode: {
      type: Boolean,
      default: false,
    },
  }
}
</script>