<template>
  <ValidationObserver ref="contact" v-slot="{ handleSubmit }">
    <b-form v-if="contact" @submit.prevent="handleSubmit(onSave)" class="p-3">
      <b-row>
        <b-col lg="4" sm="6">
          <form-field
            name="contactName"
            :label="$t('legal_name')"
            type="text"
            v-model="contact.contactName"
          />
          <form-field
            name="address"
            :label="$t('primary_address')"
            type="text"
            v-model="contact.address"
          />
        </b-col>
        <b-col lg="4" sm="6">
          <form-field
            name="primaryPhone"
            :label="$t('primary_phone')"
            type="text"
            rules="required"
            v-model="contact.primaryPhone"
          />
          <form-field
            name="secondaryPhone"
            :label="$t('secondary_phone')"
            type="text"
            v-model="contact.secondaryPhone"
          />
        </b-col>
        <b-col lg="4" sm="6">
          <form-field
            name="primaryEmail"
            :label="$t('primary_email')"
            type="email"
            rules="email|required"
            v-model="contact.primaryEmail"
          />
          <form-field
            name="secondaryEmail"
            :label="$t('secondary_email')"
            type="email"
            rules="email"
            v-model="contact.secondaryEmail"
          />
        </b-col>
      </b-row>
      <submit-button size="md" variant="primary">
        {{ $t("save") | capitalize }}
      </submit-button>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate"
import { mapActions } from "vuex"

export default {
  name: "Contact",
  components: {
    ValidationObserver,
  },
  props: {
    id: String,
    contact: Object,
  },
  methods: {
    ...mapActions("alert", ["error", "success"]),
    async onSave () {
      try {
        await this.$api.parties.membersContactUpdate(this.id, this.contact)
        this.success(this.$t("contact_updated"))
      } catch (error) {
        const { data, status } = error.response
        if (status === 400) this.$refs.contact.setErrors(data)
      }
    },
  }
}
</script>
