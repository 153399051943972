<template>
  <ValidationObserver ref="usersUpdateForm" v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(onSubmit)" >
      <!-- Role -->
      <multi-select-field
        name="status"
        :label="$t('status')"
        rules="required"
        v-model="form.status"
        :options="STATUS"
        choiceLabel="label"
        track-by="name"
        :allowEmpty="false"
        :showLabels="false"
        :showPointer="false"
        :disabled="!canChangeStatus"
        placeholder
      />

      <!-- Name -->
      <form-field
        name="name"
        :label="$t('name')"
        type="text"
        rules="required"
        v-model="form.name"
      />

      <!-- Email -->
      <form-field
        name="email"
        :label="$t('email')"
        type="email"
        rules="required|email"
        v-model="form.email"
        disabled
      />

      <!-- Role -->
      <multi-select-field
        v-if="isSocietyUser"
        name="role"
        :label="$t('role')"
        rules="required"
        v-model="form.role"
        :options="ROLES"
        choiceLabel="label"
        track-by="name"
        :allowEmpty="false"
        :hideSelected="true"
        placeholder
        :disabled="isCurrentUser(initial.id)"
      />
      <submit-button class="btn-block mt-4" variant="primary">
        {{ $t("update") }}
      </submit-button>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { activationStatuses, userRoles } from "@/constants"
import { mapGetters, mapMutations, mapState } from "vuex"
import { ValidationObserver } from "vee-validate"
import { clone } from "lodash"

const {
  MEMBER,
  REPERTOIRE_ADMIN,
  SOCIETY_ADMIN,
  BOARD_MEMBER,
} = userRoles

const {
  ACTIVE,
  INACTIVE,
  PENDING,
} = activationStatuses

export default {
  name: "UsersUpdateForm",
  components: {
    ValidationObserver,
  },
  data () {
    return {
      form: {
        name: "",
        email: "",
        status: null,
        role: null
      },
    }
  },
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    updateUserStatus (id, uid, status) {
      if (status !== this.initial.status) {
        if (status === ACTIVE) {
          return this.$api.users.usersActivate(id, uid)
        }
        if (status === INACTIVE) {
          return this.$api.users.usersDeactivate(id, uid)
        }
      } else {
        return new Promise((resolve) => resolve())
      }
    },
    async onSubmit () {
      let data = clone(this.form)
      data.role = this.form.role ? this.form.role.name : MEMBER
      data.status = this.form.status.name

      let { id, uid } = this.$route.params

      try {
        await this.updateUserStatus(id, uid, data.status)
        await this.$api.users.usersUpdate(id, uid, data)

        this.success(this.$t("user_updated"))
      } catch (error) {
        const { data, status } = error.response

        if (status === 400) {
          this.error(this.$t("form_contains_errors"))
          this.$refs.usersUpdateForm.setErrors(data)
        }
      }
      this.$bvModal.hide("user-update-modal")
    },
  },
  computed: {
    ...mapGetters("user", ["isAdmin", "isCurrentUser", "canManagePartyResource"]),
    ...mapState("user", ["user"]),
    isSocietyUser () {
      return this.party && this.isAdmin && this.party.id == this.user.party.id
    },
    STATUS () {
      if (this.initial.status === PENDING) {
        return [
          { label: this.$t("active"), name: ACTIVE },
          { label: this.$t("pending"), name: PENDING },
        ]
      } else {
        return [
          { label: this.$t("active"), name: ACTIVE },
          { label: this.$t("inactive"), name: INACTIVE },
        ]
      }
    },
    ROLES () {
      return [
        { label: this.$t("society_admin"), name: SOCIETY_ADMIN },
        { label: this.$t("repertoire_admin"), name: REPERTOIRE_ADMIN },
        { label: this.$t("board_member"), name: BOARD_MEMBER },
      ]
    },
    canChangeStatus () {
      let { id } = this.initial

      return !this.isCurrentUser(id) || this.canManagePartyResource(this.party.id)
    }
  },
  props: {
    party: {
      type: Object,
      default: () => ({ id: null })
    },
    initial: {
      type: Object,
      default: null
    },
  },
  mounted () {
    this.form.name = this.initial.name
    this.form.email = this.initial.email
    this.form.role = this.ROLES.filter(role => this.initial.role === role.name)[0]
    this.form.status = [
      { label: this.$t("active"), name: ACTIVE },
      { label: this.$t("inactive"), name: INACTIVE },
      { label: this.$t("pending"), name: PENDING },
    ].filter(status => this.initial.status === status.name)[0]
  }
}
</script>
