<template>
  <tabs-layout
    :tabs="tabs"
    @change="onTabChange"
    v-model="tabIndex"
  >
    <!-- Tab Title -->
    <template v-slot:title="{ tab }">
      <fa-icon :icon="getTabIcon(tab)" />
      {{ getTabTitle(tab) | startcase }}
    </template>

    <!-- Tab Content -->
    <template v-slot:content>
      <component v-bind:is="currentComponent" :workId="workId"></component>
    </template>
  </tabs-layout>
</template>

<script>
import Comments from "./Comments"
import History from "./History"
import Updates from "./Updates"

import { mapGetters } from "vuex"

const COMMENTS = "comments"
const HISTORY = "history"
const UPDATES = "updates"

const TABS = [COMMENTS, HISTORY, UPDATES]

const components = {
  [COMMENTS]: "Comments",
  [HISTORY]: "History",
  [UPDATES]: "Updates",
}

export default {
  name: "LogsTabs",
  data: () => {
    return {
      tabs: [],
      currentTab: null,
      currentComponent: null,
      tabIndex: null,
    }
  },
  components: {
    Comments,
    History,
    Updates,
  },
  methods: {
    getTabIcon (tab) {
      return {
        [COMMENTS]: ["fas", "comments"],
        [HISTORY]: ["fas", "list"],
        [UPDATES]: ["fas", "list"],
      }[tab]
    },
    getTabTitle (tab) {
      return {
        [COMMENTS]: this.$t("comments"),
        [HISTORY]: this.$t("history"),
        [UPDATES]: this.$t("pending_updates"),
      }[tab]
    },
    onTabChange ({ index }) {
      this.tabIndex = index
    },
    getTabs () {
      let tabs = TABS
      tabs = this.canEdit ? tabs.includes(UPDATES) ? tabs : tabs.push(UPDATES) : tabs.filter(t => t !== UPDATES)
      if (!this.isSociety) tabs = tabs.filter(t => t !== COMMENTS)
      this.tabs = tabs
    },
  },
  computed: {
    ...mapGetters("user", ["isMember", "isSociety"]),
  },
  watch: {
    isSociety () {
      this.getTabs()
    },
    canEdit () {
      this.getTabs()
    },
    tabs (value) {
      this.currentTab = value[this.tabIndex]
    },
    tabIndex (index) {
      this.currentTab = this.tabs[index]
    },
    currentTab (tab) {
      this.currentComponent = components[tab]
    }
  },
  mounted () {
    this.getTabs()
  },
  props: {
    workId: [String, Number],
    canEdit: {
      type: Boolean,
      default: true,
    },
  }
}
</script>
