import { app } from "../main"

const formatNumber = (number) => {
  return new Intl.NumberFormat(app.$i18n.locale).format(number)
}

/* Transforms a number into a locale string representation.
   Example: 1000 into 1,000
*/
const localeString = (value) => {
  return value ? Number(value).toLocaleString() : ""
}


export {
  formatNumber,
  localeString
}
