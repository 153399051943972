import { API } from "./utils"


export const users = {
  usersList: (id, params=null) => API.get(`/parties/members/${id}/users/`, { params }),
  usersCreate: (id, data) => API.post(`/parties/members/${id}/users/`, data),
  usersRetrieve: (id, uid) => API.get(`/parties/members/${id}/users/${uid}/`),
  usersUpdate: (id, uid, data) => API.put(`/parties/members/${id}/users/${uid}/`, data),
  usersDelete: (id, uid) => API.delete(`/parties/members/${id}/users/${uid}/`),
  usersActivate: (id, uid) => API.post(`/parties/members/${id}/users/${uid}/activate/`),
  usersDeactivate: (id, uid) => API.post(`/parties/members/${id}/users/${uid}/deactivate/`),
}
