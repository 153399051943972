<template>
  <b-modal v-bind="$attrs" @hide="$emit('hide')">
    <!-- Body -->
    <slot></slot>

    <!-- Footer -->
    <template v-slot:modal-footer="{cancel, ok}">
      <slot name="footer" :cancel="cancel" :ok="ok">
        <b-button variant="secondary" @click="okClick">
          {{ okTitle }}
        </b-button>
        <b-button variant="primary" @click="cancelClick">
          {{ cancelTitle }}
        </b-button>
      </slot>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "modal",
  data: () => ({}),
  methods: {
    cancelClick () {
      this.$bvModal.hide(this.$attrs.id)
      this.$emit("cancel")
    },
    okClick () {
      this.$bvModal.hide(this.$attrs.id)
      this.$emit("ok")
    }
  },
  props: {
    okTitle: {
      type: String,
      default: "Accept",
    },
    cancelTitle: {
      type: String,
      default: "Cancel",
    },
  },
}
</script>
