<template>
  <div>
    <template v-if="!!dateTime">
      <div>
        {{ parseDate(dateTime, this.$config.DATE_FORMAT) }}
      </div>
      <div class="text-gray" v-if="!dateOnly">
        {{ parseDate(dateTime, 'HH:mm') }}
      </div>
    </template>
  </div>
</template>

<script>
import { parseDate } from "@/utils/dates"

export default {
  name: "DateTimeCell",
  methods: {
    parseDate
  },
  props: {
    dateTime: String,
    dateOnly: {
      type: Boolean,
      default: false
    }
  }
}
</script>