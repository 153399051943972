<template>
  <div>
    <div v-for="(code, i) in codes" :key="i" class="mb-2">
      <b-row>
        <b-col>
          <p class="my-0">{{ code.value }}</p>
          <small class="my-0">{{ $t("type") | capitalize }}: {{ code.type }}</small>
        </b-col>
        <b-col cols="3">{{ $t(code.action) | capitalize }}</b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { getWorkFieldActionTypesDisplay } from "@/constants"

export default {
  name: "CodesUpdate",
  methods: {
    getWorkFieldActionTypesDisplay,
  },
  props: {
    codes: Array,
  }
}
</script>