<template>
  <list-layout
    :title="$t('agreements_uploads')"
    :count="count"
    :filters="filters"
    :show-refresh="true"
  >
    <!-- Status -->
    <template v-slot:status>
      <div class="mr-3">
        <status
          :text="$t('landed')"
          :name="fileStatuses.LANDED"
          :count="summary.landed"
          :icon="['fas', 'check-circle']"
          color="green"
          :active="filters.status === fileStatuses.LANDED"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('ingested')"
          :name="fileStatuses.INGESTED"
          :count="summary.ingested"
          :icon="['fas', 'check-circle']"
          color="green"
          :active="filters.status == fileStatuses.INGESTED"
          clickable
          @click="onStatusClick"
        />
      </div>
      <div class="mr-3">
        <status
          :text="$t('pending')"
          :name="fileStatuses.PENDING"
          :count="summary.pending"
          :icon="['fas', 'question-circle']"
          color="orange"
          :active="filters.status == fileStatuses.PENDING"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('processing')"
          :name="fileStatuses.PROCESSING"
          :count="summary.processing"
          :icon="['fas', 'question-circle']"
          color="orange"
          :active="filters.status == fileStatuses.PROCESSING"
          clickable
          @click="onStatusClick"
        />
      </div>
      <div class="mr-3 align-self-start">
        <status
          :text="$t('error')"
          :name="fileStatuses.ERROR"
          :count="summary.error"
          :icon="['fas', 'exclamation-circle']"
          color="red"
          :active="filters.status == fileStatuses.ERROR"
          clickable
          @click="onStatusClick"
        />
      </div>
    </template>

    <!-- Download template btn -->
    <template v-slot:actions>
      <div>
        <b-button
          :href="$config.ATLAS_AGREEMENTS_TEMPLATE_URL"
          variant="outline-primary"
        >
          {{ $t("download_template") | startcase }}
        </b-button>
      </div>
    </template>

    <template v-slot:search>
      <b-row class="mb-2">
        <b-col cols="sm-4">
          <search-input
            :value="filters.fuzzySearch"
            @search="onFileSearch"
          />
        </b-col>
        <b-col cols="sm-4">
          <date-picker
            v-model="dateRange"
            :placeholder="$t('select_date_range') | capitalize"
            range
            :format="$config.DATE_FORMAT"
            input-class="form-control"
          />
        </b-col>
      </b-row>
    </template>

    <!-- Table -->
    <template v-slot:table>
      <v-server-table
        :columns="columns"
        :options="options"
        :ref="tableRef"
        @loaded="onLoaded"
        @pagination="onPagination"
      >
        <!-- File Name -->
        <template v-slot:filename="{ row }">
          <b-row>
            <b-col>
              <router-link
                :to="{ name: 'landing-agreements-list', params: { id: row.id }}"
                class="text-info"
              >
                {{ row.filename }}
              </router-link>
            </b-col>
          </b-row>
        </template>

        <!-- Upload date -->
        <template v-slot:uploadDate="{ row }">
          <date-time-cell :dateTime="row.uploadDate"/>
        </template>

        <!-- Uploader -->
        <template v-slot:uploader="{ row }">
          <small>
            <b>{{ $t("user") | capitalize }}: </b> {{ row.uploader.name }} <br>
            <b>{{ $t("party") | capitalize }}: </b><span>{{ row.uploader.party.name }}</span>
          </small>
        </template>

        <!-- Status -->
        <template v-slot:status="{ row }">
          <status
            v-if="row.status === fileStatuses.PENDING"
            :text="$t('pending')"
            :icon="['fas', 'question-circle']"
            color="orange"
          />
          <status
            v-if="row.status === fileStatuses.PROCESSING"
            :text="$t('processing')"
            :icon="['fas', 'question-circle']"
            color="orange"
          />
          <status
            v-if="row.status === fileStatuses.LANDED"
            :text="$t('landed')"
            :icon="['fas', 'check-circle']"
            color="green"
          />
          <status
            v-if="row.status === fileStatuses.INGESTED "
            :text="$t('ingested')"
            :icon="['fas', 'check-circle']"
            color="green"
          />
          <status
            v-if="row.status === fileStatuses.ERROR"
            :text="$t('error')"
            :icon="['fas', 'exclamation-circle']"
            color="red"
            :active="true"
            @click="showErrors(row, 'file')"
            clickable
          />
        </template>

        <!-- Child Rows -->
        <template v-slot:child_row="{ row }">
          <row-extra-info :key="row.id">
            <b-row class="text-gray mb-2">
              <b-col v-if="row.stats.landingDbIngestion" cols="4">{{ $t('landing_db_ingestion') }}</b-col>
              <b-col v-if="row.stats.singleViewIngestion" cols="8">{{ $t('single_view_ingestion') }}</b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col v-if="row.stats.landingDbIngestion" cols="4">
                <b-row>
                  <b-col cols="4">{{ $t("total") }}</b-col>
                  <b-col class="text-gray" cols="8">{{ row.stats.landingDbIngestion.totalCount }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4">{{ $t("ingested") }}</b-col>
                  <b-col class="text-gray" cols="8">{{ row.stats.landingDbIngestion.ingestedCount }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4">{{ $t("errors") }}</b-col>
                  <b-col class="text-gray" cols="8">
                    {{ row.stats.landingDbIngestion.errorCount }}
                    <fa-icon
                      class="error-link ml-2"
                      size="sm"
                      :icon="['fas', 'plus-circle']"
                      v-if="row.stats.landingDbIngestion.errorCount > 0"
                      @click="showErrors(row)"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col v-if="row.stats.singleViewIngestion" cols="8">
                <b-row>
                  <b-col cols="4">{{ $t("total_unique") }}</b-col>
                  <b-col class="text-gray" cols="8">{{ row.stats.singleViewIngestion.totalUniqueCount }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4">{{ $t("linked") }}</b-col>
                  <b-col class="text-gray" cols="8">{{ row.stats.singleViewIngestion.linkedCount }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4">{{ $t("created") }}</b-col>
                  <b-col class="text-gray" cols="8">{{ row.stats.singleViewIngestion.createdCount }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4">{{ $t("pending_for_validation") }}</b-col>
                  <b-col class="text-gray" cols="8">{{ row.stats.singleViewIngestion.pendingForValidationCount }}</b-col>
                </b-row>
              </b-col>
            </b-row>
          </row-extra-info>
        </template>

        <!-- Actions -->
        <template v-slot:actions="{ row }">
          <div class="d-flex justify-content-end">
            <action
              @click="showExtraInfo(row)"
              v-if="hasStats(row)"
              :title="$t('more_information')"
              :icon="['fas', !isOpen(row.id) ?  'angle-down': 'angle-up']"
            />
          </div>
        </template>
      </v-server-table>
    </template>

    <FileErrorsModal :item="selectedFile" :onlyShowGlobalErrors="onlyShowGlobalErrors" :errorsListOptions="errorsListOptions" />

  </list-layout>
</template>

<style lang="scss" scoped>
  .error-link {
    color: var(--blue);
    cursor: pointer;
  }
</style>

<script>
import { capitalize, get } from "lodash"
import FileErrorsModal from "@/components/FileErrorsModal"
import { fileStatuses } from "@/constants"
import { isEmpty } from "lodash"
import { listRouteMixin } from "@/utils/mixins"
import moment from "moment"
import { parseDate } from "@/utils/dates"

export default {
  name: "AgreementsFilesList",
  mixins: [listRouteMixin],
  components: {
    FileErrorsModal,
  },
  methods: {
    isEmpty,
    parseDate (value) {
      return value ? parseDate(value, this.$config.DATE_FORMAT) : ""
    },
    showExtraInfo ({ id }) {
      if (this.isOpen(id)) {
        this.openChildRows = this.openChildRows.filter(openRowId => id !== openRowId)
      } else {
        this.openChildRows.push(id)
      }
      this.$refs[this.tableRef].toggleChildRow(id)
    },
    showErrors (item, level) {
      this.onlyShowGlobalErrors = ["file", "sheet"].includes(level)
      this.selectedFile = item
      this.$bvModal.show("file-errors-modal")
    },
    hasStats (item) {
      return Object.keys(item.stats).length && (item.stats.landingDbIngestion || item.stats.singleViewIngestion)
    },
    isOpen (id) {
      return this.openChildRows.includes(id)
    },
    onFileSearch (value) {
      this.filters.fuzzySearch = value
    },
    onLoaded ({ data }) {
      this.count = data.count
      this.status = data.status
      this.summary = data.summary
    },
    getData () {
      this.$refs[this.tableRef].getData()
    },
    onStatusClick (status) {
      this.filters.status = status
      this.applyFilters("status", status)
    },
  },
  data () {
    return {
      count: 0,
      dateRange: [],
      fileStatuses,
      selectedFile: {},
      filters: {
        status: "",
        fuzzySearch: "",
        to: "",
        from: ""
      },
      onlyShowGlobalErrors: false,
      summary: {},
      openChildRows: [],
      columns: ["filename", "uploadDate", "uploader", "status", "actions"],
      options: {
        showChildRowToggler: false,
        headings: {
          filename: capitalize(this.$t("filename")),
          uploadDate: capitalize(this.$t("upload_date")),
          uploader: capitalize(this.$t("uploaded_by")),
          status: capitalize(this.$t("status")),
          actions: "",
        },
        requestKeys: { query: "fuzzy_search" },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))

          return this.$api.agreements.agreementsFilesList(queryParams)
        }
      },
      errorsListOptions: {
        requestFunction (params) {
          return this.$api.agreements.agreementsFileErrorsList(this.url, params)
        }
      },
      tableRef: "agreementsFilesList",
    }
  },
  watch: {
    dateRange (val) {
      this.filters.from = this.parseDate(val[0])
      this.filters.to = this.parseDate(val[1])

      this.applyFilters("to", this.filters.to)
      this.applyFilters("from", this.filters.from)
    },
    "filters.fuzzySearch" (value) {
      this.applyFilters("fuzzy_search", value)
    },
  },
  mounted () {
    this.setFilters()

    const { from, to } = this.filters
    const fromParsed = moment(from, this.$config.ISO_DATE_FORMAT, true)
    const toParsed = moment(to, this.$config.ISO_DATE_FORMAT, true)

    if(fromParsed.isValid() && toParsed.isValid() && fromParsed <= toParsed) {
      const fromDate = new Date(from)
      const toDate = new Date(to)
      this.dateRange = [fromDate, toDate]
    } else {
      this.dateRange = [null, null]
    }
  }
}
</script>
