<template>
  <list-layout :title="$t('agreements')" :count="count">
    <!-- Search -->
    <template v-slot:search>
      <search-input
        :value="publisherSearch"
        @search="onPublisherSearch"
        :placeholder="$t('name_search') | capitalize"
        class="w-25"
      />
    </template>

    <!-- Actions -->
    <template v-slot:actions>
      <!-- Create -->
      <router-link :to="{ name: 'agreements-create' }" v-if="canEdit">
        <b-button>{{ $t("create_agreement") | startcase }}</b-button>
      </router-link>
    </template>

    <!-- Table -->
    <template v-slot:table>
      <v-server-table
        name="agreements"
        ref="agreements"
        :columns="columns"
        :options="options"
        @loaded="onLoaded"
        @pagination="onPagination"
      >
        <!-- Assignor -->
        <template v-slot:assignor="{ row }">
          <router-link
            v-if="isSociety"
            :to="{ name: partyUrl, params: { id: row.assignor.id } }"
            class="text-info"
          >
            {{ row.assignor.name }}
          </router-link>
          <div v-else>{{ row.assignor.name }}</div>
          <div
            class="text-small text-gray"
          >
            {{ row.assignor.ipiNameNumber }}
          </div>
        </template>

        <!-- Assignee -->
        <template v-slot:assignee="{ row }">
          <router-link
            v-if="isSociety"
            :to="{ name: partyUrl, params: { id: row.assignee.id } }"
            class="text-info"
          >
            {{ row.assignee.name }}
          </router-link>
          <div v-else>{{ row.assignee.name }}</div>
          <div
            class="text-small text-gray"
          >
            {{ row.assignee.ipiNameNumber }}
          </div>
        </template>

        <!-- Period -->
        <template v-slot:period="{ row }">
          <!-- Start date-->
          <template v-if="row.startDate">
            <span class="text-gray text-small">{{ $t("from") }}</span>
            <date-time-cell :dateTime="row.startDate" date-only/>
          </template>
          <!-- End date-->
          <template v-if="row.endDate">
            <span class="text-gray text-small">{{ $t("to") }}</span>
            <date-time-cell :dateTime="row.endDate" date-only/>
          </template>
          <!-- Termination date-->
          <template v-if="row.terminationDate">
            <span class="text-gray text-small">{{ $t("termination_date") }}</span>
            <date-time-cell :dateTime="row.terminationDate" date-only/>
          </template>
        </template>

        <!-- Type -->
        <template v-slot:type="{ row }">
          {{ getAgreementTypeDisplay(row.type) }}
        </template>

        <!-- Right Types -->
        <template v-slot:rightTypes="{ row }">
          {{ getRightTypesDisplay(row.rightTypes) }}
        </template>

        <!-- Status -->
        <template v-slot:status="{ row }">
          <div class="d-flex flex-row justify-content-center">
            <status
              v-if="row.status === agreementStatuses.PENDING"
              :text="$t('pending')"
              :icon="['fas', 'question-circle']"
              color="orange"
            />
            <status
              v-if="row.status === agreementStatuses.PROCESSING"
              :text="$t('processing')"
              :icon="['fas', 'question-circle']"
              color="orange"
            />
            <status
              v-if="row.status === agreementStatuses.APPLIED"
              :text="$t('applied')"
              :icon="['fas', 'check-circle']"
              color="green"
            />
             <status
              v-if="row.status === agreementStatuses.INACTIVE"
              :text="$t('inactive')"
              :icon="['fas', 'exclamation-circle']"
              color="gray"
            />
            <status
              v-if="row.status === agreementStatuses.ERROR"
              :text="$t('error')"
              :icon="['fas', 'exclamation-circle']"
              color="red"
            />
          </div>
        </template>

        <!-- Current -->
        <template v-slot:current="{ row }">
          <div class="d-flex flex-row justify-content-center">
            <status
              v-if="row.current"
              :icon="['fas', 'check-circle']"
              color="green"
            />
            <status
              v-if="!row.current"
              :icon="['fas', 'times-circle']"
              color="red"
            />
          </div>
        </template>

        <!-- Actions -->
        <template v-slot:actions="{ row }">
          <div class="d-flex flex-row justify-content-end align-items-center">
            <!-- User Action -->
            <router-link
              :to="{ name: 'actions-agreement-detail', params: { id: row.userAction } }"
              v-if="row.userAction && canEdit"
            >
              <action
                :title="$t('go_to_agreement_conflict')"
                :icon="['fas', 'exclamation-circle']"
                color="red"
              />
            </router-link>

            <!-- Detail/Update -->
            <router-link :to="{ name: 'agreements-update', params: { agreementId: row.id } }">
              <action
                :title="canEdit ? $t('edit') : $t('view')"
                :icon="canEdit ? ['fa', 'pen'] : ['far', 'eye']"
              />
            </router-link>

            <!-- Delete -->
            <action
              :title="$t('delete')"
              @click="onDelete(row.id)"
              :icon="['fas', 'trash']"
              v-if="canEdit"
            />
            <modal
              :id="'confirmation-modal-' + row.id"
              :title="$t('delete_agreement') | capitalize"
              :okTitle="$t('yes') | capitalize"
              :cancelTitle="$t('no') | capitalize"
              @ok="onConfirmDelete(row)"
            >
              <div>{{ $t("confirm_delete_agreement") | capitalize }}?</div>
            </modal>
          </div>
        </template>
      </v-server-table>
    </template>

    <router-view name="modal"></router-view>
  </list-layout>
</template>

<script>
import { agreementStatuses, getAgreementTypeDisplay } from "@/constants"
import { capitalize, get } from "lodash"
import { mapActions, mapGetters } from "vuex"
import { listRouteMixin } from "@/utils/mixins"

export default {
  name: "AgreementsList",
  mixins: [listRouteMixin],
  data () {
    return {
      agreementStatuses,
      count: null,
      publisherSearch: "",
      columns: [
        "id", "assignor", "assignee", "share", "period", "type", "rightTypes", "status", "current", "actions"
      ],
      options: {
        headings: {
          id: "",
          assignor: capitalize(this.$t("assignor")),
          assignee: capitalize(this.$t("assignee")),
          share: capitalize(this.$t("share")),
          period: capitalize(this.$t("period")),
          type: capitalize(this.$t("type")),
          rightTypes: capitalize(this.$t("rights")),
          status: capitalize(this.$t("status")),
          current: capitalize(this.$t("current")),
          actions: "",
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          const filters = this.$parent.$parent.$parent.filters
          queryParams = { ...queryParams, ...this.$route.query, ...filters }
          this.page = Number(get(queryParams, "page", 1))
          return this.$api.agreements.agreementsList(queryParams)
        }
      },
    }
  },
  methods: {
    ...mapActions("alert", ["success", "error"]),
    getAgreementTypeDisplay (type) {
      return getAgreementTypeDisplay(type)
    },
    getRightTypesDisplay (rightTypes) {
      return rightTypes.join(", ")
    },
    getData () {
      this.$refs.agreements.getData()
    },
    onDelete (id) {
      this.$bvModal.show(`confirmation-modal-${id}`)
    },
    onConfirmDelete (agreement) {
      this.$api.agreements.agreementsDelete(agreement.id)
        .then(() => {
          this.success(this.$t("agreement_deleted"))
          this.getData()
        }).catch(error => {
          if (error.response.data && error.response.data.detail) this.error(error.response.data.detail)
        })
    },
    onPublisherSearch (value) {
      this.applyFilters("fuzzy_search", value)
    },
    onLoaded ({ data }) {
      this.count = data.count
    }
  },
  computed: {
    ...mapGetters("user", ["isPublisher", "isSociety"]),
    canEdit () {
      return this.isPublisher || this.isSociety
    },
  },
  watch: {
    partyId () {
      this.getData()
    },
    count (value) {
      this.$emit("count", value)
    }
  },
  props: {
    filters: {
      type: [Object],
      default: () => ({})
    },
    partyId: String,
    partyUrl: {
      type: String,
      default: "parties-detail"
    }
  },
  mounted () {
    // set data from query params
    this.publisherSearch = get(this.$route.query, "fuzzy_search", "")
  }
}
</script>
