<template>
  <span>
    {{ message ? message : "-" }}
  </span>
</template>

<script>
export default {
  name: "BaseLogMessage",
  props: {
    message: String,
  }
}
</script>