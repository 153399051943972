<template>
  <div class="extra-info-container">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
  .extra-info-container {
    background-color: $gray;
    padding: 20px 30px;
    box-shadow: inset 0 2px 5px gray;
  }
</style>

<script>
export default {
  name: "RowExtraInfo",
}
</script>
