import { DEFAULT_MAX_DATE, DEFAULT_MIN_DATE } from "../constants"
import moment from "moment"

const parseDate = (str, format) => {
  return moment(str).format(format)
}

const isDefaultDateRange = (start, end) => {
  let startDate = new Date(start)
  let endDate = new Date(end)
  return startDate.getTime() === DEFAULT_MIN_DATE.getTime() && endDate.getTime() === DEFAULT_MAX_DATE.getTime()
}

export { isDefaultDateRange, parseDate }
