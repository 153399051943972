<template>
  <div>
    <!-- Heading -->
    <b-row class="mb-4">
      <!-- Display Name -->
      <h3 class="d-flex align-items-center">
        {{ party.displayName }}
        <!-- Badges -->
        <div class="d-flex align-items-center">
          <!-- Party Type -->
          <b-badge variant="info" class="ml-4 mr-2">{{ party.type | capitalize }}</b-badge>
          <!-- Member -->
          <b-badge v-if="party.membershipStatus" variant="success" class="mr-2">{{ $t("member") | capitalize }}</b-badge>
          <!-- Member Status -->
          <b-badge v-if="party.membershipStatus" :variant="getMembershipBadgeVariant(party.membershipStatus)" class="mr-2">{{ $t(party.membershipStatus) | capitalize }}</b-badge>
          <!-- IPI/non-IPI -->
          <b-badge variant="primary" class="mr-2" v-if="party.nonIpi">{{ $t("non_ipi") }}</b-badge>
          <b-badge variant="primary" class="mr-2" v-else>IPI</b-badge>
        </div>
      </h3>
    </b-row>

    <!-- Users -->
    <h4 class="pb-3" v-if="party.membershipStatus">{{ $t("users") | startcase }}
      <router-link :to="{ name: 'users-list', params: { id: id } }" class="pl-4">
        <item-count :icon="['fas', 'users']" :count="party.userCount" />
      </router-link>
    </h4>

    <!-- IPI Base Number -->
    <h4 class="pb-3">{{ $t("ipi_information") | startcase }}</h4>
    <b-row class="mb-3 pl-3">
      <b-col sm="6" md="2">{{ $t('ipi_base_number') | startcase }}</b-col>
      <b-col>{{ party.ipiBaseNumber }}</b-col>
    </b-row>

    <!-- IPI Status -->
    <b-row class="mb-3 pl-3">
      <b-col sm="6" md="2">{{ $t('ipi_status') | startcase }}</b-col>
      <b-col>{{ party.ipiStatus }}</b-col>
    </b-row>

    <!-- Date of Birth -->
    <b-row class="mb-3 pl-3" v-if="isContributor">
      <b-col sm="2" md="2">{{ $t('date_of_birth') | startcase }}</b-col>
      <b-col>{{ party.dateOfBirth }}</b-col>
    </b-row>
    
    <!-- Date of Death -->
    <b-row class="mb-3 pl-3" v-if="isContributor && party.dateOfDeath">
      <b-col sm="6" md="2">{{ $t('date_of_death') | startcase }}</b-col>
      <b-col>{{ party.dateOfDeath }}</b-col>
    </b-row>

    <!-- Names and Nationalities -->
    <b-row class="mt-4">
      <!-- Names -->
      <b-col v-if="party.names" sm="6">
        <h4>{{ $t("names") | startcase }}</h4>
        <b-table-lite
          :fields="namesFields"
          :items="party.names"
          borderless
        >
          <template v-slot:cell(fullName)="{ item }">
            <span class="pr-3">{{ item.fullName }}</span>
            <span v-if="item.fullNameLocal" class="text-gray text-small">{{ item.fullNameLocal }}</span>
          </template>
           <template v-slot:cell(type)="{ item }">
            <span>{{ item.type }}</span> - <span>{{ getPartyNameTypesDisplay(item.type) }}</span>
          </template>
        </b-table-lite>
      </b-col>

      <!-- Nationalities -->
      <b-col sm="6" v-if="party.nationalities && party.nationalities.length">
        <h4>{{ $t("nationalities") | capitalize }}</h4>
        <b-table-lite
          :fields="nationalitiesFields"
          :items="party.nationalities"
          borderless
        >
          <template v-slot:cell(country)="{ value }">
            {{ getCountry(value) }}
          </template>
          <template v-slot:cell(startDate)="{ value }">
            {{ parseDate(value) }}
          </template>
          <template v-slot:cell(endDate)="{ value }">
            {{ parseDate(value) }}
          </template>
        </b-table-lite>
      </b-col>
    </b-row>

    <!-- Affiliations -->
    <b-row class="mt-4" v-if="party.affiliations">
      <b-col v-if="party.names" sm="4">
        <h4>{{ $t("affiliations") | capitalize }}</h4>
        <b-table-lite
          :fields="affiliationsFields"
          :items="party.affiliations"
          borderless
        >
          <template v-slot:cell(rightType)="{ value }">
            {{ value.name }}
          </template>
          <template v-slot:cell(society)="{ item }">
            {{ item.societyName }} ({{ item.societyCisacCode }})
          </template>
        </b-table-lite>
      </b-col>
    </b-row>

    <!-- Tabs -->
    <b-row class="my-4" v-if="isContributor || isPublisher">
      <b-col lg="12" sm="12">
        <tabs-layout
          ref="partyTabs"
          :tabs="currentTabs"
          v-model="tabIndex"
          @change="onTabChange"
        >
          <!-- Tab Title -->
          <template v-slot:title="{ tab }">
            {{ getTabTitle(tab) | startcase }}
            <b-badge v-if="tab in tabItemsCount" class="ml-2 text-white" pill>
              {{ getTabItemsCount(tab) }}
            </b-badge>
          </template>

          <!-- Tab Content -->
          <template v-slot:content="{ tab }">

            <!-- Contact Tab -->
            <template v-if="tab == TABS.CONTACT">
              <Contact :id="id" :contact="party.contact"
              />
            </template>

            <!-- Mandates Tab -->
            <template v-if="tab == TABS.MANDATES">
              <Mandates :mandates="party.mandates"/>
            </template>

            <!-- Agreements Tab -->
            <template v-if="tab == TABS.AGREEMENTS">
              <AgreementsList
                class="p-2"
                :filters="{ party: patronymIpiNameNumber }"
                :showSearch="false"
                :partyUrl="$route.name"
                :partyId="party.id"
                @count="setTabItemsCount($event, tab)"
              />
            </template>

            <!-- Works Tab -->
            <template v-if="tab == TABS.WORKS">
              <div>
                <div class="d-flex justify-content-end p-3">
                  <b-button
                    variant="outline-secondary"
                    @click="onDownloadWorksReport"
                  >
                    {{ $t("download_works_list") | startcase }}
                  </b-button>
                </div>
                <works-table-lite :extraOptions="workExtraOptions" :id="id" @count="setTabItemsCount($event, tab)" />
              </div>
            </template>

            <!-- Heirs Tab -->
            <template v-if="tab == TABS.HEIRS">
              <Heirs :id="id" @count="setTabItemsCount($event, tab)" />
            </template>
          </template>
        </tabs-layout>
      </b-col>
    </b-row>

    <b-modal
      id="download-workslist-confirmation-modal"
      hide-header
      size="lg"
      centered
      ok-only
      :okTitle="$t('close') | capitalize"
      class="p-4 d-flex justify-content-center"
    >
      <b-container class="text-center mt-4">
        <div>{{ $t("works_list_for_party_will_be_emailed") | capitalize }}</div>        
      </b-container>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
  .buttons-container {
    display: flex;
    justify-content: flex-end;
    height: 38px;
  }
</style>

<script>
import { Contact, Heirs, Mandates } from "."
import { get, startCase } from "lodash"
import { getPartyNameTypesDisplay, partyNameTypes, partyTypes } from "@/constants"
import { mapGetters, mapMutations, mapState } from "vuex"
import AgreementsList from "@/pages/Agreements/AgreementsList"
import { parseDate } from "@/utils/dates"

const TABS = {
  CONTACT: "contact",
  MANDATES: "mandates",
  WORKS: "works",
  AGREEMENTS: "agreements",
  HEIRS: "heirs",
}

const ROUTES = {
  [TABS.CONTACT]: "parties-detail",
  [TABS.MANDATES]: "parties-detail-mandates",
  [TABS.WORKS]: "parties-detail-works",
  [TABS.AGREEMENTS]: "parties-detail-agreements",
  [TABS.HEIRS]: "parties-detail-heirs",
}

export default {
  name: "PartiesDetail",
  components: {
    AgreementsList,
    Contact,
    Heirs,
    Mandates,
  },
  data () {
    return {
      partyTypes,
      currentTabs: [],
      TABS,
      tabIndex: 0,
      tabItemsCount: {
        [TABS.WORKS]: "-",
        [TABS.AGREEMENTS]: "-",
        [TABS.HEIRS]: "-",
      },
      namesFields: [
        { key: "fullName", label: startCase(this.$t("names")) },
        { key: "ipiNameNumber", label: startCase(this.$t("ipi_name_number")) },
        { key: "type", label: startCase(this.$t("type")) },
      ],
      nationalitiesFields: [
        { key: "country", label: startCase(this.$t("country")) },
        { key: "startDate", label: startCase(this.$t("start_date")) },
        { key: "endDate", label: startCase(this.$t("end_date")) },
      ],
      affiliationsFields: [
        { key: "rightType", label: startCase(this.$t("rights")) },
        { key: "society", label: startCase(this.$t("society")) },
      ],
      party: {
        id: null,
        name: "",
        type: "",
        dateOfBirth: null,
        dateOfDeath: null,
        userCount: 0,
        names: [],
        affiliations: [],
        mandates: [],
        contact: null,
      },
      workExtraOptions: {
        requestFunction () {
          const id = this.$parent.$parent.id
          const queryParams = { page: this.page }
          return this.$api.parties.partiesWorksList(id, queryParams)
        }
      },
    }
  },
  methods: {
    ...mapMutations("consts", ["setCountries"]),
    getPartyNameTypesDisplay,
    getMembershipBadgeVariant (status) {
      return {
        pending: "warning",
        active: "success",
        inactive: "danger",
      }[status]
    },
    parseDate (val) {
      return val ? parseDate(val, this.$config.ISO_DATE_FORMAT) : ""
    },
    getTabTitle (type) {
      return {
        [TABS.CONTACT]: this.$t("contact"),
        [TABS.MANDATES]: this.$t("mandates"),
        [TABS.WORKS]: this.$t("works"),
        [TABS.AGREEMENTS]: this.$t("agreements"),
        [TABS.HEIRS]: this.$t("heirs"),
      }[type]
    },
    getTabItemsCount (tab) {
      return this.tabItemsCount[tab]
    },
    getCountry (country) {
      return get(this.countriesHash, country, { name: "" }).name
    },
    onTabChange ({ index }) {
      this.tabIndex = index
      this.$router.replace(this.getRoute(index))
        .catch(() => {})
    },
    onDownloadWorksReport () {
      this.$api.parties.partiesWorksReport(this.id, {})
        .then(() => {
          this.$bvModal.show("download-workslist-confirmation-modal")
        })
        .catch((err) => {
          let message = err.response.data?.message || err.response.data || err.response
          this.error(message)
        })
    },
    getRoute (index) {
      return {
        name: ROUTES[this.currentTabs[index]],
      }
    },
    retrieveParty (id) {
      this.$api.parties.partiesRetrieve(id)
        .then(response => {
          this.party = response.data
          this.setCurrentTabs()
        })
    },
    setActiveTab () {
      const routeName = this.$route.name
      const activeTab = Object.keys(ROUTES).find(route => ROUTES[route] === routeName)
      const index = this.currentTabs.indexOf(activeTab)
      this.tabIndex = index
    },
    isInCurrentTabs (tab) {
      return this.currentTabs.find(t => t === tab)
    },
    setCurrentTabs () {
      if (this.party.membershipStatus) {
        // Conditionally add the CONTACT tab.
        if (!this.isInCurrentTabs(TABS.CONTACT)) {
          this.currentTabs.push(TABS.CONTACT)
        }

        // Conditionally add the MANDATES tab.
        if (this.party.mandates && !this.isInCurrentTabs(TABS.MANDATES)) {
          this.currentTabs.push(TABS.MANDATES)
        }
      }

      if (this.isSociety) {
        // Conditionally add the WORKS tab. Not available for member users.
        this.currentTabs.push(TABS.WORKS)
      }

      if (this.party.membershipStatus) {
        // Conditionally add the AGREEMENTS tab. Only publishers.
        if (this.isPublisher && !this.isInCurrentTabs(TABS.AGREEMENTS)) {
          this.currentTabs.push(TABS.AGREEMENTS)
        }

        // Conditionally add the HEIRS tab. Only contributors with date of death.
        if (this.isContributor && this.party.dateOfDeath && !this.isInCurrentTabs(TABS.HEIRS)) {
          this.currentTabs.push(TABS.HEIRS)
        }
      }

      this.setActiveTab()
    },
    setTabItemsCount (value, tab) {
      this.tabItemsCount = { ...this.tabItemsCount, [tab]: value }
    },
  },
  computed: {
    ...mapState("consts", ["countriesHash"]),
    ...mapGetters("user", ["isSociety"]),
    patronymIpiNameNumber () {
      let names = this.party.names.filter(n => n.type === partyNameTypes.PA)
      let name = names.length ? names[0] : null
      return name ? name.ipiNameNumber : ""
    },
    isContributor () {
      return this.party.type == partyTypes.CONTRIBUTOR
    },
    isPublisher () {
      return this.party.type == partyTypes.PUBLISHER
    }
  },
  watch: {
    id (newId) {
      this.retrieveParty(newId)
    }
  },
  async mounted () {
    this.retrieveParty(this.id)

    let response = await this.$api.countries.countriesList()
    this.setCountries(response.data)
  },
  props: {
    id: String,
  },
}
</script>
