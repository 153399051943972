import axios from "axios"
import camelcaseKeys from "camelcase-keys"
import { i18n } from "../utils/i18n"
import snakecaseKeys from "snakecase-keys"

import { store } from "../store"


export const host = process.env.VUE_APP_API_HOST
export const API = axios.create({ baseURL: host })

API.defaults.headers.post["Content-Type"] = "application/json"
API.defaults.headers.patch["Content-Type"] = "application/json"
API.defaults.headers.put["Content-Type"] = "application/json"


// Authorization, language and society headers
export function headers () {
  let token = localStorage.getItem("access_token")
  let language = localStorage.getItem("language")
  let society = localStorage.getItem("society") || ""
  let headers = {
    "Accept-Language": language,
    "X-atlas-society": society
  }
  return token ? Object.assign({ "Authorization": "Bearer " + token }, headers) : headers
}

// Global Request Handling
API.interceptors.request.use(
  request => {
    // Set waiting to true
    store.commit("user/waiting", true)
    // Add autorization and language headers
    request.headers.common = Object.assign(request.headers.common, headers())
    return request
  }
)

// Global Response Handling
const successHandler = (response) => {
  store.commit("user/waiting", false)
  return response
}
const errorHandler = (error) => {
  let message = null
  const { status } = error.response

  // Set waiting to false
  store.commit("user/waiting", false)

  // 401 Unauthorized -> Logout
  if (status === 401) {
    store.dispatch("user/logout")
  // 404 Not Found -> Set Page Not Found
  } else if (status === 404) {
    store.commit("user/setPageNotFound", true)
  // 403 Unauthorized -> Set Unauthorized
  } else if (status === 403) {
    store.commit("user/setUnauthorized", true)
  // 500 Internal Error -> Display alert
  } else if (status === 500) {
    message = i18n.t("server_error_message")
    store.dispatch("alert/error", message)
  }

  return Promise.reject({ ...error })
}

API.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
)

// Transform all requests to snake_case and responses to camelCase
// Source: https://github.com/lmiller1990/axios-transform-example/
API.defaults.transformResponse = [(data, headers) => {
  if (data && headers["content-type"] == "application/json") {
    return camelcaseKeys(JSON.parse(data), { deep: true })
  }
  return data
}]

API.defaults.transformRequest = [(data, headers) => {

  if (data && headers.post["Content-Type"] == "application/json") {
    return JSON.stringify(snakecaseKeys(data, { deep: true }))
  }
  return data
}]

// Programmatically click on a url
export function clickOnUrl (url, filename) {
  const link = document.createElement("a")
  link.href = url
  if (filename) link.setAttribute("download", filename) // or any other extension
  document.body.appendChild(link)
  link.click()
  link.parentNode.removeChild(link)
}

// File downloads
export function downloadFile (data, filename) {
  const url = window.URL.createObjectURL(new Blob([data]))
  clickOnUrl(url, filename)
}

// File uploads
export function postFile (file, url) {
  const formData = new FormData()
  formData.append("file", file)

  return API.post(url, formData, {
    headers: {
      post: { "Content-Type": "multipart/form-data" },
    },
  })
}
