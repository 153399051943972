var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex px-4 justify-content-between align-items-center"},[_c('h5',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t("candidates"))))]),_c('b-button',{attrs:{"to":{ name: 'works-create'},"variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t("create_work")))+" ")])],1),_c('div',{staticClass:"d-flex px-4 py-2 align-items-center"},[_c('search-input',{staticClass:"pr-2 flex-grow-1",attrs:{"placeholder":_vm._f("startcase")(_vm.$t('search_by_title_or_alternate_titles')),"value":_vm.filters.titleSearch},on:{"search":_vm.onTitleSearch}}),_c('search-input',{staticClass:"pr-2 flex-grow-1",attrs:{"placeholder":_vm._f("upperfirst")(_vm.$t('search_by_iswc_atlas_id_or_work_code')),"value":_vm.filters.codeSearch},on:{"search":_vm.onCodeSearch}}),_c('search-input',{staticClass:"pr-2 flex-grow-1",attrs:{"placeholder":_vm._f("startcase")(_vm.$t('search_by_party_name')),"value":_vm.filters.ipNameSearch},on:{"search":_vm.onIPNameSearch}}),_c('search-input',{staticClass:"pr-2 flex-grow-1",attrs:{"placeholder":_vm._f("startcase")(_vm.$t('search_by_party_number')),"value":_vm.filters.ipNumberSearch},on:{"search":_vm.onIPNumberSearch}}),_c('search-input',{staticClass:"flex-grow-1",attrs:{"placeholder":_vm._f("startcase")(_vm.$t('search_by_performer')),"value":_vm.filters.performerSearch},on:{"search":_vm.onPerformerSearch}})],1),_c('v-server-table',{ref:"candidates",attrs:{"columns":_vm.columns,"options":_vm.options},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('router-link',{staticClass:"text-info",attrs:{"to":{ name: 'works-detail', params: { id: row.id }}}},[_vm._v(" "+_vm._s(row.title)+" ")]),_vm._l((_vm.getTitleVariants(row.titles)),function(title,index){return _c('div',{key:title+index,staticClass:"text-gray text-small"},[_vm._v(" "+_vm._s(title)+" ")])})],2)]}},{key:"contributors",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.contributorNames(row))+" ")]}},{key:"performers",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.performers.join(", "))+" ")]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-content-end mr-2"},[_c('action',{staticClass:"mr-3",attrs:{"icon":['fas', 'exclamation-circle'],"color":"text-info"},on:{"click":function($event){return _vm.showMatchedWorkInfo(row)}}}),_c('action-button',{staticClass:"ml-2 px-3",attrs:{"variant":"outline-secondary","size":"sm"},on:{"click":function($event){return _vm.match(row.id)}}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t("match")))+" ")])],1)]}}])}),_c('b-modal',{attrs:{"id":("candidate-modal-" + (_vm.element.id)),"title":_vm._f("capitalize")(_vm.$t('candidate')),"hide-footer":"","size":"xl"},on:{"hidden":_vm.onHideInfoModal}},[_c('work-detail',{attrs:{"id":_vm.currentCandidate.id,"work":_vm.currentCandidate,"shares":_vm.currentCandidateShares,"lite":"","inSingleView":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }