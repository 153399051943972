import Vue from "vue"
import VueI18n from "vue-i18n"

import en from "../locales/en"
import id from "../locales/id"
import th from "../locales/th"
import tl from "../locales/tl"

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: localStorage.getItem("language") || "en",
  fallbackLocale: "en",
  messages: {
    en,
    id,
    th,
    tl
  },
})
