import { API } from "./utils"


export const repertoire = {
  repertoireFilesList: (params) => API.get("/repertoire/uploaded-files/", { params }),
  repertoireFileErrorsList: (id, params) => API.get(`/repertoire/uploaded-files/${id}/errors/`, { params }),
  repertoireFilesRetrieve: (id) => API.get(`/repertoire/uploaded-files/${id}/`),
  repertoireFilelandingWorksList: (id, params=null) => API.get(`/repertoire/uploaded-files/${id}/landing-works/`, { params }),
  worksList: (params=null) => API.get("/repertoire/works/", { params }),
  worksCreate: (data) => API.post("/repertoire/works/", data),
  worksDelete: (id) => API.delete(`/repertoire/works/${id}/`),
  worksRetrieve: (id) => API.get(`/repertoire/works/${id}/`),
  worksSharesList: (id, params=null) => API.get(`/repertoire/works/${id}/shares/`, { params }),
  worksProductsCreate: (id, data) => API.post(`/repertoire/works/${id}/products/`, data),
  worksProductsList: (id) => API.get(`/repertoire/works/${id}/products/`),
  worksUpdate: (id, data) => API.patch(`/repertoire/works/${id}/`, data),
  worksDemerge: (id, data) => API.post(`/repertoire/works/${id}/demerge/`, data),
  worksCommentsList: (workId, params=null) => API.get(`/repertoire/works/${workId}/comments/`, { params }),
  worksCommentsCreate: (workId, data) => API.post(`/repertoire/works/${workId}/comments/`, data),
  worksCommentsUpdate: (id, workId, data) => API.put(`/repertoire/works/${workId}/comments/${id}/`, data),
  worksCommentsDelete: (id, workId) => API.delete(`/repertoire/works/${workId}/comments/${id}/`),
  worksLogsList: (workId, params=null) => API.get(`/repertoire/works/${workId}/logs/`, { params }),
  worksMerge: (data) => API.post("/repertoire/merge/", data),
  workUpdateList: (workId, params=null) => API.get(`/repertoire/works/${workId}/updates/`, { params }),
  workUpdateAccept: (workId, id) => API.post(`/repertoire/works/${workId}/updates/${id}/accept/`),
  workUpdateDecline: (workId, id) => API.post(`/repertoire/works/${workId}/updates/${id}/decline/`),
  landingWorksRetrieve: (id) => API.get(`/repertoire/landing-works/${id}/`),
}
