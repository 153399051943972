<template>
  <b-row>
    <!-- Search and Select -->
    <b-col>
      <FileSearchAndSelect
        :fileType="fileType"
        :fileOnlySelection="fileOnlySelection"
        :selectedFiles="selectedFileIds"
        :selectedSheets="selectedSheetIds"
        :searchAndSelectColumns="searchAndSelectColumns"
        :externalFilters="externalFilters"
        @select-file="updateFileSelection"
        @select-page-files="updatePageFilesSelection"

      />
    </b-col>

    <!-- Selected -->
    <b-col>
      <SelectedFiles
        :selectedFiles="selectedFiles"
        :fileOnlySelection="fileOnlySelection"
        :selectedSheets="selectedSheetIds"
        :selectedColumns="selectedColumns"
        @detach-file="detachFileSelection"
        @detach-page-files="detachPageFilesSelection"
      />
    </b-col>
  </b-row>
</template>

<script>
import { EventBus } from "@/utils/event-bus.js"
import FileSearchAndSelect from "./FileSearchAndSelect"
import SelectedFiles from "./SelectedFiles.vue"
import { mapActions } from "vuex"

const defaultSearchAndSelectColumns = ["selection", "filename"]
const defaultSelectedColumns = ["filename", "action"]

export default {
  name: "FilesSelector",
  components: {
    FileSearchAndSelect,
    SelectedFiles,
  },
  data () {
    return {
      selectedFiles: [],
    }
  },
  computed: {
    selectedSheetIds () {
      let sheets = []
      if (!this.fileOnlySelection) {
        for (let file of this.selectedFiles) {
          sheets = [ ...sheets, ...file.sheets.map(s => s.id)]
        }
      }
      return sheets
    },
    selectedFileIds () {
      return this.selectedFiles.map(f => f.id)
    }
  },
  methods: {
    ...mapActions("alert", ["success", "error"]),
    updateSheetSelection (selectedSheets, file) {
      let fileSheets = file.sheets.filter(s => selectedSheets.includes(s.id))
      let filteredFile = { ...file, sheets: fileSheets }
      let selectedFiles = this.selectedFiles.filter(f => f.id !== filteredFile.id)

      if (fileSheets.length) selectedFiles.unshift(filteredFile)
      this.selectedFiles = [ ...selectedFiles ]
    },
    detachSheetSelection (sheet, file) {
      file.sheets = file.sheets.filter(s => s.id !== sheet.id)
      let fileIndex = this.selectedFiles.findIndex(f => f.id === file.id)
      let files = [ ...this.selectedFiles ]
      if (file.sheets.length) {
        files.splice(fileIndex, 1, file)
      } else {
        files.splice(fileIndex, 1)
      }
      this.selectedFiles = [ ...files ]
    },
    updateFileSelection (allSheetsSelected, file) {
      if (!allSheetsSelected.includes(file.id)) {
        this.selectedFiles = this.selectedFiles.filter(f => f.id !== file.id)
      } else {
        this.selectedFiles = this.selectedFiles.filter(f => f.id !== file.id)
        this.selectedFiles = [ file, ...this.selectedFiles ]
      }
      if (this.fileOnlySelection) this.$emit("select-files", this.selectedFiles)
    },
    detachFileSelection (file) {
      this.selectedFiles = [ ...this.selectedFiles.filter(f => f.id !== file.id) ]
      if (this.fileOnlySelection) this.$emit("select-files", this.selectedFiles)
    },
    updatePageFilesSelection (value, pageFiles) {
      if (value) {
        let files = [ ...this.selectedFiles ]
        for (let file of pageFiles) {
          let exists = files.find(f => f.id === file.id)

          // detach existing and re-add it so that all sheets are computed
          if (exists) files = files.filter(f => f.id !== file.id)
          files.push(file)
        }
        this.selectedFiles = [ ...files ]
        if (this.fileOnlySelection) this.$emit("select-files", this.selectedFiles)
      } else {
        this.selectedFiles = this.selectedFiles.filter(f => !pageFiles.map(f => f.id).includes(f.id))
      }
    },
    detachPageFilesSelection (filesToDetach) {
      let filesToDetachIds = filesToDetach.map(f => f.id)
      this.selectedFiles = this.selectedFiles.filter(f => !filesToDetachIds.includes(f.id))
      if (this.fileOnlySelection) this.$emit("select-files", this.selectedFiles)
    }
  },
  watch: {
    initial (value) {
      this.selectedFiles = value
    },
    selectedSheetIds (sheetIds) {
      this.$emit("select-sheets", sheetIds)
    },
  },
  mounted () {
    this.selectedFiles = this.initial
    EventBus.$on("select-sheet", this.updateSheetSelection)
    EventBus.$on("detach-sheet", this.detachSheetSelection)
  },
  props: {
    initial: {
      type: Array,
      default: () => []
    },
    externalFilters: {
      type: Object,
      default: () => ({})
    },
    fileOnlySelection: {
      type: Boolean,
      default: false
    },
    searchAndSelectColumns: {
      type: Array,
      default: () => defaultSearchAndSelectColumns
    },
    selectedColumns: {
      type: Array,
      default: () => defaultSelectedColumns
    },
    fileType: String,
  },
}
</script>
