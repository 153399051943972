<template>
  <list-layout :title="$t('crd_matching')" :count="count" :filters="filters">
    <!-- Status Filters -->
    <template v-slot:status>
      <div class="mr-3">
        <status
          :text="$t('automatched')"
          :name="matchingStatuses.AUTOMATCHED"
          :count="summary.automatched"
          :icon="['fas', 'check-circle']"
          color="green"
          :active="filters.status == matchingStatuses.AUTOMATCHED"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('manually_matched')"
          :name="matchingStatuses.MANUALLY_MATCHED"
          :count="summary.manuallyMatched"
          :icon="['fas', 'check-circle']"
          color="green"
          :active="filters.status == matchingStatuses.MANUALLY_MATCHED"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('unmatched')"
          :name="matchingStatuses.UNMATCHED"
          :count="summary.unmatched"
          :icon="['fas', 'exclamation-circle']"
          color="red"
          :active="filters.status == matchingStatuses.UNMATCHED"
          clickable
          @click="onStatusClick"
        />
      </div>
    </template>

    <!-- Search and Filters -->
    <template v-slot:search>
      <b-row>
        <b-col cols="sm-3 pr-0">
          <search-input
            :value="filters.title"
            @search="onTitleSearch"
            :placeholder="$t('search_by_title') | capitalize"
            class="form-group pr-0"
            :label="$t('title')"
          />
        </b-col>
        <b-col cols="sm-3 pr-0">
          <society-select :value="filters.society" @search="onSocietySearch"/>
        </b-col>
      </b-row>
    </template>

    <template v-slot:table>
      <div class="table-container">
        <v-server-table
          :columns="columns"
          :options="options"
          ref="crdMatches"
          @loaded="onLoaded"
          @pagination="onPagination"
        >
          <!-- Title -->
          <template v-slot:title="{ row }">
            <div v-if="row.work" class="pl-4">
              <router-link
                :to="{ name: 'works-detail', params: { id: row.work }}"
                class="text-info"
              >
                {{ row.title }}
              </router-link>
            </div>
            <div v-else class="d-flex align-items-start">
              <action
                @click="showCandidatesPanel(row)"
                :title="$t('more_information')"
                :icon="['fas', !isOpen(row.id) ?  'angle-right': 'angle-up']"
                class="my-1"
              />
              {{ row.title }}
            </div>
          </template>

          <!-- Contributors -->
          <template v-slot:contributors="{ row }">
            {{ row.contributors.join(", ") }}
          </template>

          <!-- Interested parties - MIPs -->
          <template v-slot:interestedParties="{ row }">
            <!-- Toggle MIPs Modal -->
            <action
              id="mips-toggle"
              @click="$bvModal.show(`mips-modal-${row.id}`)"
              :icon="['far', 'eye']"
              color="text-gray"
            />
            <!-- MIPs Info Modal -->
            <b-modal
              :id="`mips-modal-${row.id}`"
              :title="$t('interested_parties') | capitalize"
              hide-footer
              size="xl"
            >
              <b-table-lite striped hover :items="row.uniqueMips" :fields="mipsTableFields">
                <template #cell(status)="data">
                  <status
                    v-if="data.item.status === matchingStatuses.AUTOMATCHED"
                    :text="getMatchingStatusesDisplay(data.item.status)"
                    :icon="['fas', 'check-circle']"
                    color="green"
                  />
                  <status
                    v-if="data.item.status === matchingStatuses.MANUALLY_MATCHED"
                    :text="getMatchingStatusesDisplay(data.item.status)"
                    :icon="['fas', 'check-circle']"
                    color="green"
                  />
                  <status
                    v-if="data.item.status === matchingStatuses.UNMATCHED"
                    :text="getMatchingStatusesDisplay(data.item.status)"
                    :icon="['fas', 'exclamation-circle']"
                    color="red"
                  />
                </template>
                
              </b-table-lite>
            </b-modal>
            <b-tooltip target="mips-toggle" :title="$t('interested_parties')"></b-tooltip>
          </template>

          <!-- Society -->
          <template v-slot:society="{ row }">
            <div>
              <p v-if="row.cisacCode" class="m-0">{{ cisacSocieties[row.cisacCode] }}</p>
              <p v-else>-</p>
            </div>
          </template>

          <!-- Source -->
          <template v-slot:source="{ row }">
            <div v-if="row.sources && row.sources.length === 1">
                {{ row.sources[0].filename }}
            </div>
            <div v-if="row.sources && row.sources.length > 1">
              {{ `${row.sources.length} ${$t("files")}` }}
              <fa-icon
                class="text-info clickable ml-2"
                size="sm"
                :icon="['fas', 'plus-circle']"
                @click="showSourcesModal(row)"
              />
            </div>
          </template>

          <!-- Status -->
          <template v-slot:status="{ row }">
            <status
              v-if="row.status === matchingStatuses.AUTOMATCHED"
              :text="getMatchingStatusesDisplay(row.status)"
              :icon="['fas', 'check-circle']"
              color="green"
            />
            <status
              v-if="row.status === matchingStatuses.MANUALLY_MATCHED"
              :text="getMatchingStatusesDisplay(row.status)"
              :icon="['fas', 'check-circle']"
              color="green"
            />
            <status
              v-if="row.status === matchingStatuses.UNMATCHED"
              :text="getMatchingStatusesDisplay(row.status)"
              :icon="['fas', 'exclamation-circle']"
              color="red"
            />
          </template>

          <!-- Work -->
          <template v-slot:work="{ row }">
            <!-- Toggle Matched Work Info Modal -->
            <action
              id="work-toggle"
              v-if="row.work"
              @click="showMatchedWorkInfo(row)"
              :icon="['fas', 'exclamation-circle']"
              color="text-info"
            />
            <span v-else>-</span>
            <b-tooltip target="work-toggle" :title="$t('matched_work')"></b-tooltip>
          </template>

          <!-- Child Rows -->
          <template v-slot:child_row="{row}">
            <Candidates
              v-if="row.status === matchingStatuses.UNMATCHED"
             :key="row.id"
             class="w-100 mt-3"
             :element="row"
             :type="candidatesTypes.CRD"
             @match-success="onMatchSuccess"
            />
          </template>
        </v-server-table>
      </div>
    </template>

    <!-- Sources Modal -->
    <b-modal
      id="crd-matching-sources-modal"
      :title="$t('sources') | capitalize"
      @hidden="onHideInfoModal"
      hide-footer
    >
      <p>{{ $t('sources') | capitalize }} {{ $t('for') }} {{ currentUniqueMWN.title }}</p>
      <div v-if="currentUniqueMWN.sources && currentUniqueMWN.sources.length > 1">
        <p v-for="source in currentUniqueMWN.sources" :key="source.id">
          {{ source.filename }}
        </p>
      </div>
    </b-modal>

    <!-- Matched Work Info Modal -->
    <b-modal
      id="matched-work-modal"
      :title="$t('matched_work') | capitalize"
      @hidden="onHideInfoModal"
      hide-footer
      size="xl"
    >
      <p>{{ $t('matched_work') | capitalize }} {{ $t('for') }} "{{ currentUniqueMWN.title }}"</p>
      <work-detail :id="currentWork.id" :work="currentWork" :shares="currentWorkShares" inSingleView lite />
    </b-modal>
  </list-layout>
</template>

<style scoped>
  .selection-column {
    width: 55px;
    padding: 0;
  }

  .green-dark {
    color: var(--green-dark);
  }

  ::v-deep tbody > tr > td {
    font-size: 0.9rem;
  }

  ::v-deep th:first-child {
    width: 32px;
  }

  /* stylelint-disable */
  ::v-deep .VueTables__child-row.open-row {
    border: 1px solid var(--info);
    border-right: 2px solid var(--info);
    border-top: none;
  }
  /* stylelint-enable */

  ::v-deep .open-row {
    border: 1px solid var(--info);
    border-right: 2px solid var(--info);
    border-bottom: none;
  }

</style>

<script>
import { candidatesTypes, cisacSocieties, getMatchingStatusesDisplay, matchingStatuses } from "@/constants"
import { capitalize, get } from "lodash"
import { mapActions, mapState } from "vuex"
import Candidates from "../Usages/Candidates"
import { listRouteMixin } from "@/utils/mixins"


export default {
  name: "CRDMatchingList",
  components: {
    Candidates,
  },
  mixins: [listRouteMixin],
  data () {
    return {
      matchingStatuses,
      candidatesTypes,
      currentUniqueMWN: {},
      currentWork: {},
      currentWorkShares: [],
      filters: {
        status: "",
        title: "",
        society: ""
      },
      mipsTableFields: ["name", "ipiNameNumber", "type", "status"],
      cisacSocieties,
      summary: {},
      count: 0,
      columns: ["title", "iswc", "contributors", "interestedParties", "society", "source", "status", "work"],
      options: {
        showChildRowToggler: true,
        openChildRows: [],
        headings: {
          title: capitalize(this.$t("title")),
          iswc: this.$t("iswc"),
          contributors: capitalize(this.$t("contributors")),
          interestedParties: "IPs",
          society: capitalize(this.$t("society")),
          source: capitalize(this.$t("source")),
          status: capitalize(this.$t("status")),
          work: capitalize(this.$t("work")),
        },
        columnsClasses: {
          title: "width-large text-break px-0",
          iswc: "width-small",
          contributors: "width-large text-break",
          society: "width-small text-break",
          source: "text-break",
          status: "px-0",
        },
        requestKeys: { query: "title" },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))

          return this.$api.crd.uniqueMWNList(queryParams)
        },
        responseAdapter ({ data }) {
          let component = this.$parent.$parent.$parent
          // close all open rows when new data is received
          component.options = { ...component.options, openChildRows: [] }
          component.$refs.crdMatches.$refs.table.openChildRows = []
          component.reviewInProgress = null

          return {
            data: data.results,
            count: data.count,
          }
        },
        rowClassCallback (row) {
          return this.openChildRows.includes(row.id) ? "open-row" : ""
        }
      },
    }
  },
  computed: {
    ...mapState("user", ["waiting"]),
  },
  methods: {
    ...mapActions("alert", ["success", "error"]),
    getMatchingStatusesDisplay,
    showSourcesModal (uniqueMWN) {
      this.currentUniqueMWN = uniqueMWN
      this.$bvModal.show("crd-matching-sources-modal")
    },
    async showMatchedWorkInfo (uniqueMWN) {
      this.currentUniqueMWN = uniqueMWN
      let workId = this.currentUniqueMWN.work

      try {
        const [workResponse, sharesResponse] = await Promise.all([
          this.$api.repertoire.worksRetrieve(workId),
          this.$api.repertoire.worksSharesList(workId)
        ])
        this.currentWork = { ...workResponse.data }
        this.currentWorkShares = { ...sharesResponse.data }
        this.$bvModal.show("matched-work-modal")
      } catch (err) {
        let message = err?.response?.data || err?.response || err
        this.error(message)
      }
    },
    showCandidatesPanel ({ id }) {
      let openRows = []
      if (this.isOpen(id)) {
        openRows = this.options.openChildRows.filter(openRowId => id !== openRowId)
      } else {
        openRows = [ ...this.options.openChildRows, id]
      }
      this.options = { ...this.options, openChildRows: openRows }
      this.$refs.crdMatches.toggleChildRow(id)
    },
    isOpen (id) {
      return this.options.openChildRows.includes(id)
    },
    onMatchSuccess () {
      this.getData()
    },
    onHideInfoModal () {
      this.currentUniqueMWN = {}
      this.currentWork = {}
    },
    onLoaded ({ data }) {
      this.count = data.count
      this.status = data.status
      this.summary = data.summary
    },
    onStatusClick (status) {
      this.filters.status = status
      this.applyFilters("status", status)
    },
    onTitleSearch (value) {
      this.filters.title = value
    },
    onSocietySearch (newValue) {
      this.filters.society = newValue
    },
    getData () {
      this.$refs.crdMatches.getData()
    },
  },
  watch: {
    "filters.title" (value) {
      this.applyFilters("title", value)
    },
    "filters.society" (value) {
      this.applyFilters("cisac_code", value)
    },
  },
  mounted () {
    this.setFilters()
  }
}
</script>
