<template>
  <div>
    <b-row v-for="(value, type) in funds" :key="type" class="mb-1">
      <b-col class="text-gray text-left key">
        {{ getFundTypesDisplay(type) | capitalize }}
      </b-col>
      <b-col class="text-right">
        <span v-if="value">
          <span v-if="hasPercentage(type)" class="text-gray">({{ getPercentage(type) }}%)</span>
          <span> {{ isNaN(value) ? value : formatNumber(value) }}</span>
        </span>
        <span v-else>-</span>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss" scoped>
  .key {
    max-width: 200px;
  }
</style>

<script>
import { formatNumber } from "@/utils/numbers"
import { get } from "lodash"
import { getFundTypesDisplay } from "@/constants"

export default {
  name: "FundsCell",
  methods: {
    formatNumber,
    getFundTypesDisplay,
    hasPercentage (fund) {
      let percent = get(this.percentages, fund)
      if (percent) return Boolean(parseFloat(percent))
    },
    getPercentage (fund) {
      return isNaN(this.percentages[fund]) ? this.percentages[fund] : formatNumber(this.percentages[fund])
    }
  },
  props: {
    funds: Object,
    percentages: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
