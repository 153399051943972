<template>
  <div>
    <h2 class="text-center font-weight-bold pb-2">{{ $t("reset_password") }}</h2>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)" >
        <form-field
          name="email"
          :label="$t('your_email')"
          type="email"
          rules="required|email"
          v-model="form.email"
        />
        <form-field
          name="confirmEmail"
          :label="$t('confirm_email')"
          type="email"
          rules="required|email"
          v-model="form.confirmEmail"
        />
        <div class="d-flex justify-content-end mb-2">
          <router-link :to="{ name: 'login' }" class="text-info">
            {{ $t("back_to_login") }}
          </router-link>
        </div>
        <submit-button
          :disabled="!form.email || !form.confirmEmail || (form.email !== form.confirmEmail)"
          class="btn-block"
          variant="secondary"
        >
          {{ $t("reset_password") }}
        </submit-button>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate"
import { mapActions } from "vuex"

export default {
  name: "ResetForm",
  components: {
    ValidationObserver,
  },
  data () {
    return {
      form: {
        email: "",
        confirmEmail: ""
      },
    }
  },
  methods: {
    ...mapActions("alert", ["success", "error"]),
    onSubmit () {
      this.$api.auth.resetPassword(this.form.email)
        .then(() => {
          this.success(`
            ${ this.$t("an_email_with_instructions_for_reset_pass_has_been_sent") }.
            ${ this.$t("please_check_your_inbox")}
          `)
        })
        .catch((err) => {
          if (err.response.status === 404) {
            this.error(this.$t("user_with_provided_email_doesnt_exist"))
          }
        })
    }
  },
}
</script>
