<template>
  <list-layout :title="`${fileName} - ${sheetName}`" :count="count">
    <template v-slot:subtitle>
      <div v-if="uploader">
        <p class="mb-0">{{ $t("uploaded_by") | capitalize }}: {{ uploader | get("name") }}
          <span class="font-weight-bold">({{ uploader | get("party") }})</span>
        </p>
        <p class="text-gray">{{ uploadDate }}</p>
      </div>

      <!-- Tags -->
      <div class="mt-2">
        <tag :text="tag.value" :icon="getTagIcon(tag)" v-for="(tag, index) in tags" :key="`tag_${index}`" />
      </div>
    </template>


    <!-- Status -->
    <template v-slot:status>
      <div class="mr-3">
        <status
          :text="$t('automatched')"
          :name="matchingStatuses.AUTOMATCHED"
          :count="summary.automatched"
          :icon="['fas', 'check-circle']"
          color="green"
          :active="selectedStatus == matchingStatuses.AUTOMATCHED"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('manually_matched')"
          :name="matchingStatuses.MANUALLY_MATCHED"
          :count="summary.manuallyMatched"
          :icon="['fas', 'check-circle']"
          color="green"
          :active="selectedStatus == matchingStatuses.MANUALLY_MATCHED"
          clickable
          @click="onStatusClick"
        />
      </div>
      <div class="mr-3">
        <status
          :text="$t('unmatched')"
          :name="matchingStatuses.UNMATCHED"
          :count="summary.unmatched"
          :icon="['fas', 'exclamation-circle']"
          color="red"
          :active="selectedStatus == matchingStatuses.UNMATCHED"
          clickable
          @click="onStatusClick"
        />
      </div>
      <div class="mr-3 align-self-start">
        <status
          :text="$t('clear_filters')"
          :count="''"
          :icon="['fas', 'times']"
          clickable
          :active="selectedStatus == ''"
          @click="onStatusClick('')"
        />
      </div>
    </template>

    <template v-slot:search>
      <b-row class="mb-3">
        <b-col cols="sm-4">
          <search-input
            :value="usageSearch"
            @search="onUsageSearch"
          />
        </b-col>
      </b-row>
    </template>

    <!-- Table -->
    <v-server-table
      :columns="columns"
      :options="options"
      @loaded="onLoaded"
      @pagination="onPagination"
      ref="usages"
    >
      <!-- Title -->
      <template v-slot:title="{ row }">
        <div v-if="row.work">
          <router-link
            :to="{ name: 'works-detail', params: { id: row.work }}"
            class="text-info"
          >
            {{ row.title }}
          </router-link>
        </div>
        <div v-else>
          {{ row.title }}
        </div>
      </template>

      <!-- Contributors -->
      <template v-slot:contributors="{ row }">
        {{ getContributorNames(row) }}
      </template>

      <!-- Status -->
      <template v-slot:status="{ row }">
        <status
          v-if="row.status === matchingStatuses.AUTOMATCHED"
          :text="getMatchingStatusesDisplay(row.status)"
          :icon="['fas', 'check-circle']"
          color="green"
        />
        <status
          v-if="row.status === matchingStatuses.MANUALLY_MATCHED"
          :text="getMatchingStatusesDisplay(row.status)"
          :icon="['fas', 'check-circle']"
          color="green"
        />
        <status
          v-if="row.status === matchingStatuses.UNMATCHED"
          :text="getMatchingStatusesDisplay(row.status)"
          :icon="['fas', 'exclamation-circle']"
          color="red"
        />
      </template>

       <!-- Actions -->
      <template v-slot:actions="{ row }">
        <div class="d-flex align-items-center">
          <span id="match-info">
            <action
              v-if="row.work"
              @click="showMatchedWorkInfo(row)"
              :icon="['fas', 'exclamation-circle']"
              color="text-info"
            />
          </span>
        </div>
      </template>
    </v-server-table>
    <!-- Matched Work Info Modal -->
    <b-modal
      id="matched-work-modal"
      :title="$t('matched_work') | capitalize"
      @hidden="onHideInfoModal"
      hide-footer
      size="xl"
    >
      <p>{{ $t('matched_work') | capitalize }} {{ $t('for') }} "{{ currentUsage.title }}"</p>
      <work-detail :id="currentWork.id" :work="currentWork" :shares="currentWorkShares" lite inSingleView />
    </b-modal>
  </list-layout>
</template>

<script>
import { capitalize, get, upperCase } from "lodash"
import { getMatchingStatusesDisplay, matchingStatuses, usageFileTagTypes } from "@/constants"
import { listRouteMixin } from "@/utils/mixins"
import { parseDate } from "@/utils/dates"

export default {
  name: "UsagesList",
  mixins: [listRouteMixin],
  data () {
    return {
      currentUsage: {},
      currentWork: {},
      currentWorkShares: [],
      count: 0,
      summary: {},
      fileName: "",
      sheetName: "",
      tags: [],
      usageSearch: "",
      matchingStatuses,
      uploader: null,
      uploadDate: "",
      selectedStatus: "",
      columns: ["title", "artist", "contributors", "isrc", "iswc", "airdatetime", "duration", "playcount", "status", "actions"],
      options: {
        customFilters: ["status"],
        initFilters: { "status": get(this.$route.query, "status", "") },
        headings: {
          title: capitalize(this.$t("title")),
          artist: capitalize(this.$t("artist")),
          contributors: capitalize(this.$t("contributors")),
          iswc: upperCase(this.$t("iswc")),
          isrc: upperCase(this.$t("isrc")),
          airdatetime: capitalize(this.$t("air_date_time")),
          duration: capitalize(this.$t("duration")),
          playcount: capitalize(this.$t("play_count")),
          status: capitalize(this.$t("status")),
          actions: "",
        },
        requestKeys: { query: "fuzzy_search" },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          let { params, query } = this.$route
          queryParams = { ...queryParams, ...query }
          this.page = Number(get(queryParams, "page", 1))

          return this.$api.usages.usageFileSheetUsagesList(params.id, queryParams)
        }
      },
    }
  },
  methods: {
    getMatchingStatusesDisplay,
    onStatusClick (status) {
      this.selectedStatus = status
      this.applyFilters("status", status)
    },
    getTagIcon (tag) {
      return {
        [usageFileTagTypes.PRIORITY]: ["fas", "exclamation-triangle"],
        [usageFileTagTypes.USE_TYPE]: ["fas", "list"],
        [usageFileTagTypes.LICENSEE]: ["fas", "broadcast-tower"]
      }[tag.type]
    },
    getContributorNames (usage) {
      return usage?.contributors?.length ? usage.contributors.join(", ") : ""
    },
    onUsageSearch (value) {
      this.usageSearch = value
    },
    onLoaded ({ data }) {
      if (data.context) {
        const { name, usageFile: { filename, uploadDate, uploader, tags } } = data.context
        this.fileName = filename,
        this.sheetName = name
        this.uploader = uploader
        this.uploadDate = parseDate(uploadDate, `${this.$config.DATE_FORMAT} h:mm`)
        this.tags = tags
      }
      this.count = data.count
      this.summary = data.summary
    },
    onHideInfoModal () {
      this.currentUsage = {}
      this.currentWork = {}
    },
    async showMatchedWorkInfo (usage) {
      this.currentUsage = usage
      let workId = this.currentUsage.work

      try {
        const [workResponse, sharesResponse] = await Promise.all([
          this.$api.repertoire.worksRetrieve(workId),
          this.$api.repertoire.worksSharesList(workId)
        ])
        this.currentWork = { ...workResponse.data }
        this.currentWorkShares = { ...sharesResponse.data }
        this.$bvModal.show("matched-work-modal")
      } catch (err) {
        let message = err?.response?.data || err?.response || err
        this.error(message)
      }
    },
    getData () {
      this.$refs.usages.getData()
    },
  },
  watch: {
    usageSearch (value) {
      this.applyFilters("fuzzy_search", value)
    },
  },
  mounted () {
    this.usageSearch = get(this.$route.query, "fuzzy_search", "")
    this.selectedStatus = get(this.$route.query, "status", "")
  },
}
</script>
