<template>
  <ValidationObserver ref="createProductForm" v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(onSubmit)" >
      <h3 class="mb-3">{{ $t("create_recording") | startcase }}</h3>

      <!-- Product Type -->
      <!-- <multi-select-field
        v-model="form.type"
        name="type"
        :options="productTypeOptions"
        :label="$t('type')"
        choiceLabel="display"
        track-by="value"
        :showLabels="false"
        :placeholder="$t('select_product_type') | capitalize"
        disabled
        rules="required"
      /> -->

      <!-- Title -->
      <form-field
        name="title"
        :label="$t('title') | capitalize"
        type="text"
        upper
        v-model="form.title"
        rules="required"
      />

      <!-- Artist -->
      <form-field
        name="artist"
        :label="$t('artist') | capitalize"
        type="text"
        upper
        v-model="form.artist"
      />

      <!-- ISRC -->
      <form-field
        v-if="form.type && form.type.value === productTypes.RECORDING"
        name="isrc"
        label="ISRC"
        type="text"
        upper
        rules="isrc"
        :capitalizeLabel="false"
        v-model="form.isrc"
      />

      <!-- Recording Type -->
      <multi-select-field
        v-if="form.type && form.type.value === productTypes.RECORDING"
        v-model="form.recordingType"
        name="recordingType"
        :options="recordingTypeOptions"
        :label="$t('recording_type')"
        choiceLabel="display"
        track-by="value"
        :showLabels="false"
        :placeholder="$t('select_recording_type') | startcase"
      />

      <!-- Action Buttons -->
      <div class="float-right my-3">
        <b-button
          class="mr-2"
          variant="outline-secondary"
          @click="onCancel"
        >
          {{ $t("cancel") | capitalize }}
        </b-button>
        <submit-button variant="secondary">
          {{ $t("save") | capitalize }}
        </submit-button>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { getRecordingTypeDisplay, productTypes, soundRecordingTypes } from "@/constants"
import { ValidationObserver } from "vee-validate"
import { mapActions } from "vuex"
import { startCase } from "lodash"

const { SOUND_RECORDING, MUSIC_VIDEO, OST, RBT } = soundRecordingTypes

export default {
  name: "ProductsCreateForm",
  components: {
    ValidationObserver
  },
  data () {
    return {
      productTypes,
      form: {
        type: { value: SOUND_RECORDING, display: startCase(getRecordingTypeDisplay(SOUND_RECORDING)) },
        title: "",
        artist: "",
        isrc: "",
        recordingType: null,
      },
      productTypeOptions: [
        { value: productTypes.RECORDING, display: startCase(this.$t("recording")) },
      ],
      recordingTypeOptions: [
        { value: SOUND_RECORDING, display: startCase(getRecordingTypeDisplay(SOUND_RECORDING)) },
        { value: MUSIC_VIDEO, display: startCase(getRecordingTypeDisplay(MUSIC_VIDEO)) },
        { value: OST, display: startCase(getRecordingTypeDisplay(OST)) },
        { value: RBT, display: startCase(getRecordingTypeDisplay(RBT)) },
      ],
      formErrors: {},
    }
  },
  methods: {
    ...mapActions("alert", ["success", "error"]),
    onCancel () {
      this.$emit("cancel")
    },
    onSubmit () {
      let data =  this.getRequestData()

      this.$api.repertoire.worksProductsCreate(this.workId, data)
        .then(() => {
          this.success(this.$t("recording_successfully_created"))
          this.$emit("create-success")
        })
        .catch(error => {
          this.formErrors = { ...error.response.data }
          this.$refs.createProductForm.setErrors(this.formErrors)
        })
    },
    getRequestData () {
      let data = {}
      Object.keys(this.form).forEach(field => {
        if (this.form[field]) data[field] = this.form[field].value ? this.form[field].value : this.form[field]
      })
      return data
    },
  },
  props: {
    workId: String,
  }
}
</script>