<template>
  <div>
    <status
      v-if="distributionStatus === distributionStatuses.UNALLOCATED"
      :text="getDistributionStatusDisplay(distributionStatus) | capitalize"
      :icon="['fas', 'question-circle']"
      color="orange"
    />
    <status
      v-else-if="distributionStatus === distributionStatuses.PENDING_ALLOCATION"
      :text="getDistributionStatusDisplay(distributionStatus) | capitalize"
      :icon="['fas', 'pause-circle']"
      color="orange"
    />
    <status
      v-else-if="distributionStatus === distributionStatuses.ALLOCATING"
      :text="getDistributionStatusDisplay(distributionStatus) | capitalize"
      :icon="['fas', 'sync-alt']"
      color="info"
    />
    <status
      v-else-if="distributionStatus === distributionStatuses.ALLOCATED"
      :text="getDistributionStatusDisplay(distributionStatus) | capitalize"
      :icon="['fas', 'check-circle']"
      color="info"
    />
    <status
      v-else-if="distributionStatus === distributionStatuses.CLOSED || distributionStatus === distributionStatuses.PUBLISHED"
      :text="getDistributionStatusDisplay(distributionStatus) | capitalize"
      :icon="['fas', 'check-circle']"
      color="green"
    />
    <status
      v-else-if="distributionStatus === distributionStatuses.ERROR"
      :text="getDistributionStatusDisplay(distributionStatus) | capitalize"
      :icon="['fas', 'exclamation-circle']"
      color="red"
    />
  </div>
</template>
<script>

import { distributionStatuses, getDistributionStatusDisplay  } from "@/constants"

export default {
  name: "DistributionStatus",
  data () {
    return {
      distributionStatuses
    }
  },
  methods: {
    getDistributionStatusDisplay,
  },
  props: {
    distributionStatus: String,
  }
}
</script>
