<template>
  <multi-select-field
    v-bind="$attrs"
    v-model="innerValue"
    :options="options"
    choiceLabel="display"
    track-by="name"
    :show-no-results="showNoResults"
    :noResultMessage="$t('no_parties_found') | capitalize"
    :placeholder="displayPlaceholder()"
    :searchable="true"
    :internal-search="false"
    :clear-on-select="true"
    :errors="innerErrors"
    @search-change="searchParty"
    @open="onOpen"
    :select-label="'select'"
  >
    <template v-slot:option="props">
      <div class="option__display">{{ props.option.display }}</div>
      <div class="option__extra"><span class="mr-2">{{ props.option.nameType }}</span><span>{{ props.option.ipiNameNumber }}</span></div>
    </template>
  </multi-select-field>
</template>

<style lang="scss" scoped>
  ::v-deep .multiselect {
    [class$="option__extra"] {
      font-size: 0.75rem;
    }
  }
</style>

<script>
import { debounce, startCase } from "lodash"
import { i18n } from "@/utils/i18n"

const NON_IPI_SEARCH_FIELDS = {
  NAME: "name",
  ATLAS_PARTY_ID: "atlasPartyId",
}

export default {
  name: "PartySearch",
  data () {
    return {
      options: [],
      innerValue: "",
      innerErrors: [],
      showNoResults: false,
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue (newVal, oldVal) {
      if (newVal) newVal.display = this.getDisplay(newVal)
      this.$emit("input", newVal)
      this.$emit("changes", { previous: oldVal, current: newVal })
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal
    },
    errors (newVal) {
      this.innerErrors = newVal
    },
    partyType (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.reset()
        this.options = []
      }
    }
  },
  methods: {
    reset () {
      this.innerValue = null
      this.innerErrors = []
    },
    onOpen () {
      if (!this.options.length) this.showNoResults = false
    },
    setInitial () {
      if (!this.innerValue && this.value) this.innerValue = this.value
    },
    getDisplay (party) {
      return party?.affiliations?.length ?
        `${party.name} (${[ ...new Set(party.affiliations.map(a => a.societyName))].join(", ")})`
        : party?.name
    },
    searchNonIPI: debounce( function (searchQuery) {
      const query = {
        non_ipi: this.nonIpi,
        member: this.member,
        type: this.partyType
      }

      if (this.searchField == NON_IPI_SEARCH_FIELDS.ATLAS_PARTY_ID) {
        query["code"] = searchQuery
      } else {
        query["name"] = searchQuery
      }

      this.$api.parties.partiesList(query)
        .then((response) => {
          this.options = response.data.results.map(p => ({ display: this.getDisplay(p), ...p }))
          this.showNoResults = !this.options.length
        })
        .catch((error) => {
          this.innerErrors = [error.response.data.message]
        })
    }, 1000),
    displayPlaceholder () {
      let displayText = i18n.t("search_by_name_or_complete_ipi_number")

      if (this.nonIpi && this.searchField === NON_IPI_SEARCH_FIELDS.ATLAS_PARTY_ID) displayText = i18n.t("search_by_party_id")
      if (this.nonIpi && this.searchField === NON_IPI_SEARCH_FIELDS.NAME) displayText = startCase(i18n.t("search_by_name"))
      return displayText
    },
    searchParty (query) {
      let searchQuery = query.trim()
      this.innerErrors = []

      if (!searchQuery) return

      this.searchNonIPI(searchQuery)
    },
  },
  props: {
    value: {
      type: null
    },
    nonIpi: {
      type: Boolean,
      default: null,
    },
    member: {
      type: Boolean,
      default: null,
    },
    partyType: String,
    searchField: {
      type: String,
      default: NON_IPI_SEARCH_FIELDS.NAME
    },
    errors: {
      type: Array,
      default: () => []
    },
  },
  mounted () {
    if (this.value) this.innerValue = this.value
  }
}
</script>
