export default {
  accept: "accept",
  accept_work_update: "accept work update",
  accepted: "accepted",
  acquirer: "Acquirer",
  active: "active",
  adaptor: "Adaptor",
  add: "add",
  add_heir: "add heir",
  add_interested_party: "add interested party",
  add_licensee: "add licensee",
  add_name: "add name",
  add_party: "add party",
  add_performer: "add performer",
  add_title: "add title",
  added: "added",
  added_lyrics: "added lyrics",
  additional: "additional",
  additional_names: "additional names",
  address: "address",
  administration_costs: "administration costs",
  administrator: "Administrator",
  affiliations: "affiliations",
  agreement: "agreement",
  agreement_conflict: "agreement conflict",
  agreement_conflicts: "agreement conflicts",
  agreement_created: "agreement created",
  agreement_deleted: "agreement deleted",
  agreement_is_in_conflict: "This agreement is in conflict with another agreement",
  agreement_is_inactive: "This agreement cannot be edited as it is inactive.",
  agreement_updated: "agreement updated",
  agreements: "agreements",
  agreements_uploads: "agreements uploads",
  air_date_time: "air date and time",
  album: "album",
  allocate: "allocate",
  allocate_distribution: "allocate distribution",
  allocated: "allocated",
  allocating: "allocating",
  allocation_process_successfully_triggered: "allocation process successfully triggered",
  alternate_titles: "alternate titles",
  alternative_title: "alternative title",
  amount: "amount",
  an_email_with_instructions_for_reset_pass_has_been_sent: "an email with instructions to reset your password has been sent",
  and: "and",
  applied: "applied",
  arrangement: "arrangement",
  arranger: "Arranger",
  artist: "artist",
  assignee: "assignee",
  assignor: "assignor",
  at: "at",
  atlas_id: "Atlas ID",
  author: "Author of Lyrics",
  automatched: "automatched",
  back: "back",
  back_to_distribution: "back to distribution",
  back_to_distributions: "back to distributions",
  back_to_login: "back to login",
  back_to_results: "back to results",
  bank_amount: "bank amount",
  blocked: "blocked",
  board_member: "board member",
  bulk_works_upload: "bulk works upload",
  but: "but",
  ca_e_link: "CA/E Link",
  cable: "cable",
  cancel: "cancel",
  candidate: "candidate",
  candidate_matched: "candidate was successfully matched",
  candidates: "candidates",
  chained_to: "chained to",
  changed: "changed",
  cinema: "cinema",
  claimed_mec: "claimed mechanical",
  claimed_per: "claimed performing",
  claimed_total: "claimed total",
  clear_filters: "clear filters",
  clear_selected: "clear selected",
  click_to_select_file_or_drag_and_drop: "click to select file or drag and drop",
  close: "close",
  close_distribution: "close distribution",
  closed: "closed",
  code: "code",
  collection_date: "collection date",
  comment: "comment",
  comment_deleted: "comment deleted",
  comment_saved: "comment saved",
  comment_updated: "comment updated",
  comments: "comments",
  complete: "complete",
  composer: "Composer",
  concerts: "concerts",
  confirm_allocate_distribution: "You are about to trigger allocation for all the pools in this distribution. Would you like to proceed?",
  confirm_close_distribution: "Once closed the distribution and its pools can no longer be edited or deleted. Members will not be notified until distribution is published.",
  confirm_create_agreement: "The agreement data provided would generate a conflict with the following agreement(s). Do you want to create this agreement anyways?",
  confirm_delete_agreement: "do you really want to delete this agreement",
  confirm_delete_comment: "do you really want to delete this comment",
  confirm_delete_distribution: "Do you really want to delete this distribution cycle? All settings and allocations will be lost. This action is not reversible.",
  confirm_delete_distribution_pool: "do you really want to delete this distribution pool",
  confirm_delete_heir: "do you really want to delete this heir",
  confirm_delete_interested_party: "do you wish to proceed in deleting this interested party",
  confirm_delete_pro_rata_file: "do you really want to delete this pro-rata file",
  confirm_delete_usage_file: "This usage file and its related data will be deleted. Do you really want to delete it",
  confirm_delete_user: "do you really want to delete this user",
  confirm_delete_works: "do you really want to delete this work",
  confirm_demerge_work: "confirm demerge work",
  confirm_email: "confirm email",
  confirm_generate_crd_report: "Do you want to request CRD reports for this distribution? You'll be able to download the reports when they are generated.",
  confirm_member_work_update: "Your changes will be reviewed by a society admin before being applied.",
  confirm_publish_distribution: "This action makes distribution reports available to the members. Do you wish to continue?",
  confirm_undo_allocation: "do you want to undo allocations? All allocations in this pool will be removed.",
  confirm_undo_distribution_allocation: "Do you want to undo allocations for all pools in this distribution? All allocations in these pools will be removed.",
  confirm_unmatch_usage: "do you really want to unmatch this usage",
  confirm_update: "confirm update",
  conflict: "conflict",
  conflict_detected: "conflict detected",
  conflict_detected_user_action_created: "A conflict has been detected and a user action has been created",
  conflict_with_existing_work: "the work data you provided conflicts with an existing work, and a user action to solve this conflict was created",
  conflicting_agreement: "conflicting agreement",
  contact: "contact",
  contact_name: "contact name",
  contact_updated: "contact data updated",
  continue: "continue",
  contributor: "contributor",
  contributor_roles: "contributor roles",
  contributors: "contributors",
  copy: "copy",
  copy_configuration_from: "copy configuration from",
  copyright_free: "copyright free",
  country: "country",
  crd_files: "CRD files",
  crd_matching: "CRD matching",
  crd_pool: "CRD pool",
  crd_reports: "CRD reports",
  crd_reports_confirmation: "Confirm Download CRD Reports",
  crd_successfully_matched: "crd successfully matched",
  create: "create",
  create_agreement: "create agreement",
  create_crd_distribution_pool: "create CRD distribution pool",
  create_digital_distribution_pool: "create PRONTO distribution pool",
  create_distribution: "create distribution",
  create_distribution_pool: "create distribution pool",
  create_new_user_for: "create a new user for",
  create_non_ipi_party: "Create Non-IPI Party",
  create_pool: "create pool",
  create_recording: "create recording",
  create_vod_distribution_pool: "Create VOD distribution pool",
  create_work: "create work",
  created: "created",
  created_by: "created by",
  creation_date: "creation date",
  currency: "currency",
  current: "current",
  date_of_birth: "Date of Birth",
  date_of_death: "Date of Death",
  dates: "dates",
  day: "day",
  declare_new_work: "declare new work",
  decline: "decline",
  decline_work_update: "decline work update",
  declined: "declined",
  deductibles: "deductibles",
  delete: "delete",
  delete_agreement: "delete agreement",
  delete_distribution: "delete distribution",
  delete_distribution_pool: "delete distribution pool",
  delete_heir: "delete heir",
  delete_interested_party: "delete interested party",
  delete_pro_rata_file: "delete current file",
  delete_usage_file: "delete usage file",
  delete_user: "delete user",
  delete_work: "delete work",
  deleted: "deleted",
  demerge: "demerge",
  demerge_message: "The following source will be demerged from this work",
  demerged: "demerged",
  demerged_work: "demerged work",
  description: "description",
  detach_all_files: "detach all files",
  detach_file: "detach file",
  detach_sheet: "detach sheet",
  different_spelling: "Different Spelling",
  digital_files: "digital files",
  digital_pool: "PRONTO pool",
  distributable: "distributable",
  distributable_net: "distributable net",
  distribution: "distribution",
  distribution_errors: "distribution errors",
  distribution_pool_successfully_created: "distribution pool successfully created",
  distribution_pool_successfully_deleted: "distribution pool successfully deleted",
  distribution_pool_successfully_edited: "distribution pool successfully edited",
  distribution_successfully_closed: "distribution successfully closed",
  distribution_successfully_created: "distribution successfully created",
  distribution_successfully_deleted: "distribution successfully deleted",
  distribution_successfully_edited: "distribution successfully edited",
  distribution_successfully_published: "distribution successfully published",
  distributions: "distributions",
  do_you_want_to_accept_this_update: "do you want to accept this update",
  do_you_want_to_continue: "Do you want to continue?",
  do_you_want_to_decline_this_update: "do you want to decline this update",
  domestic_pool: "domestic pool",
  done: "done",
  download_crd_reports: "download CRD reports",
  download_current_file: "download current file",
  download_prorata_file_template: "download pro-rata file template",
  download_qa_reports: "download QA reports",
  download_template: "download template",
  download_works_list: "download works list",
  dsp: "DSP",
  duplicates_not_allowed: "duplicates not allowed",
  duration: "duration",
  edit: "edit",
  edit_crd_distribution_pool: "edit CRD distribution pool",
  edit_digital_distribution_pool: "edit PRONTO distribution pool",
  edit_distribution: "edit distribution",
  edit_distribution_pool: "edit distribution pool",
  edit_shares: "edit shares",
  edit_tags: "edit tags",
  edit_vod_distribution_pool: "Edit VOD distribution pool",
  edit_work: "edit work",
  edited: "edited",
  editing_multiple_tags_warning: "The following tags will be applied to all selected files. If no tag is specified, any existing tags will be removed from the files.",
  email: "email",
  end: "end",
  end_date: "end date",
  error: "error",
  errors: "errors",
  events: "events",
  exclude: "exclude",
  existing_agreements: "existing agreements",
  extra_search_title: "extra search title",
  field: "field",
  file_errors: "file errors",
  file_not_attached: "file not attached",
  file_successfully_uploaded: "file successfully uploaded",
  filename: "filename",
  files: "files",
  first_line_of_text: "first line of text",
  first_name: "first name",
  for: "for",
  form_contains_errors: "the form contains errors. please verify all the information provided",
  formal_title: "formal title",
  from: "from",
  full_name: "full name",
  fund_id: "fund ID",
  funds: "funds",
  general: "general",
  general_users: "general users",
  generate_UP1_report: "Generate UP1 report",
  go_to: "go to",
  go_to_agreement_conflict: "go to agreement conflict",
  go_to_detail_page: "go to detail page",
  go_to_user_action: "go to user action",
  go_to_work: "go to work",
  go_to_works_matching_user_action: "go to works matching user action",
  gross_amount: "gross",
  has_files: "has files",
  heir_deleted: "heir deleted",
  heirs: "heirs",
  hide_reviewed: "hide reviewed",
  high: "high",
  history: "history",
  holding_reference: "Holding Reference",
  inactive: "inactive",
  include: "include",
  include_exclude: "include/exclude",
  income_participant: "Income Participant",
  incoming_work: "incoming work",
  incomplete: "incomplete",
  incorrect_title: "incorrect title",
  ingested: "ingested",
  ingestion_stats: "ingestion stats",
  inheritance_date: "inheritance date",
  interested_parties: "interested parties",
  interested_party: "interested party",
  interested_party_name: "interested party name",
  interested_party_number: "interested party number",
  invalid_ipi_base_number: "Invalid IPI base number", // DO NOT REMOVE
  invalid_isrc: "invalid ISRC",
  invalid_iswc: "invalid ISWC", // DO NOT REMOVE
  ip_matching_stats: "IP matching stats",
  ipi_base_number: "IPI base number",
  ipi_information: "IPI information",
  ipi_name_number: "IPI name number",
  ipi_number: "IPI number",
  ipi_status: "IPI status",
  ipi_status_1: "Self-referenced valid IP",
  ipi_status_2: "Purchase",
  ipi_status_3: "Logical deletion with cross-reference",
  ipi_status_4: "Total logical deletion without cross-reference",
  isrc: "ISRC",
  iswc: "ISWC",
  item: "item",
  iteration: "iteration",
  karaoke: "karaoke",
  landed: "landed",
  landing_db_ingestion: "landing db ingestion",
  language: "language",
  last_name: "last name",
  last_run: "last run",
  latest: "latest",
  legal_name: "legal name",
  less: "less",
  licensee: "licensee",
  link: "link",
  link_recording: "link recording",
  linked: "linked",
  linked_to_existing_work: "the work has been linked to an existing work",
  linked_work: "linked work",
  login: "login",
  logout: "logout",
  low: "low",
  lyrical_adaptations: "lyrical adaptations",
  mandates: "mandates",
  manually_created: "manually created",
  manually_linked: "manually linked",
  manually_matched: "manually matched",
  mark_as_reviewed: "mark as reviewed",
  marked_for_deletion: "marked for deletion",
  match: "match",
  matched: "matched",
  matched_by: "matched by",
  matched_work: "matched work",
  matching_stats: "matching stats",
  mechanical: "mechanical",
  medium: "medium",
  member: "member",
  member_reports: "member reports",
  member_reports_download: "download distribution reports",
  member_reports_processing: "processing distribution reports",
  member_reports_request: "request distribution reports",
  member_work_matching_message: "This work registration is a potential duplicate and is pending review by your society administrators.",
  member_work_update_notice: "You'll be able to track any pending changes in the “work updates” tab within the work's page.",
  membership_status: "membership status",
  merge: "merge",
  merge_works: "merge works",
  merging_more_than_three_works_not_allowed: "merging more than three works is not allowed",
  merging_not_allowed_for_non_domestic_works: "merging not allowed for non domestic works",
  metadata: "metadata",
  modification: "Modification",
  modified_lyrics: "modified lyrics",
  modified_version: "modified version",
  month: "month",
  more: "more",
  more_information: "more information",
  music_added_to_text: "music added to text",
  music_arrangement: "music arrangement",
  music_video: "music video", // DO NOT REMOVE
  my_profile: "my profile",
  my_works: "my works",
  name: "name",
  name_search: "name search",
  names: "names",
  nationalities: "nationalities",
  net_distributable_amount: "net",
  new_lyrics_added: "new lyrics added",
  new_music_added: "new music added",
  new_work_created: "a new work was successfully created",
  no: "no",
  no_chained_shares: "no chained shares",
  no_crd_reports: "CRD reports not available",
  no_distribution_reports_for_member: "You are not entitled to any payment in this distribution cycle, so reports are disabled",
  no_files: "no files",
  no_lyrics: "no lyrics",
  no_member_reports: "member reports not available",
  no_parties_found: "no parties found",
  no_payments_for_you: "you are not entitled to any payment in this distribution cycle, so reports are disabled",
  no_performers_for_this_work: "no performers declared for this work.",
  no_qa_reports: "QA reports not available",
  no_shares: "no shares",
  no_sources_found: "no sources found for this work",
  no_stats_info_available: "no stats available",
  no_work_report: "work report not available",
  non_ipi: "non IPI",
  not_current: "not current",
  nothing_has_been_selected: "nothing has been selected",
  on: "on",
  only_alpha_numeric_characters_allowed: "only alpha numeric characters allowed",
  only_domestic: "only domestic works",
  only_with_usages: "only with usages",
  original: "original",
  original_lyrics: "original lyrics",
  original_music: "original music",
  original_publisher: "Original Publisher",
  original_sound_track: "original sound track", // DO NOT REMOVE
  original_title: "original title",
  original_title_translated: "original title translated",
  other_reference: "Other Reference",
  override_distribution: "override distribution",
  overview: "overview",
  own_works: "own works",
  part_title: "part title",
  parties: "parties",
  party: "party",
  password: "password",
  patronym: "Patronym",
  payees: "payees",
  pending: "pending",
  pending_allocation: "pending allocation",
  pending_for_validation: "pending for validation",
  pending_updates: "pending updates",
  per_and_mec: "PER and MEC",
  per_duration: "per duration",
  per_play: "per play",
  per_weighted_play: "per weighted play",
  percent: "percent",
  performer: "performer",
  performers: "performers",
  performing: "performing",
  period: "period",
  play_count: "play count",
  plays: "plays",
  please_check_your_inbox: "please, check your inbox",
  pool: "pool",
  pool_must_be_created_before_attaching: "the distribution pool must be created before attaching a pro-rata file",
  pools: "pools",
  preferred: "preferred",
  preferred_iswc: "preferred iswc",
  primary: "primary",
  primary_address: "primary address",
  primary_email: "primary email",
  primary_phone: "primary phone",
  priority: "priority",
  privacy_policy: "privacy policy",
  pro_rata_equal: "pro-rata - equal",
  pro_rata_file_deleted: "pro-rata file successfully deleted",
  pro_rata_percentage: "pro-rata - % per payee",
  processing: "processing",
  processing_crd_reports: "processing CRD reports",
  products_not_found: "no recordings declared for this work.",
  pronto_pre_distribution: "pronto pre-distribution",
  proprietary_id: "proprietary id",
  pseudonym_groups: "Pseudonym Groups",
  pseudonym_of_individuals: "Pseudonym of Individuals",
  public_domain: "public domain",
  publish: "publish",
  publish_distribution: "publish distribution",
  published: "published",
  published_date: "published date",
  publisher: "publisher",
  publisher_name: "publisher name",
  publisher_roles: "publisher roles",
  qa_reports: "QA reports",
  queued_for_processing: "added to processing queue",
  radio: "radio",
  reason: "reason",
  reconciled: "reconciled",
  reconciliation: "reconciliation",
  recording: "recording",
  recording_conflict: "recording conflict",
  recording_conflict_help_text: "The recording on top is currently linked to the following works. Select one work, and it will be removed from the rest.",
  recording_conflicts: "recording conflicts",
  recording_details: "recording details",
  recording_release: "recording release",
  recording_successfully_created: "recording successfully created",
  recording_successfully_linked: "recording successfully linked",
  recording_successfully_unlinked: "recording successfully unlinked",
  recording_title: "recording title",
  recording_type: "recording type",
  recordings: "recordings",
  refresh: "refresh",
  register: "register",
  remitting_society_distribution_identifier: "remitting society distribution id",
  remove: "remove",
  repertoire_admin: "repertoire admin",
  replaced_lyrics: "replaced lyrics",
  report_generation_confirmation: "report generation confirmation",
  report_generation_has_successfully_been_initiated: "report generation has successfully been initiated",
  request_changes: "request changes",
  request_member_reports: "request member reports",
  request_reports_success: "The reports have been requested and will be sent to your inbox as soon as they are ready",
  request_sent: "Request sent, report will be available shortly",
  request_work_report: "request work report",
  requested_by: "requested by",
  reset_password: "reset password",
  resolution: "resolution",
  resolved_by: "resolved by",
  restricted_title: "restricted title",
  right_types: "right types",
  rights: "rights",
  ring_back_tone: "ring back tone", // DO NOT REMOVE
  role: "role",
  rule: "rule",
  rules: "rules",
  rules_change_warning: "This rules change will detach any files attached to the pool. Do you want to continue",
  save: "save",
  search: "search",
  search_and_select: "search and select",
  search_by: "search by",
  search_by_artist: "artist",
  search_by_atlas_id: "Atlas ID",
  search_by_filename: "filename",
  search_by_ipi_number: "by IPI number",
  search_by_iswc_atlas_id_or_work_code: "ISWC, ATLAS ID, Mis@Asia or DIVA",
  search_by_name: "name",
  search_by_name_or_complete_ipi_number: "Name or Complete IPI Number",
  search_by_party_id: "Party ID",
  search_by_party_name: "Composer or Publisher Name",
  search_by_party_number: "party number",
  search_by_performer: "Performer Name or Artist Name",
  search_by_title: "title",
  search_by_title_or_alternate_titles: "Original or Alternate Title",
  search_existing_works: "search existing works",
  search_ipi_db: "Search IPI Database",
  search_non_ipi_parties: "Search Non-IPI Parties",
  search_results: "search results",
  secondary_email: "secondary email",
  secondary_phone: "secondary phone",
  select_agreement: "select agreement",
  select_candidate: "select candidate",
  select_date_range: "select date range",
  select_option: "select option",
  select_priority: "select priority",
  select_product_type: "select product type",
  select_recording_type: "select recording type",
  select_this_work: "select this work",
  select_use_type: "select use type",
  selected: "selected",
  selected_files: "selected files",
  sender: "sender",
  sender_currency: "sender currency",
  server_error_message: "something went wrong, please contact the administrator.", // DO NOT REMOVE
  service: "service",
  set_as_preferred_iswc: "set as preferred iswc",
  share: "share",
  shares: "shares",
  show_distribution: "show the distribution key",
  show_members_only: "show members only",
  show_non_ipi_only: "Show non IPI only",
  show_source_metadata: "show source metadata",
  signup_confirmation: "you have signed up successfully. we're reviewing your application. please wait for your account to be activated.",
  single_view_ingestion: "single view ingestion",
  society: "society",
  society_admin: "society admin",
  sound_recording: "sound recording",
  source: "source",
  sources: "sources",
  specific: "specific",
  split: "split",
  standardized_spelling: "Standardized Spelling",
  start: "start",
  start_date: "start date",
  stats: "stats",
  status: "status",
  sub_arranger: "Sub Arranger",
  sub_author: "Sub Author",
  submit_for_review: "submit for review",
  submitted_by: "submitted by",
  substituted_publisher: "Substituted Publisher",
  synchronisation: "synchronisation",
  tag_filters: "tag filters",
  tagging_was_successful: "tagging was successful",
  termination_date: "termination date",
  territories: "territories",
  territory: "territory",
  the_following_errors_have_to_fixed_for_allocation_to_run: "the following errors have to fixed for allocation to run",
  this_field_confirm_does_not_match: "this field confirmation does not match", // DO NOT REMOVE
  this_field_is_required: "this field is required", // DO NOT REMOVE
  this_field_must_be_a_valid_email: "this field must be a valid email", // DO NOT REMOVE
  title: "title",
  to: "to",
  total: "total",
  total_unique: "total unique",
  transaction: "transaction",
  translated_lyrics: "translated lyrics",
  translator: "Translator",
  tv: "tv",
  type: "type",
  unallocated: "unallocated",
  undo_allocation: "undo allocation",
  undo_allocation_success: "undo allocation success",
  unlink: "unlink",
  unmatch: "unmatch",
  unmatch_confirmation: "unmatch confirmation",
  unmatched: "unmatched",
  unspecified: "unspecified",
  up1_report_confirmation: "a report for the following files will be generated and a link to download the report will be sent to this email address",
  update: "update",
  update_user: "update user",
  upload: "upload",
  upload_date: "upload date",
  upload_pro_rata_file: "upload pro-rata file",
  uploaded_by: "uploaded by",
  uploader: "uploader",
  usage_file_successfully_marked_for_deletion: "usage file successfully marked for deletion",
  usage_files: "usage files",
  usage_period: "usage period",
  usage_successfully_matched: "usage successfully matched",
  usage_successfully_unmatched: "usage successfully unmatched",
  usages_matching: "usages matching",
  use_type: "use type",
  user: "user",
  user_action: "user action",
  user_actions: "user actions",
  user_email: "user email",
  user_email_field_help_text: "this email will be used to login to the application",
  user_name: "user name",
  user_name_field_help_text: "this name identifies a user that can login to the application",
  user_updated: "user updated",
  user_with_provided_email_doesnt_exist: "a user with the provided email does not exist",
  users: "users",
  view: "view",
  view_in_works_database: "view in works database",
  view_user_action: "view user action",
  view_work: "view work",
  vod_files: "VOD Files",
  vod_pool: "VOD Pool",
  warning: "warning",
  week: "week",
  wex_remitted_royalty_amount: "WEX remitted royalty amount",
  which_work_do_you_want_to_keep: "which work do you want to keep",
  with_up: "allocate to UP",
  without_up: "do not allocate to UP",
  work: "work",
  work_code: "work code",
  work_codes: "work codes",
  work_detail: "work detail",
  work_edit_to_be_reviewed: "your request for changes has been successfully submitted, and will be verified by your society admins.",
  work_id: "Work ID",
  work_matching: "work matching",
  work_matching_stats: "work matching stats",
  work_registration: "work registration",
  work_registration_conflict: "this work registration is a potential duplicate, click on the link to go to the user actions page.",
  work_registration_created: "the work was successfully created, click the link below to see details.",
  work_registration_details: "work registration details",
  work_registration_linked: "this work registration was identified as a duplicate and was linked to an existing work, click on the link to view the details.",
  work_registration_to_be_reviewed: "your work registration has been successfully submitted, and will be verified by your society admins.",
  work_report: "work report",
  work_selected: "work selected",
  work_successfully_created: "the work was successfully created",
  work_successfully_deleted: "work successfully deleted",
  work_successfully_demerged: "work successfully demerged",
  work_successfully_updated: "work successfully updated",
  work_title: "work title",
  work_type: "work type",
  work_update_accepted: "work update accepted",
  work_update_declined: "work update declined",
  work_updates: "work updates",
  works: "works",
  works_list_for_party_will_be_emailed: "A file containing all the works for this party is being generated and a link to download will be sent via email once it is ready.",
  works_list_for_you_will_be_emailed: "A file containing all your works is being generated and a link to download will be sent via email once it is ready.",
  works_matching: "works matching",
  works_search_by: "search within publisher works by title, alternate title or iswc",
  works_single_view: "works single view",
  works_successfuly_merged: "works successfully merged",
  works_with_non_ipi_ips: "works with non-IPI IPs",
  wrong_role_for_party: "wrong role for party",
  yes: "yes",
  your_email: "your email",
}
