<template>
  <v-server-table
    name="workRegistration"
    ref="workRegistration"
    :columns="columns"
    :options="options"
    @loaded="onLoaded"
    @pagination="onPagination"
    @sorted="onSorted"
  >
    <!-- Title -->
    <template v-slot:title="{ row }">
      <router-link
        :to="{ name: 'actions-registration-detail', params: { id: row.id } }"
        class="text-info"
      >
        {{ row.landingWork.title }}
      </router-link>
    </template>

    <!-- Created date -->
    <template v-slot:created="{ row }">
      <date-time-cell :dateTime="row.created"/>
    </template>

    <!-- Created by -->
    <template v-slot:createdBy="{ row }">
      <div>{{ row.createdBy.name }}</div>
      <div class="text-gray">{{ row.createdBy.party }}</div>
    </template>

    <!-- Status -->
    <template v-slot:status="{ row }">
      <status
        v-if="row.status === statuses.DONE"
        :text="$t('done')"
        :icon="['fas', 'check-circle']"
        color="green"
      />
      <status
        v-else-if="row.status === statuses.PENDING"
        :text="$t('pending')"
        :icon="['fas', 'question-circle']"
        color="orange"
      />
    </template>

    <!-- Resolved by -->
    <template v-slot:resolvedBy="{ row }">
      <div>{{ row.resolvedBy.name }}</div>
      <div class="text-gray">{{ row.resolvedBy.party }}</div>
    </template>

    <!-- Resolution -->
    <template v-slot:resolutionType="{ row }">
      <div>
        <span v-if="row.resolutionType">
          {{ resolutionDisplay(row.resolutionType) | capitalize }}
        </span>
        <span v-else class="ml-4">-</span>
      </div>
    </template>
  </v-server-table>
</template>

<script>
import { capitalize, get } from "lodash"
import { getUserActionResolutionTypeDisplay, userActionStatuses } from "@/constants"
import { listRouteMixin } from "@/utils/mixins"

export default {
  name: "WorkRegistrationList",
  mixins: [listRouteMixin],
  data () {
    return {
      statuses: userActionStatuses,
      columns: ["title", "created", "createdBy", "status", "resolvedBy", "resolutionType"],
      options: {
        headings: {
          title: capitalize(this.$t("title")),
          created: capitalize(this.$t("created")),
          createdBy: capitalize(this.$t("created_by")),
          status: capitalize(this.$t("status")),
          resolvedBy: capitalize(this.$t("resolved_by")),
          resolutionType: capitalize(this.$t("resolution")),
        },
        sortable: ["created"],
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          return this.$api.userActions.workRegistrationList(queryParams)
        }
      },

    }
  },
  methods: {
    getData () {
      this.$refs.workRegistration.getData()
    },
    onLoaded ({ data }) {
      this.$emit("loaded", data.count)
    },
    onSorted (sorting) {
      this.applyFilters("order_by", sorting.column)
      this.applyFilters("ascending", sorting.ascending)
    },
    resolutionDisplay (resolution) {
      return resolution ? getUserActionResolutionTypeDisplay(resolution) : ""
    },
  },
  watch: {
    resolution (resolution) {
      this.applyFilters("show_done", this.showDone)
      this.applyFilters("resolution", resolution?.value)
    },
    status (value) {
      this.applyFilters("status", value ? value.value : null)
    },
    titleSearch (value) {
      this.applyFilters("title", value)
    },
  },
  props: {
    titleSearch: String,
    resolution: {
      type: Object,
      default: () => ({}),
    },
    status: Object,
  }
}
</script>
