<template>
  <b-modal id="edit-tags-modal" hide-header hide-footer>
    <EditTagsForm :selectedFiles="selectedFiles" @cancel="onCancel" @tagging-success="onTaggingSuccess" />
  </b-modal>
</template>

<script>
import EditTagsForm from "./EditTagsForm"

export default {
  name: "EditTagsModal",
  components: {
    EditTagsForm,
  },
  data () {
    return {}
  },
  methods: {
    onCancel () {
      this.$bvModal.hide("edit-tags-modal")
    },
    onTaggingSuccess () {
      this.$emit("refresh")
      this.$bvModal.hide("edit-tags-modal")
    }
  },
  props: {
    selectedFiles: {
      Type: Array,
      default: () => ([])
    }
  }
}
</script>