<template>
  <div>
    <ValidationObserver ref="agreementsCreate" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div class="d-flex justify-content-end">
          <b-button variant="outline-secondary" class="" @click="$router.go(-1)">
            {{ $t("cancel") | capitalize }}
          </b-button>
          <submit-button class="ml-2" variant="secondary">{{ $t("save") | capitalize }}</submit-button>
        </div>
         <b-row>
          <b-col cols="6">
            <!-- ASSIGNOR -->
            <ipi-search
              v-model="form.assignor"
              :label="$t('assignor')"
              :party-type="PUBLISHER"
              name="assignor"
              rules="required"
              @input="onPublisherInput"
            />
          </b-col>
          <b-col cols="6">
            <!-- ASSIGNEE -->
            <ipi-search
              v-model="form.assignee"
              :label="$t('assignee')"
              :party-type="PUBLISHER"
              name="assignee"
              rules="required"
              :errors="errors | get('assignee')"
            />
          </b-col>
        </b-row>

        <!-- DATES -->
        <b-row>
          <b-col cols="6">
            <date-field
              :label="$t('start_date')"
              name="startDate"
              v-model="form.startDate"
              rules="required"
            />
          </b-col>
          <b-col cols="6">
            <date-field
              :label="$t('end_date')"
              name="endDate"
              v-model="form.endDate"
              :disabled="!form.startDate"
              :disabled-date="beforeStartDate"
            />
          </b-col>
       </b-row>

        <b-row>
          <b-col cols="6">
            <!-- RIGHT TYPES -->
            <multi-select-field
              :label="$t('rights')"
              name="rightTypes"
              rules="required"
              v-model="form.rightTypes"
              :options="rightTypes"
              choiceLabel="name"
              :multiple="true"
              track-by="code"
              :hideSelected="true"
              :showLabels="false"
              @input="onChange()"
              placeholder
            />
          </b-col>
          <b-col cols="6">
            <!-- SHARES -->
            <split-input
              :label="$t('share')"
              name="share"
              v-model="form.share"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <!-- TYPE -->
            <form-select
              v-model="form.type"
              :label="$t('type')"
              :options="typeOptions"
              rules="required"
              class="w-50 mr-3"
            />
          </b-col>
          <b-col v-if="form.type === agreementTypes.SPECIFIC" cols="6">
            <!-- INCLUDE/EXCLUDE -->
            <form-select
              v-model="form.inclusionFlag"
              :label="$t('include_exclude')"
              :options="inclusionOptions"
              rules="required"
              class="w-50 mr-3"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <!-- SPECIFIC AGREEMENT SELECTED WORKS -->
            <multi-select-field
              v-if="form.type === agreementTypes.SPECIFIC"
              name="works"
              :label="$t('works')"
              v-model="form.works"
              :options="works"
              choiceLabel="title"
              track-by="id"
              rules="required"
              :allowEmpty="true"
              :multiple="true"
              :hideSelected="true"
              :placeholder="$t('works_search_by')"
              :help-text="$t('works_search_by')"
              :searchable="true"
              :internal-search="false"
              :show-no-results="showNoResults"
              :custom-label="getWorkLabel"
              @search-change="onWorksSearchChange"
              class="flex-grow-1"
            />
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<style lang="scss" scoped>
  label {
    font-weight: bold;
    font-size: 0.85rem;
  }
</style>

<script>
import { agreementTypes, inclusionFlags, partyTypes } from "@/constants"
import { clone, debounce, get } from "lodash"
import { ValidationObserver } from "vee-validate"
import { mapState } from "vuex"
import moment from "moment"

export default {
  name: "AgreementsCreate",
  components: {
    ValidationObserver,
  },
  data () {
    return {
      PUBLISHER: partyTypes.PUBLISHER,
      works: [],
      showNoResults: true,
      typeOptions: [
        { text: this.$t("general"), value: agreementTypes.GENERAL },
        { text: this.$t("specific"), value: agreementTypes.SPECIFIC }
      ],
      inclusionOptions: [
        { text: this.$t("include"), value: inclusionFlags.INCLUDE },
        { text: this.$t("exclude"), value: inclusionFlags.EXCLUDE }
      ],
      form: {
        assignor: null,
        assignee: null,
        share: 100,
        startDate: null,
        endDate: null,
        type: agreementTypes.GENERAL,
        inclusionFlag: null,
        works: [],
        rightTypes: [],
        normalizedCountries: [],
      },
      agreementTypes,
    }
  },
  computed: {
    ...mapState("consts", ["rightTypes"]),
  },
  methods: {
    beforeStartDate (date) {
      return date < moment(this.form.startDate)
    },
    onChange () {
      this.$nextTick(() => {
        this.$emit("input", this.form)
      })
    },
    onPublisherInput () {
      // Reset any selected works information.
      this.form.works = []
      this.works = []
      this.showNoResults = true

      // Get assignor works
      this.getPublisherWorks()
    },
    getWorkLabel (work) {
      return work.iswc ? `${work.iswc} - ${work.title}` : work.title
    },
    onSubmit () {
      let data = this.getRequestData(this.form)

      this.$emit("submit", data)
    },
    getRequestData () {
      let data = clone(this.form)

      data.assignor = data.assignor.ipiBaseNumber
      data.assignee = data.assignee.ipiBaseNumber
      data.normalized_countries = data.normalizedCountries.map(country => country.id)
      data.works = data.type == agreementTypes.SPECIFIC ? data.works.map(work => work.id): []
      data.rightTypes = data.rightTypes.map(rightType => rightType.code)

      return data
    },
    getPublisherWorks: debounce(
      function (query) {
        if (!this.form.assignor) {
          return
        }

        let queryParams = {
          limit: 100,
          ip_number: this.form.assignor.ipiNameNumber
        }

        if (query) {
          queryParams["title_search"] = query.trim()
        }

        this.$api.repertoire.worksList(queryParams)
          .then((response) => {
            this.works = get(response.data, "results", [])
            if (!this.works.length) this.showNoResults = true
          })
          .catch((error) => {
            this.error(error.response.data)
          })
      }, 1000),

    onWorksSearchChange (query) {
      this.getPublisherWorks(query)
    },
  },
  watch: {
    errors (data) {
      this.$refs.agreementsCreate.setErrors(data)
    },
  },
  props: {
    errors: Object,
  }
}
</script>
