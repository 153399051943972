<template>
  <div class="mb-4">
    <b-row v-for="comment in comments" :key="comment.id" class="mx-2 mb-3">
      <!-- Badge -->
      <b-col cols="1" xs="4" class="d-flex avatar-column justify-content-center pt-2 px-0">
        <Avatar :username="comment.createdBy" v-if="comment.createdBy" />
      </b-col>

      <!-- Comment -->
      <b-col class="pt-2 mr-2 pl-1">
        <div class="work-details-container">
          <b-row>
            <b-col class="d-flex justify-content-between">
              <!-- Comment metadata -->
              <div>
                <span class="ml-2 font-weight-bold">{{ comment.createdBy }},</span>
                <span class="ml-2">{{ parseDate(comment.created, DATE_FORMAT) }}</span>
                <span class="ml-2" v-if="comment.hasBeenEdited">[{{ $t("edited") | capitalize }}]</span>
              </div>

              <!-- Actions -->
              <div class="d-flex mr-2">
                <a
                  href="#"
                  class="text-info mr-2"
                  @click.prevent="enableEdit(comment)"
                  v-if="comment.canEdit"
                >
                  {{ $t("edit") | capitalize }}
                </a>
                <action
                  :title="$t('delete')"
                  @click="onDelete(comment)"
                  :icon="['fas', 'trash']"
                  color="red"
                  v-if="comment.canDelete"
                />
              </div>
            </b-col>
          </b-row>
          <CommentForm
            :workId="workId"
            @update-comments="getComments({ page: currentPage })"
            :comment="comment"
            :readonly="comment.readOnly"
          />
        </div>
      </b-col>
    </b-row>

    <!-- Add Comment -->
    <b-row class="mx-2" v-if="showAddForm">
      <b-col cols="1" xs="4" class="d-flex avatar-column justify-content-center pt-3 px-0">
        <Avatar :username="user.name" v-if="user && user.name" />
      </b-col>
      <b-col class="pt-2 mr-4 pl-1">
        <CommentForm
          :workId="workId"
          @update-comments="getComments({ page: currentPage })"
        />
      </b-col>
    </b-row>

    <!-- Pagination -->
    <b-row class="mt-3">
      <b-col cols="1" xs="4" class="pt-3 px-0">
      </b-col>
      <b-col md="6">
        <b-pagination
          v-if="totalCount > perPage"
          v-model="currentPage"
          :total-rows="totalCount"
          :per-page="perPage"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- Delete Modal -->
    <modal
      id="comment-delete-modal"
      :title="$t('confirm_delete_comment') + '?' | capitalize"
      :okTitle="$t('yes') | capitalize"
      :cancelTitle="$t('no') | capitalize"
      @ok="onConfirmDelete"
      @hidden="onHidden"
    >
      <p><small>{{ currentComment && truncateComment(currentComment.text) }}</small></p>
    </modal>
  </div>
</template>

<style lang="scss" scoped>
  .avatar-column {
    flex: 0 1 5rem;
  }
</style>

<script>
import { mapActions, mapState } from "vuex"
import Avatar from "vue-avatar"
import CommentForm from "./CommentForm"
import { parseDate } from "@/utils/dates"


const DATE_FORMAT = "MMMM Do YYYY, h:mm:ss a"


export default {
  name: "Comments",
  data: () => {
    return {
      comments: [],
      totalCount: 0,
      currentPage: 1,
      perPage: 10,
      currentComment: null,
      DATE_FORMAT
    }
  },
  components: {
    Avatar,
    CommentForm,
  },
  methods: {
    ...mapActions("alert", ["success", "error"]),
    onConfirmDelete () {
      this.$api.repertoire
        .worksCommentsDelete(this.currentComment.id, this.workId)
        .then(() => {
          this.success(this.$t("comment_deleted"))
          this.getComments({ page: this.currentPage })
        })
    },
    onDelete (comment) {
      this.currentComment = { ...comment }
      this.$bvModal.show("comment-delete-modal")
    },
    onHidden () {
      this.currentComment = null
    },
    getComments (params=null) {
      this.$api.repertoire
        .worksCommentsList(this.workId, params)
        .then((response) => {
          this.comments = [ ...response.data.results ]
          this.comments.forEach(comment => comment.readOnly = true)
          this.totalCount = response.data.count
        })
    },
    truncateComment (comment) {
      let maxLength = 300
      return (comment.length > maxLength) ? comment.substr(0, maxLength-1) + " ..." : comment
    },
    enableEdit (edit) {
      this.comments = this.comments.map(comment => {
        return comment.id === edit.id ? { ...comment, readOnly: false } : comment
      })
    },
    parseDate,
  },
  computed: {
    ...mapState("user", ["user"]),
    showAddForm () {
      if (this.totalCount > this.perPage &&
          this.currentPage !== Math.ceil(this.totalCount/this.perPage)
      ) return false
      return true
    },
  },
  watch: {
    currentPage (value) {
      this.getComments({ page: value })
    },
  },
  async mounted () {
    this.getComments()
  },
  props: {
    workId: [String, Number],
  }
}
</script>