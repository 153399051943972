<template>
  <list-layout
    :title="$t('usage_files')"
    :count="count"
    :show-refresh="true"
    :filters="filters"
  >
    <template v-slot:actions>
      <div class="mb-3">
        <action-button
          v-if="selectedData.length"
          variant="secondary"
          @click="generateReport"
        >
          {{ $t("generate_UP1_report") }}
        </action-button>
      </div>
    </template>

    <!-- Status -->
    <template v-slot:status>
      <div>
        <status
          :text="$t('matched')"
          :name="fileStatuses.MATCHED"
          :count="summary.matched"
          :icon="['fas', 'check-circle']"
          color="green"
          :active="filters.status === fileStatuses.MATCHED"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('ingested')"
          :name="fileStatuses.INGESTED"
          :count="summary.ingested"
          :icon="['fas', 'check-circle']"
          color="green"
          :active="filters.status == fileStatuses.INGESTED"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('landed')"
          :name="fileStatuses.LANDED"
          :count="summary.landed"
          :icon="['fas', 'check-circle']"
          color="green"
          :active="filters.status === fileStatuses.LANDED"
          clickable
          @click="onStatusClick"
        />
      </div>
      <div class="mr-3 align-self-start">
        <status
          :text="$t('processing')"
          :name="fileStatuses.PROCESSING"
          :count="summary.processing"
          :icon="['fas', 'question-circle']"
          color="orange"
          :active="filters.status == fileStatuses.PROCESSING"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('pending')"
          :name="fileStatuses.PENDING"
          :count="summary.pending"
          :icon="['fas', 'question-circle']"
          color="orange"
          :active="filters.status == fileStatuses.PENDING"
          clickable
          @click="onStatusClick"
        />
        <status
          :text="$t('error')"
          :name="fileStatuses.ERROR"
          :count="summary.error"
          :icon="['fas', 'exclamation-circle']"
          color="red"
          :active="filters.status == fileStatuses.ERROR"
          clickable
          @click="onStatusClick"
        />
      </div>
    </template>

    <!-- Search and Filters -->
    <template v-slot:search>
      <!-- Filters -->
      <b-row class="d-flex flex-wrap">
        <b-col cols="3" class="d-flex flex-column">
          <div>
            <multi-select-field
              v-model="filters.type"
              name="usageType"
              :options="usageTypeOptions"
              :label="$t('type')"
              choiceLabel="display"
              track-by="value"
              :showLabels="false"
            />
          </div>
          <distribution-filter
            name="distributionSearch"
            :label="$t('distribution') | capitalize"
            v-model="filters.distribution"
            choiceLabel="name"
            :placeholder="$t('search_and_select') | capitalize"
            />
          <search-input
            :value="filters.fuzzySearch"
            @search="onFileSearch"
            :placeholder="$t('search_by_filename') | capitalize"
            class="form-group mt-auto"
          />
        </b-col>
        <!-- Tag Filters -->
        <b-col class="pl-0">
          <TagFilters @filter="onTagsFilter" :initFilters="initialTagFilters" />
        </b-col>
      </b-row>
    </template>

    <!-- Count -->
    <template v-slot:count>
      <selection-summary
        v-if="selectedData.length || viewingSelection"
        class="mb-2 smaller"
        :selectedItems="selectedData"
        :clear="clearSelection"
        :switchView="toggleSelection"
      >
        <template v-slot:actions>
          <b-button
            v-if="selectedData.length > 0"
            @click="showTagsForm"
            variant="outline-secondary"
            size="sm"
          >
            {{ $t("edit_tags") | capitalize }}
          </b-button>
        </template>
      </selection-summary>
    </template>

    <!-- Table -->
    <template v-slot:table>
      <v-server-table
        :columns="columns"
        :options="options"
        ref="usageFiles"
        @loaded="onLoaded"
        @pagination="onPagination"
      >
        <!-- Select all -->
        <template slot="h__selection" v-if="!viewingSelection">
          <div class="d-flex">
            <b-checkbox
              v-model="options.allSelected"
              @change="selectPage"
            />
          </div>
        </template>

        <!-- Selection -->
        <template v-slot:selection="{ row}">
          <div class="d-flex">
            <b-checkbox
              v-model="selectedData"
              :value="row"
            />
          </div>
        </template>

        <!-- File Name -->
        <template v-slot:filename="{ row }">
          <b-row>
            <b-col>
              <div>{{ row.filename }}</div>
              <div class="text-gray">
                <small>
                  {{ $t("uploaded_by") | capitalize }} "{{ row.uploader.name }}" {{ $t("on") }} {{ parseDate(row.uploadDate) }} {{ $t("at") }} {{ parseTime(row.uploadDate) }}
                </small>
              </div>

              <!-- Tags -->
              <div class="mt-2">
                <tag :text="getTagText(tag)" :icon="getTagIcon(tag)" v-for="(tag, index) in row.tags" :key="`tag_${row.id}_${index}`" />
              </div>
            </b-col>
          </b-row>
        </template>

        <!-- Usage Period -->
        <template v-slot:usagePeriod="{ row }">
          <span class="font-8">
            {{ getUsagePeriodDisplay(row) }}
          </span>
        </template>

        <!-- Type -->
        <template v-slot:type="{}">
        </template>

        <!-- Status -->
        <template v-slot:status="{ row }">
          <status
            v-if="row.status === fileStatuses.PENDING"
            :text="$t('pending')"
            :icon="['fas', 'question-circle']"
            color="orange"
          />
          <status
            v-if="row.status === fileStatuses.PROCESSING"
            :text="$t('processing')"
            :icon="['fas', 'question-circle']"
            color="orange"
          />
          <status
            v-if="row.status === fileStatuses.LANDED"
            :text="$t('landed')"
            :icon="['fas', 'check-circle']"
            color="green"
          />
          <status
            v-if="row.status === fileStatuses.INGESTED "
            :text="$t('ingested')"
            :icon="['fas', 'check-circle']"
            color="green"
          />
          <status
            v-if="row.status === fileStatuses.MATCHED "
            :text="$t('matched')"
            :icon="['fas', 'check-circle']"
            color="green"
          />
          <status
            v-if="row.status === fileStatuses.ERROR"
            :text="$t('error')"
            :icon="['fas', 'exclamation-circle']"
            color="red"
            :active="true"
            @click="showErrors(row, 'file')"
            clickable
          />
          <status
            v-if="row.status === fileStatuses.MARKED_FOR_DELETION"
            :text="$t('marked_for_deletion')"
            :icon="['fas', 'ban']"
            color="red"
          />
        </template>

        <!-- Actions -->
        <template v-slot:actions="{ row }">
          <div class="d-flex flex-row justify-content-end align-items-center">
            <!-- Delete -->
            <b-spinner v-if="deleteInProgress === row.id" variant="danger" small></b-spinner>
            <action
              v-if="canDelete(row)"
              :title="$t('delete')"
              @click="onDelete(row.id)"
              :icon="['fas', 'trash']"
              color="red"
            />
            <modal
              :id="'delete-confirmation-modal-' + row.id"
              :title="$t('delete_usage_file') | capitalize"
              :okTitle="$t('yes') | capitalize"
              :cancelTitle="$t('no') | capitalize"
              @ok="onConfirmDelete(row.id)"
            >
              <div>{{ $t("confirm_delete_usage_file") | capitalize }}?</div>
            </modal>
          </div>
        </template>

        <!-- Child Rows -->
        <template v-slot:child_row="{ row }">
          <UsageFileSheetsTable :items="row.sheets" v-if="row.sheets.length" :key="row.id" @show-errors="showErrors" />
        </template>
      </v-server-table>
    </template>

    <FileErrorsModal :item="selectedItem" :onlyShowGlobalErrors="onlyShowGlobalErrors" :errorsListOptions="errorsListOptions" />
    <EditTagsModal :selectedFiles="selectedData" @refresh="onRefreshTags" />

    <!-- Generate Report Confirmation Modal -->
    <modal
      id="generate-report-confirmation-modal"
      :title="$t('report_generation_confirmation') | capitalize"
      @ok="onConfirmGenerateReport"
    >
      <div>{{ $t("up1_report_confirmation") | capitalize }}: {{ user.email }}</div>
      <div class="mb-3 p-2">
        <div v-for="file in selectedData" :key="file.id" class="font-weight-bold">{{ file.filename }}</div>
      </div>
    </modal>
  </list-layout>
</template>

<style lang="scss" scoped>
  .sheet-icon {
    color: $info;
  }

  .selection-column {
    width: 55px;
  }

  .font-8 {
    font-size: 0.8rem;
  }
</style>

<script>
import { camelCase, capitalize, get } from "lodash"
import { fileStatuses, getTagUseTypesDisplay, getUsageTypeDisplay, usageFileTagTypes, usageTypes } from "@/constants"
import { isDefaultDateRange, parseDate } from "@/utils/dates"
import { listRouteMixin, selectionMixin } from "@/utils/mixins"
import { mapActions, mapState } from "vuex"
import EditTagsModal from "./EditTagsModal"
import { FileErrorsModal } from "@/components"
import TagFilters from "./TagFilters"
import UsageFileSheetsTable from "./UsageFileSheetsTable"


export default {
  name: "UsageFilesList",
  mixins: [listRouteMixin, selectionMixin],
  components: {
    EditTagsModal,
    FileErrorsModal,
    TagFilters,
    UsageFileSheetsTable,
  },
  data () {
    return {
      fileStatuses,
      usageTypeOptions: [
        { value: usageTypes.DURATION, display: capitalize(getUsageTypeDisplay(usageTypes.DURATION)) },
        { value: usageTypes.PLAY_COUNT, display: capitalize(getUsageTypeDisplay(usageTypes.PLAY_COUNT)) },
      ],
      columns: ["selection", "filename", "usagePeriod", "type", "status", "ingestionStats", "matchingStats", "actions"],
      viewingSelection: false,
      onlyShowGlobalErrors: false,
      deleteInProgress: null,
      selectedData: [],
      errorType: "landing_db_ingestion",
      options: {
        skin: "table",
        allSelected: false,
        showChildRowToggler: false,
        headings: {
          selection: "",
          filename: capitalize(this.$t("filename")),
          usagePeriod: capitalize(this.$t("period")),
          type: capitalize(this.$t("type")),
          status: capitalize(this.$t("status")),
          ingestionStats: capitalize(this.$t("ingestion_stats")),
          matchingStats: capitalize(this.$t("matching_stats")),
          actions: "",
        },
        columnsClasses: {
          selection: "selection-column",
          filename: "w-22 text-break",
          usagePeriod: "w-10",
          type: "w-8",
          status: "w-8",
          ingestionStats: "w-15 text-center",
          matchingStats: "w-15 text-center",
        },
        requestKeys: { query: "fuzzy_search" },
        requestFunction (queryParams) {
          let component = this.$parent.$parent.$parent
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))

          if (component.viewingSelection) {
            return component.filterSelectionItems()
          } else {
            return this.$api.usages.usageFilesList(queryParams)
          }
        },
        responseAdapter ({ data }) {
          let component = this.$parent.$parent.$parent
          component.deleteInProgress = null
          const openRows = data.results.map(element => element.id)
          component.$refs.usageFiles.$refs.table.openChildRows = openRows

          return {
            data: data.results,
            count: data.count,
          }
        },
      },
      errorsListOptions: {
        requestFunction (params) {
          const component = this.$parent.$parent.$parent.$parent.$parent.$parent
          const queryParams = { ...params, type: component.errorType }

          return this.$api.usages.usageFileErrorsList(this.url, queryParams)
        }
      },
      selectedItem: {},
      summary: {},
      count: 0,
      filters: {
        status: "",
        type: "",
        licensee: false,
        useType: false,
        priority: false,
        usagePeriod: false,
        fuzzySearch: "",
        distribution: "",
      },
    }
  },
  computed: {
    ...mapState("selection", ["selection"]),
    ...mapState("user", ["user"]),
    initialTagFilters () {
      const {
        licensee,
        useType,
        priority,
        usagePeriod,
      } = this.filters
      return {
        licensee,
        useType,
        priority,
        usagePeriod,
      }
    }
  },
  methods: {
    ...mapActions("alert", ["error", "success"]),
    ...mapActions("selection", ["setSelection"]),
    parseDate (date) {
      return parseDate(date, this.$config.DATE_FORMAT)
    },
    parseTime (date) {
      return parseDate(date, "h:mm")
    },
    canDeleteWithStatus (status) {
      const { PENDING, MATCHED, ERROR } = fileStatuses
      const allowedStatuses = [PENDING, MATCHED, ERROR]
      return allowedStatuses.includes(status)
    },
    canDelete (row) {
      return this.canDeleteWithStatus(row.status) && !(this.deleteInProgress === row.id)
    },
    onLoaded ({ data }) {
      this.count = data.count
      this.status = data.status
      this.summary = { ...data.summary }
      this.recomputeAllSelected()
    },
    onFileSearch (value) {
      this.filters.fuzzySearch = value
    },
    onStatusClick (status) {
      this.filters.status = status
      this.applyFilters("status", status)
    },
    onRefreshTags () {
      this.clearSelection()
      this.getData()
    },
    onTagsFilter (type, value) {
      const camelCaseType = camelCase(type)
      this.filters[camelCaseType] = value
      this.applyFilters(type, value)
    },
    onDelete (id) {
      this.$bvModal.show(`delete-confirmation-modal-${id}`)
    },
    onConfirmDelete (id) {
      this.deleteInProgress = id
      this.$api.usages.usageFilesDelete(id)
        .then(() => {
          this.success(this.$t("usage_file_successfully_marked_for_deletion"))
          this.removeSelected(id)
          this.getData()
        }).catch(err => {
          this.deleteInProgress = null
          let message = err?.response?.data?.message ||  err?.response?.data || err?.response || err
          this.error(message)
        })
    },
    getTagIcon (tag) {
      return {
        [usageFileTagTypes.PRIORITY]: ["fas", "exclamation-triangle"],
        [usageFileTagTypes.USE_TYPE]: ["fas", "list"],
        [usageFileTagTypes.LICENSEE]: ["fas", "broadcast-tower"]
      }[tag.type]
    },
    getTagText (tag) {
      let text = tag.value
      if (tag.type === usageFileTagTypes.USE_TYPE) text = getTagUseTypesDisplay(tag.value)
      return this.truncateTag(text)
    },
    truncateTag (text) {
      const maxLength = 20
      return (text.length > maxLength) ? text.substr(0, maxLength-1) + " ..." : text
    },
    showErrors (item, level, type) {
      this.errorType = type
      this.onlyShowGlobalErrors = ["file", "sheet"].includes(level)
      this.selectedItem = item
      this.$bvModal.show("file-errors-modal")
    },
    showTagsForm () {
      this.$bvModal.show("edit-tags-modal")
    },
    getData () {
      this.$refs.usageFiles.getData()
    },
    getUsagePeriodDisplay (row) {
      let { usagePeriodEnd, usagePeriodStart } = row
      if (isDefaultDateRange(usagePeriodStart, usagePeriodEnd)) return ""
      usagePeriodStart = this.parseDate(usagePeriodStart, this.$config.DATE_FORMAT)
      usagePeriodEnd = this.parseDate(usagePeriodEnd, this.$config.DATE_FORMAT)
      return `${usagePeriodStart} / ${usagePeriodEnd}`
    },
    recomputeAllSelected (newVal=false) {
      let selectedData = newVal || this.selectedData
      let allSelected = this.options.allSelected

      if (this.$refs.usageFiles && this.$refs.usageFiles.data.length){
        allSelected = this.$refs.usageFiles.data.every(d => {
          return selectedData.map(f => f.id).includes(d.id)
        })
      } else {
        allSelected = false
      }
      this.options = { ...this.options, allSelected }
      this.setSelection(selectedData)
    },
    selectPage () {
      if (!this.options.allSelected) {
        this.selectedData.push(
          ...this.$refs.usageFiles.data
            .filter(d => !this.selectedData.map(f => f.id).includes(d.id))
            .map(d => d)
        )
      } else {
        this.$refs.usageFiles.data.forEach(d => this.removeSelected(d.id))
      }
    },
    removeSelected (id) {
      this.selectedData.splice(this.selectedData.map(f => f.id).indexOf(id), 1)
    },
    generateReport () {
      this.$bvModal.show("generate-report-confirmation-modal")
    },
    onConfirmGenerateReport () {
      let usageFileIds = this.selectedData.map(file => file.id)
      this.$api.usages.usageFilesGenerateReport({ usageFileIds })
        .then(() => {
          this.success(this.$t("report_generation_has_successfully_been_initiated"))
        })
        .catch(err => {
          let message = err.response.data?.detail || err.response.data || err.response
          this.error(message)
        })
    }
  },
  watch: {
    "filters.fuzzySearch" (value) {
      this.applyFilters("fuzzy_search", value)
    },
    "filters.type" (type) {
      this.applyFilters("type", type ? type.value : null)
    },
    selectedData (newVal) {
      this.recomputeAllSelected(newVal)
    },
    "filters.distribution" (value) {
      this.applyFilters("distribution", value)
    }
  },
  mounted () {
    this.setFilters()

    const typeQuery = get(this.$route.query, "type", "")
    this.filters.type = typeQuery ? this.usageTypeOptions.find((usageTypeOption) => usageTypeOption.value === typeQuery) : null
  }
}
</script>
