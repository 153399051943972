<template>
  <div class="d-flex flex-wrap">
    <!-- Licensee -->
    <TagSearchSelect
      name="licensee"
      :label="getUsageTagTypeDisplay(LICENSEE)"
      :placeholder="$t('search_and_select') | capitalize"
      :tagType="LICENSEE"
      v-model="licensee"
      choiceLabel="name"
      class="col-sm-3 pl-0 pr-1"
    />

    <!-- Use Type -->
    <multi-select-field
      id="use-type"
      v-model="useType"
      name="useType"
      :options="useTypeOptions"
      :label="$t('use_type')"
      choiceLabel="display"
      track-by="value"
      :showLabels="false"
      class="col-sm-3 pl-0 pr-1"
    />

    <!-- Priority -->
    <multi-select-field
      id="priority"
      v-model="priority"
      :allowEmpty="true"
      name="priority"
      :options="priorityOptions"
      :label="$t('priority')"
      choiceLabel="display"
      track-by="value"
      :showLabels="false"
      class="col-sm-3 pl-0 pr-1"
    />
    
    <!-- Usage Period -->
    <div class="col-sm-3 pl-0 pr-1">
      <label for="usage-period"> {{ $t("usage_period") | capitalize }}:</label>
      <date-picker
        v-model="usagePeriod"
        range
        :format="$config.DATE_FORMAT"
        input-class="form-control"
        :placeholder="$t('select_date_range') | capitalize"
        :input-attr="{id: 'usage-period'}"
        class="w-100 form-group"
      />
    </div>
  </div>
</template>

<style scoped>
  label {
    font-weight: bold;
    font-size: 0.85rem;
  }

  ::v-deep .mx-input-wrapper input {
    font-size: 0.85rem;
  }

  ::v-deep .mx-input-wrapper input::placeholder {
    color: var(--input-gray) !important;
  }
</style>

<script>
import {
  getTagUseTypesDisplay,
  getUsageTagTypeDisplay,
  tagPriorityTypes,
  tagUseTypes,
  usageFileTagTypes
} from "@/constants"
import TagSearchSelect from "./TagSearchSelect"
import { capitalize } from "lodash"
import moment from "moment"
import { parseDate } from "@/utils/dates"

const { LICENSEE } = usageFileTagTypes
const { HIGH, MEDIUM, LOW } = tagPriorityTypes
const { RADIO, KARAOKE, TV, CABLE, CONCERTS, EVENTS, CINEMA, GENERAL_USERS } = tagUseTypes

export default {
  name: "TagFilters",
  components: {
    TagSearchSelect,
  },
  data () {
    return {
      licensee: null,
      useType: null,
      priority: null,
      usagePeriod: [],
      priorityOptions: [
        { value: HIGH, display: capitalize(this.$t("high")) },
        { value: MEDIUM, display: capitalize(this.$t("medium")) },
        { value: LOW, display: capitalize(this.$t("low")) },
      ],
      useTypeOptions: [
        { value: RADIO, display: capitalize(getTagUseTypesDisplay(RADIO)) },
        { value: KARAOKE, display: capitalize(getTagUseTypesDisplay(KARAOKE)) },
        { value: TV, display: capitalize(getTagUseTypesDisplay(TV)) },
        { value: CABLE, display: capitalize(getTagUseTypesDisplay(CABLE)) },
        { value: CONCERTS, display: capitalize(getTagUseTypesDisplay(CONCERTS)) },
        { value: EVENTS, display: capitalize(getTagUseTypesDisplay(EVENTS)) },
        { value: CINEMA, display: capitalize(getTagUseTypesDisplay(CINEMA)) },
        { value: GENERAL_USERS, display: capitalize(getTagUseTypesDisplay(GENERAL_USERS)) },
      ],
      LICENSEE
    }
  },
  methods: {
    getUsageTagTypeDisplay,
    parseDate,
    validateDate (date, format) {
      return moment(date, format, true).isValid()
    },
    setInitialUsagePeriod () {
      let { usagePeriod } = this.initFilters
      let range = usagePeriod.split(",")

      if (range.length !== 2) return []

      let [start, end] = range
      let isStartValid = this.validateDate(start, this.$config.ISO_DATE_FORMAT)
      let isSEndValid = this.validateDate(end, this.$config.ISO_DATE_FORMAT)
      
      return isStartValid && isSEndValid ? [new Date(start), new Date(end)] : []
    },
    setInitialUseType () {
      let { useType } = this.initFilters
      let option = this.useTypeOptions.filter(u => u.value === useType)
      return option.length === 1 ? option[0] : null
    },
    setInitialPriority () {
      let { priority } = this.initFilters
      let option = this.priorityOptions.filter(p => p.value === priority)
      return option.length === 1 ? option[0] : null
    },
    setInitialLicensee () {
      let { licensee } = this.initFilters
      return { value: licensee }
    },
    setInitialFilters () {
      this.usagePeriod = this.initFilters.usagePeriod ? this.setInitialUsagePeriod() : []
      this.useType = this.initFilters.useType ? this.setInitialUseType() : null
      this.priority = this.initFilters.priority ? this.setInitialPriority() : null
      this.licensee = this.initFilters.licensee ? this.setInitialLicensee() : null
    }
  },
  watch: {
    licensee (value) {
      this.$emit("filter", "licensee", value ? value.value : null)
    },
    useType (value) {
      this.$emit("filter", "use_type", value ? value.value : null)
    },
    priority (value) {
      this.$emit("filter", "priority", value ? value.value : null)
    },
    usagePeriod (value) {
      if (value.every(v => v === null)) return this.$emit("filter", "usage_period", null)
      let period = value.map(date => parseDate(date, this.$config.ISO_DATE_FORMAT)).join(",")
      this.$emit("filter", "usage_period", period)
    },
    initFilters: {
      deep: true,
      handler: function () {
        this.setInitialFilters()
      }
    },
  },
  mounted () {
    this.setInitialFilters()
  },
  props: {
    initFilters: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>