<template>
  <div>
    <!-- Title -->
    <slot name="title">
      <h3 class="mb-2">
        {{ title }}
        <b-badge v-if="badgeText" :variant="badgeVariant" class="float-right">{{ badgeText | capitalize }}</b-badge>
      </h3>
    </slot>

    <!-- Sub-Title -->
    <slot name="subtitle">
    </slot>

    <!-- Top -->
    <div class="top-container py-2 mb-4">
      <slot name="top"></slot>
    </div>

    <!-- Content -->
    <div class="content-container">
      <slot name="content"></slot>
    </div>

    <!-- Bottom -->
    <div class="bottom-container">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkDetailLayout",
  props: {
    title: String,
    badgeText: String,
    badgeVariant: String,
  },
}
</script>