<template>
  <b-navbar id="navbar" type="dark" variant="primary" sticky>
    <!-- Logo -->
    <b-navbar-brand id="logo" href="#" :class="logoClass">
      <b-img :src="logo" />
    </b-navbar-brand>

    <b-container fluid>
      <b-navbar-nav class="ml-auto">
        <!-- Society Selector -->
        <society-selector />
        <!-- Language Selector -->
        <language-selector />
        <!-- User Menu -->
        <b-nav-item-dropdown right>
          <template v-slot:button-content v-if="user">
            <fa-icon class="mr-2 text-white" :icon="['far', 'user-circle']"></fa-icon>
            <span class="text-white mr-2">{{ user.name }}</span>
          </template>
          <b-dropdown-item @click="$router.push({ name: 'users-list', params: { id: user.party.id } })">
            <fa-icon class="icon text-gray" :icon="['fas', 'users']" sm/>
            <span class="text-gray">{{ $t("users") | startcase }}</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <fa-icon class="icon text-white clickable my-auto mr-0 pr-0" :icon="['fas', 'power-off']" :title="$t('logout')" @click="logout"/>
      </b-navbar-nav>
    </b-container>
  </b-navbar>
</template>

<style lang="scss" scoped>
  #navbar {
    padding-left: 0;
  }

  #logo {
    width: 250px;
    margin-right: 0;
  }

  .filscap-logo {
    img {
      max-height: 60px;
    }
  }

  .mct-logo {
    img {
      max-height: 50px;
      padding: 6px 10px;
    }
  }

  .wami-logo {
    img {
      max-height: 50px;
      padding: 7px 0 7px 12px;
    }
  }

  .atlas-logo {
    img {
      max-height: 50px;
      padding: 10px;
    }
  }

  .navbar {
    height: 50px;
  }

  .icon {
    width: 35px;
    margin-right: 10px;
  }

  ::v-deep .dropdown-menu {
    border: 1px solid var(--light-gray);
    box-shadow: 0 2px 4px 0 var(--light-gray);
    border-radius: 5px;
    min-width: 3rem;
  }

  ::v-deep .dropdown-item {
    padding: 5px 20px;
    letter-spacing: 0.2px;
  }

  ::v-deep .dropdown-toggle::after {
    color: var(--white);
  }
</style>

<script>
import { mapActions, mapState } from "vuex"
import { get } from "lodash"

export default {
  name: "TopBar",
  computed:{
    ...mapState("user", ["user"]),
    logo () {
      const key = get(this.user.society, "name", "none")

      const map = {
        "wami": "wami_logo.png",
        "filscap": "filscap_logo.png",
        "mct": "mct_logo.png",
      }

      let logo = get(map, key.toLowerCase(), "logo.png")

      return require(`@/assets/${logo}`)
    },
    logoClass () {
      const key = get(this.user.society, "name", "none")

      const map = {
        "wami": "wami-logo",
        "filscap": "filscap-logo",
        "mct": "mct-logo",
      }

      return get(map, key.toLowerCase(), "atlas-logo")
    }
  },
  methods: {
    ...mapActions("user", ["logout"]),
  },
}
</script>
